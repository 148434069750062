import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Typography,
  Button,
  Divider,
  Select,
  Input,
  Icon,
} from "antd";

import SpinBox from "components/SpinBox";
import { BackIcon } from "components/Icons";
import { updateReportInfo } from "redux/report";
import * as API from "utils/api";

import "./FuturePrescription.scss";

const { Title, Text } = Typography;
const { Option } = Select;

const calculationMethodItems = [
  "Average Wholesale Price",
  "Actual Charges",
  "Fee Schedule",
  "Other",
];

class FuturePrescription extends Component {
  constructor(props) {
    super(props);

    let report_id = "";
    if (this.props.location && this.props.location.state) {
      report_id = this.props.location.state.data.report_id;
    }

    this.state = {
      report_id: report_id,
      future_prescription_drug_treatment_calculation_method:
        "Average Wholesale Price",
      loadingTitle: "Loading",
      loading: false,
    };
    this.timeoutId = setInterval(
      this.handleUpdateReport,
      this.props.autoSavingTime
    );
  }
  componentWillUnmount = () => {
    clearInterval(this.timeoutId);
  };
  componentDidMount() {
    const { report_id } = this.state;

    this.setState({ loading: true });
    API.getReportById(report_id).then((res) => {
      let {
        future_prescription_drug_treatment_calculation_method,
      } = res.report;
      let future_prescription_drug_treatment_calculation_method_text = "";
      if (
        !calculationMethodItems.includes(
          future_prescription_drug_treatment_calculation_method
        )
      ) {
        future_prescription_drug_treatment_calculation_method_text = future_prescription_drug_treatment_calculation_method;
        future_prescription_drug_treatment_calculation_method = "Other";
      }
      this.setState({
        reportInfo: res.report,
        future_prescription_drug_treatment_calculation_method_text,
        future_prescription_drug_treatment_calculation_method:
          future_prescription_drug_treatment_calculation_method ||
          "Average Wholesale Price",
        loading: false,
      });
    });
  }

  componentDidUpdate(prevProps) {
    const { reportInfo } = this.props;
    if (JSON.stringify(reportInfo) !== JSON.stringify(prevProps.reportInfo)) {
      const {
        future_prescription_drug_treatment_calculation_method,
      } = reportInfo;
      this.setState({
        future_prescription_drug_treatment_calculation_method:
          future_prescription_drug_treatment_calculation_method ||
          "Average Wholesale Price",
        loading: false,
      });
    }
  }

  handleUpdateReport = (isNextStep = false) => {
    const {
      report_id,
      future_prescription_drug_treatment_calculation_method,
      future_prescription_drug_treatment_calculation_method_text,
    } = this.state;

    let future_prescription_drug_treatment_calculation_method_value = future_prescription_drug_treatment_calculation_method;
    if (future_prescription_drug_treatment_calculation_method === "Other") {
      future_prescription_drug_treatment_calculation_method_value = future_prescription_drug_treatment_calculation_method_text;
    }

    const params = {
      id: report_id,
      future_prescription_drug_treatment_calculation_method: future_prescription_drug_treatment_calculation_method_value,
    };

    if (isNextStep) {
      this.setState({
        loading: true,
        loadingTitle: "Saving...",
      });

      API.updateReport(params).then((res) => {
        this.setState({ loading: false });
        clearTimeout(this.timeoutId);
        this.props.history.push("/app/reports/msa/calculationTable", {
          data: { report_id: report_id },
        });
      });
    } else {
      API.updateReport(params);
    }
  };

  handleSelectChange = (value) => {
    this.setState({
      future_prescription_drug_treatment_calculation_method: value,
      future_prescription_drug_treatment_calculation_method_text: "",
    });
  };

  goToPreviousStep = () => {
    this.props.history.goBack();
  };

  render() {
    const {
      future_prescription_drug_treatment_calculation_method,
      future_prescription_drug_treatment_calculation_method_text,
      loadingTitle,
      loading,
    } = this.state;

    return (
      <Row className="msa-future-prescription-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Setup MSA Report
            </Title>
          </Col>
          <Col className="back" onClick={() => this.props.history.goBack()}>
            <BackIcon className="icon" />
            <Text>Cancel</Text>
          </Col>
          <Col className="step">
            <Text>Step 14 of 17</Text>
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <Text className="title">
                    Future Prescription Drug Treatment Calculation Method
                  </Text>
                </Col>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">Calculation method</Text>
                </Col>
                <Col>
                  <Row>
                    <Select
                      placeholder="Please select"
                      optionFilterProp="children"
                      className="dropdown"
                      value={
                        future_prescription_drug_treatment_calculation_method
                      }
                      onChange={this.handleSelectChange}
                      suffixIcon={
                        <Icon
                          type="caret-down"
                          style={{ color: "black" }}
                          theme="filled"
                        />
                      }
                    >
                      {calculationMethodItems.map((item) => (
                        <Option key={item}>{item}</Option>
                      ))}
                    </Select>
                  </Row>
                </Col>
              </Row>
              {future_prescription_drug_treatment_calculation_method ===
                "Other" && (
                <Row type="flex" className="row">
                  <Col className="label"></Col>
                  <Col>
                    <Row>
                      <Input
                        placeholder=""
                        className="calculation_method-input"
                        value={
                          future_prescription_drug_treatment_calculation_method_text
                        }
                        onChange={(e) =>
                          this.setState({
                            future_prescription_drug_treatment_calculation_method_text:
                              e.target.value,
                          })
                        }
                      />
                    </Row>
                  </Col>
                </Row>
              )}

              <Divider className="divider" />
              <Row
                type="flex"
                justify="center"
                align="middle"
                className="confirm-button"
              >
                <Button
                  type="link"
                  className="prev-button button"
                  onClick={this.goToPreviousStep}
                >
                  &lt; PREVIOUS STEP
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="green-btn button"
                  onClick={() => this.handleUpdateReport(true)}
                >
                  Next Step
                </Button>
              </Row>
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  const props = {
    autoSavingTime: state.report.autoSavingTime,
    reportInfo: state.report.reportInfo,
  };
  return props;
};

export default connect(mapStateToProps, { updateReportInfo })(
  FuturePrescription
);
