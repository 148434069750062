import React, { Component } from "react";
import moment from "moment";
import {
  Row,
  Col,
  Typography,
  Input,
  Empty,
  Badge,
  Modal,
  Button,
  Select,
  Icon,
  DatePicker,
} from "antd";

import InputBox from "components/InputBox";
import SpinBox from "components/SpinBox";
import { BackIcon, FilterIcon, ResetIcon, CloseIcon } from "components/Icons";
import Pagination from "components/Pagination";
import * as API from "utils/api";
import DropdownStatus from "components/DropdownStatus";
import "./Reports.scss";

const { Search } = Input;
const { Title, Text } = Typography;
const { Option } = Select;

export default class Reports extends Component {
  constructor() {
    super();

    this.state = {
      pageIndex: 0,
      reportsData: [],
      totalCount: 0,
      searchQuery: "",
      clientsList: [],
      reportStatuses: [],
      showModal: false,
      filterCount: 0,
      client: undefined,
      newClient: undefined,
      birthDate: undefined,
      newBirthDate: undefined,
      reportType: undefined,
      newReportType: undefined,
      completedDate: undefined,
      newCompletedDate: undefined,
      ssn: "",
      newSsn: "",
      reportStatus: undefined,
      newReportStatus: undefined,
      loading: false,
      reportTypes: [],
    };
  }

  componentDidMount() {
    API.getClientsData("", 0, 1000) //Getting all clients
      .then((res) => {
        this.setState({
          clientsList: res.clients,
        });
      });
    API.getReportTypes().then((res) => {
      this.setState({ reportTypes: res.reportTypes });
    });
    this.getReportStatuses();
    this.handleSearch();
  }

  getReportStatuses = () => {
    API.getReportStatuses().then((res) => {
      this.setState({
        reportStatuses: res.reportStatuses,
      });
    });
  };

  handlePagination = (pageIndex) => {
    this.setState(
      {
        pageIndex: pageIndex,
      },
      () => this.handleSearch()
    );
  };

  handleOpenModal = () => {
    this.setState({
      showModal: true,
      newClient: this.state.client,
      newBirthDate: this.state.birthDate,
      newReportType: this.state.reportType,
      newCompletedDate: this.state.completedDate,
      newSsn: this.state.ssn,
      newReportStatus: this.state.reportStatus,
    });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
    });
  };

  handleApply = () => {
    const {
      newClient,
      newBirthDate,
      newReportType,
      newCompletedDate,
      newSsn,
      newReportStatus,
    } = this.state;
    let count = 0;

    if (newClient) count++;
    if (newBirthDate) count++;
    if (newReportType) count++;
    if (newCompletedDate) count++;
    if (newSsn !== "") count++;
    if (newReportStatus) count++;

    this.setState(
      {
        client: newClient,
        birthDate: newBirthDate,
        reportType: newReportType,
        completedDate: newCompletedDate,
        ssn: newSsn,
        reportStatus: newReportStatus,
        showModal: false,
        count: count,
      },
      this.handleSearch
    );
  };

  handleReset = () => {
    this.setState({
      newClient: undefined,
      newBirthDate: undefined,
      newReportType: undefined,
      newCompletedDate: undefined,
      newSsn: "",
      newReportStatus: undefined,
    });
  };

  handleSetValue = (type, value) => {
    this.setState({
      [type]: value,
    });
  };

  handleSearch = () => {
    const {
      searchQuery,
      client,
      birthDate,
      reportType,
      completedDate,
      ssn,
      reportStatus,
      pageIndex,
    } = this.state;
    const param = {
      client_id: client,
      SSN: ssn,
      dob: birthDate ? moment(birthDate).format("YYYY-MM-DD") : undefined,
      createdAt: completedDate
        ? moment(completedDate).format("YYYY-MM-DD")
        : undefined,
      report_type_id: reportType,
      report_status_id: reportStatus,
    };
    this.setState({ loading: true });
    API.getReportsData(searchQuery, pageIndex, 10, param).then((res) => {
      this.setState({
        reportsData: res.reports,
        totalCount: res.total_count,
        loading: false,
      });
    });
  };

  render() {
    const {
      pageIndex,
      totalCount,
      showModal,
      newClient,
      newBirthDate,
      newReportStatus,
      newReportType,
      newCompletedDate,
      newSsn,
      count,
      reportsData,
      searchQuery,
      clientsList,
      reportStatuses,
      loading,
      reportTypes,
    } = this.state;

    return (
      <Row className="reports-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Reports
            </Title>
          </Col>
          <Col className="back-icon">
            <BackIcon
              className="icon"
              onClick={() => this.props.history.goBack()}
            />
          </Col>
          <Col className="search">
            <Search
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => this.setState({ searchQuery: e.target.value })}
              onSearch={this.handleSearch}
              className="search-box"
            />
          </Col>
          <Col>
            <Badge count={count}>
              <Row className="filter-icon" onClick={this.handleOpenModal}>
                <FilterIcon className="icon" />
              </Row>
            </Badge>
          </Col>
          <Col className="pagination">
            <Pagination
              onChange={this.handlePagination}
              totalCount={totalCount}
              pageIndex={pageIndex}
              pageSize={10}
            />
          </Col>
        </Row>
        <Row type="flex" className="table-header">
          <Col md={5}>Client</Col>
          <Col md={4}>Date of Birth</Col>
          <Col md={4}>SSN</Col>
          <Col md={4}>Date Completed</Col>
          <Col md={4}>Report Type</Col>
          <Col md={3}>Report Status</Col>
        </Row>
        <Row className="table-content">
          <SpinBox loading={loading}>
            {reportsData.length > 0 ? (
              <Row>
                {reportsData.map((item, index) => (
                  <Row key={index} className="record">
                    <Col md={5}>
                      <Text>{item.claimant.client.client_name}</Text>
                    </Col>
                    <Col md={4}>
                      <Text>
                        {moment(item.claimant.dob).format("MMM DD, YYYY")}
                      </Text>
                    </Col>
                    <Col md={4}>
                      <Text>{item.claimant.SSN}</Text>
                    </Col>
                    <Col md={4}>
                      <Text>
                        {moment(item.createdAt).format("MMM DD, YYYY")}
                      </Text>
                    </Col>
                    <Col md={4}>
                      <Text>{item.report_type.name}</Text>
                    </Col>
                    <Col md={3} className="report-status">
                      <DropdownStatus
                        reportStatuses={reportStatuses}
                        data={item}
                      />
                    </Col>
                  </Row>
                ))}
              </Row>
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                className="empty-icon"
              />
            )}
          </SpinBox>
        </Row>
        <Modal
          visible={showModal}
          onCancel={this.handleCloseModal}
          footer={null}
          centered={true}
          closable={false}
          width={650}
          className="filter-modal"
          title={
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              className="filter-header"
            >
              <Col className="filter-reset" onClick={this.handleReset}>
                <ResetIcon />
                <Text>Reset</Text>
              </Col>
              <Col className="filter-title">
                <Title level={4}>Filters</Title>
              </Col>
              <Row
                type="flex"
                justify="end"
                align="middle"
                className="filter-close"
              >
                <CloseIcon className="icon" onClick={this.handleCloseModal} />
              </Row>
            </Row>
          }
        >
          <Row type="flex" align="middle" className="filter-body">
            <Row type="flex" justify="space-between" className="condition-row">
              <Col>
                <Row>
                  <Text>Client</Text>
                </Row>
                <Row>
                  <Select
                    showSearch
                    placeholder="Please select"
                    optionFilterProp="children"
                    className="dropdown"
                    value={newClient}
                    onChange={(value) =>
                      this.handleSetValue("newClient", value)
                    }
                    allowClear={true}
                    suffixIcon={
                      <Icon
                        type="caret-down"
                        style={{ color: "black" }}
                        theme="filled"
                      />
                    }
                  >
                    {clientsList.map((item, index) => (
                      <Option key={index} value={item.id}>
                        {item.client_name}
                      </Option>
                    ))}
                  </Select>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Text>Date of Birth</Text>
                </Row>
                <Row>
                  <DatePicker
                    className="date"
                    format="MM/DD/YYYY"
                    value={newBirthDate}
                    onChange={(date, dateString) =>
                      this.handleSetValue("newBirthDate", date)
                    }
                  />
                </Row>
              </Col>
            </Row>
            <Row type="flex" justify="space-between" className="condition-row">
              <Col>
                <Row>
                  <Text>Date Completed</Text>
                </Row>
                <Row>
                  <DatePicker
                    className="date"
                    format="MM/DD/YYYY"
                    value={newCompletedDate}
                    onChange={(date, dateString) =>
                      this.handleSetValue("newCompletedDate", date)
                    }
                  />
                </Row>
              </Col>
              <Col>
                <Row>
                  <Text> Completed TO</Text>
                </Row>
                <Row>
                  <DatePicker
                    className="date"
                    format="MM/DD/YYYY"
                    value={newCompletedDate}
                    // onChange={(date, dateString) =>
                    //   this.handleSetValue("newCompletedDate", date)
                    // }
                  />
                </Row>
              </Col>{" "}
            </Row>
            {/* <Row type="flex" justify="space-between" className="condition-row">
              <Col>
                <Row>
                  <Text>Report type</Text>
                </Row>
                <Row>
                  <Select
                    showSearch
                    placeholder="Please select"
                    optionFilterProp="children"
                    className="dropdown"
                    value={newReportType}
                    onChange={(value) =>
                      this.handleSetValue("newReportType", value)
                    }
                    allowClear={true}
                    suffixIcon={
                      <Icon
                        type="caret-down"
                        style={{ color: "black" }}
                        theme="filled"
                      />
                    }
                  >
                    {reportTypes.map((item, i) => {
                      return (
                        <Option value={item.id} key={i}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Text>Date Completed</Text>
                </Row>
                <Row>
                  <DatePicker
                    className="date"
                    format="MM/DD/YYYY"
                    value={newCompletedDate}
                    onChange={(date, dateString) =>
                      this.handleSetValue("newCompletedDate", date)
                    }
                  />
                </Row>
              </Col>
            </Row> */}

            <Row type="flex" justify="space-between" className="condition-row">
              <Col>
                {/* <Row>
                  <Text>SSN</Text>
                </Row>
                <Row>
                  <Input className="input ssn" placeholder="Please enter" value={newSsn} onChange={(e) => this.handleSetValue("newSsn", e.target.value)} />
                </Row> */}
                <InputBox
                  label="SSN"
                  value={newSsn}
                  onChange={(value) => this.handleSetValue("newSsn", value)}
                />
              </Col>
              <Col>
                <Row>
                  <Text>Report status</Text>
                </Row>
                <Row>
                  <Select
                    placeholder="Please select"
                    className="dropdown"
                    allowClear={true}
                    value={newReportStatus}
                    onChange={(value) =>
                      this.handleSetValue("newReportStatus", value)
                    }
                    suffixIcon={
                      <Icon
                        type="caret-down"
                        style={{ color: "black" }}
                        theme="filled"
                      />
                    }
                  >
                    {reportStatuses.map((item) => (
                      <Option value={item.id} key={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Row>
              </Col>
            </Row>
            <Row style={{ width: "100%" }}>
              <Col md={24}>
                <Row>
                  <Text style={{ marginLeft: "4%" }}>Report type</Text>
                </Row>
                <Select
                  showSearch
                  placeholder="Please select"
                  optionFilterProp="children"
                  style={{ width: "91%", marginLeft: "4%" }}
                  className="dropdown"
                  value={newReportType}
                  onChange={(value) =>
                    this.handleSetValue("newReportType", value)
                  }
                  allowClear={true}
                  suffixIcon={
                    <Icon
                      type="caret-down"
                      style={{ color: "black" }}
                      theme="filled"
                    />
                  }
                >
                  {reportTypes.map((item, i) => {
                    return (
                      <Option value={item.id} key={i}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>
            <Row>
              <Button
                type="primary"
                size="large"
                className="green-btn button"
                onClick={this.handleApply}
              >
                Apply
              </Button>
            </Row>
          </Row>
        </Modal>
      </Row>
    );
  }
}
