import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Col, Button, Input, Typography } from 'antd';
import { resetPassword } from "../../../redux/auth/actions";
import AuthSideBar from '../../../components/AuthSideBar';
import "./ResetPassword.scss";

const { Title, Text } = Typography;

class ResetPassword extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      newPassword: "",
      confirmPassword: "",
      changeFlag: false,
      error: ""
    };
  }

  componentDidUpdate(prevProps) {
    if (!this.props.isLoading && this.props.isLoading !== prevProps.isLoading) {
      if (this.props.errorMessage === "") {
        this.props.history.push("/confirmReset");
      }
    }
  }

  resetPassword = () => {
    const { newPassword, confirmPassword } = this.state;

    let search = this.props.location.search;
    let params = new URLSearchParams(search);

    const userName = params.get("user_name");
    const confirmCode = params.get("confirmation_code");

    if (!newPassword) {
      this.setState({
        error: "Invalid Password",
        changeFlag: false
      })
      return;
    }

    if (newPassword !== confirmPassword) {
      this.setState({
        error: "Password should be same",
        changeFlag: false
      })
      return;
    }
    this.setState({ changeFlag: false, error: "" });
    this.props.resetPassword(userName, confirmCode, newPassword);    
  }

  render() {
    const { newPassword, confirmPassword, changeFlag, error } = this.state;
    const { isLoading, errorMessage } = this.props;
    
    return (
      <Row type="flex" justify="center" className="resetPassword-container">
        <Col xs={0} xl={8}>
          <AuthSideBar />
        </Col>
        <Col xs={24} xl={16}>
          <Row type="flex" justify="center">
            <Col xs={20} md={20}>
              <Row type="flex" justify="space-around" className="main-container">
                <Col style={{margin: "auto"}}>
                  <Row type="flex" justify="center" align="middle" style={{ flexDirection: "column" }}>
                    <Title level={1} style={{ textAlign: "center" }}>
                      Set New Password
                    </Title>
                    <Text className="label">
                      For more security the password should contain numbers and uppercase letters
                    </Text>
                    <Row type="flex" justify="space-around" className="input-row">
                      <Input
                        size="large"
                        placeholder="New Password"
                        className="password"
                        value={newPassword}
                        type="password"
                        onChange={e => this.setState({ newPassword: e.target.value, changeFlag: true})}
                      />
                      <Input
                        size="large"
                        placeholder="Confirm New Password"
                        className="password"
                        value={confirmPassword}
                        type="password"
                        onChange={e => this.setState({ confirmPassword: e.target.value, changeFlag: true})}
                      />
                    </Row>
                    <Row className="error-text">
                    {
                      (error || errorMessage) && !changeFlag &&
                        <Text type="danger">{error || errorMessage}</Text>                      
                    }
                    </Row>
                    <Row>
                      {
                        isLoading ?
                          <Button type="primary" size="large" className="green-btn button" loading>
                            Loading
                          </Button>
                        :                          
                          <Button type="primary" size="large" onClick={this.resetPassword} className="green-btn button">
                            Change Password
                          </Button>
                      }
                    </Row>
                  </Row>
                </Col>
                <Row className="reset">
                  Back to
                  <Button type="link" href="/login">Sign in</Button>
                </Row>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = state => {
  const props = {
    isLoading: state.auth.isLoading,
    errorMessage: state.auth.errorMessage,
  };
  return props;
}

export default connect(
  mapStateToProps,
  { resetPassword }
)(ResetPassword);
