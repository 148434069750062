import React, { useState } from "react";
import {
  Row,
  Col,
  Typography,
  Button,
  Modal,
  Select,
  Radio,
  Icon,
  Checkbox,
  message,
  Upload,
  InputNumber,
  Input,
  Skeleton,
} from "antd";
import * as API from "utils/api";
import InputBox from "components/InputBox";
import { UploadOutlined } from "@ant-design/icons";
import SpinBox from "components/SpinBox";
import AssignmentFiles from "components/AssigmentFile/Index";
const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

export default function RatedAgesById({
  isOpen,
  onConfirm,
  onCancel,
  onChangeName,
  EditFile,
  onChange,
  onFileSubmit,
  handleDataChanges,
  editRatedData,
  handleonSubmit,
  loading,
  claimant_id,
  saveEditRatedAges,
}) {
  // const [loading, setLoading] = useState(true);

  // const onSubmit = () => {
  //   const id = editRatedData.id;
  //   const data = {
  //     note: editRatedData.note,
  //   };
  //   API.UpdateCreatedAssignement(id, data).then((res) => {
  //     console.log(res, `update`);
  //   });
  // };
  return (
    <div>
      <Modal
        visible={isOpen}
        onCancel={onCancel}
        footer={null}
        centered={true}
        closable={true}
        width={550}
        className="report-modal"
        title={"Update File"}
      >
        <SpinBox loading={loading}>
          <Row type="flex" align="middle" className="report-body">
            <Col md={24} className="row">
              <Row style={{ marginTop: 5, paddingBottom: 5 }}>
                {" "}
                <Text> Note</Text>
              </Row>
              <TextArea
                placeholder="Note"
                value={editRatedData.note}
                onChange={handleDataChanges}
              />
            </Col>
          </Row>
          <Row type="flex" align="middle" className="report-body">
            <Col md={24} className="row">
              <Row style={{ marginTop: 5, paddingBottom: 5 }}>
                {" "}
                <Text> Files</Text>
              </Row>
              <Row style={{ marginTop: 5, paddingBottom: 5 }}>
                <AssignmentFiles
                  assignment_id={editRatedData.id}
                  claimant_id={claimant_id}
                />
              </Row>
            </Col>
          </Row>
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="primary"
              size="default"
              className="green-btn button"
              onClick={() => {
                saveEditRatedAges();
              }}
            >
              Save
            </Button>
          </Row>
        </SpinBox>
      </Modal>
    </div>
  );
}
