import React, { Component } from "react";
import { Row, Col, Typography, Button, Divider } from "antd";

import SpinBox from "components/SpinBox";
import { BackIcon } from "components/Icons";
import * as API from "utils/api";
import { Auth } from "aws-amplify";
import "./ContactInfo.scss";

const { Title, Text } = Typography;

export default class ContactInfo extends Component {
  constructor(props) {
    super(props);

    let report_id = "";
    if (this.props.location && this.props.location.state) {
      report_id = this.props.location.state.data.report_id;
    }

    this.state = {
      report_id: report_id,
      reportInfo: {},
      msa_calculation_method: "Fee Schedule",
      loading: false,
      user: {},
    };
  }

  componentDidMount() {
    const { report_id } = this.state;
    Auth.currentUserInfo().then((res) => {
      console.log(res.attributes);
      this.setState({ user: res.attributes });
    });
    this.setState({ loading: true });
    API.getReportById(report_id).then((res) => {
      const { msa_calculation_method } = res.report;
      this.setState({
        reportInfo: res.report,
        msa_calculation_method: msa_calculation_method || "Fee Schedule",
        loading: false,
      });
    });
  }

  handleUpdateReport = () => {
    const { report_id, msa_calculation_method } = this.state;

    const params = {
      id: report_id,
      msa_calculation_method,
    };

    API.updateReport(params).then((res) => {
      this.props.history.push("/app/reports/msa/documentUpload", {
        data: { report_id: report_id },
      });
    });
  };

  goToPreviousStep = () => {
    this.props.history.goBack();
  };

  render() {
    const { reportInfo, loading } = this.state;

    return (
      <Row className="msa-contact-info-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Setup MSA Report
            </Title>
          </Col>
          <Col className="back" onClick={() => this.props.history.goBack()}>
            <BackIcon className="icon" />
            <Text>Cancel</Text>
          </Col>
          <Col className="step">
            <Text>Step 16 of 17</Text>
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading}>
            <Row className="content">
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <Text className="title">Contact Information</Text>
                </Col>
              </Row>
              <Row className="row">
                <Col>
                  <p>
                    If you have any questions or require additional information,
                    please contact me at:
                  </p>
                </Col>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">Signature</Text>
                </Col>
                <Col className="input-col">
                  <Text className="text">{reportInfo.signature}</Text>
                </Col>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">Date</Text>
                </Col>
                <Col className="input-col">
                  <Text className="text">{reportInfo.date_of_report}</Text>
                </Col>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">Phone</Text>
                </Col>
                <Col className="input-col">
                  <Text className="text">
                    {this.state.user.locale ? this.state.user.locale : "-"}
                  </Text>
                </Col>
              </Row>
              {/* <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">Fax</Text>
                </Col>
                <Col className="input-col">
                  <Text className="text">
                    {reportInfo.claimant && reportInfo.claimant.fax}
                  </Text>
                </Col>
              </Row> */}
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">Email</Text>
                </Col>
                <Col className="input-col">
                  <Text className="text">{this.state.user.email}</Text>
                </Col>
              </Row>
              <Divider className="divider" />
              <Row
                type="flex"
                justify="center"
                align="middle"
                className="confirm-button"
              >
                <Button
                  type="link"
                  className="prev-button button"
                  onClick={this.goToPreviousStep}
                >
                  &lt; PREVIOUS STEP
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="green-btn button"
                  onClick={this.handleUpdateReport}
                >
                  Next Step
                </Button>
              </Row>
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}
