import React, { Component } from "react";
import { Row, Col, Typography, Button, Checkbox, Modal } from "antd";
import InputMask from "react-input-mask";

import InputBox from "components/InputBox";
import { BackIcon } from "components/Icons";
import SpinBox from "components/SpinBox";
import * as API from "utils/api";
import UserRole from "components/UserRole/Index";
import "./AddUser.scss";

const { Title, Text } = Typography;

export default class AddUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      given_name: "",
      family_name: "",
      email: "",
      phone: "",
      restriction_templates: false,
      restriction_users: false,
      restriction_contacts: false,
      loadingTitle: "Loading...",
      loading: false,
      roleValue: [],
    };
  }

  handleSetValue = (type, value) => {
    this.setState({
      [type]: value,
    });
  };

  handleCreateUser = () => {
    const {
      given_name,
      family_name,
      email,
      phone,
      restriction_templates,
      restriction_contacts,
      restriction_users,
    } = this.state;

    let restrictions = "";
    if (restriction_templates) restrictions += "templates,";
    if (restriction_contacts) restrictions += "contacts,";
    if (restriction_users) restrictions += "users,";

    const param = {
      email,
      given_name,
      family_name,
      locale: phone,
      restrictions: restrictions,
    };

    this.setState({ loadingTitle: "Saving...", loading: true });
    API.createUser(param)
      .then((res) => {
        // const data = {
        //   user_id: res.data.User.Username,
        //   role_id: this.state.roleValue.map((id) => id),
        //   role_id: this.state.roleValue,
        // };
        let rolesToAppend = this.state.roleValue.map((role_id) => {
          return { user_id: res.data.User.Username, role_id };
        });

        API.synchroniseUser({ ...param, id: res.data.User.Username }).then(
          (res) => {
            API.AppendRoles(rolesToAppend);
            this.setState({ loading: false });
            Modal.success({
              content: "Success",
              onOk: () => this.props.history.goBack(),
            });
          }
        );
      })
      .catch((error) => {
        this.setState({ loading: false });
        const errorMessage = error.response.data.reason
          ? error.response.data.reason[0].message
          : "Error";
        Modal.error({
          content: errorMessage,
        });
      });
  };

  render() {
    const {
      given_name,
      family_name,
      email,
      phone,
      restriction_templates,
      restriction_contacts,
      restriction_users,
      loadingTitle,
      loading,
      roleValue,
    } = this.state;
    return (
      <Row className="add-user-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Add New User
            </Title>
          </Col>
          <Col className="back" onClick={() => this.props.history.goBack()}>
            <BackIcon className="icon" />
            <Text>Cancel</Text>
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="main-info"
              >
                <Col md={11} className="row">
                  <InputBox
                    label="First Name"
                    value={given_name}
                    onChange={(value) => this.setState({ given_name: value })}
                  />
                </Col>
                <Col md={11} className="row">
                  <InputBox
                    label="Last Name"
                    value={family_name}
                    onChange={(value) => this.setState({ family_name: value })}
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="main-info"
              >
                <Col md={11} className="row">
                  <InputBox
                    label="Email"
                    value={email}
                    onChange={(value) => this.setState({ email: value })}
                  />
                </Col>
                <Col md={11} className="row">
                  <Row className="label">
                    <Text>Phone</Text>
                  </Row>
                  <Row>
                    <InputMask
                      mask="999-99-9999"
                      placeholder="Phone"
                      className="input-ssn"
                      value={phone}
                      onChange={(e) => this.setState({ phone: e.target.value })}
                    />
                  </Row>
                </Col>
              </Row>
              <Row>
                <Row className="row" style={{ marginBottom: "10px" }}>
                  <Text className="checkbox-label">Roles</Text>
                </Row>
                <Row>
                  <Col md={11}>
                    <UserRole
                      roleValue={roleValue}
                      OnRoleChange={(value) => {
                        this.setState({ roleValue: value });
                        console.log(value);
                      }}
                    />
                  </Col>
                </Row>
                {/* <Row className="checkbox-item">
                  <Checkbox
                    onChange={(e) =>
                      this.setState({ restriction_templates: e.target.checked })
                    }
                    checked={restriction_templates}
                  >
                    Accessing templates
                  </Checkbox>
                </Row>
                <Row className="checkbox-item">
                  <Checkbox
                    onChange={(e) =>
                      this.setState({ restriction_contacts: e.target.checked })
                    }
                    checked={restriction_contacts}
                  >
                    Editing and adding contacts
                  </Checkbox>
                </Row>
                <Row className="checkbox-item">
                  <Checkbox
                    onChange={(e) =>
                      this.setState({ restriction_users: e.target.checked })
                    }
                    checked={restriction_users}
                  >
                    Editing users
                  </Checkbox>
                </Row> */}
              </Row>
              <Row type="flex" justify="center" className="confirm-button">
                <Button
                  type="primary"
                  size="large"
                  className="green-btn button"
                  onClick={this.handleCreateUser}
                >
                  Create
                </Button>
              </Row>
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}
