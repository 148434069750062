import React, { Component } from "react";
import { Spin } from "antd";

import "./SpinBox.scss";

export default class SpinBox extends Component {
  render() {
    const { loading, children, size } = this.props;
    let { title } = this.props;
    if (!title) {
      title = "Loading...";
    }

    if (loading) {
      return (
        <Spin size={size} tip={title}>
          {children}
        </Spin>
      );
    } else return <>{children}</>;
  }
}
