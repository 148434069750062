import React, { Component } from "react";
import { Row } from "antd";
import ContentEditable from "react-contenteditable";
import * as API from "utils/api";

import { Upload, Typography, message } from "antd";
import {
  UserOutlined,
  SolutionOutlined,
  LoadingOutlined,
  CreditCardOutlined,
  PlusOutlined,
} from "@ant-design/icons";
const { Text } = Typography;

export default class UploadLogo extends Component {
  state = { loading: false };
  upload = async (file) => {
    this.setState({ loading: true });
    const data = await API.getPresignedLogo({
      key: file.name,
      type: file.type,
    });
    console.log(data);

    const formData = new FormData();
    // formData.append("Content-Type", file.type);

    Object.entries(data.data.url.data.fields).forEach(([k, v]) => {
      formData.append(k, v);
    });
    formData.append("file", file);

    await fetch(data.data.url.data.url, {
      method: "POST",
      body: formData,
    });
    let url = data.data.url.data.url + "/" + data.data.url.data.fields["key"];
    this.props.changeImage(url);

    this.setState({ loading: false });
  };
  render() {
    let imageUrl = "";
    const { label, value, disabled, rows, placeholder, onChange } = this.props;
    const beforeUpload = (file) => {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
        return false;
      }
      this.upload(file);
      return false;
      return isJpgOrPng && isLt2M;
    };
    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <Row className="tags-input-box-container">
        <label>Upload your logo</label>
        <div className="upload_logo_wrapper">
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            beforeUpload={beforeUpload}
          // onChange={handleChange}
          >
            {this.props.client_logo ? (
              <img
                src={this.props.client_logo}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
                uploadButton
              )}
          </Upload>
        </div>
      </Row>
    );
  }
}
