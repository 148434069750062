import React, { Component } from "react";
import { Row, Col, Typography, Button, Select, Icon, Modal } from "antd";
import InputMask from "react-input-mask";
import InputBox from "components/InputBox";
import { BackIcon } from "components/Icons";
import SpinBox from "components/SpinBox";
import * as API from "utils/api";
import * as Utils from "utils/utils";
import Address from "components/Address";
import "./EditContact.scss";
import dynamicData from "dynamicData";

const { Title, Text } = Typography;
const { Option } = Select;

export default class EditContact extends Component {
  constructor(props) {
    super(props);

    let contactInfo = {};
    if (this.props.location && this.props.location.state) {
      contactInfo = this.props.location.state.data;
    }

    this.state = {
      contact_id: contactInfo.id,
      contact_name: contactInfo.contact_name,
      organisation_name: contactInfo.organisation_name,
      address: contactInfo.address,
      phone_number: contactInfo.phone_number,
      fax_number: contactInfo.fax_number,
      email: contactInfo.email,
      contact_type: contactInfo.contact_type_id,
      loading: false,
      loadingTitle: "Loading...",
      contact_list: [],
    };
  }

  handleSetValue = (type, value) => {
    this.setState({
      [type]: value,
    });
  };
  getAddress = (address) => {
    this.setState({ address });
  };

  handleEditContact = () => {
    const {
      contact_id,
      contact_name,
      organisation_name,
      address,
      phone_number,
      fax_number,
      email,
      contact_type,
    } = this.state;

    const param = {
      contact_name,
      organisation_name,
      address,
      phone_number,
      fax_number,
      email,
      contact_type_id: contact_type,
    };

    if (!contact_name) {
      Modal.error({ content: "Please input the Contact Name" });
      return;
    }

    if (!organisation_name) {
      Modal.error({ content: "Please input the Organization Name" });
      return;
    }

    if (!phone_number || phone_number.includes("_")) {
      Modal.error({ content: "Please input the Phone number" });
      return;
    }

    if (fax_number != "" && fax_number.includes("_")) {
      Modal.error({ content: "Please input the Fax Number" });
      return;
    }

    if (!contact_type) {
      Modal.error({ content: "Please select a contact type" });
      return;
    }
    if (
      contact_type == dynamicData().plaintiff_attorney_id ||
      contact_type == dynamicData().defense_attorney_id
    ) {
      if (!email || !Utils.validateEmail(email)) {
        Modal.error({ content: "Please input the Valid Email" });
        return;
      }
    }

    this.setState({ loadingTitle: "Saving...", loading: true });
    API.updateContact(contact_id, param)
      .then((res) => {
        this.setState({ loading: false });
        Modal.success({
          content: "Success",
          onOk: () => this.props.history.goBack(),
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        const errorMessage = error.response.data.reason
          ? error.response.data.reason[0].message
          : "Error";
        Modal.error({
          content: errorMessage,
        });
      });
  };
  getContactTypes = () => {
    API.getContactTypes().then((res) => {
      this.setState({ contact_list: res.contact_type });
    });
  };
  componentDidMount = () => {
    this.getContactTypes();
  };
  render() {
    const {
      contact_name,
      organisation_name,
      address,
      phone_number,
      fax_number,
      email,
      contact_type,
      loadingTitle,
      loading,
      contact_list,
    } = this.state;
    return (
      <Row className="add-user-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Edit Contact
            </Title>
          </Col>
          <Col className="back" onClick={() => this.props.history.goBack()}>
            <BackIcon className="icon" />
            <Text>Cancel</Text>
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="main-info"
              >
                <Col md={11} className="row">
                  <InputBox
                    label="Contact Name"
                    value={contact_name}
                    onChange={(value) => this.setState({ contact_name: value })}
                  />
                </Col>
                <Col md={11} className="row">
                  <InputBox
                    label="Organization Name"
                    value={organisation_name}
                    onChange={(value) =>
                      this.setState({ organisation_name: value })
                    }
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="main-info"
              >
                <Col md={24} className="row">
                  <Address
                    getAddress={this.getAddress}
                    address={this.state.address}
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="main-info"
              >
                <Col md={11} className="row">
                  <Row className="label">
                    <Text>Phone</Text>
                  </Row>
                  <InputMask
                    mask="999-999-9999"
                    placeholder="Phone"
                    className="input-ssn"
                    value={phone_number}
                    onChange={(e) =>
                      this.setState({ phone_number: e.target.value })
                    }
                  />
                </Col>
                <Col md={11} className="row">
                  <Row className="label">
                    <Text>Fax Number</Text>
                  </Row>
                  <InputMask
                    mask="999-999-9999"
                    placeholder="Fax Number"
                    className="input-ssn"
                    value={fax_number}
                    onChange={(e) =>
                      this.setState({ fax_number: e.target.value })
                    }
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="main-info"
              >
                <Col md={11} className="row">
                  <InputBox
                    placeholder={
                      contact_type == dynamicData().plaintiff_attorney_id ||
                      contact_type == dynamicData().defense_attorney_id
                        ? "Required"
                        : " "
                    }
                    label="Email"
                    value={email}
                    onChange={(value) => this.setState({ email: value })}
                  />
                </Col>
                <Col md={11} className="row">
                  <Row>
                    <Text>Contact Type</Text>
                  </Row>
                  <Row>
                    <Select
                      showSearch
                      placeholder="Please select"
                      optionFilterProp="children"
                      className="dropdown"
                      value={contact_type}
                      onChange={(value) =>
                        this.handleSetValue("contact_type", value)
                      }
                      suffixIcon={
                        <Icon
                          type="caret-down"
                          style={{ color: "black" }}
                          theme="filled"
                        />
                      }
                    >
                      {contact_list.map((el, i) => {
                        return (
                          <Option key={i} value={el.id}>
                            {el.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Row>
                </Col>
              </Row>
              <Row type="flex" justify="center" className="confirm-button">
                <Button
                  type="primary"
                  size="large"
                  className="green-btn button"
                  onClick={this.handleEditContact}
                >
                  Save Changes
                </Button>
              </Row>
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}
