import React, { Component } from "react";
import {
  Row,
  Col,
  Typography,
  Button,
  Select,
  Icon,
  DatePicker,
  Modal,
} from "antd";
import InputMask from "react-input-mask";

import InputBox from "components/InputBox";
import { BackIcon } from "components/Icons";
import SpinBox from "components/SpinBox";
import * as API from "utils/api";
import Address from "components/Address";

import "./AddClaimant.scss";
import moment from "moment";

const { Title, Text } = Typography;
const { Option } = Select;

export default class AddClaimant extends Component {
  constructor(props) {
    super(props);

    let client_id = "";
    if (this.props.location && this.props.location.state) {
      client_id = this.props.location.state.data;
    }

    this.state = {
      client_id: client_id,
      claimant_name: "",
      claimant_middle_name: "",
      claimant_last_name: "",
      claimant_title: "",
      claimant_email: "",
      claimant_phone: "",
      dob: moment(),
      rated_age: "",
      SSN: "",
      HICN: "",
      Gender: "Male",
      race_id: undefined,
      address: "",
      additional_addres: "",
      loadingTitle: "Loading...",
      loading: false,
    };
  }

  handleSetValue = (type, value) => {
    this.setState({
      [type]: value,
    });
  };

  handleCreateClaimant = () => {
    const {
      client_id,
      claimant_name,
      claimant_middle_name,
      claimant_last_name,
      claimant_title,
      claimant_email,
      claimant_phone,
      dob,
      rated_age,
      SSN,
      HICN,
      Gender,
      race_id,
      address,
      additional_addres,
    } = this.state;

    const param = {
      client_id,
      claimant_name,
      claimant_middle_name,
      claimant_last_name,
      claimant_title,
      claimant_email,
      claimant_phone,
      dob,
      rated_age,
      SSN,
      HICN,
      Gender,
      address,
      additional_addres,
      race_id: race_id ? race_id : null,
    };
    this.setState({ loadingTitle: "Saving...", loading: true });
    var validationSuccess = this.validateRequired();
    if (validationSuccess) {
      API.createClaimant(param)
        .then((res) => {
          this.setState({ loading: false });
          Modal.success({
            content: "Success",
            onOk: () => this.props.history.goBack(),
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          const errorMessage = error.response.data.reason
            ? error.response.data.reason[0].message
            : "Error";
          Modal.error({
            content: errorMessage,
          });
        });
    } else {
      this.setState({ loading: false });
    }
  };
  getAddress = (address) => {
    this.setState({ address });
  };
  validateRequired = () => {
    const {
      claimant_name,
      claimant_last_name,
      claimant_title,
      dob,
      SSN,
      claimant_phone,
    } = this.state;
    if (!claimant_title) {
      Modal.error({
        content: "Title must not be empty",
      });
      return false;
    } else if (!claimant_name) {
      Modal.error({
        content: "First name must not be empty",
      });
      return false;
    } else if (!claimant_last_name) {
      Modal.error({
        content: "Last name must not be empty",
      });
      return false;
    } else if (dob == moment()) {
      Modal.error({
        content: "Please enter correct date of birth",
      });
      return false;
    } else if (!SSN || SSN.includes("_")) {
      Modal.error({
        content: "Please enter SSN correctly",
      });
      return false;
    } else if (!claimant_phone || claimant_phone.includes("_")) {
      Modal.error({
        content: "Please enter Phone Number correctly",
      });
      return false;
    }
    return true;
  };

  render() {
    const {
      claimant_name,
      claimant_middle_name,
      claimant_last_name,
      claimant_title,
      claimant_email,
      claimant_phone,
      dob,
      rated_age,
      SSN,
      HICN,
      Gender,
      address,
      additional_addres,
      loadingTitle,
      loading,
    } = this.state;
    return (
      <Row className="add-claimant-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Add New Claimant
            </Title>
          </Col>
          <Col className="back" onClick={() => this.props.history.goBack()}>
            <BackIcon className="icon" />
            <Text>Cancel</Text>
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="main-info"
              >
                <Col md={24} className="row">
                  <InputBox
                    label="Title"
                    value={claimant_title}
                    onChange={(value) =>
                      this.setState({ claimant_title: value })
                    }
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="main-info"
              >
                <Col md={7} className="row">
                  <InputBox
                    label="First Name"
                    value={claimant_name}
                    onChange={(value) =>
                      this.setState({ claimant_name: value })
                    }
                  />
                </Col>
                <Col md={7} className="row">
                  <InputBox
                    label="Middle Name"
                    placeholder=" "
                    value={claimant_middle_name}
                    onChange={(value) =>
                      this.setState({ claimant_middle_name: value })
                    }
                  />
                </Col>
                <Col md={7} className="row">
                  <InputBox
                    label="Last Name"
                    value={claimant_last_name}
                    onChange={(value) =>
                      this.setState({ claimant_last_name: value })
                    }
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="main-info"
              >
                <Col md={11} className="row">
                  <Row className="label">
                    <Text>Date of Birth</Text>
                  </Row>
                  <Row>
                    <DatePicker
                      className="date"
                      format="MM/DD/YYYY"
                      allowClear={false}
                      value={dob}
                      onChange={(date, dateString) =>
                        this.handleSetValue("dob", date)
                      }
                    />
                  </Row>
                </Col>
                <Col md={11} className="row">
                  <InputBox
                    placeholder={" "}
                    label="Rated Age"
                    value={rated_age}
                    onChange={(value) => this.setState({ rated_age: value })}
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="main-info"
              >
                <Col md={11} className="row">
                  <Row className="label">
                    <Text>SSN</Text>
                  </Row>
                  <Row>
                    <InputMask
                      mask="999-99-9999"
                      placeholder="Required"
                      className="input-ssn"
                      value={SSN}
                      onChange={(e) => this.setState({ SSN: e.target.value })}
                    />
                  </Row>
                </Col>
                <Col md={11} className="row">
                  <InputBox
                    label="HICN"
                    placeholder=" "
                    value={HICN}
                    onChange={(value) => this.setState({ HICN: value })}
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="main-info"
              >
                <Col md={11} className="row">
                  <Row className="label">
                    <Text>Gender</Text>
                  </Row>
                  <Row>
                    <Select
                      showSearch
                      placeholder="Please select"
                      optionFilterProp="children"
                      className="dropdown"
                      value={Gender}
                      onChange={(value) => this.handleSetValue("Gender", value)}
                      suffixIcon={
                        <Icon
                          type="caret-down"
                          style={{ color: "black" }}
                          theme="filled"
                        />
                      }
                    >
                      <Option value={"Male"}>Male</Option>
                      <Option value={"Female"}>Female</Option>
                    </Select>
                  </Row>
                </Col>
                {/* <Col md={11} className="row">
                  <Row className="label">
                    <Text>Race</Text>
                  </Row>
                  <Row>
                    <Select
                      allowClear
                      showSearch
                      placeholder="Please select"
                      optionFilterProp="children"
                      className="dropdown"
                      value={race_id}
                      onChange={(value)=>this.handleSetValue("race_id", value)}
                      suffixIcon={
                        <Icon type="caret-down" style={{color: "black"}} theme="filled" />
                      }
                    >
                      <Option value={1}>Black</Option>
                      <Option value={2}>White</Option>
                      <Option value={3}>Hispanic</Option>
                    </Select>
                  </Row>
                </Col> */}
              </Row>
              <Row>
                <Address
                  getAddress={this.getAddress}
                  address={this.state.address}
                />
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="main-info"
              >
                <Col md={11} className="row">
                  <InputBox
                    label="Email"
                    placeholder=" "
                    value={claimant_email}
                    onChange={(value) =>
                      this.setState({ claimant_email: value })
                    }
                  />
                </Col>
                <Col md={11} className="row">
                  <Row className="label">
                    <Text>Phone</Text>
                  </Row>
                  <InputMask
                    mask="999-999-9999"
                    className="input-ssn"
                    placeholder="Required"
                    value={claimant_phone}
                    onChange={(e) =>
                      this.setState({ claimant_phone: e.target.value })
                    }
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center" className="confirm-button">
                <Button
                  type="primary"
                  size="large"
                  className="green-btn button"
                  onClick={this.handleCreateClaimant}
                >
                  Create
                </Button>
              </Row>
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}
