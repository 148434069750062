import React, { Component } from "react";
import { Row, Col, Typography, Empty, Tooltip } from "antd";
import { connect } from "react-redux";
import { formatCurrency } from "utils/utils";
import {
  EditIcon,
  RemoveIcon,
  CloneIcon,
  UpIcon,
  DownIcon,
} from "components/Icons";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  UpCircleOutlined,
  DownCircleOutlined,
  UpOutlined,
  DownOutlined,
  ArrowUpOutlined,
} from "@ant-design/icons";
import "./PrescriptionTable.scss";
import getInflation from "../../utils/inflation";
const { Text } = Typography;

class PrescriptionTable extends Component {
  render() {
    const {
      data,
      handleEdit,
      handleClone,
      handleDelete,
      handleSeed,
      handleChangePosition,
    } = this.props;
    console.log(this.props, "PROPS");
    return (
      <Row className="msa-prescription-table-container">
        <Row type="flex" align="middle" className="table-header">
          <Col md={2} className="border-right center">
            Prescription
          </Col>
          <Col md={3}>
            <Row className="group">
              <Col md={12} className="border-right center">
                NDC
              </Col>
              <Col md={12} className="border-right center">
                Unit Form
              </Col>
            </Row>
          </Col>
          <Col md={2} className="border-right center">
            Prescribed Strength
          </Col>
          <Col md={3} className="border-right">
            <Row className="sub-group-header">
              <Col md={24}>Prescribed Frequency</Col>
            </Row>
            <Row type="flex" align="middle" className="sub-header border-top">
              <Col md={8} className="border-right center">
                Units Needed
              </Col>
              <Col md={8} className="border-right center">
                Every
              </Col>
              <Col md={8} className="center">
                'X' Years
              </Col>
            </Row>
          </Col>
          <Col className="border-right comment">
            <Row className="sub-group-header">
              <Col md={24}>Pricing Policy: Average Wholesale Price(AWP)</Col>
            </Row>
            <Row type="flex" align="middle" className="sub-header border-top">
              <Col md={3} className="border-right center">
                Price Per Unit
              </Col>
              <Col md={3} className="border-right center">
                Annual Cost
              </Col>
              <Col md={3} className="border-right center">
                Total Years
              </Col>
              <Col md={3} className="border-right center">
                Total Cost
              </Col>
              {this.props.report.has_inflation && (
                <Col md={3} className="border-right center">
                  Inflation Total
                </Col>
              )}
              <Col className="center comment">Comments</Col>
            </Row>
          </Col>
          {!this.props.isMcp && (
            <Col md={2} className="border-right center">
              Used in seed money calculation
            </Col>
          )}

          <Col className="action"></Col>
        </Row>
        <Row className="table-content">
          {data.length > 0 ? (
            <Row>
              {data
                .sort((a, b) => {
                  return a.position - b.position;
                })
                .map((item, index) => (
                  <Row
                    key={index}
                    className="record"
                    id={"table-item-" + item.id}
                  >
                    <Col md={2} className="text-left">
                      <Text>{item.prescriptions}</Text>
                    </Col>
                    <Col md={3}>
                      <Row>
                        <Col md={12}>
                          <Text>{item.ndc}</Text>
                        </Col>
                        <Col md={12}>
                          <Text>{item.unit_form && item.unit_form.name}</Text>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={2}>
                      <Text>{item.prescription_strength}</Text>
                    </Col>
                    <Col md={3}>
                      <Row type="flex" align="middle">
                        <Col md={8}>
                          <Text>{item.units_needed}</Text>
                        </Col>
                        <Col md={8}>
                          <Text>{"Every"}</Text>
                        </Col>
                        <Col md={8}>
                          <Text>{item.every_x_year}</Text>
                        </Col>
                      </Row>
                    </Col>
                    <Col className="comment">
                      <Row className="AWP-container">
                        <Col md={3}>
                          <Text>{formatCurrency(item.per_unit_cost)}</Text>
                        </Col>
                        <Col md={3}>
                          <Text>{formatCurrency(item.annual_cost)}</Text>
                        </Col>
                        <Col md={3}>
                          <Text>{item.total_years}</Text>
                        </Col>
                        <Col md={3}>
                          <Text>{formatCurrency(item.total_cost)}</Text>
                        </Col>
                        {this.props.report.has_inflation && (
                          <Col md={3}>
                            <Text>
                              {formatCurrency(
                                getInflation(
                                  {
                                    interest_rate: this.props.report
                                      .interest_rate,
                                  },
                                  item.total_cost,
                                  item.total_years
                                )
                              )}
                            </Text>
                          </Col>
                        )}
                        <Col className="text-left">
                          <Text>{item.comments}</Text>
                        </Col>
                      </Row>
                    </Col>
                    {!this.props.isMcp && (
                      <Col md={2}>
                        <Text>
                          {!item.is_seed_calculated ? (
                            <CloseCircleOutlined
                              onClick={() => {
                                handleSeed(item.id, item.is_seed_calculated);
                              }}
                              style={{
                                color: "gray",
                                fontSize: 17,
                                fontWeight: "bold",
                              }}
                            />
                          ) : (
                            <CheckCircleOutlined
                              onClick={() => {
                                handleSeed(item.id, item.is_seed_calculated);
                              }}
                              style={{
                                color: "green",
                                fontSize: 17,
                                fontWeight: "bold",
                              }}
                            />
                          )}
                        </Text>
                      </Col>
                    )}
                    <Col
                      style={{
                        textAlign: "right",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                      className="actions"
                    >
                      <div
                        className="position"
                        style={{
                          height: 34,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        {" "}
                        <UpOutlined
                          onClick={() => {
                            handleChangePosition(item.id, "up");
                          }}
                          style={{
                            fontSize: 15,
                            fontWeight: "bold",
                            color: "#0082FF",
                          }}
                          className="icon"
                        />
                        <DownOutlined
                          onClick={() => {
                            handleChangePosition(item.id, "down");
                          }}
                          style={{
                            fontSize: 15,
                            fontWeight: "bold",
                            color: "#0082FF",
                          }}
                          className="icon"
                        />
                      </div>
                      <Tooltip title="Clone">
                        <CloneIcon
                          className="icon"
                          onClick={() => handleClone(item)}
                        />
                      </Tooltip>
                      <Tooltip title="Edit">
                        <EditIcon
                          className="icon"
                          onClick={() => handleEdit("EDIT", item)}
                        />
                      </Tooltip>
                      <Tooltip title="Remove">
                        <RemoveIcon
                          className="icon"
                          onClick={() => handleDelete(item)}
                        />
                      </Tooltip>
                    </Col>
                  </Row>
                ))}
            </Row>
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              className="empty-icon"
            />
          )}
        </Row>
      </Row>
    );
  }
}
const mapStateToProps = (state) => {
  const props = {
    settings: state.auth.settings,
    report: state.report.reportInfo,
  };
  return props;
};

export default connect(mapStateToProps, {})(PrescriptionTable);
