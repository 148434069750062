const getInflation = (settings, P, t, format) => {
  //P is the totalCost,number of years
  settings.interest_period_per_year = 1;
  let A = 0;
  let r = parseFloat(settings.interest_rate) / 100;
  let n = parseFloat(settings.interest_period_per_year);
  let base = 1 + r / n;
  let power = n * t;
  A = P * Math.pow(base, power);
  if (format) {
    return A.toFixed(2);
  }
  return A;
};
export default getInflation;
