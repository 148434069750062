import React, { Component } from 'react';
import { connect } from "react-redux";
import { Layout, Menu, Icon, Row, Col, Typography } from 'antd';
import { getUserInfo, logout } from "../../redux/auth/actions";
import {
    DashboardIcon,
    TemplatesIcon,
    ClientsIcon,
    UserIcon,
    ContactsIcon,
    LogoutIcon,
    HideIcon,
    ShowIcon,
    UploadFileIcon,
  } from "../../components/Icons";
import "./Navigation.scss";

const { Sider } = Layout;
const { Text } = Typography;

class Navigation extends Component {

  constructor(pro) {
    super();
    this.state = {
      collapsed: false,
      selectedKey: 'dashboard'
    }
  }

  componentDidMount() {
    if (this.props.location.pathname.includes("/main")) {
      this.setState({
        selectedKey: "dashboard"
      })
    }
    if (this.props.location.pathname.includes("/clients")) {
      this.setState({
        selectedKey: "clients"
      })
    }
    if (this.props.location.pathname.includes("/users")) {
      this.setState({
        selectedKey: "users"
      })
    }
    if (this.props.location.pathname.includes("/templates")) {
      this.setState({
        selectedKey: "templates"
      })
    }
    if (this.props.location.pathname.includes("/clients")) {
      this.setState({
        selectedKey: "clients"
      })
    }
    if (this.props.location.pathname.includes("/contacts")) {
      this.setState({
        selectedKey: "contacts"
      })
    }
    this.props.getUserInfo();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (this.props.location.pathname.includes("/main")) {
        this.setState({
          selectedKey: "dashboard"
        })
      }
      if (this.props.location.pathname.includes("/clients")) {
        this.setState({
          selectedKey: "clients"
        })
      }
      if (this.props.location.pathname.includes("/users")) {
        this.setState({
          selectedKey: "users"
        })
      }
      if (this.props.location.pathname.includes("/templates")) {
        this.setState({
          selectedKey: "templates"
        })
      }
      if (this.props.location.pathname.includes("/clients")) {
        this.setState({
          selectedKey: "clients"
        })
      }
      if (this.props.location.pathname.includes("/contacts")) {
        this.setState({
          selectedKey: "contacts"
        })
      }
    }
  }

  onCollapse = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  onSelectMenu = (item) => {
    this.setState({
      selectedKey: item.key
    });
    switch(item.key) {
      case "dashboard":
        this.props.history.push("/app/main")
        return;
      case "templates":
        this.props.history.push("/app/templates")
        return;
      case "clients":
        this.props.history.push("/app/clients")
        return;
      case "users":
        this.props.history.push("/app/users")
        return;
      case "contacts":
        this.props.history.push("/app/contacts")
        return;
      case "uploads":
        this.props.history.push("/app/uploadCode")
        return;
      default:
        return;
    }
  }

  render() {
    const { collapsed, selectedKey } = this.state;
    const { userInfo } = this.props;

    return (
      <Sider
        width={250}
        className="navigation-container"
        collapsed={collapsed}
      >
        <Row type="flex" align="middle" className="logo">
          <Col>
            <Row type="flex" justify="center" align="middle" className="avatar">
              <Text>
                { 
                  userInfo && userInfo.attributes && 
                  (userInfo.attributes.given_name ? userInfo.attributes.given_name.substr(0,1).toUpperCase() : "") + (userInfo.attributes.family_name ? userInfo.attributes.family_name.substr(0,1).toUpperCase() : "")
                }
              </Text>
            </Row>
          </Col>
          {
            !collapsed &&
            <Col>
              <Row className="user-info">
                <Text ellipsis className="name">
                  { 
                    userInfo && userInfo.attributes && 
                    (userInfo.attributes.given_name ? userInfo.attributes.given_name : "") + " " + (userInfo.attributes.family_name ? userInfo.attributes.family_name : "")
                  }
                </Text>
              </Row>
              <Row className="user-info">
                <Text ellipsis className="email">
                  { userInfo && userInfo.attributes && userInfo.attributes.email}
                </Text>
              </Row>
            </Col>
          }
        </Row>
        <Menu
          selectedKeys={[selectedKey]}
          mode="inline"
          className="menu"
          theme="dark"
          onClick={this.onSelectMenu}
          onSelect={this.onSelectMenu}
        >
          <Menu.Divider className="divider" />
          <Menu.Item key="dashboard" className="menu-item">
            <Icon component={DashboardIcon} className="menu-item" />
            <span>Dashboard</span>
          </Menu.Item>
          <Menu.Item key="templates" className="menu-item">
            <Icon component={TemplatesIcon} className="menu-item" />
            <span>Templates</span>
          </Menu.Item>
          <Menu.Item key="clients" className="menu-item">
            <Icon component={ClientsIcon} className="menu-item" />
            <span>My Clients</span>
          </Menu.Item>
          <Menu.Item key="users" className="menu-item">
            <Icon component={UserIcon} className="menu-item" />
            <span>My Users</span>
          </Menu.Item>
          <Menu.Item key="contacts" className="menu-item">
            <Icon component={ContactsIcon} className="menu-item" />
            <span>Contacts</span>
          </Menu.Item>
          {/* <Menu.Item key="uploads" className="menu-item">
            <Icon component={UploadFileIcon} className="menu-item" />
            <span>Uploads</span>
          </Menu.Item> */}
          <Menu.Divider className="divider" />
          <Menu.Item key="6" className="menu-item" onClick={this.props.logout}>
            <Icon component={LogoutIcon} className="menu-item" />
            <span>Logout</span>
          </Menu.Item>
          <Menu.Divider className="divider" />
        </Menu>
        {
          !collapsed &&
          <Row>
            <Row className="text">
              <Text>
                Copyright © 2020
                MSA Settements, Inc.
              </Text>    
            </Row>
            <Row className="link">
              <a href="/terms">
                Terms and conditions
              </a>
            </Row>
          </Row>
        }
        
        <Row className="collapse">
          <Col style={{height: "100%"}} onClick={this.onCollapse}>
            {
              collapsed ?
                <Col className="collapse-content">
                  <ShowIcon />
                </Col>
              :
                <Col className="collapse-content">
                  <HideIcon />
                  <span className="collapse-text">Hide</span>
                </Col>                
            }
          </Col>            
        </Row>
      </Sider>
    )
  }
}

const mapStateToProps = state => {
  const props = {
    userInfo: state.auth.userInfo,
  };
  return props;
}

export default connect(
  mapStateToProps,
  { getUserInfo, logout }
)(Navigation);