import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import moment from "moment";
import { UploadFile } from "./utils/upload";
export let listener = function (event) {
  // event.preventDefault();
  // localStorage.setItem("beforeUnloadDate", moment());
  // event.returnValue = "Write something clever here..";
};

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// window.onbeforeunload = function (event) {};
serviceWorker.unregister();
