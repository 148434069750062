import React, { Component } from "react";
import { Row, Typography } from "antd";
import ContentEditable from "react-contenteditable";

import "./TagsInputBox.scss";

const { Text } = Typography;

export default class TagsInputBox extends Component {
  cleanse = (input = "") => {
    console.log(typeof input);
    return input.replace(/font-family/g, "f");
  };
  handleKeyPress = (e) => {
    const { value, onChange } = this.props;
    let newValue = value;

    if (e.keyCode === 13 && !value.includes("<li>")) {
      newValue = `<li>${newValue}</li>`;

      onChange(this.cleanse(newValue));
    }
  };

  handleBlur = () => {
    const { value, onChange } = this.props;
    let newValue = value;
    console.log(value);
    if (newValue) {
      newValue = newValue.replace(/<[^/>][^>]*><\/[^>]+>|<li><br><\/li>/g, "");
      onChange(this.cleanse(newValue));
    }
  };

  render() {
    const { label, value, disabled, rows, placeholder, onChange } = this.props;

    return (
      <Row className="tags-input-box-container">
        <Row className="label">
          <Text>{label}</Text>
        </Row>
        <Row>
          {/* <Select
            mode="tags"
            className="tags-input"
            placeholder={placeholder || 'Required'}
            tokenSeparators={[',']}
            disabled={disabled || false}
            value={values}
            onChange={(value)=>onChange(value.toString())}
          /> */}
          <ContentEditable
            className="editable-content"
            tagName="div"
            html={value || ""} // innerHTML of the editable div
            disabled={disabled || false} // use true to disable edition
            onChange={(e) => {
              onChange(this.cleanse(e.target.value));
              //onChange(e.target.value);
            }} // handle innerHTML change
            onKeyDown={(e) => this.handleKeyPress(e)}
            onBlur={this.handleBlur}
          />
        </Row>
      </Row>
    );
  }
}
