import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  Row,
  Col,
  Typography,
  Button,
  Divider,
  Select,
  Icon,
  DatePicker,
} from "antd";

import SpinBox from "components/SpinBox";
import InputCurrencyBox from "components/InputCurrencyBox";
import { BackIcon } from "components/Icons";
import { updateReportInfo } from "redux/report";
import * as API from "utils/api";

import "./SettlementInfo.scss";

const { Title, Text } = Typography;
const { Option } = Select;

class SettlementInfo extends Component {
  constructor(props) {
    super(props);

    let report_id = "";
    if (this.props.location && this.props.location.state) {
      report_id = this.props.location.state.data.report_id;
    }

    this.state = {
      report_id: report_id,
      settlement_amount: "",
      settlement_type: "lump sum",
      proposed_settlement_date: moment(),
      loadingTitle: "Loading...",
      loading: false,
    };
    this.timeoutId = setInterval(
      this.handleUpdateReport,
      this.props.autoSavingTime
    );
  }
  componentWillUnmount = () => {
    clearInterval(this.timeoutId);
  };
  componentDidMount() {
    const { report_id } = this.state;

    this.setState({ loading: true });
    API.getReportById(report_id).then((res) => {
      const reportInfo = res.report;
      this.setState({
        settlement_amount: reportInfo.settlement_amount || "",
        settlement_type: reportInfo.settlement_type || "lump sum",
        proposed_settlement_date: reportInfo.proposed_settlement_date
          ? moment(reportInfo.proposed_settlement_date)
          : moment(),
        loading: false,
      });
      this.props.updateReportInfo(reportInfo);
    });
  }

  componentDidUpdate(prevProps) {
    const { reportInfo } = this.props;
    if (JSON.stringify(reportInfo) !== JSON.stringify(prevProps.reportInfo)) {
      this.setState({
        settlement_amount: reportInfo.settlement_amount || "",
        settlement_type: reportInfo.settlement_type || "lump sum",
        proposed_settlement_date: reportInfo.proposed_settlement_date
          ? moment(reportInfo.proposed_settlement_date)
          : moment(),
        loading: false,
      });
    }
  }

  handleUpdateReport = (isNextStep = false) => {
    const {
      report_id,
      settlement_amount,
      settlement_type,
      proposed_settlement_date,
    } = this.state;

    const params = {
      id: report_id,
      settlement_amount,
      settlement_type,
      proposed_settlement_date: moment(proposed_settlement_date).format(
        "MM/DD/YYYY"
      ),
    };

    if (isNextStep) {
      this.setState({
        loading: true,
        loadingTitle: "Saving...",
      });

      API.updateReport(params).then((res) => {
        this.setState({ loading: false });
        clearTimeout(this.timeoutId);
        this.props.history.push("/app/reports/msa/proposedMedicare", {
          data: { report_id: report_id },
        });
      });
    } else {
      API.updateReport(params);
    }
  };

  goToPreviousStep = () => {
    this.props.history.goBack();
  };

  render() {
    const {
      settlement_amount,
      settlement_type,
      proposed_settlement_date,
      loadingTitle,
      loading,
    } = this.state;

    return (
      <Row className="msa-settlement-info-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Setup MSA Report
            </Title>
          </Col>
          <Col className="back" onClick={() => this.props.history.goBack()}>
            <BackIcon className="icon" />
            <Text>Cancel</Text>
          </Col>
          <Col className="step">
            <Text>Step 10 of 17</Text>
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <Text className="title">Settlement Information</Text>
                </Col>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">Settlement Amount</Text>
                </Col>
                <Col className="input-col">
                  <InputCurrencyBox
                    value={settlement_amount}
                    onChange={(value) =>
                      this.setState({ settlement_amount: value })
                    }
                  />
                </Col>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">Type of Settlement</Text>
                </Col>
                <Row>
                  <Select
                    showSearch
                    placeholder="Please select"
                    optionFilterProp="children"
                    className="dropdown"
                    value={settlement_type}
                    onChange={(value) =>
                      this.setState({ settlement_type: value })
                    }
                    suffixIcon={
                      <Icon
                        type="caret-down"
                        style={{ color: "black" }}
                        theme="filled"
                      />
                    }
                  >
                    <Option value={"lump sum"} key={"1"}>
                      Lump sum
                    </Option>
                    <Option value={"structured"} key={"2"}>
                      Structured
                    </Option>
                  </Select>
                </Row>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">Proposed Settlement Date</Text>
                </Col>
                <Row>
                  <DatePicker
                    className="date"
                    format="MM/DD/YYYY"
                    value={proposed_settlement_date}
                    onChange={(date, dateString) =>
                      this.setState({ proposed_settlement_date: date })
                    }
                  />
                </Row>
              </Row>
              <Divider className="divider" />
              <Row
                type="flex"
                justify="center"
                align="middle"
                className="confirm-button"
              >
                <Button
                  type="link"
                  className="prev-button button"
                  onClick={this.goToPreviousStep}
                >
                  &lt; PREVIOUS STEP
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="green-btn button"
                  onClick={() => this.handleUpdateReport(true)}
                >
                  Next Step
                </Button>
              </Row>
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  const props = {
    autoSavingTime: state.report.autoSavingTime,
    reportInfo: state.report.reportInfo,
  };
  return props;
};

export default connect(mapStateToProps, { updateReportInfo })(SettlementInfo);
