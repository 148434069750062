import axios from "axios";
import { API } from "aws-amplify";
let urlForZip = "https://services.msaprojections.com";
export const getDashboardData = () => {
  return API.get("MyAPI", `/clientservice/dashboard/getStats`);
};

export const getAssignmentsData = (
  searchQuery = "",
  pageIndex = 0,
  pageSize = 10
) => {
  return API.get(
    "MyAPI",
    `/clientservice/notes?search=${searchQuery}&page=${pageIndex}&pagesize=${pageSize}`
  );
};
export const getContactTypes = () => {
  return API.get("MyAPI", `/contacts/type`);
};

export const updateAssignment = (params) => {
  return API.put("MyAPI", `/clientservice/notes`, { body: params });
};

export const getReportsData = (
  searchQuery = "",
  pageIndex = 0,
  pageSize = 10,
  params
) => {
  return API.post(
    "MyAPI",
    `/clientservice/filteredreports?search=${searchQuery}&page=${pageIndex}&pagesize=${pageSize}`,
    { body: params }
  );
};

export const getReportsDataByClaimantId = (
  claimantId,
  pageIndex = 0,
  pageSize = 10
) => {
  return API.get(
    "MyAPI",
    `/clientservice/reports?claimant_id=${claimantId}&page=${pageIndex}&pagesize=${pageSize}`
  );
};

export const getReportById = (reportId) => {
  return API.get("MyAPI", `/clientservice/reports/${reportId}`);
};

export const getClientsData = (
  searchQuery = "",
  pageIndex = 0,
  pageSize = 10
) => {
  return API.get(
    "MyAPI",
    `/clientservice/clients?search=${searchQuery}&page=${pageIndex}&pagesize=${pageSize}`
  );
};

export const createClient = (params) => {
  return API.post("MyAPI", `/clientservice/clients`, { body: params });
};

export const updateClient = (params) => {
  return API.put("MyAPI", `/clientservice/clients`, { body: params });
};

export const deleteClient = (params) => {
  return API.del("MyAPI", `/clientservice/clients`, { body: params });
};

export const getTemplatesData = (
  searchQuery = "",
  pageIndex = 0,
  pageSize = 10
) => {
  return API.get(
    "MyAPI",
    `/clientservice/templates?search=${searchQuery}&page=${pageIndex}&pagesize=${pageSize}`
  );
};

export const createTemplate = (params) => {
  return API.post("MyAPI", `/clientservice/templates`, { body: params });
};

export const updateTemplate = (params) => {
  return API.put("MyAPI", `/clientservice/templates`, { body: params });
};

export const deleteTemplate = (params) => {
  return API.del("MyAPI", `/clientservice/templates`, { body: params });
};

export const getReportSections = (report_type_id) => {
  return API.get(
    "MyAPI",
    `/clientservice/report_section?report_type_id=` + report_type_id
  );
};

export const getTemplateOrders = (templateId) => {
  return API.get(
    "MyAPI",
    `/clientservice/template_orders?template_id=${templateId}`
  );
};

export const createTemplateOrder = (params) => {
  return API.post("MyAPI", `/clientservice/template_orders`, { body: params });
};

export const getUsersData = (
  searchQuery = "",
  pageIndex = 0,
  pageSize = 10
) => {
  return API.get(
    "MyAPI",
    `/users/getUsers?search=${searchQuery}&page=${pageIndex}&pagesize=${pageSize}`
  );
};
export const getUsersDataSynchronised = (
  searchQuery = "",
  pageIndex = 0,
  pageSize = 10
) => {
  return API.get(
    "MyAPI",
    `/clientservice/users?search=${searchQuery}&page=${pageIndex}&pagesize=${pageSize}`
  );
};
export const createUser = (params) => {
  return API.post("MyAPI", `/users`, { body: params });
};
export const createUserTenant = (params) => {
  // return API.post("MyAPI", `/clientservice/tenants
  // `, { body: params });
  return axios.post(`${process.env.REACT_APP_API_URL}/public/tenants`, params);
};
export const createUserBankData = (params) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/public/payment_profile `,
    params
  );
};
export const synchroniseUser = (params) => {
  return API.post("MyAPI", `/clientservice/users`, { body: params });
};

export const updateUser = (email, params) => {
  return API.patch("MyAPI", `/users/${email}`, { body: params });
};
export const updateUserSynchronised = (params) => {
  return API.put("MyAPI", `/clientservice/users`, { body: params });
};
export const deleteUser = (email) => {
  return API.del("MyAPI", `/users/${email}`);
};
export const deleteUserSynchronised = (id) => {
  alert(id);
  return API.del("MyAPI", `/clientservice/users/${id}`);
};

export const getContactsData = (
  searchQuery = "",
  pageIndex = 0,
  pageSize = 10
) => {
  return API.get(
    "MyAPI",
    `/contacts?search=${searchQuery}&page=${pageIndex}&pagesize=${pageSize}`
  );
};

export const createContact = (params) => {
  return API.post("MyAPI", `/contacts`, { body: params });
};

export const updateContact = (id, params) => {
  return API.patch("MyAPI", `/contacts/${id}`, { body: params });
};

export const deleteContact = (id) => {
  return API.del("MyAPI", `/contacts/${id}`);
};

export const getClaimantsData = (
  clientId,
  searchQuery = "",
  pageIndex = 0,
  pageSize = 10
) => {
  return API.get(
    "MyAPI",
    `/clientservice/claimants?client_id=${clientId}&search=${searchQuery}&page=${pageIndex}&pagesize=${pageSize}`
  );
};

export const getClaimantsDataByClaimantId = (claimantId) => {
  return API.get("MyAPI", `/clientservice/claimants/${claimantId}`);
};

export const createClaimant = (params) => {
  return API.post("MyAPI", `/clientservice/claimants`, { body: params });
};

export const updateClaimant = (params) => {
  return API.put("MyAPI", `/clientservice/claimants`, { body: params });
};

export const deleteClaimant = (params) => {
  return API.del("MyAPI", `/clientservice/claimants`, { body: params });
};

export const createReport = (params) => {
  return API.post("MyAPI", `/clientservice/reports`, { body: params });
};

export const updateReport = (params) => {
  return API.put("MyAPI", `/clientservice/reports`, { body: params });
};

export const copyReport = (params) => {
  const url = params.report_type_id
    ? `/clientservice/copyreport/${params.id}?report_type_id=${params.report_type_id}`
    : `/clientservice/copyreport/${params.id}`;
  return API.get("MyAPI", url);
};

export const deleteReport = (params) => {
  return API.del("MyAPI", `/clientservice/reports`, { body: params });
};

export const getReportStatuses = () => {
  return API.get("MyAPI", `/clientservice/report_status`);
};

export const getNotesDataByClaimantId = (
  claimantId,
  pageIndex = 0,
  pageSize = 10
) => {
  return API.get(
    "MyAPI",
    `/clientservice/notes?claimant_id=${claimantId}&page=${pageIndex}&pagesize=${pageSize}`
  );
};

export const createNote = (params) => {
  return API.post("MyAPI", `/clientservice/notes`, { body: params });
};

export const updateNote = (params) => {
  return API.put("MyAPI", `/clientservice/notes`, { body: params });
};

export const deleteNote = (params) => {
  return API.del("MyAPI", `/clientservice/notes`, { body: params });
};

export const getSpeciality = () => {
  return API.get("MyAPI", `/clientservice/speciality`);
};

export const getMedicareData = (reportId) => {
  return API.get(
    "MyAPI",
    `/clientservice/medicare_cost_projection?report_id=${reportId}`
  );
};
export const getReportTypes = () => {
  return API.get("MyAPI", `/clientservice/report_type`);
};

export const createMedicareItem = (params) => {
  return API.post("MyAPI", `/clientservice/medicare_cost_projection`, {
    body: params,
  });
};

export const updateMedicareItem = (params) => {
  return API.patch(
    "MyAPI",
    `/clientservice/medicare_cost_projection/${params.id}`,
    { body: params }
  );
};

export const deleteMedicareItem = (medicareId) => {
  return API.del(
    "MyAPI",
    `/clientservice/medicare_cost_projection/${medicareId}`
  );
};

export const getCPTData = (value) => {
  if (value) {
    return API.get("MyAPI", `/lookups/cpt_codes?search=` + value);
  }
  return API.get("MyAPI", `/lookups/cpt_codes`);
};
export const addCptCode = (params) => {
  return API.post("MyAPI", `/lookups/cpt_code`, { body: params });
};

export const getIcdCodeData = (search) => {
  if (search) {
    return API.get("MyAPI", `/lookups/icd_codes?search=` + search);
  }
  return API.get("MyAPI", `/lookups/icd_codes`);
};

export const getJuridictionStateList = () => {
  return API.get("MyAPI", `/clientservice/juridiction_states`);
};

export const getIcdCodeNameByReportId = (reportId) => {
  return API.get("MyAPI", `/lookups/bulk_icd_codes/${reportId}`);
};

export const getMedicareInfoData = (reportId) => {
  return API.get("MyAPI", `/clientservice/report/${reportId}/total`);
};

export const getNonMedicareInfoData = (reportId) => {
  return API.get(
    "MyAPI",
    `/clientservice/report/${reportId}/non_medicare_total`
  );
};

export const setUpReport = (reportId) => {
  return API.get("MyAPI", `/clientservice/setUpReport/${reportId}`);
};
export const setUpMCPReport = (reportId) => {
  return API.get("MyAPI", `/clientservice/setupMcp/${reportId}`);
};

export const downloadReport = (reportId) => {
  return API.get("MyAPI", `/clientservice/downloadReport/${reportId}`);
};

export const addActiveUser = (params) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/session/active_user`,
    params
  );
};

export const isActiveUser = (param) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/session/active_user?email=${param}`
  );
};

export const deleteActiveUser = (params) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/session/active_user`, {
    data: params,
  });
};

export const updateActiveUser = (params) => {
  return axios.put(
    `${process.env.REACT_APP_API_URL}/session/active_user`,
    params
  );
};

export const getLifeSources = () => {
  return API.get("MyAPI", `/lookups/source`);
};

export const getLifeTables = () => {
  return API.get("MyAPI", `/lookups/life_table`);
};

export const getLifeExpect = (claimantId, params) => {
  return API.post("MyAPI", `/lookups/years_left?claimant_id=${claimantId}`, {
    body: params,
  });
};

export const getUnitForms = () => {
  return API.get("MyAPI", `/clientservice/unit_form`);
};

//report contacts
export const addReportContact = (report_id, contact_id, type) => {
  return API.post("MyAPI", `/clientservice/reportcontacts`, {
    body: { report_id, contact_id, type },
  });
};
export const removeReportContact = (report_id, contact_id, type) => {
  return API.del("MyAPI", `/clientservice/reportcontacts`, {
    body: { report_id, contact_id, type },
  });
};
export const getReportContacts = (report_id) => {
  return API.get(
    "MyAPI",
    `/clientservice/reportcontacts?report_id=${report_id}`
  );
};

export const getPresignedUrl = (fileName, fileType) => {
  return API.post("MyAPI", `/clientservice/presign`, {
    body: { fileName, fileType: "application/pdf" },
  });
};
export const zipFiles = async (data) => {
  axios.defaults.timeout = 3000000;
  return await axios.post(urlForZip + "/zip", data);
};

export const ReportFileUpload = (claimantId, params) => {
  return API.post("MyAPI", `/clientservice/files/${claimantId}`, {
    body: params,
  });
};
export const getNurseRoles = () => {
  return API.get("MyAPI", `/clientservice/roles?isNurse=true`);
};

export const AppendRoles = (params) => {
  return API.post("MyAPI", `/clientservice/roles/bulk`, {
    body: params,
  });
};
export const GetUserRoles = (id) => {
  return API.get("MyAPI", `/clientservice/user/${id}/roles`);
};
export const DeleteRoles = (user_id, role_id) => {
  return API.del("MyAPI", `/clientservice/user/${user_id}/role/${role_id}`);
};
export const AddRole = (user_id, role_id) => {
  return API.post("MyAPI", `/clientservice/user/${user_id}/role/${role_id}`);
};
export const getStates = async (data) => {
  axios.defaults.timeout = 3000000;
  return await axios.get(`${process.env.REACT_APP_API_URL}/public/states`);
};
export const GetReportFiles = (claimantId) => {
  return API.get(
    "MyAPI",
    `/clientservice/files` + (claimantId ? "?claimant_id=" + claimantId : "")
  );
};
export const deleteFile = (claimantId, params) => {
  return API.del("MyAPI", `/clientservice/files/${claimantId}`, {
    body: params,
  });
};
export const getPresignedLogo = async (data) => {
  axios.defaults.timeout = 3000000;
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/public/presign`,
    data
  );
};

export const updateUploadedFile = (claimantId, params) => {
  return API.patch("MyAPI", `/clientservice/files/${claimantId}`, {
    body: params,
  });
};

export const getFile = (claimantId) => {
  return API.get("MyAPI", `/clientservice/files/${claimantId}`);
};

export const getPresignLogoUrl = (id) => {
  return API.get("MyAPI", `/clientservice/files/presigned/${id}`);
};
export const getPresignUrlFile = async (data) => {
  return await API.post("MyAPI", "/clientservice/files/presign", {
    body: data,
  });
};

export const GetAllUsers = () => {
  return API.get("MyAPI", `/clientservice/users/role?name=RA`);
};

export const CreateRateDUser = (params) => {
  return API.post("MyAPI", `/clientservice/assignments`, {
    body: params,
  });
};

export const CreateAssignementFiles = (params) => {
  return API.post("MyAPI", `/clientservice/assignment/files/bulk`, {
    body: params,
  });
};
export const CreateAssignementFile = (file_id, assignment_id) => {
  return API.post("MyAPI", `/clientservice/assignment/files`, {
    body: { file_id, assignment_id },
  });
};
export const GetCreatedAssignement = (id) => {
  return API.get(
    "MyAPI",
    `/clientservice/assignments?assignment_type_id=3&claimant_id=${id}`
  );
};
export const UpdateCreatedAssignement = (id, params) => {
  return API.patch("MyAPI", `/clientservice/assignments/${id}`, {
    body: params,
  });
};
export const deleteAssignement = (id, params) => {
  return API.del("MyAPI", `/clientservice/assignments/${id}`, { body: params });
};

export const getAssigmentFiles = (assignment_id) => {
  return API.get(
    "MyAPI",
    `/clientservice/assignment/files${
      assignment_id ? "?assignment_id=" + assignment_id : ""
    }`
  );
};
export const DeleteAssigmentFile = (id) => {
  return API.del("MyAPI", `/clientservice/assignment/files/${id}`);
};
