import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Input, Typography } from "antd";
import { setPassword } from "../../../redux/auth/actions";
import AuthSideBar from "../../../components/AuthSideBar";
import "./SetPassword.scss";
import * as API from "../../../utils/api";

const { Title, Text } = Typography;

class SetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: "",
      confirmPassword: "",
      changeFlag: false,
      error: "",
    };
  }

  setPassword = () => {
    const { newPassword, confirmPassword } = this.state;
    const { userInfo } = this.props;
    if (!newPassword) {
      this.setState({
        error: "Invalid Password",
        changeFlag: false,
      });
      return;
    }

    if (newPassword !== confirmPassword) {
      this.setState({
        error: "Password should be same",
        changeFlag: false,
      });
      return;
    }

    this.setState({ changeFlag: false, error: "" });
    this.props.setPassword(userInfo, newPassword);
    API.updateActiveUser({
      email: userInfo.challengeParam.userAttributes.email,
      is_active: false,
    });
    this.props.history.push("/confirmSet");
  };

  render() {
    const { newPassword, confirmPassword, changeFlag, error } = this.state;
    const { isLoading, errorMessage, userInfo } = this.props;

    return (
      <Row type="flex" justify="center" className="setPassword-container">
        <Col xs={0} xl={8}>
          <AuthSideBar />
        </Col>
        <Col xs={24} xl={16}>
          <Row type="flex" justify="center">
            <Col xs={20} md={20}>
              <Row
                type="flex"
                justify="space-around"
                className="main-container"
              >
                <Col style={{ margin: "auto" }}>
                  <Row
                    type="flex"
                    justify="center"
                    align="middle"
                    style={{ flexDirection: "column" }}
                  >
                    <Title level={2} className="tit">
                      Welcome to MSA,
                      {userInfo &&
                        userInfo.challengeParam &&
                        userInfo.challengeParam.userAttributes &&
                        (userInfo.challengeParam.userAttributes.given_name
                          ? userInfo.challengeParam.userAttributes.given_name
                          : "") +
                          " " +
                          (userInfo.challengeParam.userAttributes.family_name
                            ? userInfo.challengeParam.userAttributes.family_name
                            : "")}
                    </Title>
                    <Title level={1} className="sub-title">
                      Please set your password
                    </Title>
                    <Text className="label">
                      For more security the password should contain numbers and
                      uppercase letters
                    </Text>
                    <Row
                      type="flex"
                      justify="space-around"
                      className="input-row"
                    >
                      <Input
                        size="large"
                        placeholder="Your Password"
                        className="password"
                        value={newPassword}
                        type="password"
                        onChange={(e) =>
                          this.setState({
                            newPassword: e.target.value,
                            changeFlag: true,
                          })
                        }
                      />
                      <Input
                        size="large"
                        placeholder="Confirm Your Password"
                        className="password"
                        value={confirmPassword}
                        type="password"
                        onChange={(e) =>
                          this.setState({
                            confirmPassword: e.target.value,
                            changeFlag: true,
                          })
                        }
                      />
                    </Row>
                    <Row className="error-text">
                      {(error || errorMessage) && !changeFlag && (
                        <Text type="danger">{error || errorMessage}</Text>
                      )}
                    </Row>
                    <Row>
                      {isLoading ? (
                        <Button
                          type="primary"
                          size="large"
                          className="green-btn button"
                          loading
                        >
                          Loading
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          size="large"
                          onClick={this.setPassword}
                          className="green-btn button"
                        >
                          Create Account
                        </Button>
                      )}
                    </Row>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  const props = {
    isLoading: state.auth.isLoading,
    errorMessage: state.auth.errorMessage,
    userInfo: state.auth.userInfo,
  };
  return props;
};

export default connect(mapStateToProps, { setPassword })(SetPassword);
