import React, { useState, useEffect } from "react";
import SpinBox from "components/SpinBox";
import {
  Row,
  Col,
  Typography,
  Button,
  Divider,
  Tabs,
  Badge,
  Empty,
  Select,
  Menu,
  Dropdown,
  message,
  Upload,
  Modal,
} from "antd";
import { CloneIcon, EditIcon, RemoveIcon, DetailsIcon } from "components/Icons";
import * as API from "utils/api";
import EditRatedAges from "./EditRatedAges";
import ClaimantInfo from "components/ClaimantInfoOnWizard";
export default function RatedAgesData({
  claimantInfo,
  GetAllAssignemetData,
  loading,
  deleteAssignementData,
  getAllData,
}) {
  const { Title, Text } = Typography;
  const [isOpen, setIsopen] = useState(false);
  console.log(GetAllAssignemetData, "GetAllAssignemetData");

  const id = claimantInfo.id;
  const [s, setLoading] = useState(true);
  const [editRatedData, setEditRatedData] = useState("");
  // useEffect(() => {
  //   API.GetCreatedAssignement(id).then((res) => {
  //     console.log(res.assignments, "resut");
  //     setGetAllData(res.assignments);
  //     setLoading(false);
  //     console.log(res, "result");
  //   });
  // }, []);
  const OpenModal = (editRatedData) => {
    setEditRatedData(editRatedData);
    setIsopen(true);
  };
  const saveEditRatedAges = () => {
    API.UpdateCreatedAssignement(editRatedData.id, {
      note: editRatedData.note,
    }).then((res) => {
      getAllData();
      setIsopen(false);
    });
  };
  const CloseModal = () => {
    setIsopen(false);
  };

  const handleDataChanges = (e) => {
    let value = e.target.value;
    setEditRatedData({
      ...editRatedData,
      note: value,
    });
  };

  const deleteData = (id) => {
    // const filteredItems = GetAllData.filter((el) => el.id !== id);
    // setLoading(true);
    // API.deleteAssignement(id).then((res) => {
    //   console.log(res, "ok");
    //   setGetAllData(filteredItems);
    //   setLoading(false);
    // });
  };
  console.log(editRatedData, "ok");
  return (
    <Row className="reports-list-container">
      <Row type="flex" className="table-header">
        <Col md={6}> Note</Col>
        <Col md={6} style={{ textAlign: "center" }}>
          Sent To
        </Col>
        <Col md={6}>Status</Col>
        <Col md={3} style={{ textAlign: "right" }}>
          Actions
        </Col>
      </Row>
      <Row className="table-content">
        <SpinBox loading={loading}>
          {GetAllAssignemetData && GetAllAssignemetData.length > 0 ? (
            <Row>
              {GetAllAssignemetData.map((data, index) => (
                <Row
                  key={index}
                  className="record"
                  style={{ justifyContent: "initial" }}
                >
                  <Col md={6}>
                    <Text>{data.note}</Text>
                  </Col>

                  <Col md={6} style={{ textAlign: "center" }}>
                    {data.user
                      ? data.user.given_name + " " + data.user.family_name
                      : ""}
                  </Col>

                  <Col md={6}>
                    <div className="status-wrapper">
                      <div className="status-rounded"></div>
                      <Text style={{ paddingLeft: "5px" }}>hello</Text>
                    </div>
                  </Col>
                  <Col
                    md={3}
                    style={{ textAlign: "right" }}
                    className="actions"
                  >
                    <EditIcon
                      className="icon"
                      onClick={() => OpenModal(data)}
                    />
                    {console.log(data.id, "hi")}
                    <RemoveIcon
                      className="icon"
                      onClick={() => deleteAssignementData(data.id)}
                      //   onClick={this.onStaticDelete}
                    />
                  </Col>
                </Row>
              ))}
            </Row>
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              className="empty-icon"
            />
          )}
        </SpinBox>
      </Row>

      {console.log(editRatedData, "editRatedData")}
      <EditRatedAges
        isOpen={isOpen}
        saveEditRatedAges={saveEditRatedAges}
        onCofirm={OpenModal}
        onCancel={CloseModal}
        claimant_id={claimantInfo.id}
        editRatedData={editRatedData}
        handleDataChanges={handleDataChanges}
        handleonSubmit={() => {
          const id = editRatedData.id;
          const data = {
            note: editRatedData.note,
          };
          setLoading(true);
          API.UpdateCreatedAssignement(id, data).then((res) => {
            setLoading(false);

            console.log(res.assignment, `update`);
          });
        }}
        loading={loading}
      />
    </Row>
  );
}
