import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Typography,
  Button,
  Divider,
  Upload,
  Modal,
  Badge,
} from "antd";
import { FilePdfOutlined, CloseCircleOutlined } from "@ant-design/icons";
import SpinBox from "components/SpinBox";
import { BackIcon } from "components/Icons";
import { getBase64, readFileAsync } from "utils/utils";
import { updateReportInfo } from "redux/report";
import * as API from "utils/api";
import { Spin } from "antd";
import { Tooltip } from "antd";
import "./DocumentUpload.scss";
import { UploadFile } from "../../../../utils/upload";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

const SortableItem = SortableElement(({ value, i, list, type, deleteFile }) => {
  // el=el.title
  let el = value;
  let filename = el.split("?")[0].split("/")[
    el.split("?")[0].split("/").length - 1
  ];
  let removedTimestamp = filename.substring(13);
  removedTimestamp = decodeURI(removedTimestamp);
  let putAloader = false;

  if (i === list.length - 1 && this.state.isUploading[type]) {
    putAloader = true;
  }
  return (
    <Col sm={3} style={{}} className="item">
      <Tooltip
        title={
          removedTimestamp
          // .substring
        }
      >
        <Badge
          count={
            <CloseCircleOutlined
              onClick={() => {
                deleteFile(type, el);
              }}
              style={{
                color: "#ff0000",
                fontSize: 12,
                marginTop: 7,
                borderRadius: 360,
              }}
            />
          }
        >
          <FilePdfOutlined
            style={{
              fontSize: 30,
              color: "#40a9ff",
              marginTop: 7,
            }}
            key={i}
            onClick={() => {
              window.open(el, "_blank");
            }}
          />
        </Badge>
      </Tooltip>
      &nbsp; &nbsp;
    </Col>
  );
});

const SortableList = SortableContainer(
  ({ items, type, onSortEnd, deleteFile }) => {
    return (
      <Row wrap={true}>
        {items.map((value, index) => (
          <SortableItem
            deleteFile={deleteFile}
            key={`item-${value}`}
            list={items}
            type={type}
            index={index}
            value={value}
          />
        ))}
      </Row>
    );
  }
);
const { Title, Text } = Typography;

const beforeUpload = (file) => {
  if (!file) return false;
  const isPdf = file.type === "application/pdf";
  if (!isPdf) {
    Modal.error({
      content: "You can only upload PDF file!",
    });
  }
  const isLt2M = file.size / 1024 / 1024 < 10;
  // if (!isLt2M) {
  //   Modal.error({
  //     content: "Pdf file must smaller than 10MB!",
  //   });
  // }
  return isPdf;
};
class DocumentUpload extends Component {
  constructor(props) {
    super(props);

    let report_id = "";
    if (this.props.location && this.props.location.state) {
      report_id = this.props.location.state.data.report_id;
    }
    const { reportInfo } = this.props;

    const {
      consent_forms,
      rated_ages,
      court_wc_board_documents,
      msa_administration_agreement,
      medical_records,
      payment_information,
      supplemental_documentation_additional_info,
    } = reportInfo;

    this.state = {
      report_id: report_id,
      consent_forms,
      rated_ages,
      court_wc_board_documents,
      msa_administration_agreement,
      medical_records,
      payment_information,
      supplemental_documentation_additional_info,
      loadingText: "Loading...",
      loading: false,
      isUploading: {},
    };
  }

  componentDidMount() {
    const { report_id } = this.state;

    this.setState({ loading: true });
    API.getReportById(report_id).then((res) => {
      this.setState({ loading: false });
      this.props.updateReportInfo(res.report);
    });
  }

  componentDidUpdate(prevProps) {
    const { reportInfo } = this.props;
    if (JSON.stringify(reportInfo) !== JSON.stringify(prevProps.reportInfo)) {
      const {
        consent_forms,
        rated_ages,
        court_wc_board_documents,
        msa_administration_agreement,
        medical_records,
        payment_information,
        supplemental_documentation_additional_info,
      } = reportInfo;

      this.setState({
        consent_forms,
        rated_ages,
        court_wc_board_documents,
        msa_administration_agreement,
        medical_records,
        payment_information,
        supplemental_documentation_additional_info,
        loading: false,
      });
    }
  }
  handleDocUpload = async (info, type) => {
    var base64Array = [];
    let promiseArray = [];
    let hasUploading = false;
    let isUploading = { ...this.state.isUploading };
    isUploading[type] = true;
    this.setState({ isUploading: isUploading });
    console.log(info, "info");
    let names = info.fileList.map((file) => +new Date() + file.name);
    console.log(names);
    info.fileList.map((el, i) => {
      if (beforeUpload(el.originFileObj)) {
        if ("uploading" === el.status) {
          hasUploading = true;
        }
        console.log(el, "here");

        promiseArray.push(readFileAsync(el.originFileObj));
      }
    });
    if (hasUploading) {
      return;
    }
    let all_files = await Promise.all(promiseArray);
    console.log("started uploading");

    let uploadArrayWithPromises = [];
    all_files.map((file, i) => {
      uploadArrayWithPromises.push(UploadFile(file, names[i]));
    });
    console.log("Finished packing up upload array with promises");
    let urls = await Promise.all(uploadArrayWithPromises);
    console.log(urls);

    var params = {
      id: this.state.report_id,
      [type]: urls,
    };

    API.updateReport(params)
      .then((res) => {
        this[type].state.fileList = [];
        let isUploading = { ...this.state.isUploading };
        isUploading[type] = false;
        this.setState({ isUploading: isUploading });
        this.setState({
          loading: false,
          isUploading,
          [type]: res.report[type],
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  handleGenerateReport = () => {
    const {
      report_id,
      consent_forms,
      court_wc_board_documents,
      medical_records,
      payment_information,
    } = this.state;

    if (!consent_forms) {
      Modal.error({
        content: "You need to upload the 10-Consent Forms pdf file",
      });
      return;
    }

    if (!court_wc_board_documents) {
      Modal.error({
        content: "You need to upload the 25-Court/WC Board Documents pdf file",
      });
      return;
    }

    if (!medical_records) {
      Modal.error({
        content: "You need to upload the 35-Medical Records pdf file",
      });
      return;
    }

    if (!payment_information) {
      Modal.error({
        content: "You need to upload the 40-Payment Information pdf file",
      });
      return;
    }

    this.setState({
      loading: true,
      loadingTitle: "Generating...",
    });

    API.setUpReport(report_id)
      .then((res) => {
        this.setState({
          loading: true,
          loadingText: "Report generated , packaging the files... ",
        });

        let send = res.generatedZip;
        console.log(send);
        API.zipFiles(send).then((zipped) => {
          this.setState({ loading: false, loadingText: "Loading..." });
          Modal.success({
            content: "Report is generated",
            onOk: () => {
              window.open(zipped.data.Location);
            },
          });
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        Modal.error({
          content: "Error",
        });
      });
  };

  goToPreviousStep = () => {
    this.props.history.goBack();
  };

  goToClaimant = () => {
    const { reportInfo } = this.props;
    this.props.history.push("/app/claimants/detail", {
      data: reportInfo.claimant,
    });
  };
  deleteFile = (type, el) => {
    console.log(type, el, "here");
    let isUploading = { ...this.state.isUploading };
    isUploading[type] = true;
    this.setState({ isUploading });
    var existingArray = this.state[type].split("$");
    existingArray.splice(existingArray.indexOf(el), 1);
    var newArray = existingArray.join("$");
    //this.setState({ [type]: newArray });
    var params = {
      id: this.state.report_id,
      [type]: newArray,
    };
    API.updateReport(params)
      .then((res) => {
        isUploading[type] = false;
        this.setState({ isUploading });
        this.setState({ loading: false, [type]: res.report[type] });
      })
      .catch((err) => {
        isUploading[type] = false;
        this.setState({ isUploading });
        this.setState({ loading: false });
      });
  };
  renderFiles = (list, type) => {
    if (list == null || list == "") {
      if (this.state.isUploading[type]) {
        return <Spin />;
      }
      return <Text>No documents uploaded yet...</Text>;
    } else {
      return (
        <Spin spinning={this.state.isUploading[type] ? true : false}>
          <Row className="example horizontal">
            <SortableList
              deleteFile={this.deleteFile}
              distance={1}
              items={list.split("$")}
              type={type}
              axis={"x"}
              onSortEnd={(e) => {
                this.onSortEnd(e, list.split("$"), type);
              }}
            />
          </Row>
        </Spin>
      );
    }
  };
  onSortEnd = ({ oldIndex, newIndex }, items, type) => {
    let isUploading = { ...this.state.isUploading };
    isUploading[type] = true;
    this.setState({ isUploading });
    var params = {
      id: this.state.report_id,
      [type]: arrayMove(items, oldIndex, newIndex).join("$"),
    };
    API.updateReport(params)
      .then((res) => {
        this.setState({ loading: false, [type]: res.report[type] });
        isUploading[type] = false;
        this.setState({ isUploading });
      })
      .catch((err) => {
        this.setState({ loading: false });
        isUploading[type] = false;
        this.setState({ isUploading });
      });
  };
  render() {
    const {
      consent_forms,
      rated_ages,
      court_wc_board_documents,
      msa_administration_agreement,
      medical_records,
      payment_information,
      supplemental_documentation_additional_info,
      loadingTitle,
      loading,
    } = this.state;

    return (
      <Row className="msa-document-upload-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Setup MSA Report
            </Title>
          </Col>
          <Col className="back" onClick={() => this.props.history.goBack()}>
            <BackIcon className="icon" />
            <Text>Cancel</Text>
          </Col>
          <Col className="step">
            <Text>Step 17 of 17</Text>
          </Col>
        </Row>
        <Row className="main">
          <SpinBox
            loading={loading}
            size={"large"}
            title={this.state.loadingText}
          >
            <Row className="content">
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <Text className="title">Document Upload</Text>
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="flex-start"
                className="row"
              >
                <Col md={11}>
                  <Row className="label">
                    <Text>10-Consent Forms</Text>
                  </Row>
                  <Row
                    type="flex"
                    justify="center"
                    align="middle"
                    className="upload"
                  >
                    <Upload
                      multiple={true}
                      name="logo"
                      listType="text"
                      accept="application/pdf"
                      className="logo-uploader"
                      showUploadList={false}
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      beforeUpload={beforeUpload}
                      onChange={(info) =>
                        this.handleDocUpload(info, "consent_forms")
                      }
                      ref={(node) => {
                        this.consent_forms = node;
                      }}
                    >
                      {consent_forms ? (
                        <Text className="logo-button uploaded">Uploaded</Text>
                      ) : (
                        <Text className="logo-button">Upload</Text>
                      )}
                    </Upload>
                  </Row>
                  <Row>{this.renderFiles(consent_forms, "consent_forms")}</Row>
                </Col>
                <Col md={11}>
                  <Row className="label">
                    <Text>15-Rated Ages</Text>
                  </Row>
                  <Row
                    type="flex"
                    justify="center"
                    align="middle"
                    className="upload"
                  >
                    <Upload
                      multiple={true}
                      name="logo"
                      listType="text"
                      className="logo-uploader"
                      accept="application/pdf"
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                      action={""}
                      onChange={(info) => {
                        //console.log(info);
                        this.handleDocUpload({ ...info }, "rated_ages");
                      }}
                      ref={(node) => {
                        this.rated_ages = node;
                      }}
                    >
                      {rated_ages ? (
                        <Text className="logo-button uploaded">Uploaded</Text>
                      ) : (
                        <Text className="logo-button">Upload</Text>
                      )}
                    </Upload>
                  </Row>
                  <Row>{this.renderFiles(rated_ages, "rated_ages")}</Row>
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="flex-start"
                className="row"
              >
                <Col md={11}>
                  <Row className="label">
                    <Text>25-Court/WC Board Documents</Text>
                  </Row>
                  <Row
                    type="flex"
                    justify="center"
                    align="middle"
                    className="upload"
                  >
                    <Upload
                      multiple={true}
                      name="logo"
                      accept="application/pdf"
                      listType="text"
                      className="logo-uploader"
                      showUploadList={false}
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      beforeUpload={beforeUpload}
                      onChange={(info) =>
                        this.handleDocUpload(info, "court_wc_board_documents")
                      }
                      ref={(node) => {
                        this.court_wc_board_documents = node;
                      }}
                    >
                      {court_wc_board_documents ? (
                        <Text className="logo-button uploaded">Uploaded</Text>
                      ) : (
                        <Text className="logo-button">Upload</Text>
                      )}
                    </Upload>
                  </Row>
                  <Row>
                    {this.renderFiles(
                      court_wc_board_documents,
                      "court_wc_board_documents"
                    )}
                  </Row>
                </Col>
                <Col md={11}>
                  <Row className="label">
                    <Text>30-MSA Administration Agreement</Text>
                  </Row>
                  <Row
                    type="flex"
                    justify="center"
                    align="middle"
                    className="upload"
                  >
                    <Upload
                      multiple={true}
                      name="logo"
                      accept="application/pdf"
                      listType="text"
                      className="logo-uploader"
                      showUploadList={false}
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      beforeUpload={beforeUpload}
                      onChange={(info) =>
                        this.handleDocUpload(
                          info,
                          "msa_administration_agreement"
                        )
                      }
                      ref={(node) => {
                        this.msa_administration_agreement = node;
                      }}
                    >
                      {msa_administration_agreement ? (
                        <Text className="logo-button uploaded">Uploaded</Text>
                      ) : (
                        <Text className="logo-button">Upload</Text>
                      )}
                    </Upload>
                  </Row>
                  <Row>
                    {this.renderFiles(
                      msa_administration_agreement,
                      "msa_administration_agreement"
                    )}
                  </Row>
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="flex-start"
                className="row"
              >
                <Col md={11}>
                  <Row className="label">
                    <Text>35-Medical Records</Text>
                  </Row>
                  <Row
                    type="flex"
                    justify="center"
                    align="middle"
                    className="upload"
                  >
                    <Upload
                      multiple={true}
                      name="logo"
                      accept="application/pdf"
                      listType="text"
                      className="logo-uploader"
                      showUploadList={false}
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      beforeUpload={beforeUpload}
                      onChange={(info) =>
                        this.handleDocUpload(info, "medical_records")
                      }
                      ref={(node) => {
                        this.medical_records = node;
                      }}
                    >
                      {medical_records ? (
                        <Text className="logo-button uploaded">Uploaded</Text>
                      ) : (
                        <Text className="logo-button">Upload</Text>
                      )}
                    </Upload>
                  </Row>
                  <Row>
                    {this.renderFiles(medical_records, "medical_records")}
                  </Row>
                </Col>
                <Col md={11}>
                  <Row className="label">
                    <Text>40-Payment Information</Text>
                  </Row>
                  <Row
                    type="flex"
                    justify="center"
                    align="middle"
                    className="upload"
                  >
                    <Upload
                      multiple={true}
                      name="logo"
                      listType="text"
                      accept="application/pdf"
                      className="logo-uploader"
                      showUploadList={false}
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      beforeUpload={beforeUpload}
                      onChange={(info) =>
                        this.handleDocUpload(info, "payment_information")
                      }
                      ref={(node) => {
                        this.payment_information = node;
                      }}
                    >
                      {payment_information ? (
                        <Text className="logo-button uploaded">Uploaded</Text>
                      ) : (
                        <Text className="logo-button">Upload</Text>
                      )}
                    </Upload>
                  </Row>
                  <Row>
                    {this.renderFiles(
                      payment_information,
                      "payment_information"
                    )}
                  </Row>
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="flex-start"
                className="row"
              >
                <Col md={11}>
                  <Row className="label">
                    <Text>50-Supplemental Doc / Additional Info</Text>
                  </Row>
                  <Row
                    type="flex"
                    justify="center"
                    align="middle"
                    className="upload"
                  >
                    <Upload
                      multiple={true}
                      name="logo"
                      listType="text"
                      accept="application/pdf"
                      className="logo-uploader"
                      showUploadList={false}
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      beforeUpload={beforeUpload}
                      onChange={(info) =>
                        this.handleDocUpload(
                          info,
                          "supplemental_documentation_additional_info"
                        )
                      }
                      ref={(node) => {
                        this.supplemental_documentation_additional_info = node;
                      }}
                    >
                      {supplemental_documentation_additional_info ? (
                        <Text className="logo-button uploaded">Uploaded</Text>
                      ) : (
                        <Text className="logo-button">Upload</Text>
                      )}
                    </Upload>
                  </Row>
                  <Row>
                    {this.renderFiles(
                      supplemental_documentation_additional_info,
                      "supplemental_documentation_additional_info"
                    )}
                  </Row>
                </Col>
              </Row>
              <Divider className="divider" />
              <Row
                type="flex"
                justify="center"
                align="middle"
                className="confirm-button row"
              >
                <Button
                  type="link"
                  className="prev-button button"
                  onClick={this.goToPreviousStep}
                >
                  &lt; PREVIOUS STEP
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="green-btn button"
                  onClick={this.handleGenerateReport}
                >
                  Generate Report
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="green-btn back-btn button"
                  onClick={this.goToClaimant}
                >
                  Go Back
                </Button>
              </Row>
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  const props = {
    reportInfo: state.report.reportInfo,
  };
  return props;
};

export default connect(mapStateToProps, { updateReportInfo })(DocumentUpload);
