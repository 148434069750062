import React, { Component } from "react";
import moment from "moment";
import {
  Row,
  Col,
  Typography,
  Input,
  Empty,
  Avatar,
  Button,
  Modal,
} from "antd";

import { EditIcon, RemoveIcon, TrashIcon } from "components/Icons";
import SpinBox from "components/SpinBox";
import Pagination from "components/Pagination";
import * as API from "utils/api";

import "./MyUsers.scss";

const { Search } = Input;
const { Title, Text } = Typography;

export default class MyUsers extends Component {
  constructor() {
    super();

    this.state = {
      pageIndex: 0,
      showModal: false,
      usersData: [],
      totalCount: 0,
      selectedUser: {},
      searchQuery: "",
      loading: false,
      loadingTitle: "Loading...",
    };
  }

  componentDidMount() {
    this.handleSearch();
  }

  handlePagination = (pageIndex) => {
    this.setState(
      {
        pageIndex: pageIndex,
      },
      () => this.handleSearch()
    );
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
    });
  };

  handleOpenModal = (item) => {
    this.setState({
      showModal: true,
      selectedUser: item,
    });
  };

  handleEditUser = (item) => {
    this.props.history.push("/app/users/edit", { data: item });
  };

  handleDeleteUser = (item) => {
    this.setState({
      showModal: false,
      loading: true,
      loadingTitle: "Deleting...",
    });

    API.deleteUser(item.email)
      .then((res) => {
        console.log(item);
        API.deleteUserSynchronised(item.id).then((res) => {
          this.setState({ loading: false });
          Modal.success({
            content: "Success",
            onOk: () => this.handleSearch(),
          });
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        const errorMessage = error.response.data.reason
          ? error.response.data.reason[0].message
          : "Error";
        Modal.error({
          content: errorMessage,
        });
      });
  };

  handleSearch = () => {
    const { searchQuery, pageIndex } = this.state;
    this.setState({ loading: true, loadingTitle: "Loading" });
    API.getUsersDataSynchronised(searchQuery, pageIndex).then((res) => {
      this.setState({
        usersData: res.users,
        totalCount: res.total_count,
        loading: false,
      });
    });
  };

  render() {
    const {
      pageIndex,
      totalCount,
      usersData,
      showModal,
      selectedUser,
      loading,
      loadingTitle,
    } = this.state;

    return (
      <Row className="users-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              My Users
            </Title>
          </Col>
          <Col className="search">
            <Search
              placeholder="Search..."
              className="search-box"
              onChange={(e) => this.setState({ searchQuery: e.target.value })}
              onSearch={this.handleSearch}
            />
          </Col>
          <Col className="pagination">
            <Pagination
              onChange={this.handlePagination}
              totalCount={totalCount}
              pageIndex={pageIndex}
              pageSize={10}
            />
          </Col>
          <Col className="add-button">
            <Button
              type="primary"
              className="green-btn"
              onClick={() => this.props.history.push("/app/users/add")}
            >
              Add New User
            </Button>
          </Col>
        </Row>
        <Row type="flex" className="table-header">
          <Col xs={8}>User</Col>
          <Col xs={6}>Date Added</Col>
          <Col xs={5}>email</Col>
          <Col xs={5} style={{ textAlign: "right" }}>
            Actions
          </Col>
        </Row>
        <Row className="table-content">
          <SpinBox loading={loading} title={loadingTitle}>
            {usersData.length > 0 ? (
              <Row>
                {usersData.map((item, index) => (
                  <Row key={index} className="record">
                    <Col xs={8} className="user-column">
                      <Col>
                        <Text className="user-name">
                          {item.given_name + " " + item.family_name}
                        </Text>
                      </Col>
                    </Col>
                    <Col xs={6}>
                      <Text>
                        {moment(item.UserCreateDate).format("MMMM DD, YYYY")}
                      </Text>
                    </Col>
                    <Col xs={5}>
                      <Text className="wrapped-text">{item.email}</Text>
                    </Col>
                    <Col xs={5} className="actions">
                      <EditIcon
                        className="icon"
                        onClick={() => this.handleEditUser(item)}
                      />
                      <RemoveIcon
                        className="icon"
                        onClick={() => this.handleOpenModal(item)}
                      />
                    </Col>
                  </Row>
                ))}
              </Row>
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                className="empty-icon"
              />
            )}
          </SpinBox>
        </Row>
        <Modal
          visible={showModal}
          onCancel={this.handleCloseModal}
          footer={null}
          centered={true}
          closable={false}
          width={400}
          className="delete-modal"
          title={null}
        >
          <Row type="flex" align="middle" className="delete-body">
            <Row className="trash-icon">
              <TrashIcon />
            </Row>
            <Row>
              <Text className="label">Delete this User?</Text>
            </Row>
            <Row type="flex" align="middle" className="user-info">
              <Avatar src={selectedUser.client_logo} size={48} />
              <Col className="user-name">
                <Row>
                  <Text ellipsis className="text-row name">
                    {selectedUser.given_name + " " + selectedUser.family_name}
                  </Text>
                </Row>
                <Row>
                  <Text ellipsis className="text-row">
                    {selectedUser.email}
                  </Text>
                </Row>
              </Col>
            </Row>
            <Row>
              <Button
                type="primary"
                className="pink-btn button"
                onClick={() => this.handleDeleteUser(selectedUser)}
              >
                Delete
              </Button>
            </Row>
            <Row className="cancel-button">
              <Text onClick={this.handleCloseModal}>Cancel</Text>
            </Row>
          </Row>
        </Modal>
      </Row>
    );
  }
}
