import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Input,
  Upload,
  Steps,
  Icon,
  message,
  Typography,
  Alert,
  Modal,
  Select,
} from "antd";
import UploadLogo from "components/UploadLogo/UploadLogo";
import AuthSideBar from "../../../components/AuthSideBar";
import CreditCard from "components/CreditCard/CreditCard.jsx";
// import Cards from "react-credit-cards";
import * as API from "utils/api";
import InputBox from "components/InputBox";

import {
  UserOutlined,
  SolutionOutlined,
  LoadingOutlined,
  CreditCardOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import "react-credit-cards/lib/styles.scss";
// import 'react-credit-cards/es/styles-compiled.css';
import InputMask from "react-input-mask";

import "containers/Auth/SignUp/SignUp.scss";
export default function Signup(props) {
  const { Title, Text } = Typography;
  const { Option } = Select;

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const { Step } = Steps;
  const steps = [
    {
      title: "Personal Information",
      content: "First-content",
      key: 1,
      icon: <UserOutlined />,
    },
    {
      title: "Organization",
      content: "Second-content",
      key: 2,
      icon: <SolutionOutlined />,
    },
    {
      title: "Pay",
      content: "Last-content",
      key: 3,
      icon: <CreditCardOutlined />,
    },
  ];
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageurl] = useState("");

  const [userData, setUserData] = useState({
    email: "",
    given_name: "",
    family_name: "",
    locale: "",
    errorName: "",
    errorLname: "",
    errorPhone: "",
    errorEmail: "",
  });

  const [TenantData, setTenantData] = useState({
    tenant_name: "",
    TenantNameError: "",
    website: "",
    WebsiteErrorr: "",
    address_line: "",
    adressError: "",
    address_line_2: "",
    phone_number_1: "",
    TenantPhoneErrorr: "",
    phone_number_2: "",
    client_logo: "",
  });
  const [cardFields, setCardFields] = useState({
    cvc: "",
    expiry: "",
    focus: "",
    name: "",
    number: "",
    lastName: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    email: "",
  });

  const [juridiction_list, setJuridiction_list] = useState([]);
  const [state, setStatename] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [existEmailError, setExistEmailErrorr] = useState("");

  const createFinalAddress = () => {
    var address = `${TenantData.address_line.replace(
      /,/g,
      ""
    )},${TenantData.address_line_2.replace(/,/g, "")},${city.replace(
      /,/g,
      ""
    )},${state.replace(/,/g, "")},${zip.replace(/,/g, "")}`;
    console.log(address, "address");
    // this.props.getAddress(address);
  };
  const handleNextStep = () => {
    setCurrent(current + 1);
    if (current == 0) {
      if (userData.given_name.length == 0) {
        setUserData({
          ...userData,
          errorName: "Name cannot be empty!",
        });
        setCurrent(current);

        return;
      }
      if (userData.family_name.length == 0) {
        setCurrent(current);
        setUserData({
          ...userData,
          errorLname: "Last Name cannot be empty!",
        });
        return;
      }
      if (userData.locale.length == 0) {
        setCurrent(current);
        setUserData({
          ...userData,
          errorPhone: "Phone cannot be empty!",
        });
        return;
      }
      if (userData.email.length == 0) {
        setCurrent(current);
        setUserData({
          ...userData,
          errorEmail: "Email cannot be empty!",
        });
        return;
      }
    }
    if (current == 1) {
      // UserDataSubmit();
      handleTenantsSubmit();
    }
  };

  const handlePreviousStep = () => {
    setCurrent(current - 1);
  };
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(
        info.file.originFileObj,
        (imageUrl) => setImageurl(imageUrl),
        //   this.setState({
        //     imageUrl,
        //     loading: false,
        //   }),
        console.log(imageUrl, "image")
      );
    }
  };

  const handleCardFocus = (e) => {
    setCardFields({ ...cardFields, focus: e.target.name });
  };

  const handleCardInput = (e) => {
    const { name, value } = e.target;

    if (name == "cvc" && value.length > 3) {
      return;
    }
    if (name == "number" && value.length > 16) {
      return;
    }
    if (name == "expiry" && value.length > 4) {
      return;
    }

    setCardFields({ ...cardFields, [name]: value });
  };

  const handleUserInfo = (e) => {
    setUserData({
      ...userData,
      errorName: "",
      errorLname: "",
      errorPhone: "",
      errorEmail: "",
      [e.target.name]: e.target.value,
    });
    console.log(userData);
  };
  const handleTenantData = (e) => {
    setTenantData({
      ...TenantData,
      TenantNameError: "",
      WebsiteErrorr: "",
      TenantPhoneErrorr: "",
      adressError: "",
      [e.target.name]: e.target.value,
    });
  };

  const handleTenantsSubmit = () => {
    let params = {
      user: {
        given_name: userData.given_name,
        family_name: userData.family_name,
        locale: userData.locale,
        email: userData.email,
      },
      tenant: {
        tenant_name: TenantData.tenant_name,
        website: TenantData.website,
        address_line: TenantData.address_line,
        address_line_2: TenantData.address_line,
        phone_number_1: TenantData.phone_number_1,
        phone_number_2: TenantData.phone_number_1,
        city: city,
        state: state,
        zip: zip,
        client_logo: TenantData.client_logo,
      },
    };
    console.log(params);
    if (TenantData.tenant_name.length == 0) {
      setCurrent(current);
      setTenantData({
        ...TenantData,
        TenantNameError: "Name cannot be empty!",
      });
      return;
    }
    if (TenantData.website.length == 0) {
      setCurrent(current);
      setTenantData({
        ...TenantData,
        WebsiteErrorr: "Website cannot be empty!",
      });
      return;
    }
    if (TenantData.address_line.length == 0) {
      setCurrent(current);
      setTenantData({
        ...TenantData,
        adressError: "Adress cannot be empty!",
      });
      return;
    }
    if (TenantData.phone_number_1.length == 0) {
      setCurrent(current);
      setTenantData({
        ...TenantData,
        TenantPhoneErrorr: "Phone cannot be empty!",
      });
      return;
    }
    API.createUserTenant(params)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        if (error.response && error.responsedata) {
          const errorMessage =
            error.response.data.reason && error.response.data.reason[0].message;
          if (
            errorMessage == "An account with the given email already exists."
          ) {
            setExistEmailErrorr(errorMessage);
            setCurrent(current);
          }
        }
      });
  };

  useEffect(() => {
    API.getStates().then((res) => {
      console.log(juridiction_list, "juri");
      // this.setState({ juridiction_list: res.juridictionStates });
      setJuridiction_list(res.data.states);
    });
  }, []);

  const CreatePaymentProfile = () => {
    let data = {
      firstName: cardFields.name,
      lastName: userData.lastname,
      cardNumber: cardFields.number,
      expirationDate: cardFields.expiry,
      cvc: cardFields.cvc,
      address: TenantData.address_line,
      city: city,
      state: state,
      zip: zip,
      number: userData.locale,
      email: userData.email,
    };
    console.log(data);
    API.createUserBankData(data)
      .then((res) => {
        Modal.success({
          content: "Success! Check your email to login.",
        });
        props.history.push("/login");
      })
      .catch((error) => {
        let errorMessage = error.response.data.error;
        Modal.error({
          title: "Oops something went wrong!",
          content: errorMessage,
        });
        console.log(error.response.data.error, "errorr");
      });
  };

  return (
    <>
      <Row type="flex" justify="center" className="login-container">
        <Col xs={0} xl={8}>
          <AuthSideBar />
        </Col>
        <Col xs={24} xl={16} className="main_login_container">
          <Row style={{ display: "flex", flexDirection: "row-reverse" }}>
            <div className="auth_wrapper">
              <Text>Have an Account?</Text>
              <Button
                type="primary"
                href="/login"
                className="signup_cta_btn"
                ghost
              >
                Sign in
              </Button>
            </div>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={20} md={24}>
              <Row
                type="flex"
                justify="space-around"
                //   className="main-container"
              >
                <Row
                  type="flex"
                  justify="center"
                  style={{
                    flexDirection: "column",
                    width: "80%",
                    paddingBottom: "20px",
                  }}
                  align="middle"
                >
                  <Steps current={current}>
                    {steps.map((item) => (
                      <Step
                        key={item.title}
                        title={item.title}
                        icon={item.icon}
                      />
                    ))}
                  </Steps>
                  <div className="steps-content">
                    {steps[current].content === "First-content" && (
                      <div style={{ marginTop: "18px" }}>
                        <Input
                          placeholder="First Name"
                          className="card_input"
                          name="given_name"
                          value={userData.given_name}
                          onChange={handleUserInfo}
                        />

                        <Alert
                          type="error"
                          message={userData.errorName}
                          banner
                          style={
                            userData.errorName
                              ? { display: "block", marginBottom: "6px" }
                              : { display: "none" }
                          }
                        />

                        <Input
                          placeholder="Last Name"
                          className="card_input"
                          name="family_name"
                          value={userData.family_name}
                          onChange={handleUserInfo}
                        />
                        <Alert
                          type="error"
                          message={userData.errorLname}
                          banner
                          style={
                            userData.errorLname
                              ? { display: "block", marginBottom: "6px" }
                              : { display: "none" }
                          }
                        />

                        <Input
                          placeholder="Phone  "
                          className="card_input"
                          name="locale"
                          value={userData.locale}
                          onChange={handleUserInfo}
                        />
                        <Alert
                          type="error"
                          message={userData.errorPhone}
                          banner
                          style={
                            userData.errorPhone
                              ? { display: "block", marginBottom: "6px" }
                              : { display: "none" }
                          }
                        />

                        <Input
                          placeholder="Email"
                          className="card_input"
                          name="email"
                          value={userData.email}
                          onChange={handleUserInfo}
                        />
                        <Alert
                          type="error"
                          message={userData.errorEmail}
                          banner
                          style={
                            userData.errorEmail
                              ? { display: "block", marginBottom: "6px" }
                              : { display: "none" }
                          }
                        />
                        {existEmailError && (
                          <Alert
                            type="error"
                            message={existEmailError}
                            banner
                          />
                        )}
                      </div>
                    )}
                    {steps[current].content === "Second-content" && (
                      <div style={{ marginTop: "18px" }}>
                        <Row>
                          <Text>Company Name</Text>
                        </Row>
                        <Input
                          placeholder="Company Name"
                          name="tenant_name"
                          value={TenantData.tenant_name}
                          onChange={handleTenantData}
                          className="card_input"
                        />
                        <Alert
                          type="error"
                          message={TenantData.TenantNameError}
                          banner
                          style={
                            TenantData.TenantNameError
                              ? { display: "block", marginBottom: "6px" }
                              : { display: "none" }
                          }
                        />
                        <Row>
                          <Text>Website</Text>
                        </Row>
                        <Input
                          placeholder="Website"
                          name="website"
                          value={TenantData.website}
                          onChange={handleTenantData}
                          className="card_input"
                        />
                        <Alert
                          type="error"
                          message={TenantData.WebsiteErrorr}
                          banner
                          style={
                            TenantData.WebsiteErrorr
                              ? { display: "block", marginBottom: "6px" }
                              : { display: "none" }
                          }
                        />
                        <Row>
                          <Text>Address</Text>
                        </Row>
                        <Input
                          placeholder="Address"
                          className="card_input"
                          name="address_line"
                          value={TenantData.address_line}
                          onChange={handleTenantData}
                        />

                        <Alert
                          type="error"
                          message={TenantData.adressError}
                          banner
                          style={
                            TenantData.adressError
                              ? { display: "block", marginBottom: "6px" }
                              : { display: "none" }
                          }
                        />
                        <Row>
                          <Text>City</Text>
                        </Row>
                        <Input
                          placeholder="City"
                          className="card_input"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />

                        <Row>
                          <Text>State</Text>
                        </Row>
                        <Select
                          showSearch
                          placeholder="Please select"
                          optionFilterProp="children"
                          className="dropdown "
                          style={{
                            background: "#f2f2f2",
                            marginBottom: "10px",
                          }}
                          value={state}
                          onChange={(value) => {
                            setStatename(value);
                          }}
                          suffixIcon={
                            <Icon
                              type="caret-down"
                              style={{ color: "black" }}
                              theme="filled"
                            />
                          }
                        >
                          {juridiction_list.map((el, i) => {
                            return (
                              <Option key={i} value={el.name}>
                                {el.name}
                              </Option>
                            );
                          })}
                        </Select>
                        <Row>
                          <Text>Zip</Text>
                        </Row>
                        <InputMask
                          mask="99999"
                          placeholder="Zip"
                          className="input-ssn card_input"
                          value={zip}
                          onChange={(e) =>
                            setZip(e.target.value, createFinalAddress)
                          }
                        />
                        <Row>
                          <Text>Phone</Text>
                        </Row>
                        <Input
                          placeholder="Phone"
                          className="card_input"
                          name="phone_number_1"
                          value={TenantData.phone_number_1}
                          onChange={handleTenantData}
                        />
                        <Alert
                          type="error"
                          message={TenantData.TenantPhoneErrorr}
                          banner
                          style={
                            TenantData.TenantPhoneErrorr
                              ? { display: "block", marginBottom: "6px" }
                              : { display: "none" }
                          }
                        />
                        <UploadLogo
                          changeImage={(logo) => {
                            setTenantData({ ...TenantData, client_logo: logo });
                          }}
                          client_logo={TenantData.client_logo}
                        />
                      </div>
                    )}
                    {steps[current].content === "Last-content" && (
                      <>
                        <Row style={{ width: "100%" }}>
                          <div id="App-payment">
                            <CreditCard
                              cardFields={cardFields}
                              handleCardInput={handleCardInput}
                              handleCardFocus={handleCardFocus}
                              CreatePaymentProfile={CreatePaymentProfile}
                            />
                          </div>
                        </Row>
                      </>
                    )}
                  </div>
                  <div className="steps-action">
                    {current < steps.length - 1 && (
                      <Button
                        type="primary"
                        size="large"
                        id={"input" + current}
                        className="green-btn button secondary_action_btn"
                        onClick={() => handleNextStep()}
                      >
                        Next
                      </Button>
                    )}
                    {current === steps.length - 1 && (
                      <Button
                        type="primary"
                        id="Done"
                        className="green-btn button secondary_action_btn "
                        onClick={CreatePaymentProfile}
                      >
                        Done
                      </Button>
                    )}
                    {current > 0 && (
                      <Button
                        style={{ margin: "0 8px" }}
                        className="secondary_action_btn"
                        onClick={() => handlePreviousStep()}
                      >
                        Previous
                      </Button>
                    )}
                  </div>
                </Row>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>{" "}
    </>
  );
}
