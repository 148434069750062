import React, { useState } from "react";
import {
  Row,
  Col,
  Typography,
  Button,
  Modal,
  Select,
  Radio,
  Icon,
  Checkbox,
  message,
  Upload,
  InputNumber,
  Input,
} from "antd";
import * as API from "utils/api";
import "../ReportModal/ReportModal.scss";
import InputBox from "components/InputBox";
import { UploadOutlined } from "@ant-design/icons";
import SpinBox from "components/SpinBox";

const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

export default function Index({
  isOpen,
  onConfirm,
  onCancel,
  onChangeName,
  EditFile,
  onChange,
  onFileSubmit,
  loading,
}) {
  // const { file } = editFileID;

  // const [loading, setLoading] = useState(false);
  // console.log(editFileID);
  // const [data, setData] = useState(editFileID.file ? editFileID.file : {});

  // let fileDatas = { ...file };
  //   console.log(file && file.name, "boject");
  //   let name = file ? file.name : "";
  //   let type = file && file.type;

  return (
    <div>
      <Modal
        visible={isOpen}
        onCancel={onCancel}
        footer={null}
        centered={true}
        closable={true}
        width={550}
        className="report-modal"
        title={"Update File"}
      >
        <Row type="flex" align="middle" className="report-body">
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            className="main-info"
          >
            <SpinBox loading={loading}>
              <Col md={24}>
                <InputBox
                  label="Name"
                  name="name"
                  value={EditFile.name}
                  onChange={onChangeName}
                  // onChange={(name) => {
                  //   setData({
                  //     ...data,
                  //     name: name,
                  //   });
                  //   console.log(name);
                  // }}
                />
              </Col>
            </SpinBox>
          </Row>

          <Button
            type="primary"
            size="large"
            className="green-btn button"
            onClick={onFileSubmit}
          >
            Update
          </Button>
        </Row>
      </Modal>
    </div>
  );
}
