import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  Row,
  Col,
  Typography,
  Button,
  Select,
  Icon,
  DatePicker,
  Input,
  Divider,
  Upload,
  Modal,
  message,
} from "antd";
import ClaimAndInjury from "components/ClaimAndInjury/index";
import InlineContact from "components/InlineContact";
import SpinBox from "components/SpinBox";
import TextAreaBox from "components/TextAreaBox";
import InputBox from "components/InputBox";
import TagsInputBox from "components/TagsInputBox";
import InputCurrencyBox from "components/InputCurrencyBox";
import { BackIcon } from "components/Icons";
import { getBase64 } from "utils/utils";
import { updateReportInfo } from "redux/report";
import * as API from "utils/api";
import * as Utils from "utils/utils";
import dynamicData from "dynamicData";

import "./Demographic.scss";
import ClaimantInfo from "components/ClaimantInfoOnWizard";

const { Title, Text } = Typography;
const { Option } = Select;

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

class Demographic extends Component {
  constructor(props) {
    super(props);

    let report_id = "";
    if (this.props.location && this.props.location.state) {
      report_id = this.props.location.state.data.report_id;
    }

    this.state = {
      report_id: report_id,
      contactsList: [],
      icdCodeList: [],
      juridictionStateList: [],
      plaintiff_attorney: undefined,
      defense_attorney: undefined,
      insurance_carrier: undefined,
      report_claim_id: "",
      employer_demographics: undefined,
      body_parts: "",
      additional_comments: "",
      related_diagnosis: [],
      referral_date: moment(),
      date_of_report: moment(),
      signature: "",
      imageUrl: "",
      icd_code: "",
      icd_descr: "",
      newIcdCodeList: [],
      isModalOpen: false,
      medicareInfo: {},
      loadingTitle: "Loading...",
      loading: false,
      report_contacts: [],
      juridiction_state_id: undefined,
      date_of_injury: moment(),
      juridictionStateList: [],
      report_contacts: [],
      plaintifList: [],
      defenseList: [],
      insuranceList: [],
      employerList: [],
    };
    this.timeoutId = setInterval(
      this.handleUpdateReport,
      this.props.autoSavingTime
    );
  }
  componentWillUnmount = () => {
    clearInterval(this.timeoutId);
  };
  handleSearchIcdCodes = (value) => {
    API.getIcdCodeData(value).then((res) => {
      let correctArr = [...res.icd_codes];
      console.log(res.icd_codes);
      this.setState({
        icdCodeList: correctArr,
      });
    });
  };
  componentDidMount() {
    const { report_id, newIcdCodeList } = this.state;

    this.setState({ loading: true });
    API.getContactsData("", 0, 1000) //Getting all contacts data
      .then((res) => {
        const dynamic = dynamicData();

        this.setState({
          contactsList: res.contacts,
          plaintifList: res.contacts.filter(
            (item) =>
              (item.contact_type ? item.contact_type.name : "") ==
              dynamic.plaintiff_attorney
          ),
          defenseList: res.contacts.filter(
            (item) =>
              (item.contact_type ? item.contact_type.name : "") ==
              dynamic.defense_attorney
          ),
          insuranceList: res.contacts.filter(
            (item) =>
              (item.contact_type ? item.contact_type.name : "") ==
              dynamic.insurance_carrier
          ),
          employerList: res.contacts.filter(
            (item) =>
              (item.contact_type ? item.contact_type.name : "") ==
              dynamic.employer_demographics
          ),
        });
      });
    API.getIcdCodeData().then((res) => {
      let correctArr = [...res.icd_codes];
      this.setState({
        icdCodeList: correctArr,
      });
    });
    API.getJuridictionStateList().then((res) => {
      this.setState({
        juridictionStateList: res.juridictionStates,
      });
    });
    API.getMedicareInfoData(report_id).then((res) => {
      this.setState({
        medicareInfo: res.totals,
        loading: false,
      });
    });
    API.getReportContacts(report_id).then((res) => {
      console.log(res);
      this.setState({ report_contacts: res.report_contacts.rows });
    });
    API.getReportById(report_id).then((res) => {
      const reportInfo = res.report;
      this.props.updateReportInfo(reportInfo);
      let related_diagnosis = [];
      let newIcdCodeList = [];
      if (reportInfo.related_diagnosis && reportInfo.related_diagnosis !== "") {
        related_diagnosis = reportInfo.related_diagnosis.split(",");
        newIcdCodeList = related_diagnosis.map((item) => {
          return {
            ...JSON.parse(item.split("<").join(",").split("$").join(",")),
            //med_descr: "",
          };
        });
      }
      this.setState({
        oldDiagnosis: reportInfo.related_diagnosis,
        plaintiff_attorney: reportInfo.plaintiff_attorney || undefined,
        defense_attorney: reportInfo.defense_attorney || undefined,
        insurance_carrier: reportInfo.insurance_carrier || undefined,
        report_claim_id: reportInfo.report_claim_id || "",
        body_parts: reportInfo.body_parts,
        additional_comments: reportInfo.additional_comments,
        related_diagnosis: related_diagnosis,
        newIcdCodeList: newIcdCodeList,
        juridiction_state_id: reportInfo.juridiction_state_id || undefined,
        date_of_injury: reportInfo.date_of_injury
          ? moment(reportInfo.date_of_injury)
          : moment(),
        referral_date: reportInfo.referral_date
          ? moment(reportInfo.referral_date)
          : moment(),
        date_of_report: reportInfo.date_of_report
          ? moment(reportInfo.date_of_report)
          : moment(),
        signature: reportInfo.is_signature_photo ? "" : reportInfo.signature,
        imageUrl: reportInfo.is_signature_photo ? reportInfo.signature : "",
        loading: false,
      });
    });
  }

  handlePhotoChange = (info) => {
    // if (info.file.status === 'uploading') {
    //   this.setState({ loading: true });
    //   return;
    // }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl,
          loading: false,
        })
      );
    }
  };

  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  handleAddNewICDCode = () => {
    const {
      icd_code,
      icd_descr,
      newIcdCodeList,
      related_diagnosis,
    } = this.state;

    const newItem = { code: icd_code, med_descr: icd_descr };
    if (icd_code === "" || icd_descr === "") {
      Modal.error({
        content: "Please input the values",
      });
      return;
    }

    newItem.med_descr = newItem.med_descr.split(",").join("$");
    newIcdCodeList.push(newItem);
    related_diagnosis.push(JSON.stringify(newItem).split(",").join("<"));

    this.setState({ isModalOpen: false, icd_code: "", icd_descr: "" });
  };
  getReportContacts = async () => {
    const { report_id } = this.state;
    let res = await API.getReportContacts(report_id);
    this.setState({ report_contacts: res.report_contacts.rows });
  };
  handleUpdateReport = (isNextStep = false) => {
    const {
      report_id,
      plaintiff_attorney,
      defense_attorney,
      insurance_carrier,
      juridiction_state_id,
      date_of_injury,
      report_claim_id,
      body_parts,
      additional_comments,
      related_diagnosis,
      referral_date,
      date_of_report,
      signature,
      imageUrl,
      newIcdCodeList,
    } = this.state;

    const new_related_diagnosis = [];
    related_diagnosis.forEach((value) => {
      let item = newIcdCodeList.find((ele) => value === ele.code);
      if (item) {
        new_related_diagnosis.push(JSON.stringify(item).replace(",", "|"));
      } else new_related_diagnosis.push(value);
    });

    const params = {
      id: report_id,
      plaintiff_attorney,
      defense_attorney,
      insurance_carrier,
      report_claim_id,
      body_parts,
      additional_comments,
      related_diagnosis: new_related_diagnosis.toString(),
      referral_date: moment(referral_date).format("MM/DD/YYYY"),
      date_of_report: moment(date_of_report).format("MM/DD/YYYY"),
      signature: imageUrl ? imageUrl : signature,
      is_signature_photo: imageUrl ? true : false,
      juridiction_state_id,
      date_of_injury: moment(date_of_injury).format("MM/DD/YYYY"),
    };

    if (isNextStep) {
      this.setState({
        loading: true,
        loadingTitle: "Saving...  ",
      });

      API.updateReport(params).then((res) => {
        this.props.updateReportInfo(res.report);
        this.setState({ loading: false });
        clearTimeout(this.timeoutId);
        this.props.history.push("/app/reports/mcp/medicalContent", {
          data: { report_id: report_id },
        });
      });
    } else {
      API.updateReport(params).then((res) => {
        this.props.updateReportInfo(res.report);
      });
    }
  };

  render() {
    let dataDynamic = { ...dynamicData() };
    const {
      contactsList,
      icdCodeList,
      newIcdCodeList,
      plaintiff_attorney,
      defense_attorney,
      insurance_carrier,
      report_claim_id,
      body_parts,
      additional_comments,
      related_diagnosis,
      referral_date,
      date_of_report,
      signature,
      imageUrl,
      icd_code,
      icd_descr,
      isModalOpen,
      medicareInfo,
      loadingTitle,
      loading,
      juridiction_state_id,
      date_of_injury,
      juridictionStateList,
      plaintifList,
      defenseList,
      insuranceList,
      employerList,
    } = this.state;
    console.log(related_diagnosis, " related_diagnosis");

    return (
      <Row className="msa-demographic-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Setup MCP Report
            </Title>
          </Col>
          <Col className="back" onClick={() => this.props.history.goBack()}>
            <BackIcon className="icon" />
            <Text>Cancel</Text>
          </Col>
          <Col className="step">
            <Text>Step 1 of 5</Text>
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <ClaimantInfo
                    claimant={{
                      ...this.props.reportInfo.claimant,
                      report_claim_id,
                    }}
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <Text className="title">Demographic Information</Text>
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={11}>
                  <InlineContact
                    getReportContacts={this.getReportContacts}
                    contact_type_id={dataDynamic.plaintiff_attorney_id}
                    another={false}
                    label="Plaintiff Attorney"
                    report_id={this.state.report_id}
                    contactsList={plaintifList}
                    type={"plaintiff_attorney"}
                    value={this.state.report_contacts
                      .filter((item) => item.type === "plaintiff_attorney")
                      .map((item) => {
                        if (!item.contact) {
                          return null;
                        }
                        return item.contact.id;
                      })
                      .filter((x) => x)}
                  />
                </Col>
                <Col md={11}>
                  <InlineContact
                    getReportContacts={this.getReportContacts}
                    contact_type_id={dataDynamic.defense_attorney_id}
                    another={false}
                    label="Defense Attorney"
                    report_id={this.state.report_id}
                    contactsList={defenseList}
                    type={"defense_attorney"}
                    value={this.state.report_contacts
                      .filter((item) => item.type === "defense_attorney")
                      .map((item) => item.contact.id)}
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={11}>
                  <InlineContact
                    getReportContacts={this.getReportContacts}
                    contact_type_id={dataDynamic.insurance_carrier_id}
                    another={false}
                    label="Insurance Carrier"
                    report_id={this.state.report_id}
                    contactsList={insuranceList}
                    type={"insurance_carrier"}
                    value={this.state.report_contacts
                      .filter((item) => item.type === "insurance_carrier")
                      .map((item) => item.contact.id)}
                  />
                </Col>
                <Col style={{ marginTop: -52 }} md={11}>
                  <Row>
                    <Text>State of Jurisdiction</Text>
                  </Row>
                  <Row>
                    <Select
                      showSearch
                      placeholder="Please select"
                      optionFilterProp="children"
                      className="dropdown"
                      value={juridiction_state_id}
                      onChange={(value) =>
                        this.setState({ juridiction_state_id: value })
                      }
                      suffixIcon={
                        <Icon
                          type="caret-down"
                          style={{ color: "black" }}
                          theme="filled"
                        />
                      }
                    >
                      {juridictionStateList.map((item) => (
                        <Option value={item.id} key={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Row>
                </Col>
                {/* <Col md={11}>
                  <InputBox
                    label="Claim Number"
                    placeholder="Please enter"
                    value={report_claim_id}
                    onChange={(value) =>
                      this.setState({ report_claim_id: value })
                    }
                  />
                </Col> */}
              </Row>

              <ClaimAndInjury
                onChange={(value) => this.setState({ report_claim_id: value })}
                report_claim_id={report_claim_id}
              />

              {/* <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <TagsInputBox
                    label="Body Parts/Conditions Accepted"
                    placeholder="Please enter"
                    value={body_parts}
                    onChange={(value) => this.setState({ body_parts: value })}
                  />
                </Col>
              </Row> */}
              {/* <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <TextAreaBox
                    label="Additional Comments"
                    placeholder="Please enter"
                    value={additional_comments}
                    onChange={(value) =>
                      this.setState({ additional_comments: value })
                    }
                  />
                </Col>
              </Row> */}
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <Row className="label">
                    <Text>Related Diagnosis</Text>
                  </Row>
                  <Row>
                    <Select
                      mode="multiple"
                      className="dropdown"
                      // optionFilterProp="filter"
                      placeholder="Please enter"
                      value={related_diagnosis}
                      filterOption={false}
                      // showSearch={true}
                      onSearch={(value) => {
                        if (value) {
                          this.handleSearchIcdCodes(value);
                        }
                      }}
                      onChange={(value) => {
                        this.setState({ related_diagnosis: value });
                      }}
                    >
                      {icdCodeList.map((item, index) => {
                        let value = JSON.stringify({
                          code: item.code,
                          med_descr: item.med_descr
                            ? item.med_descr.replace(/,/g, "$")
                            : "",
                        }).replace(/,/g, "<");
                        return (
                          <Option
                            key={index}
                            //filter={item.code.concat("_" + item.med_descr)}
                            value={value}
                          >
                            {`${item.med_descr} (${item.code})`}
                          </Option>
                        );
                      })}
                      {newIcdCodeList.map((item, index) => {
                        let value = JSON.stringify({
                          code: item.code,
                          med_descr: item.med_descr.replace(/,/g, "$"),
                        }).replace(/,/g, "<");
                        return (
                          <Option
                            key={index}
                            //filter={item.code.concat("_" + item.med_descr)}
                            value={value}
                          >
                            {item.med_descr}
                          </Option>
                        );
                      })}
                    </Select>
                  </Row>
                  <Row>
                    <Button
                      type="primary"
                      className="green-btn add-icd-code_button"
                      onClick={this.handleOpenModal}
                    >
                      Add Custom Code
                    </Button>
                  </Row>
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <InputCurrencyBox
                    label="Future Medical Costs"
                    placeholder="Please enter"
                    disabled={true}
                    value={
                      medicareInfo.total_cost_total
                        ? medicareInfo.total_cost_total.toFixed(2)
                        : 0
                    }
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={11}>
                  <Row className="label">
                    <Text>Referral Date</Text>
                  </Row>
                  <Row>
                    <DatePicker
                      className="date"
                      format="MM/DD/YYYY"
                      value={referral_date}
                      onChange={(date, dateString) =>
                        this.setState({ referral_date: date })
                      }
                    />
                  </Row>
                </Col>
                <Col md={11}>
                  <Row className="label">
                    <Text>Date of Report</Text>
                  </Row>
                  <Row>
                    <DatePicker
                      className="date"
                      format="MM/DD/YYYY"
                      value={date_of_report}
                      onChange={(date, dateString) =>
                        this.setState({ date_of_report: date })
                      }
                    />
                  </Row>
                </Col>
              </Row>
              <Row className="row">
                <Row className="label">
                  <Text>Signature</Text>
                </Row>
                <Row type="flex" justify="space-between" align="middle">
                  <Col md={11}>
                    <Input
                      placeholder="Your Name"
                      className="input"
                      value={signature}
                      onChange={(e) =>
                        this.setState({ signature: e.target.value })
                      }
                    />
                  </Col>
                  <Col md={1} style={{ textAlign: "center" }}>
                    <Text>or</Text>
                  </Col>
                  <Col md={11}>
                    <Row
                      type="flex"
                      justify="center"
                      align="middle"
                      className="upload"
                    >
                      <Upload
                        name="logo"
                        listType="text"
                        className="logo-uploader"
                        showUploadList={false}
                        action="/"
                        beforeUpload={beforeUpload}
                        onChange={this.handlePhotoChange}
                        ref={(node) => {
                          this.upload_btn = node;
                        }}
                      >
                        {imageUrl ? (
                          <img
                            src={imageUrl}
                            alt="signature"
                            className="signature-image"
                          />
                        ) : (
                          <Text className="logo-button">
                            Upload a picture of signature
                          </Text>
                        )}
                      </Upload>
                    </Row>
                  </Col>
                </Row>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                {/* <Col md={11}>
                  <Row>
                    <Text>State of Jurisdiction</Text>
                  </Row>
                  <Row>
                    <Select
                      showSearch
                      placeholder="Please select"
                      optionFilterProp="children"
                      className="dropdown"
                      value={juridiction_state_id}
                      onChange={(value) =>
                        this.setState({ juridiction_state_id: value })
                      }
                      suffixIcon={
                        <Icon
                          type="caret-down"
                          style={{ color: "black" }}
                          theme="filled"
                        />
                      }
                    >
                      {juridictionStateList.map((item) => (
                        <Option value={item.id} key={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Row>
                </Col> */}
                {/* <Col md={11}>
                  <Row className="label">
                    <Text>Injury Date</Text>
                  </Row>
                  <Row>
                    <DatePicker
                      className="date"
                      format="MM/DD/YYYY"
                      value={date_of_injury}
                      onChange={(date, dateString) =>
                        this.setState({ date_of_injury: date })
                      }
                    />
                  </Row>
                </Col> */}
              </Row>

              <Divider className="divider" />
              <Row
                type="flex"
                justify="center"
                align="middle"
                className="confirm-button"
              >
                <Button
                  type="primary"
                  size="large"
                  className="green-btn button"
                  onClick={() => this.handleUpdateReport(true)}
                >
                  Next Step
                </Button>
              </Row>
            </Row>
          </SpinBox>
        </Row>
        <Modal
          visible={isModalOpen}
          onCancel={this.handleCloseModal}
          footer={null}
          centered={true}
          closable={true}
          width={500}
          className="add-icd-code-modal"
          title={"Add Custom Code"}
        >
          <Row className="modal-body">
            <Row type="flex" justify="start" align="middle" className="row">
              <Col className="label">
                <Text className="label">Code</Text>
              </Col>
              <Col className="input-col">
                <Input
                  placeholder={"Required"}
                  className="input"
                  disabled={false}
                  value={icd_code}
                  onChange={(e) => this.setState({ icd_code: e.target.value })}
                />
              </Col>
            </Row>
            <Row type="flex" justify="start" align="middle" className="row">
              <Col className="label">
                <Text className="label">Description</Text>
              </Col>
              <Col className="input-col">
                <Input
                  placeholder={"Required"}
                  className="input"
                  disabled={false}
                  value={icd_descr}
                  onChange={(e) => this.setState({ icd_descr: e.target.value })}
                />
              </Col>
            </Row>
            <Row type="flex" justify="end">
              <Button
                type="primary"
                className="green-btn button"
                onClick={this.handleAddNewICDCode}
              >
                ADD
              </Button>
            </Row>
          </Row>
        </Modal>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  const props = {
    autoSavingTime: state.report.autoSavingTime,
    reportInfo: state.report.reportInfo,
  };
  return props;
};

export default connect(mapStateToProps, { updateReportInfo })(Demographic);
