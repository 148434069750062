import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Row, Col, Button, Input, Typography } from "antd";
import { login } from "../../../redux/auth/actions";
import AuthSideBar from "../../../components/AuthSideBar";
import Password from "../../../components/Password";
import "./Login.scss";

const { Title, Text } = Typography;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      changeFlag: false,
    };
  }

  login = () => {
    const { username, password } = this.state;
    this.setState({ changeFlag: false });
    this.props.login(username, password);
  };

  render() {
    const { username, password, changeFlag } = this.state;
    const { errorMessage, isLoading } = this.props;

    if (this.props.isNewPasswordRequest === true) {
      return <Redirect to="/setPassword" />;
    }

    if (this.props.isAuthenticated === true) {
      return <Redirect to="/" />;
    }
    return (
      <Row type="flex" justify="center" className="login-container">
        <Col xs={0} xl={8}>
          <AuthSideBar />
        </Col>
        <Col xs={24} xl={16}>
          <Row style={{ display: "flex", flexDirection: "row-reverse" }}>
            <div className="auth_wrapper">
              <Text>Don't have an Account?</Text>
              <Button
                type="primary"
                href="/Signup"
                className="signup_cta_btn"
                ghost
              >
                Sign Up
              </Button>
            </div>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={20} md={10}>
              <Row
                type="flex"
                justify="space-around"
                className="main-container"
              >
                <Col style={{ margin: "auto" }}>
                  <Row
                    type="flex"
                    justify="center"
                    align="middle"
                    style={{ flexDirection: "column" }}
                  >
                    <Title level={1} className="title">
                      Sign In
                    </Title>
                    <Row className="input-row">
                      <Input
                        size="large"
                        placeholder="Login"
                        className="email"
                        value={username}
                        onChange={(e) =>
                          this.setState({
                            username: e.target.value,
                            changeFlag: true,
                          })
                        }
                        onPressEnter={this.login}
                      />
                      <Password
                        size="large"
                        placeholder="Password"
                        className="password"
                        value={password}
                        onChange={(e) =>
                          this.setState({
                            password: e.target.value,
                            changeFlag: true,
                          })
                        }
                        onPressEnter={this.login}
                      />
                    </Row>
                    <Row className="error-text">
                      {errorMessage && !changeFlag && (
                        <Text type="danger">{errorMessage}</Text>
                      )}
                    </Row>
                    <Row>
                      {isLoading ? (
                        <Button
                          type="primary"
                          size="large"
                          className="green-btn button"
                          loading
                        >
                          Loading
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          size="large"
                          onClick={this.login}
                          className="green-btn button"
                        >
                          Sign me in
                        </Button>
                      )}
                    </Row>
                  </Row>
                </Col>
                <Row className="reset">
                  Forgot your password?
                  <Button type="link" href="/sendRequest">
                    Reset
                  </Button>
                </Row>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  const props = {
    isNewPasswordRequest: state.auth.isNewPasswordRequest,
    isAuthenticated: state.auth.isAuthenticated,
    isLoading: state.auth.isLoading,
    errorMessage: state.auth.errorMessage,
  };
  return props;
};

export default connect(mapStateToProps, { login })(Login);
