import React, { Component } from 'react';
import { Row, Typography, Input, InputNumber } from 'antd';

import './InputCurrencyBox.scss';

const { Text } = Typography;

export default class InputCurrencyBox extends Component {
  render() {
    const { label, value, disabled, placeholder, onChange, } = this.props;

    return (
      <Row className="input-currency-box-container">
        {
          label &&
          <Row className="label">
            <Text>{label}</Text>
          </Row>
        }        
        <Row>
          <InputNumber
            placeholder={placeholder || 'Required'}
            className="input"
            disabled={disabled || false}
            value={value}
            step={0.01}
            prefix="$"
            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            onChange={(value)=>onChange(Number(value).toFixed(2))}
          />
        </Row>
      </Row>
    )
  }
}
