import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, Typography, Button, Divider, Input, DatePicker } from "antd";
import * as Logic from "../ProposedMedicare/logic";
import SpinBox from "components/SpinBox";
import InputCurrencyBox from "components/InputCurrencyBox";
import { BackIcon } from "components/Icons";
import { updateReportInfo } from "redux/report";
import * as API from "utils/api";

import "./AnnuityInfo.scss";

const { Title, Text } = Typography;

class AnnuityInfo extends Component {
  constructor(props) {
    super(props);

    let report_id = "";
    if (this.props.location && this.props.location.state) {
      report_id = this.props.location.state.data.report_id;
    }

    this.state = {
      medicare: [],

      report_id: report_id,
      life_expectancy: 0,
      annuity_payment_over_remaining_expectancy_at_starting_date: 0,
      annuity_start_date: moment(),
      annuity_length: 0,
      annuity_amount: 0,
      loadingText: "Loading",
      loading: false,
    };
    this.timeoutId = setInterval(
      this.handleUpdateReport,
      this.props.autoSavingTime
    );
  }
  componentWillUnmount = () => {
    clearInterval(this.timeoutId);
  };
  componentDidMount() {
    const { report_id } = this.state;

    this.setState({ loading: true });
    API.getMedicareData(report_id).then((res) => {
      this.setState({ medicare: res.medicare_cost_projections });
    });
    API.getReportById(report_id).then((res) => {
      const reportInfo = res.report;
      const {
        life_expectancy,
        annuity_payment_over_remaining_expectancy_at_starting_date,
        annuity_start_date,
      } = reportInfo;

      let annuity_length =
        life_expectancy - moment().diff(moment(annuity_start_date), "years");
      let annuity_amount = annuity_payment_over_remaining_expectancy_at_starting_date;
      if (annuity_length !== 0)
        annuity_amount =
          annuity_payment_over_remaining_expectancy_at_starting_date /
          annuity_length;

      this.setState({
        report: res.report,
        life_expectancy: life_expectancy || 0,
        annuity_payment_over_remaining_expectancy_at_starting_date:
          annuity_payment_over_remaining_expectancy_at_starting_date || 0,
        annuity_start_date: annuity_start_date
          ? moment(annuity_start_date)
          : moment(),
        annuity_length: annuity_length || 0,
        annuity_amount: Number(annuity_amount).toFixed(2) || 0,
        loading: false,
      });
      this.props.updateReportInfo(res.report);
    });
  }

  componentDidUpdate(prevProps) {
    const { reportInfo } = this.props;
    if (JSON.stringify(reportInfo) !== JSON.stringify(prevProps.reportInfo)) {
      const {
        life_expectancy,
        annuity_payment_over_remaining_expectancy_at_starting_date,
        annuity_start_date,
      } = reportInfo;

      let annuity_length =
        life_expectancy - moment().diff(moment(annuity_start_date), "years");
      let annuity_amount = annuity_payment_over_remaining_expectancy_at_starting_date;
      if (annuity_length !== 0)
        annuity_amount =
          annuity_payment_over_remaining_expectancy_at_starting_date /
          annuity_length;

      this.setState({
        life_expectancy: life_expectancy || 0,
        annuity_payment_over_remaining_expectancy_at_starting_date:
          annuity_payment_over_remaining_expectancy_at_starting_date || 0,
        annuity_start_date: annuity_start_date
          ? moment(annuity_start_date)
          : moment(),
        annuity_length: annuity_length || 0,
        annuity_amount: Number(annuity_amount).toFixed(2) || 0,
        loading: false,
      });
    }
  }

  handleUpdateReport = (isNextStep = false) => {
    const { report_id, annuity_start_date, annuity_amount } = this.state;

    const params = {
      id: report_id,
      annuity_start_date: moment(annuity_start_date).format("MM/DD/YYYY"),
      annuity_amount: Number(annuity_amount).toFixed(2),
    };

    if (isNextStep) {
      this.setState({
        loading: true,
        loadingTitle: "Saving...",
      });

      API.updateReport(params).then((res) => {
        this.setState({ loading: false });
        if (isNextStep) {
          clearTimeout(this.timeoutId);
          this.props.history.push("/app/reports/msa/calculationMethod", {
            data: { report_id: report_id },
          });
        }
      });
    } else {
      API.updateReport(params);
    }
  };

  goToPreviousStep = () => {
    this.props.history.goBack();
  };

  handleDateChange = (date, dateString) => {
    const {
      life_expectancy,
      annuity_payment_over_remaining_expectancy_at_starting_date,
    } = this.state;

    let annuity_length = life_expectancy - moment().diff(date, "years");
    let annuity_amount = annuity_payment_over_remaining_expectancy_at_starting_date;
    if (annuity_length !== 0)
      annuity_amount =
        annuity_payment_over_remaining_expectancy_at_starting_date /
        annuity_length;
    this.setState({
      annuity_start_date: date,
      annuity_length,
      annuity_amount: annuity_amount.toFixed(2),
    });
  };

  render() {
    const {
      annuity_start_date,
      annuity_length,
      annuity_amount,
      loadingTitle,
      loading,
    } = this.state;

    return (
      <Row className="msa-annuity-info-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Setup MSA Report
            </Title>
          </Col>
          <Col className="back" onClick={() => this.props.history.goBack()}>
            <BackIcon className="icon" />
            <Text>Cancel</Text>
          </Col>
          <Col className="step">
            <Text>Step 12 of 17</Text>
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <Text className="title">Annuity Information</Text>
                </Col>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">Annuity Start Date</Text>
                </Col>
                <Row>
                  <DatePicker
                    className="date"
                    format="MM/DD/YYYY"
                    value={annuity_start_date}
                    onChange={this.handleDateChange}
                  />
                </Row>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">Annuity Length</Text>
                </Col>
                <Col className="input-col">
                  <Input
                    placeholder={"Required"}
                    className="input"
                    disabled={true}
                    value={
                      this.state.report
                        ? Logic.lengthOfAnnuity(this.state.report)
                        : 0
                    }
                    onChange={(e) =>
                      this.setState({ annuity_length: e.target.value })
                    }
                  />
                </Col>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">Annuity Amount</Text>
                </Col>
                <Col className="input-col">
                  <InputCurrencyBox
                    placeholder={"Required"}
                    className="input"
                    disabled={true}
                    value={
                      this.state.report
                        ? Logic.seedMoney(
                            this.state.medicare,
                            this.state.report
                          ).annual_annuity.toFixed(2)
                        : 0
                    }
                    onChange={(value) =>
                      this.setState({ annuity_amount: value })
                    }
                  />
                </Col>
              </Row>
              <Divider className="divider" />
              <Row
                type="flex"
                justify="center"
                align="middle"
                className="confirm-button"
              >
                <Button
                  type="link"
                  className="prev-button button"
                  onClick={this.goToPreviousStep}
                >
                  &lt; PREVIOUS STEP
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="green-btn button"
                  onClick={() => this.handleUpdateReport(true)}
                >
                  Next Step
                </Button>
              </Row>
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  const props = {
    autoSavingTime: state.report.autoSavingTime,
    reportInfo: state.report.reportInfo,
  };
  return props;
};

export default connect(mapStateToProps, { updateReportInfo })(AnnuityInfo);
