import React, { Component } from "react";
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import ProtectedRoute from "./ProtectedRoute";
import Login from "../containers/Auth/Login";
import SendRequest from "../containers/Auth/SendRequest";
import CheckEmail from "../containers/Auth/CheckEmail";
import SetPassword from "../containers/Auth/SetPassword";
import ConfirmSet from "../containers/Auth/ConfirmSet";
import ResetPassword from "../containers/Auth/ResetPassword";
import ConfirmReset from "../containers/Auth/ConfirmReset";
import Terms from "../containers/Auth/Terms";
import CustomLayout from "../layout";
import Signup from "../containers/Auth/SignUp/Signup";
export default class Routes extends Component {
  render() {
    return (
      <BrowserRouter>
        <Route path="/" exact render={() => <Redirect to="/app/main"/>}/>
        <Route path="/app" exact render={() => <Redirect to="/app/main"/>}/>
        <ProtectedRoute path="/app" dispatch={this.props.dispatch} component={CustomLayout} />
        <Route path="/login" component={Login} />
        <Route path="/Signup" component={Signup} />

        <Route path="/sendRequest" component={SendRequest} />
        <Route path="/checkEmail" component={CheckEmail} />
        <Route path="/resetPassword" component={ResetPassword} />
        <Route path="/confirmReset" component={ConfirmReset} />
        <Route path="/setPassword" component={SetPassword} />
        <Route path="/confirmSet" component={ConfirmSet} />
        <Route path="/terms" component={Terms} />
      </BrowserRouter>
    );
  }
}

