import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, Typography, Button, DatePicker, Input, Divider } from "antd";

import SpinBox from "components/SpinBox";
import { BackIcon } from "components/Icons";
import { updateReportInfo } from "redux/report";
import * as API from "utils/api";

import "./SubmitCoverLetter.scss";

const { Title, Text } = Typography;

class SubmitCoverLetter extends Component {
  constructor(props) {
    super(props);

    let report_id = "";
    if (this.props.location && this.props.location.state) {
      report_id = this.props.location.state.data.report_id;
    }

    this.state = {
      report_id: report_id,
      proposed_settlement_date: moment(),
      age_at_proposed_settlement_date: 0,
      loadingTitle: "Loading",
      loading: false,
    };
    this.timeoutId = setInterval(
      this.handleUpdateReport,
      this.props.autoSavingTime
    );
  }
  componentWillUnmount = () => {
    clearInterval(this.timeoutId);
  };
  componentDidMount() {
    const { report_id } = this.state;

    this.setState({ loading: true });
    API.getReportById(report_id).then((res) => {
      const reportInfo = res.report;
      const { claimant } = reportInfo;
      let age = 0;
      if (claimant) {
        age = moment().diff(claimant.dob, "years");
      }
      this.setState({
        proposed_settlement_date: reportInfo.proposed_settlement_date
          ? moment(reportInfo.proposed_settlement_date)
          : moment(),
        age_at_proposed_settlement_date: age,
        loading: false,
      });
      this.props.updateReportInfo(res.report);
    });
  }

  componentDidUpdate(prevProps) {
    const { reportInfo } = this.props;
    const { claimant } = reportInfo;
    let age = 0;
    if (claimant) {
      age = moment().diff(claimant.dob, "years");
    }
    if (JSON.stringify(reportInfo) !== JSON.stringify(prevProps.reportInfo)) {
      this.setState({
        proposed_settlement_date: reportInfo.proposed_settlement_date
          ? moment(reportInfo.proposed_settlement_date)
          : moment(),
        age_at_proposed_settlement_date: age,
        loading: false,
      });
    }
  }

  handleDateChange = (date, dateString) => {
    const { claimant } = this.props.reportInfo;
    let age = 0;
    if (claimant) {
      age = moment(date).diff(claimant.dob, "years");
    }
    this.setState({
      proposed_settlement_date: date,
      age_at_proposed_settlement_date: age,
    });
  };

  handleUpdateReport = (isNextStep = false) => {
    const {
      report_id,
      proposed_settlement_date,
      age_at_proposed_settlement_date,
    } = this.state;
    const params = {
      id: report_id,
      proposed_settlement_date: moment(proposed_settlement_date).format(
        "MM/DD/YYYY"
      ),
      age_at_proposed_settlement_date,
    };

    if (isNextStep) {
      this.setState({
        loading: true,
        loadingTitle: "Saving...",
      });

      API.updateReport(params).then((res) => {
        this.setState({ loading: false });
        clearTimeout(this.timeoutId);
        this.props.history.push("/app/reports/msa/intro", {
          data: { report_id: report_id },
        });
      });
    } else {
      API.updateReport(params);
    }
  };

  goToPreviousStep = () => {
    this.props.history.goBack();
  };

  render() {
    const {
      proposed_settlement_date,
      age_at_proposed_settlement_date,
      loadingTitle,
      loading,
    } = this.state;
    const { reportInfo } = this.props;
    const claimant = reportInfo.claimant ? reportInfo.claimant : null;

    return (
      <Row className="msa-submit-cover-letter-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Setup MSA Report
            </Title>
          </Col>
          <Col className="back" onClick={() => this.props.history.goBack()}>
            <BackIcon className="icon" />
            <Text>Cancel</Text>
          </Col>
          <Col className="step">
            <Text>Step 7 of 17</Text>
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <Text className="title">Submitter Cover Letter</Text>
                </Col>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text>Name</Text>
                </Col>
                <Text className="text">
                  {claimant &&
                    claimant.claimant_name +
                      " " +
                      claimant.claimant_middle_name +
                      " " +
                      claimant.claimant_last_name}
                </Text>
              </Row>
              {claimant && claimant.address && (
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text>Address</Text>
                  </Col>
                  <Text className="text">
                    {claimant &&
                      claimant.address + " " + claimant.additional_addres}
                  </Text>
                </Row>
              )}
              {claimant && claimant.claimant_phone && (
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text>Phone</Text>
                  </Col>
                  <Text className="text">
                    {claimant && claimant.claimant_phone}
                  </Text>
                </Row>
              )}
              {claimant && claimant.fax && (
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text>Fax</Text>
                  </Col>
                  <Text className="text">{claimant && claimant.fax}</Text>
                </Row>
              )}
              {claimant && claimant.claimant_email && (
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text>Email</Text>
                  </Col>
                  <Text className="text">
                    {claimant && claimant.claimant_email}
                  </Text>
                </Row>
              )}
              {claimant && claimant.SSN && (
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text>SSN</Text>
                  </Col>
                  <Text className="text">{claimant && claimant.SSN}</Text>
                </Row>
              )}
              {claimant && claimant.HICN && (
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text>HICN</Text>
                  </Col>
                  <Text className="text">{claimant && claimant.HICN}</Text>
                </Row>
              )}
              {claimant && claimant.Gender && (
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text>Gender</Text>
                  </Col>
                  <Text className="text">{claimant && claimant.Gender}</Text>
                </Row>
              )}
              <Row type="flex" align="middle" className="row">
                <Col className="label">
                  <Text>Proposed settlement date</Text>
                </Col>
                <DatePicker
                  className="date"
                  allowClear={false}
                  format="MM/DD/YYYY"
                  value={proposed_settlement_date}
                  onChange={this.handleDateChange}
                />
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text>Age at proposed settlement date</Text>
                </Col>
                <Input
                  className="input"
                  value={age_at_proposed_settlement_date}
                  disabled={true}
                />
              </Row>
              {(claimant && claimant.rated_age != null) ||
                (claimant && claimant.rated_age != 0 && (
                  <Row type="flex" className="row">
                    <Col className="label">
                      <Text>Rated Age</Text>
                    </Col>
                    <Text className="text">
                      {claimant && claimant.rated_age}
                    </Text>
                  </Row>
                ))}
              <Row type="flex" className="row">
                <Col className="label">
                  <Text>Life Expectancy</Text>
                </Col>
                <Text className="text">
                  {reportInfo && reportInfo.life_expectancy}
                </Text>
              </Row>
              <Divider className="divider" />
              <Row
                type="flex"
                justify="center"
                align="middle"
                className="confirm-button"
              >
                <Button
                  type="link"
                  className="prev-button button"
                  onClick={this.goToPreviousStep}
                >
                  &lt; PREVIOUS STEP
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="green-btn button"
                  onClick={() => this.handleUpdateReport(true)}
                >
                  Next Step
                </Button>
              </Row>
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  const props = {
    autoSavingTime: state.report.autoSavingTime,
    reportInfo: state.report.reportInfo,
  };
  return props;
};

export default connect(mapStateToProps, { updateReportInfo })(
  SubmitCoverLetter
);
