import React, { Component } from "react";
import { Row, Col, Typography, Button, Divider, Modal } from "antd";

import SpinBox from "components/SpinBox";
import DeleteModal from "components/DeleteModal";
import { BackIcon } from "components/Icons";
import * as API from "utils/api";

import MedicareCostModal from "components/MedicareCostModal";
import MedicareCostTable from "components/MedicareCostTable";
import "./NonMedicareCost.scss";
import PrescriptionTable from "components/PrescriptionTable";
import { getPosition, get2NewPositions } from "utils/shared";
const { Title, Text } = Typography;

export default class NonMedicareCost extends Component {
  constructor(props) {
    super(props);

    let report_id = "";
    if (this.props.location && this.props.location.state) {
      report_id = this.props.location.state.data.report_id;
    }

    this.state = {
      report_id: report_id,
      reportData: {},
      contactsList: [],
      medicareDataList: [],
      cptList: [],
      unitFormList: [],
      specialityList: [],
      actionType: "ADD",
      selectedItem: null,
      showMedicareModal: false,
      loading: false,
    };
  }

  componentDidMount() {
    const { report_id } = this.state;

    this.setState({ loading: true });
    API.getSpeciality().then((res) => {
      this.setState({
        specialityList: res.specialities,
      });
    });
    API.getCPTData().then((res) => {
      this.setState({
        cptList: res.cpt_codes,
      });
    });
    API.getReportById(report_id).then((res) => {
      this.setState({
        reportData: res.report,
      });
    });
    API.getUnitForms().then((res) => {
      this.setState({
        unitFormList: res.unit_forms,
      });
    });
    API.getMedicareData(report_id).then((res) => {
      this.setState({
        medicareDataList: res.medicare_cost_projections,
        loading: false,
      });
    });
  }

  openMedicareModal = (actionType, selectedItem) => {
    this.setState({
      actionType,
      selectedItem,
      showMedicareModal: true,
    });
  };

  closeMedicareModal = () => {
    this.setState({ showMedicareModal: false });
  };

  handleMedicareCost = (params) => {
    const { actionType, report_id, selectedItem } = this.state;

    this.closeMedicareModal();
    this.setState({ loading: true });
    if (actionType === "ADD") {
      let position = getPosition(this.state.medicareDataList, params.type);
      const param = {
        position,
        ...params,
        report_id,
      };
      API.createMedicareItem(param)
        .then((res) => {
          API.getMedicareData(report_id).then((res) => {
            this.setState({
              medicareDataList: res.medicare_cost_projections,
              loading: false,
            });
          });
        })
        .catch((err) => {
          this.setState(
            {
              loading: false,
            },
            () => {
              Modal.error({
                content: "Error",
              });
            }
          );
        });
    } else {
      const param = {
        ...params,
        id: selectedItem.id,
      };
      API.updateMedicareItem(param)
        .then((res) => {
          API.getMedicareData(report_id).then((res) => {
            this.setState({
              medicareDataList: res.medicare_cost_projections,
              loading: false,
            });
          });
        })
        .catch((err) => {
          this.setState(
            {
              loading: false,
            },
            () => {
              Modal.error({
                content: "Error",
              });
            }
          );
        });
    }
  };

  handleEdit = () => {
    const { selectedItem, report_id } = this.state;

    this.closeDeleteModal();
    this.setState({ loading: true });
    API.updateMedicareItem(selectedItem.id)
      .then((res) => {
        API.getMedicareData(report_id, selectedItem).then((res) => {
          this.setState({
            medicareDataList: res.medicare_cost_projections,
            loading: false,
          });
        });
      })
      .catch((err) => {
        this.setState(
          {
            loading: false,
          },
          () => {
            Modal.error({
              content: "Error",
            });
          }
        );
      });
  };

  handleDelete = () => {
    const { selectedItem, report_id } = this.state;

    this.closeDeleteModal();
    this.setState({ loading: true });
    API.deleteMedicareItem(selectedItem.id).then((res) => {
      API.getMedicareData(report_id).then((res) => {
        this.setState({
          medicareDataList: res.medicare_cost_projections,
          loading: false,
        });
      });
    });
  };

  openDeleteModal = (selectedItem) => {
    this.setState({
      selectedItem,
      showDeleteModal: true,
    });
  };

  closeDeleteModal = () => {
    this.setState({ showDeleteModal: false });
  };

  goToNextStep = () => {
    const { report_id } = this.state;
    this.props.history.push("/app/reports/msa/nonMedicareTotals", {
      data: { report_id: report_id },
    });
  };

  goToPreviousStep = () => {
    this.props.history.goBack();
  };
  searchCodes = (value) => {
    API.getCPTData(value).then((res) => {
      this.setState({
        cptList: res.cpt_codes,
      });
    });
  };
  handleChangePosition = (id, move) => {
    let result = get2NewPositions(this.state.medicareDataList, id, move);
    if (result.success) {
      let Promise1 = API.updateMedicareItem(result.update1);
      let Promise2 = API.updateMedicareItem(result.update2);
      Promise.all([Promise1, Promise2]).then(() => {
        // item.classList.remove("animate-row");
        // item = document.getElementById("table-item-" + id);
        // item.classList.add("animate-row");
        this.setState({ medicareDataList: result.newArr }, () => {
          let item2 = document.getElementById(
            "table-item-" + result.update1.id
          );
          let item1 = document.getElementById(
            "table-item-" + result.update2.id
          );
          console.log(item1, item2);

          item2.classList.remove("animate-" + (move === "up" ? "down" : "up"));
          item1.classList.remove("animate-" + move);

          return;
        });
      });
    }
  };
  handleClone = (item) => {
    const { selectedItem, report_id } = this.state;
    let position = getPosition(this.state.medicareDataList, item.type);
    let newItem = { ...item, position };
    delete newItem.id;
    delete newItem.updatedAt;
    delete newItem.createdAt;
    delete newItem.deletedAt;
    delete newItem.cpt_code;
    API.createMedicareItem(newItem)
      .then((res) => {
        API.getMedicareData(report_id).then((res) => {
          this.setState({
            medicareDataList: res.medicare_cost_projections,
            loading: false,
          });
        });
      })
      .catch((err) => {
        this.setState(
          {
            loading: false,
          },
          () => {
            Modal.error({
              content: "Error, can't clone the item",
            });
          }
        );
      });
  };
  render() {
    const {
      showMedicareModal,
      selectedItem,
      showDeleteModal,
      actionType,
      medicareDataList,
      cptList,
      unitFormList,
      specialityList,
      reportData,
      loading,
    } = this.state;

    const futureList = medicareDataList.filter((item) => {
      return item.type === "Future Medical Needed";
    });
    const surgeriesList = medicareDataList.filter((item) => {
      return item.type === "Surgeries, Replacements & Procedures(non-medicare)";
    });
    const prescriptionList = medicareDataList.filter((item) => {
      return item.type === "Prescription Medications Covered by Medicare";
    });

    return (
      <Row className="msa-non-medicare-cost-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Setup MSA Report
            </Title>
          </Col>
          <Col className="back" onClick={() => this.props.history.goBack()}>
            <BackIcon className="icon" />
            <Text>Cancel</Text>
          </Col>
          <Col className="step">
            <Text>Step 5 of 17</Text>
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading}>
            <Row className="content">
              <Row type="flex" justify="space-between" align="middle">
                <Col md={24}>
                  <Text className="title">Non-Medicare Cost Projection </Text>
                </Col>
              </Row>
              <Row type="flex" justify="end" className="row">
                <Button
                  type="primary"
                  size="default"
                  className="green-btn button"
                  onClick={() => this.openMedicareModal("ADD", null)}
                >
                  Add Non-Medicare Cost
                </Button>
              </Row>
              <Row className="section">
                <Row className="sub-title">
                  <Text>1. Medical Costs</Text>
                </Row>
                <MedicareCostTable
                  searchCodes={this.searchCodes}
                  data={futureList}
                  handleEdit={this.openMedicareModal}
                  handleDelete={this.openDeleteModal}
                  handleClone={this.handleClone}
                  handleChangePosition={this.handleChangePosition}
                />
              </Row>
              <Row className="section">
                <Row className="sub-title">
                  <Text>2. Surgeries, Replacements and Procedures</Text>
                </Row>
                <MedicareCostTable
                  searchCodes={this.searchCodes}
                  data={surgeriesList}
                  handleEdit={this.openMedicareModal}
                  handleDelete={this.openDeleteModal}
                  handleClone={this.handleClone}
                  handleChangePosition={this.handleChangePosition}
                />
              </Row>
              <Row className="section">
                <Row className="sub-title">
                  <Text>3. Prescription Drug Data</Text>
                </Row>
                <PrescriptionTable
                  data={prescriptionList}
                  handleEdit={this.openMedicareModal}
                  handleDelete={this.openDeleteModal}
                  handleClone={this.handleClone}
                  handleChangePosition={this.handleChangePosition}
                />
              </Row>
              <Divider className="divider" />
              <Row
                type="flex"
                justify="center"
                align="middle"
                className="confirm-button"
              >
                <Button
                  type="link"
                  className="prev-button button"
                  onClick={this.goToPreviousStep}
                >
                  &lt; PREVIOUS STEP
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="green-btn button"
                  onClick={this.goToNextStep}
                >
                  Next Step
                </Button>
              </Row>
            </Row>
          </SpinBox>
        </Row>
        <MedicareCostModal
          searchCodes={this.searchCodes}
          isOpen={showMedicareModal}
          actionType={actionType}
          modalType="MSA_NON_MEDICARE"
          specialityList={specialityList}
          cptList={cptList}
          unitFormList={unitFormList}
          selectedItem={selectedItem}
          reportData={reportData}
          onConfirm={this.handleMedicareCost}
          onCancel={this.closeMedicareModal}
        />
        <DeleteModal
          isOpen={showDeleteModal}
          title="Delete Non-Medicare Item"
          content={selectedItem && selectedItem.name}
          onConfirm={this.handleDelete}
          onCancel={this.closeDeleteModal}
        />
      </Row>
    );
  }
}
