import React, { Component } from 'react';
import { Row, Typography, InputNumber } from 'antd';

import './InputNumberBox.scss';

const { Text } = Typography;

export default class InputNumberBox extends Component {
  render() {
    const { label, value, disabled, placeholder, onChange, min, max } = this.props;

    return (
      <Row className="input-number-box-container">
        <Row className="label">
          <Text>{label}</Text>
        </Row>
        <Row>
          <InputNumber
            placeholder={placeholder || 'Required'}
            className="input"
            disabled={disabled || false}
            value={value}
            min={min}
            max={max}
            onChange={(value)=>onChange(value)}
          />
        </Row>
      </Row>
    )
  }
}
