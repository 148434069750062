import React, { Component } from "react";
import "./ClaimantInfo.scss";
import {
  Row,
  Col,
  Typography,
  Button,
  Select,
  Icon,
  DatePicker,
  Input,
  Divider,
  Upload,
  Modal,
  message,
} from "antd";
import moment from "moment";
const { Title, Text } = Typography;

export default class ClaimantInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      injuryDates: [],
    };
  }
  getInjuryDates = (all) => {
    if (all.length == 0) {
      var injuryDates = [];
      injuryDates.push(moment().format("MM/DD/YYYY"));
      this.setState({
        injuryDates,
      });
    } else {
      var arrayFromComma = all.split(",");
      var injuryDates = [];
      arrayFromComma.map((el) => {
        injuryDates.push(moment(el.split("$")[0] * 1000).format("MM/DD/YYYY"));
      });
      this.setState({
        injuryDates,
      });
    }
  };
  componentDidMount = () => {
    setTimeout(() => {
      var all = this.props.claimant.report_claim_id;
      this.getInjuryDates(all);
    }, 750);
  };
  componentWillReceiveProps = (nextprops) => {
    var all = nextprops.claimant.report_claim_id;

    this.getInjuryDates(all);
  };
  render() {
    var claimant = this.props.claimant;
    var first_name = claimant.claimant_name ? claimant.claimant_name : "";
    var middle_name = claimant.claimant_middle_name
      ? claimant.claimant_middle_name
      : "";
    var last_name = claimant.claimant_last_name
      ? claimant.claimant_last_name
      : "";
    return (
      <Col md={24} style={{ width: "100%", marginBottom: 25 }}>
        <Row>
          <Text className="title">Claimant Info</Text>
        </Row>
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          className="row-custom"
        >
          <Text>Name:{` ${first_name} ${middle_name} ${last_name}`}</Text>
          <Text>
            Date of Birth:{" "}
            {this.props.claimant && moment(claimant.dob).format("MM/DD/YYYY")}
          </Text>
          <Text>
            Age:{" "}
            {this.props.claimant &&
              moment().diff(moment(claimant.dob), "years")}
          </Text>
        </Row>
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          className="row-custom"
        >
          <Text className={"date-injury"}>
            Date of Injury:{"\xa0\xa0"}
            {this.props.claimant &&
              this.state.injuryDates.map((el) => {
                return <Text>{el + "\xa0\xa0\xa0"}</Text>;
              })}
          </Text>
        </Row>
      </Col>
    );
  }
}
