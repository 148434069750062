import React, { Component } from "react";
import {
  Row,
  Col,
  Typography,
  Button,
  Select,
  Icon,
  DatePicker,
  Input,
  Divider,
  Upload,
  Modal,
  message,
  Tooltip,
} from "antd";
import { connect } from "react-redux";
import TextAreaBox from "components/TextAreaBox";
import InputBox from "components/InputBox";
import InputCurrencyBox from "components/InputCurrencyBox";
import InputNumberBox from "components/InputNumberBox";
import * as API from "utils/api";
import ClaimantInfo from "components/ClaimantInfoOnWizard";
import "./MedicareCostModal.scss";
import getInflation from "../../utils/inflation";
const { Text } = Typography;
const { Option } = Select;

class MedicareCostModal extends Component {
  constructor(props) {
    super(props);

    const { modalType } = this.props;
    console.log("props", this.props);
    const totalYears = this.props.reportData.life_expectancy;

    this.state = {
      name: "",
      type:
        modalType === "MSA_NON_MEDICARE"
          ? "Future Medical Needed"
          : "Future Medical Need covered by Medicare",
      frequency: null,
      units_needed: null,
      every_x_year: null,
      total_years: totalYears,
      per_unit_cost: null,
      total_cost: 0,
      annual_cost: 0,
      inflation_total: null,
      has_inflation: false,
      interest_rate: "0",
      comments: "",
      speciality_id: undefined,
      cpt_hcpcs_id: undefined,
      is_seed_calculated: false,
      code: "",
      isOpen1: false,
      customCode: "",
      customDescription: "",
      report_claim_id: "",
    };
  }
  addCptCode = () => {
    API.addCptCode({
      code: this.state.customCode,
      med_descr: this.state.customDescription,
      short_descr: this.state.customDescription,
    }).then((res) => {
      this.setState({
        cpt_hcpcs_id: res.id,
        code: this.state.customCode,
        name: this.state.customDescription,
        isOpen1: false,

        customCode: "",
        customDescription: "",
      });
    });
  };
  componentDidUpdate(prevProps, prevState) {
    const { modalType, selectedItem, reportData } = this.props;

    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      console.log(selectedItem, reportData, "HERRRE");
      if (selectedItem === null) {
        if (
          JSON.stringify(prevProps.cptList) !==
          JSON.stringify(this.props.cptList)
        ) {
          // this.setState({
          //   name: "",
          //   type:
          //     modalType === "MSA_NON_MEDICARE"
          //       ? "Future Medical Needed"
          //       : "Future Medical Need covered by Medicare",
          //   prescriptions: "",
          //   ndc: "",
          //   unit_form_id: undefined,
          //   prescription_strength: "",
          //   frequency: "",
          //   units_needed: null,
          //   every_x_year: null,
          //   total_years: reportData.life_expectancy,
          //   per_unit_cost: null,
          //   total_cost: 0,
          //   annual_cost: 0,
          //   inflation_total: null,
          //   comments: "",
          //   speciality_id: undefined,
          //   cpt_hcpcs_id: undefined,
          // });
        } else {
          this.setInitial();
        }
      } else {
        this.setState({
          name: selectedItem.name,
          type: selectedItem.type,
          prescriptions: selectedItem.prescriptions,
          ndc: selectedItem.ndc,
          unit_form_id: selectedItem.unit_form_id,
          prescription_strength: selectedItem.prescription_strength,
          frequency: selectedItem.frequency,
          units_needed: selectedItem.units_needed,
          every_x_year: selectedItem.every_x_year,
          total_years: selectedItem.total_years,
          per_unit_cost: selectedItem.per_unit_cost,
          total_cost: selectedItem.total_cost,
          annual_cost: selectedItem.annual_cost,
          inflation_total: selectedItem.inflation_total,
          comments: selectedItem.comments,
          speciality_id: selectedItem.speciality_id,
          cpt_hcpcs_id: selectedItem.cpt_hcpcs_id,
          is_seed_calculated: selectedItem.is_seed_calculated,
        });
      }
    }

    const {
      units_needed,
      per_unit_cost,
      every_x_year,
      total_years,
    } = this.state;

    if (
      prevState.units_needed !== units_needed ||
      prevState.per_unit_cost !== per_unit_cost ||
      prevState.every_x_year !== every_x_year ||
      prevState.total_years !== total_years
    ) {
      const total_cost =
        Number(every_x_year) !== 0
          ? Number(
              units_needed * per_unit_cost * (total_years / every_x_year)
            ).toFixed(2)
          : 0;
      const annual_cost =
        Number(every_x_year) !== 0
          ? Number((units_needed * per_unit_cost) / every_x_year).toFixed(2)
          : 0;
      this.setState({ total_cost, annual_cost });
    }
  }
  setInitial = () => {
    console.log("setting initial");
    const { modalType } = this.props;
    console.log(this.props, "props");
    let state = {
      name: "",
      type:
        modalType === "MSA_NON_MEDICARE"
          ? "Future Medical Needed"
          : "Future Medical Need covered by Medicare",
      prescriptions: "",
      ndc: "",
      unit_form_id: undefined,
      prescription_strength: "",
      frequency: null,
      units_needed: null,
      every_x_year: null,
      total_years: this.props.reportData
        ? this.props.reportData.life_expectancy
        : "",
      per_unit_cost: null,
      total_cost: 0,
      annual_cost: 0,
      inflation_total: null,
      comments: "",
      speciality_id: undefined,
      cpt_hcpcs_id: undefined,
      is_seed_calculated: false,
      code: "",
      isOpen1: false,
      customCode: "",
      customDescription: "",
    };
    this.setState(state);
  };
  handleConfirm = () => {
    const { onConfirm } = this.props;
    const {
      name,
      type,
      prescriptions,
      ndc,
      unit_form_id,
      prescription_strength,
      frequency,
      units_needed,
      every_x_year,
      total_years,
      per_unit_cost,
      total_cost,
      annual_cost,
      inflation_total,
      comments,
      speciality_id,
      cpt_hcpcs_id,
      is_seed_calculated,
    } = this.state;
    const NoneValues = () => {
      Modal.confirm({
        width: 700,
        onCancel: () => {
          return;
        },
        onOk: () => {
          let none = "None";
          let nonNumber = 0;
          const params = {
            name: name ? name : none,
            type,
            prescriptions: prescriptions ? prescriptions : none,
            ndc: ndc ? ndc : none,
            unit_form_id,
            prescription_strength: prescription_strength
              ? prescription_strength
              : none,
            frequency: frequency ? frequency : nonNumber,
            units_needed: units_needed ? units_needed : nonNumber,
            every_x_year: every_x_year ? every_x_year : nonNumber,
            total_years: total_years ? total_years : nonNumber,
            per_unit_cost: per_unit_cost ? per_unit_cost : nonNumber,
            total_cost: total_cost ? total_cost : nonNumber,
            annual_cost: annual_cost ? annual_cost : nonNumber,
            inflation_total,
            comments: comments ? comments : "",
            speciality_id,
            cpt_hcpcs_id,
            is_seed_calculated,
          };
          this.setInitial();
          onConfirm(params);

          this.setState({ code: "" });
        },
        okButtonProps: {
          style: { background: "#00D374", borderColor: "white" },
        },
        content:
          "It appears you have left some fields blank. Do you want to continue and set these values as None?",
      });
    };
    if (type.includes("Prescription Medication")) {
      if (!prescriptions) {
        NoneValues();
        return;
      }
      if (!ndc) {
        NoneValues();
        return;
      }
      if (!unit_form_id) {
        NoneValues();
        return;
      }
      if (!prescription_strength) {
        NoneValues();
        return;
      }
    } else {
      if (!cpt_hcpcs_id) {
        NoneValues();
        return;
      }
      if (!speciality_id) {
        NoneValues();
        return;
      }
    }
    if (!units_needed) {
      NoneValues();
      return;
    }
    if (!per_unit_cost) {
      NoneValues();
      return;
    }
    if (!every_x_year) {
      NoneValues();
      return;
    }
    if (!total_years) {
      NoneValues();
      return;
    }

    const params = {
      name,
      type,
      prescriptions,
      ndc,
      unit_form_id,
      prescription_strength,
      frequency,
      units_needed,
      every_x_year,
      total_years,
      per_unit_cost,
      total_cost,
      annual_cost,
      inflation_total,
      comments,
      speciality_id,
      cpt_hcpcs_id,
      is_seed_calculated,
    };
    this.setInitial();
    onConfirm(params);

    this.setState({ code: "" });
  };

  render() {
    console.log(this.props, "settings");
    const {
      modalType,
      actionType,
      cptList,
      unitFormList,
      specialityList,
      isOpen,
      onCancel,
    } = this.props;
    console.log(cptList, unitFormList, specialityList, "lists");
    const {
      name,
      type,
      prescriptions,
      ndc,
      unit_form_id,
      prescription_strength,
      frequency,
      units_needed,
      every_x_year,
      total_years,
      per_unit_cost,
      total_cost,
      annual_cost,
      inflation_total,
      comments,
      speciality_id,
      cpt_hcpcs_id,
      is_seed_calculated,
      code,
      isOpen1,
      customCode,
      customDescription,
      report_claim_id,
    } = this.state;
    console.log("CHECK PROPS", this.props);
    return (
      <>
        <Modal
          visible={isOpen}
          onCancel={onCancel}
          footer={null}
          centered={true}
          closable={true}
          width={800}
          className="medicare-cost-modal"
          title={
            modalType === "MSA_NON_MEDICARE"
              ? actionType === "ADD"
                ? "Add Non-Medicare Cost"
                : "Edit Non-Medicare Cost"
              : actionType === "ADD"
              ? "Add Medicare Cost"
              : "Edit Medicare Cost"
          }
        >
          <Row type="flex" align="middle" className="report-body">
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              className="main-info"
            >
              <Col md={24} className="row">
                <Col md={24}>
                  <ClaimantInfo
                    claimant={{
                      ...this.props.reportData.claimant,
                      report_claim_id: this.props.reportData.report_claim_id,
                    }}
                  />
                </Col>
                <Row>
                  <Text>Type</Text>
                </Row>
                <Row>
                  {modalType === "MSA_NON_MEDICARE" ? (
                    <Select
                      showSearch
                      placeholder="Please select"
                      optionFilterProp="children"
                      className="dropdown"
                      value={type}
                      onChange={(value) => this.setState({ type: value })}
                      suffixIcon={
                        <Icon
                          type="caret-down"
                          style={{ color: "black" }}
                          theme="filled"
                        />
                      }
                    >
                      <Option value={"Future Medical Needed"}>
                        Future Medical Needed
                      </Option>
                      <Option
                        value={
                          "Surgeries, Replacements & Procedures(non-medicare)"
                        }
                      >
                        Surgeries, Replacements & Procedures(non-medicare)
                      </Option>
                      <Option
                        value={"Prescription Medications Covered by Medicare"}
                      >
                        Prescription Medications Covered by Medicare
                      </Option>
                    </Select>
                  ) : (
                    <Select
                      showSearch
                      placeholder="Please select"
                      optionFilterProp="children"
                      className="dropdown"
                      value={type}
                      onChange={(value) => {
                        console.log(value);
                        this.setState({ type: value });
                      }}
                      suffixIcon={
                        <Icon
                          type="caret-down"
                          style={{ color: "black" }}
                          theme="filled"
                        />
                      }
                    >
                      <Option value={"Future Medical Need covered by Medicare"}>
                        Future Medical Need covered by Medicare
                      </Option>
                      <Option value={"Surgeries, Replacements & Procedures"}>
                        Surgeries, Replacements & Procedures
                      </Option>
                      <Option value={"Prescription Medications Covered"}>
                        Prescription Medications Covered
                      </Option>
                    </Select>
                  )}
                </Row>
              </Col>
            </Row>
            {type.includes("Prescription Medication") ? (
              <>
                <Row
                  type="flex"
                  justify="space-between"
                  align="middle"
                  className="main-info"
                >
                  <Col md={11} className="row">
                    <InputBox
                      label="Prescriptions"
                      value={prescriptions}
                      onChange={(value) =>
                        this.setState({ prescriptions: value })
                      }
                    />
                  </Col>
                  <Col md={11} className="row">
                    <InputBox
                      label="NDC"
                      value={ndc}
                      onChange={(value) => this.setState({ ndc: value })}
                    />
                  </Col>
                </Row>
                <Row
                  type="flex"
                  justify="space-between"
                  align="middle"
                  className="main-info"
                >
                  <Col md={11} className="row">
                    <Row>
                      <Text>Unit Form</Text>
                    </Row>
                    <Row>
                      <Select
                        showSearch
                        placeholder="Please select"
                        optionFilterProp="filter"
                        className="dropdown"
                        value={unit_form_id}
                        onChange={(value, option) =>
                          this.setState({ unit_form_id: value })
                        }
                        suffixIcon={
                          <Icon
                            type="caret-down"
                            style={{ color: "black" }}
                            theme="filled"
                          />
                        }
                      >
                        {unitFormList.map((item) => (
                          <Option
                            value={item.id}
                            filter={item.id.toString().concat("_" + item.name)}
                            key={item.id}
                          >
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Row>
                  </Col>
                  <Col md={11} className="row">
                    <InputBox
                      label="Prescribed Strength"
                      value={prescription_strength}
                      onChange={(value) =>
                        this.setState({ prescription_strength: value })
                      }
                    />
                  </Col>
                </Row>
                {/* <Row type="flex" justify="space-between" align="middle" className="main-info">
                <Col md={11} className="row">
                  <InputBox
                    label="Frequency/Duration Of Need"
                    value={frequency}
                    onChange={value=>this.setState({frequency: value})}
                  />
                </Col>
              </Row> */}
                <Row
                  type="flex"
                  justify="space-between"
                  align="middle"
                  className="main-info"
                >
                  <Col md={11} className="row">
                    <InputNumberBox
                      label="Total Years"
                      value={total_years}
                      min={0}
                      onChange={(value) =>
                        this.setState({ total_years: value })
                      }
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row
                  type="flex"
                  justify="space-between"
                  align="middle"
                  className="main-info"
                >
                  <Col md={24} className="row">
                    <Row>
                      <Text>CPT/HCPCS</Text>
                    </Row>
                    <Row>
                      <Select
                        showSearch
                        placeholder="Please select"
                        filterOption={false}
                        className="dropdown"
                        onSearch={(value) => {
                          console.log(value);
                          if (value) {
                            this.props.searchCodes(value);
                          }
                        }}
                        value={cpt_hcpcs_id}
                        onChange={(value, option) => {
                          this.setState({
                            cpt_hcpcs_id: value,
                            name: option.props.name,
                            code: option.props.code,
                          });
                        }}
                        suffixIcon={
                          <Icon
                            type="caret-down"
                            style={{ color: "black" }}
                            theme="filled"
                          />
                        }
                      >
                        {cptList &&
                          cptList.map((item) => (
                            <Option
                              value={item.id}
                              name={item.short_descr}
                              code={item.code}
                              filter={item.code.concat("_" + item.med_descr)}
                              key={item.id}
                            >
                              <Tooltip
                                //mouseEnterDelay={0}
                                placement="right"
                                title={item.long_descr}
                              >
                                {item.code + " (" + item.short_descr + ")"}
                              </Tooltip>
                            </Option>
                          ))}
                      </Select>
                    </Row>
                  </Col>
                </Row>
                <Row
                  style={{
                    marginBottom: 20,
                    justifyContent: "flex-start",
                    width: "100%",
                  }}
                >
                  <Button
                    type="primary"
                    size="default"
                    className="green-btn button"
                    onClick={() => {
                      this.setState({ isOpen1: true });
                    }}
                  >
                    Add Custom Code
                  </Button>
                </Row>

                <Row
                  type="flex"
                  justify="space-between"
                  align="middle"
                  className="main-info"
                >
                  <Col md={24} className="row">
                    <InputBox
                      label="Item Name"
                      value={name}
                      disabled={true}
                      onChange={(value) => this.setState({ name: value })}
                    />
                  </Col>
                </Row>
                <Row
                  type="flex"
                  justify="space-between"
                  align="middle"
                  className="main-info"
                >
                  <Col md={11} className="row">
                    <Row>
                      <Text>Speciality</Text>
                    </Row>
                    <Row>
                      <Select
                        showSearch
                        placeholder="Please select"
                        optionFilterProp="children"
                        className="dropdown"
                        value={speciality_id}
                        onChange={(value) =>
                          this.setState({ speciality_id: value })
                        }
                        suffixIcon={
                          <Icon
                            type="caret-down"
                            style={{ color: "black" }}
                            theme="filled"
                          />
                        }
                      >
                        {specialityList.map((item) => (
                          <Option value={item.id} key={item.id}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Row>
                  </Col>
                  {/* <Col md={11} className="row">
                  <InputBox
                    label="Frequency/Duration Of Need"
                    value={frequency}
                    onChange={value=>this.setState({frequency: value})}
                  />
                </Col> */}
                  <Col md={11} className="row">
                    <InputNumberBox
                      label="Total Years"
                      value={total_years}
                      min={0}
                      onChange={(value) =>
                        this.setState({ total_years: value })
                      }
                    />
                  </Col>
                </Row>
              </>
            )}
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              className="main-info"
            >
              <Col md={5} className="row">
                <InputNumberBox
                  label="Units needed"
                  value={units_needed}
                  min={0}
                  onChange={(value) => this.setState({ units_needed: value })}
                />
              </Col>
              <Col md={5} className="row">
                <InputBox label="Every" value={"Every"} disabled={true} />
              </Col>
              <Col md={5} className="row">
                <InputNumberBox
                  label="Years"
                  value={every_x_year}
                  min={0}
                  onChange={(value) => this.setState({ every_x_year: value })}
                />
              </Col>
              <Col md={5} className="row">
                <InputCurrencyBox
                  label="Per Unit cost"
                  value={per_unit_cost}
                  min={0}
                  onChange={(value) => this.setState({ per_unit_cost: value })}
                />
              </Col>
            </Row>
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              className="main-info"
            >
              <Col md={11} className="row">
                <InputCurrencyBox
                  label="Total Cost"
                  disabled={true}
                  value={total_cost}
                  onChange={(value) => this.setState({ total_cost: value })}
                />
              </Col>
              <Col md={11} className="row">
                <InputCurrencyBox
                  label="Annual Cost"
                  disabled={true}
                  value={annual_cost}
                  onChange={(value) => this.setState({ annual_cost: value })}
                />
              </Col>
              {/* <Row className="checkbox-item">
              <br />
              <Checkbox
                onChange={(e) =>
                  this.setState({ is_seed_calculated: e.target.checked })
                }
                checked={is_seed_calculated}
              >
                Calculate in seed money
              </Checkbox>
            </Row> */}

              {this.props.reportData.has_inflation && (
                <Row
                  type="flex"
                  justify="space-between"
                  align="middle"
                  className="main-info"
                >
                  <Col md={24} className="row">
                    <InputCurrencyBox
                      label="Inflation Total"
                      placeholder=" "
                      disabled={true}
                      value={getInflation(
                        { interest_rate: this.props.reportData.interest_rate },
                        total_cost,
                        total_years,
                        true
                      )}
                      onChange={(value) =>
                        this.setState({ inflation_total: value })
                      }
                    />
                  </Col>
                </Row>
              )}
            </Row>
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              className="main-info"
            >
              <Col md={24} className="row">
                <TextAreaBox
                  label="Comment"
                  placeholder="Optional"
                  value={comments}
                  onChange={(value) => this.setState({ comments: value })}
                />
              </Col>
            </Row>
            {!this.props.selectedItem ? (
              <Button
                type="primary"
                size="large"
                className="green-btn button"
                onClick={this.handleConfirm}
              >
                Create
              </Button>
            ) : (
              <Button
                type="primary"
                size="large"
                className="green-btn button"
                onClick={this.handleConfirm}
              >
                Save Changes
              </Button>
            )}
          </Row>
        </Modal>
        <Modal
          visible={isOpen1}
          onCancel={() => {
            this.setState({ isOpen1: false });
          }}
          footer={null}
          centered={true}
          closable={true}
          width={500}
          className="add-icd-code-modal"
          title={"Add Custom Code"}
        >
          <Row className="modal-body">
            <Row type="flex" justify="start" align="middle" className="row">
              <Col className="label">
                <Text className="label">Code</Text>
              </Col>
              <Col className="input-col">
                <Input
                  placeholder={"Required"}
                  className="input"
                  disabled={false}
                  value={customCode}
                  onChange={(e) =>
                    this.setState({ customCode: e.target.value })
                  }
                />
              </Col>
            </Row>
            <Row type="flex" justify="start" align="middle" className="row">
              <Col className="label">
                <Text className="label">Description</Text>
              </Col>
              <Col className="input-col">
                <Input
                  placeholder={"Required"}
                  className="input"
                  disabled={false}
                  value={customDescription}
                  onChange={(e) =>
                    this.setState({ customDescription: e.target.value })
                  }
                />
              </Col>
            </Row>
            <Row type="flex" justify="end">
              <Button
                type="primary"
                className="green-btn button"
                onClick={() => {
                  this.addCptCode();
                }}
              >
                ADD
              </Button>
            </Row>
          </Row>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  const props = {
    settings: state.auth.settings,
  };
  return props;
};

export default connect(mapStateToProps, {})(MedicareCostModal);
