import React, { useState, useEffect } from "react";
import { Select, Radio } from "antd";
import "./AssigmentFile.scss";
import * as API from "utils/api";

export default function Index({
  roleValue,
  OnRoleChange,
  assignment_id,
  claimant_id,
}) {
  const [files, setFiles] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [assignmentFiles, setAssignmentFiles] = useState([]);
  useEffect(() => {
    API.GetReportFiles(claimant_id).then((files) => {
      setFiles(files.files);
    });
    API.getAssigmentFiles(assignment_id).then((res) => {
      setAssignmentFiles(res.assignment_files);
      setSelectedValues(res.assignment_files.map((el) => el.file_id));
      console.log(res.roles);
    });
  }, [assignment_id]);
  const { Option } = Select;
  const children = [];
  for (let i = 0; i < files.length; i++) {
    children.push(
      <Option key={i.toString(36) + i} value={files[i].id}>
        {files[i].name}
      </Option>
    );
  }

  // function handleChange(value) {
  //   console.log(`Selected: ${value}`);
  //   setSelectedValues(value);
  //   console.log(selectedValues, "select");
  // }
  return (
    <div>
      <Select
        mode="multiple"
        placeholder="Please select"
        onChange={OnRoleChange}
        value={selectedValues}
        onSelect={(value) => {
          API.CreateAssignementFile(value, assignment_id);
          setSelectedValues([...selectedValues, value]);
        }}
        onDeselect={(value) => {
          let assgFile = assignmentFiles.find((item) => item.file_id === value);
          API.DeleteAssigmentFile(assgFile.id, assignment_id);
          setSelectedValues([
            ...selectedValues.filter((item) => item != value),
          ]);
        }}
        style={{ width: "100%" }}
      >
        {children}
      </Select>
    </div>
  );
}
