import React, { useEffect } from "react";
import "./App.css";
import "antd/dist/antd.css";

import Routes from "./route";
import { Provider } from "react-redux";
import IdleTimer from "react-idle-timer";
import Amplify from "aws-amplify";
import store from "./redux/store";
import config from "./config";
import { Auth } from "aws-amplify";
import * as API from "./utils/api";
import "./assets/scss/main.scss";
import moment from "moment";
import { listener } from "index";
Amplify.configure(config.amplify);

const onIdle = (inrv) => {
  clearInterval(inrv);
  Auth.currentUserInfo()
    .then((res) => {
      if (res !== null) {
        API.updateActiveUser({
          email: res.attributes.email,
          is_active: false,
        }).then(() => {
          Auth.signOut();
          window.removeEventListener("beforeunload", listener);
          localStorage.removeItem("isAuthenticated");
          sessionStorage.clear();
          document.location = "/";
        });
      }
    })
    .catch((err) => {
      Auth.signOut();
      window.removeEventListener("beforeunload", listener);
      localStorage.removeItem("isAuthenticated");
      sessionStorage.clear();
      document.location = "/";
    });
};

function App() {
  let inrv;
  useEffect(() => {
    inrv = setInterval(() => {
      localStorage.setItem("beforeUnloadDate", moment());
    }, 60 * 1000);
    // window.addEventListener("beforeunload", listener);
    var lastDate = localStorage.getItem("beforeUnloadDate");
    if (!lastDate || lastDate === "") {
      return;
    }

    var differenceInMinutes = moment().diff(lastDate, "minutes");
    if (differenceInMinutes >= 20) {
      // if (differenceInMinutes >= 1) {
      onIdle();
    } else {
      localStorage.setItem("beforeUnloadDate", "");
    }
  }, []);
  return (
    <Provider store={store}>
      <div>
        <IdleTimer
          element={document}
          onIdle={() => {
            onIdle(inrv);
          }}
          debounce={1000}
          timeout={1000 * 60 * 20}
          // timeout={1000 * 10}
        />
        <Routes />
      </div>
    </Provider>
  );
}

export default App;
