import * as types from './actionTypes';
import * as API from '../../utils/api';

export const getReportById = (reportId) => dispatch => {
	dispatch({
		type: types.GET_REPORT_DATA_REQUEST
	});
	API.getReportById(reportId)
	.then((res) => {
		dispatch({
			type: types.GET_REPORT_DATA_SUCCESS,
			reportInfo: res.report
		});
	})	
	.catch((error) => {
		let errorMessage = "";
		if (error) {
			errorMessage = error.message;
		}
		dispatch({
			type: types.GET_REPORT_DATA_FAILURE,
			errorMessage: errorMessage
		});
	});
}

export const updateReportInfo = (res) => dispatch => {
	dispatch({
		type: types.UPDATE_REPORT_DATA,
		reportInfo: res
	});
}