import React, { Component } from 'react';
import { Row, Typography, Button, Icon } from 'antd';
import { SketchPicker } from "react-color";

import './ColorPicker.scss';

const { Text } = Typography;

export default class ColorPicker extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    }
  }

  render() {
    const { color, title, onChange } = this.props;
    const { isOpen } = this.state;

    return (
      <div>
        {
          isOpen &&
          <Row className="popover">
            <Row className="cover" onClick={() => this.setState({isOpen: false})}/>
            <SketchPicker disableAlpha={true} color={color} onChange={onChange} />
          </Row>
        }
        <Button className="color-button" onClick={() => this.setState({isOpen: true})}>
          <Text className="color-label" style={{backgroundColor: color}}></Text>
          <Text>{title}</Text>
          <Icon type="caret-down" className="arrow-icon"/>
        </Button>
      </div>
    )
  }
}
