import React, { Component } from "react";
import {
  Row,
  Typography,
  Input,
  Select,
  Icon,
  Button,
  Modal,
  Tooltip,
} from "antd";
import * as API from "utils/api";
import "./index.scss";
import Add from "../../containers/Contacts/AddContact/index";
const { Option } = Select;
const { Text } = Typography;

export default class InputBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      isModalOpen: false,
      extraContacts: [],
    };
  }
  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };
  handleAddContact = (contact_id, custom = true, contactRes) => {
    var extraContacts = this.state.extraContacts;
    var extra = false;
    if (contactRes != undefined) {
      extra = true;
      extraContacts.push(contactRes);
    }

    API.addReportContact(
      this.props.report_id,
      contact_id,
      this.props.type
    ).then((res) => {
      if (extra) {
        this.setState({ extraContacts });
      }

      this.props.getReportContacts().then((res) => {
        this.handleCloseModal();
      });
    });
  };
  handleRemoveContact = (contact_id) => {
    API.removeReportContact(
      this.props.report_id,
      contact_id,
      this.props.type
    ).then((res) => {});
  };
  componentWillReceiveProps = (nextProps) => {
    this.setState({ value: nextProps.value });
  };
  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
  };
  render() {
    const { isModalOpen } = this.state;
    return (
      <>
        <Row className="label">
          <Text>{this.props.label}</Text>
        </Row>
        <Row>
          <div>
            <Select
              onDeselect={this.handleRemoveContact}
              onSelect={this.handleAddContact}
              showSearch
              placeholder="Please select"
              mode={"multiple"}
              optionFilterProp="filter"
              className="dropdown"
              value={this.state.value}
              onChange={(value, option) => {
                this.setState({ value: value });
              }}
              suffixIcon={
                <Icon
                  type="caret-down"
                  style={{ color: "black" }}
                  theme="filled"
                />
              }
            >
              {this.props.contactsList.map((item) => {
                return (
                  <Option
                    filter={item.id
                      .toString()
                      .concat("_" + item.organisation_name)
                      .concat("_" + item.contact_name)
                      .concat("_" + item.email)}
                    title=""
                    value={item.id}
                    key={item.id}
                  >
                    <Tooltip
                      overlayStyle={{ maxWidth: 300 }}
                      placement="right"
                      title={
                        <>
                          <div
                            style={{ fontSize: 16 }}
                          >{`${item.organisation_name} (${item.contact_name})`}</div>
                          {item.address.length != 0 ? (
                            <div>{`Address: ${item.address}`}</div>
                          ) : null}
                          {item.email.length != 0 ? (
                            <div>{`E-mail: ${item.email}`}</div>
                          ) : null}
                          {item.phone_number.length != 0 ? (
                            <div>{`Phone Number: ${item.phone_number}`}</div>
                          ) : null}
                        </>
                      }
                    >
                      {`${item.organisation_name} (${item.contact_name})`}
                    </Tooltip>
                  </Option>
                );
              })}
              {this.state.extraContacts.map((item) => {
                return (
                  <Option title="" value={item.id} key={item.id}>
                    {item.organisation_name}
                  </Option>
                );
              })}
            </Select>
            <Button
              type="primary"
              className="green-btn add-icd-code_button"
              onClick={this.handleOpenModal}
            >
              Add Custom Contact
            </Button>
          </div>
        </Row>
        <Modal
          visible={isModalOpen}
          onCancel={this.handleCloseModal}
          footer={null}
          centered={true}
          closable={true}
          width={800}
          className="add-icd-code-modal"
          title={"Add Custom Contact"}
        >
          <Add
            contact_type_id={this.props.contact_type_id}
            another={this.props.another}
            removeHeader={true}
            func={(res) => {
              this.handleAddContact(res.id, true, res);
            }}
          />
        </Modal>
      </>
    );
  }
}
