import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { Layout } from "antd";
import Navigation from "../components/Navigation";
import Dashboard from "../containers/Dashboard/Dashboard";
import Assignments from "../containers/Dashboard/Assignments";
import Reports from "../containers/Dashboard/Reports";
import Templates from "../containers/Templates";
import AddTemplate from "../containers/Templates/AddTemplate";
import EditTemplate from "../containers/Templates/EditTemplate";
import EditTemplateContent from "../containers/Templates/EditTemplate/EditTemplateContent.jsx";

import MyClients from "../containers/MyClients";
import AddClient from "../containers/MyClients/AddClient";
import EditClient from "../containers/MyClients/EditClient";
import MyUsers from "../containers/MyUsers";
import AddUser from "../containers/MyUsers/AddUser";
import EditUser from "../containers/MyUsers/EditUser";
import Contacts from "../containers/Contacts";
import AddContact from "../containers/Contacts/AddContact";
import EditContact from "../containers/Contacts/EditContact";
import Claimants from "../containers/Claimants";
import AddClaimant from "../containers/Claimants/AddClaimant";
import EditClaimant from "../containers/Claimants/EditClaimant";
import DetailedClaimant from "../containers/Claimants/DetailedClaimant";
import MSADemographic from "../containers/Reports/MSA/Demographic";
import MSAMedicalContent from "../containers/Reports/MSA/MedicalContent";
import MSAMedicareCost from "../containers/Reports/MSA/MedicareCost";
import MSAMedicareTotals from "../containers/Reports/MSA/MedicareTotals";
import MSANonMedicareCost from "../containers/Reports/MSA/NonMedicareCost";
import MSANonMedicareTotals from "../containers/Reports/MSA/NonMedicareTotals";
import MSASubmitCoverLetterTotals from "../containers/Reports/MSA/SubmitCoverLetter";
import MSAIntro from "../containers/Reports/MSA/Intro";
import MSAInjuryInfo from "../containers/Reports/MSA/InjuryInfo";
import MSASettlementInfo from "../containers/Reports/MSA/SettlementInfo";
import MSAProposedMedicare from "../containers/Reports/MSA/ProposedMedicare";
import MSAAnnuityInfo from "../containers/Reports/MSA/AnnuityInfo";
import MSACalculationMethod from "../containers/Reports/MSA/CalculationMethod";
import MSAFuturePrescription from "../containers/Reports/MSA/FuturePrescription";
import MSACalculationTable from "../containers/Reports/MSA/CalculationTable";
import MSAContactInfo from "../containers/Reports/MSA/ContactInfo";
import MSADocumentUpload from "../containers/Reports/MSA/DocumentUpload";
import MCPDemographic from "../containers/Reports/MCP/Demographic";
import MCPMedicalContent from "../containers/Reports/MCP/MedicalContent";
import MCPMedicareCost from "../containers/Reports/MCP/MedicareCost";
import MCPMedicareTotals from "../containers/Reports/MCP/MedicareTotals";
import MCPDocumentUpload from "../containers/Reports/MCP/DocumentUpload";
import UploadCode from "../containers/UploadCode";
import "./CustomLayout.scss";
import NurseDashboard from "../containers/Dashboard/Dashboard/NurseDashboard";

class CustomLayout extends Component {
  render() {
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Navigation {...this.props} />
        <Layout>
          <Switch>
            {/* <Route path="/app/main" exact component={Dashboard} /> */}
            <Route path="/app/main" exact component={NurseDashboard} />

            <Route path="/app/assignments" exact component={Assignments} />
            <Route path="/app/reports" exact component={Reports} />
            <Route path="/app/templates" exact component={Templates} />
            <Route path="/app/templates/add" exact component={AddTemplate} />
            <Route path="/app/templates/edit" exact component={EditTemplate} />
            <Route
              path="/app/templates/edit/section"
              exact
              component={EditTemplateContent}
            />

            <Route path="/app/clients" exact component={MyClients} />
            <Route path="/app/clients/add" exact component={AddClient} />
            <Route path="/app/clients/edit" exact component={EditClient} />
            <Route path="/app/users" exact component={MyUsers} />
            <Route path="/app/users/add" exact component={AddUser} />
            <Route path="/app/users/edit" exact component={EditUser} />
            <Route path="/app/contacts" exact component={Contacts} />
            <Route path="/app/contacts/add" exact component={AddContact} />
            <Route path="/app/contacts/edit" exact component={EditContact} />
            <Route path="/app/claimants" exact component={Claimants} />
            <Route path="/app/claimants/add" exact component={AddClaimant} />
            <Route path="/app/claimants/edit" exact component={EditClaimant} />
            <Route
              path="/app/claimants/detail"
              exact
              component={DetailedClaimant}
            />
            <Route
              path="/app/reports/msa/demographic"
              exact
              component={MSADemographic}
            />
            <Route
              path="/app/reports/msa/medicalContent"
              exact
              component={MSAMedicalContent}
            />
            <Route
              path="/app/reports/msa/medicareCost"
              exact
              component={MSAMedicareCost}
            />
            <Route
              path="/app/reports/msa/medicareTotals"
              exact
              component={MSAMedicareTotals}
            />
            <Route
              path="/app/reports/msa/nonMedicareCost"
              exact
              component={MSANonMedicareCost}
            />
            <Route
              path="/app/reports/msa/nonMedicareTotals"
              exact
              component={MSANonMedicareTotals}
            />
            <Route
              path="/app/reports/msa/submitCoverLetter"
              exact
              component={MSASubmitCoverLetterTotals}
            />
            <Route path="/app/reports/msa/intro" exact component={MSAIntro} />
            <Route
              path="/app/reports/msa/injuryInfo"
              exact
              component={MSAInjuryInfo}
            />
            <Route
              path="/app/reports/msa/settlementInfo"
              exact
              component={MSASettlementInfo}
            />
            <Route
              path="/app/reports/msa/proposedMedicare"
              exact
              component={MSAProposedMedicare}
            />
            <Route
              path="/app/reports/msa/annuityInfo"
              exact
              component={MSAAnnuityInfo}
            />
            <Route
              path="/app/reports/msa/calculationMethod"
              exact
              component={MSACalculationMethod}
            />
            <Route
              path="/app/reports/msa/futurePrescription"
              exact
              component={MSAFuturePrescription}
            />
            <Route
              path="/app/reports/msa/calculationTable"
              exact
              component={MSACalculationTable}
            />
            <Route
              path="/app/reports/msa/contactInfo"
              exact
              component={MSAContactInfo}
            />
            <Route
              path="/app/reports/msa/documentUpload"
              exact
              component={MSADocumentUpload}
            />
            <Route
              path="/app/reports/mcp/demographic"
              exact
              component={MCPDemographic}
            />
            <Route
              path="/app/reports/mcp/medicalContent"
              exact
              component={MCPMedicalContent}
            />
            <Route
              path="/app/reports/mcp/medicareCost"
              exact
              component={MCPMedicareCost}
            />
            <Route
              path="/app/reports/mcp/medicareTotals"
              exact
              component={MCPMedicareTotals}
            />
            <Route
              path="/app/reports/mcp/documentUpload"
              exact
              component={MCPDocumentUpload}
            />
            <Route path="/app/uploadCode" exact component={UploadCode} />
          </Switch>
        </Layout>
      </Layout>
    );
  }
}

export default withRouter(CustomLayout);
