import React, { Component } from 'react';
import { Row, Col } from "antd";
import { PrevIcon, NextIcon } from "../../components/Icons";
import "./Pagination.scss";

export default class Pagination extends Component {

  prev = () => {
    const { pageIndex } = this.props;
    if (pageIndex < 1) return;
    this.props.onChange(pageIndex - 1);
  }

  next = () => {
    const { pageIndex, pageSize, totalCount } = this.props;
    if ((pageIndex + 1) * pageSize >= totalCount) return;
    this.props.onChange(pageIndex + 1);
  }

  render() {
    const { pageIndex, pageSize, totalCount } = this.props;
    let startNumber = totalCount === 0 ? 0 : pageIndex * pageSize + 1;
    let endIndex = (pageIndex + 1) * pageSize > totalCount ? totalCount : (pageIndex + 1) * pageSize;

    return (
      <Row type="flex" align="middle" justify="center" className="pagination-container">
        <Col className="text">{startNumber} — {endIndex} of {totalCount}</Col>
        <Col>
          <PrevIcon className="icon" onClick={this.prev} />
        </Col>
        <Col>
          <NextIcon className="icon" onClick={this.next} />
        </Col>
      </Row>
    )
  }
}
