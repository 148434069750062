import * as types from "./actionTypes";

const initialState = {
  isLoading: false,
  loadingTitle: "Loading",
  autoSavingTime: 5 * 60 * 1000, // every 5 minutes
  reportInfo: {},
  errorMessage: "",
};

const report = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_REPORT_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
      };
    case types.GET_REPORT_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        reportInfo: action.reportInfo,
      };
    case types.GET_REPORT_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.errorMessage,
      };
    case types.UPDATE_REPORT_DATA:
      return {
        ...state,
        reportInfo: { ...state.reportInfo, ...action.reportInfo },
      };
    default:
      return state;
  }
};

export default report;
