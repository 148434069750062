import { Auth } from "aws-amplify";

export default {
  amplify: {
    Auth: {
      region: process.env.REACT_APP_AWS_REGION,
      userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
    },
    aws_appsync_graphqlEndpoint: 'URL',
    aws_appsync_region: process.env.REACT_APP_AWS_REGION,
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    API: {
      endpoints: [
        {
          name: 'MyAPI',
          endpoint: process.env.REACT_APP_API_URL,
          custom_header: async () => {
            return {
              Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            }
          }
        },
      ],
    },
  },
};