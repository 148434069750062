import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Typography, Button, Radio, Checkbox, Divider } from "antd";

import SpinBox from "components/SpinBox";
import TextAreaBox from "components/TextAreaBox";
import { BackIcon } from "components/Icons";
import { updateReportInfo } from "redux/report";
import * as API from "utils/api";

import "./Intro.scss";

const { Title, Text } = Typography;

class Intro extends Component {
  constructor(props) {
    super(props);

    let report_id = "";
    if (this.props.location && this.props.location.state) {
      report_id = this.props.location.state.data.report_id;
    }

    this.state = {
      report_id: report_id,
      medicare_partAB: null,
      applied_SSDB: false,
      denied_SSDB: false,
      process_SSDB: false,
      years_months_days: false,
      ESRD: false,
      checkboxes_other: "",
      loadingText: "Loading...",
      loading: false,
    };
    this.timeoutId = setInterval(
      this.handleUpdateReport,
      this.props.autoSavingTime
    );
  }
  componentWillUnmount = () => {
    clearInterval(this.timeoutId);
  };
  componentDidMount() {
    const { report_id } = this.state;

    this.setState({ loading: true });
    API.getReportById(report_id).then((res) => {
      const reportInfo = res.report;
      this.setState({
        medicare_partAB: reportInfo.medicare_partAB,
        applied_SSDB: reportInfo.applied_SSDB,
        denied_SSDB: reportInfo.denied_SSDB,
        process_SSDB: reportInfo.process_SSDB,
        years_months_days: reportInfo.years_months_days,
        ESRD: reportInfo.ESRD,
        checkboxes_other: reportInfo.checkboxes_other,
        loading: false,
      });
      this.props.updateReportInfo(reportInfo);
    });
  }

  componentDidUpdate(prevProps) {
    const { reportInfo } = this.props;
    if (JSON.stringify(reportInfo) !== JSON.stringify(prevProps.reportInfo)) {
      this.setState({
        medicare_partAB: reportInfo.medicare_partAB,
        applied_SSDB: reportInfo.applied_SSDB,
        denied_SSDB: reportInfo.denied_SSDB,
        process_SSDB: reportInfo.process_SSDB,
        years_months_days: reportInfo.years_months_days,
        ESRD: reportInfo.ESRD,
        checkboxes_other: reportInfo.checkboxes_other,
        loading: false,
      });
    }
  }

  handleUpdateReport = (isNextStep = false) => {
    const {
      report_id,
      medicare_partAB,
      applied_SSDB,
      denied_SSDB,
      process_SSDB,
      years_months_days,
      ESRD,
      checkboxes_other,
    } = this.state;

    const params = {
      id: report_id,
      medicare_partAB,
      applied_SSDB,
      denied_SSDB,
      process_SSDB,
      years_months_days,
      ESRD,
      checkboxes_other,
    };

    if (isNextStep) {
      this.setState({
        loading: true,
        loadingTitle: "Saving...",
      });

      API.updateReport(params).then((res) => {
        this.setState({ loading: false });
        clearTimeout(this.timeoutId);
        this.props.history.push("/app/reports/msa/injuryInfo", {
          data: { report_id: report_id },
        });
      });
    } else {
      API.updateReport(params);
    }
  };

  goToPreviousStep = () => {
    this.props.history.goBack();
  };

  render() {
    const {
      medicare_partAB,
      applied_SSDB,
      denied_SSDB,
      process_SSDB,
      years_months_days,
      ESRD,
      checkboxes_other,
      loadingTitle,
      loading,
    } = this.state;
    const { reportInfo } = this.props;
    const claimant = reportInfo.claimant ? reportInfo.claimant : null;
    const patientName = claimant
      ? claimant.claimant_name +
        " " +
        claimant.claimant_middle_name +
        " " +
        claimant.claimant_last_name
      : "";

    return (
      <Row className="msa-intro-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Setup MSA Report
            </Title>
          </Col>
          <Col className="back" onClick={() => this.props.history.goBack()}>
            <BackIcon className="icon" />
            <Text>Cancel</Text>
          </Col>
          <Col className="step">
            <Text>Step 8 of 17</Text>
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <Text className="title">Introduction</Text>
                </Col>
              </Row>
              <Row className="row">
                <Col>
                  <p>
                    Dear Sir/Madam, <br />
                    <br /> We represent {patientName} and have been asked by the
                    parties to refer the above case to your office for review
                    and approval of the Worker'sCompensation Medicare Set-aside
                    Arrangement ("WCMSA") outlined in the attached settlement
                    documents. The following is the pertinent information in
                    regard to the above-captioned claimant
                  </p>
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <Text className="title">Medicare checkboxes</Text>
                </Col>
              </Row>
              <Row className="checkbox-item">
                <Checkbox
                  onChange={(e) =>
                    this.setState({
                      medicare_partAB: e.target.checked ? "A" : null,
                      applied_SSDB: false,
                      denied_SSDB: false,
                      process_SSDB: false,
                      years_months_days: false,
                      ESRD: false,
                    })
                  }
                  checked={medicare_partAB ? true : false}
                >
                  <Text className="checkbox-label">
                    Select Medicare part A or B
                  </Text>
                </Checkbox>
              </Row>
              {medicare_partAB && (
                <Row className="radio-item">
                  <Radio.Group
                    onChange={(e) =>
                      this.setState({ medicare_partAB: e.target.value })
                    }
                    value={medicare_partAB}
                  >
                    <Radio value={"A"}>A</Radio>
                    <Radio value={"B"}>B</Radio>
                  </Radio.Group>
                </Row>
              )}
              <Row className="checkbox-item">
                <Checkbox
                  onChange={(e) =>
                    this.setState({ applied_SSDB: e.target.checked })
                  }
                  disabled={medicare_partAB ? true : false}
                  checked={applied_SSDB}
                >
                  <Text className="checkbox-label">
                    Claimant has applied for Social Security disability
                    benefits(SSDB)
                  </Text>
                </Checkbox>
              </Row>
              <Row className="checkbox-item">
                <Checkbox
                  onChange={(e) =>
                    this.setState({ denied_SSDB: e.target.checked })
                  }
                  disabled={medicare_partAB ? true : false}
                  checked={denied_SSDB}
                >
                  <Text className="checkbox-label">
                    Claimant has been denied SSDB but anticipates an appeal
                  </Text>
                </Checkbox>
              </Row>
              <Row className="checkbox-item">
                <Checkbox
                  onChange={(e) =>
                    this.setState({ process_SSDB: e.target.checked })
                  }
                  disabled={medicare_partAB ? true : false}
                  checked={process_SSDB}
                >
                  <Text className="checkbox-label">
                    Claimant is in the process of appealing and/or re-filing for
                    SSDB
                  </Text>
                </Checkbox>
              </Row>
              <Row className="checkbox-item">
                <Checkbox
                  onChange={(e) =>
                    this.setState({ years_months_days: e.target.checked })
                  }
                  disabled={medicare_partAB ? true : false}
                  checked={years_months_days}
                >
                  <Text className="checkbox-label">
                    Claimant is (or will be) at least 62 years and 6 months old
                    120 days from today
                  </Text>
                </Checkbox>
              </Row>
              <Row className="checkbox-item">
                <Checkbox
                  onChange={(e) => this.setState({ ESRD: e.target.checked })}
                  disabled={medicare_partAB ? true : false}
                  checked={ESRD}
                >
                  <Text className="checkbox-label">
                    Claimant has end stage renal disease (ESRD) but does not yet
                    qualify for Medicare based on ESRD
                  </Text>
                </Checkbox>
              </Row>
              <Row>
                <TextAreaBox
                  value={checkboxes_other}
                  label="Other, Please Explain"
                  placeholder="Optional"
                  onChange={(value) =>
                    this.setState({ checkboxes_other: value })
                  }
                />
              </Row>
              <Divider className="divider" />
              <Row
                type="flex"
                justify="center"
                align="middle"
                className="confirm-button"
              >
                <Button
                  type="link"
                  className="prev-button button"
                  onClick={this.goToPreviousStep}
                >
                  &lt; PREVIOUS STEP
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="green-btn button"
                  onClick={() => this.handleUpdateReport(true)}
                >
                  Next Step
                </Button>
              </Row>
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  const props = {
    autoSavingTime: state.report.autoSavingTime,
    reportInfo: state.report.reportInfo,
  };
  return props;
};

export default connect(mapStateToProps, { updateReportInfo })(Intro);
