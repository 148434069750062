const moment = require("moment");
export const total_anticipated_medicare_cost = (medicareArray) => {
  let total = 0.0;
  medicareArray.map((medicareItem) => {
    total = total + medicareItem.total_cost;
  });
  console.log(total);
  return total;
};
export const anticipated_non_medicare_cost = (medicareArray) => {
  let total = 0.0;
  medicareArray.map((medicareItem) => {
    if (
      medicareItem.type == "Future Medical Needed" ||
      medicareItem.type ==
        "Surgeries, Replacements & Procedures(non-medicare)" ||
      medicareItem.type == "Prescription Medications Covered by Medicare"
    ) {
      total = total + medicareItem.total_cost;
    }
  });
  return total;
};

export const anticipated_medicare_cost = (medicareArray) => {
  let total = 0.0;
  medicareArray.map((medicareItem) => {
    if (
      medicareItem.type == "Future Medical Need covered by Medicare" ||
      medicareItem.type == "Surgeries, Replacements & Procedures" ||
      medicareItem.type == "Prescription Medications Covered"
    ) {
      total = total + medicareItem.total_cost;
    }
  });
  return total;
};
export const first_surgery_cost = (medicareArray) => {
  let total = 0.0;
  let NewMedicareArray = medicareArray.filter((item) => {
    return item.type == "Surgeries, Replacements & Procedures";
  });
  let newMedicareArray2 = NewMedicareArray.sort((a, b) => {
    if (a.id > b.id) {
      return 1;
    }
    if (a.id < b.id) {
      return -1;
    }
    if (a.id == b.id) {
      return 0;
    }
  });

  if (newMedicareArray2[0]) {
    medicareArray.map((medicareItem) => {
      if (
        medicareItem.is_seed_calculated &&
        medicareItem.id !== newMedicareArray2[0].id
      ) {
        total += medicareItem.per_unit_cost;
      }
    });
    total += newMedicareArray2[0].per_unit_cost;
  } else {
    medicareArray.map((medicareItem) => {
      if (medicareItem.is_seed_calculated) {
        total += medicareItem.per_unit_cost;
      }
    });
  }
  return total;
};
export const seedMoney = (medicareArray, report) => {
  let first_surgery_ = first_surgery_cost(medicareArray);
  console.log(first_surgery_, "first_surgery");
  let step2 = first_surgery_;
  let step3 = anticipated_medicare_cost(medicareArray) - first_surgery_;

  let step4 = step3 / report.life_expectancy;

  let step5 = step4 * 2;
  console.log(step5);
  let step6_seed_money = step2 + step5;
  let step7a_annuity_over_life_expectancy =
    anticipated_medicare_cost(medicareArray) - step6_seed_money;
  let step7b_annual_annuity =
    step7a_annuity_over_life_expectancy / (report.life_expectancy - 1);
  return {
    seed_money: step6_seed_money,
    annuity_amount_over_life: step7a_annuity_over_life_expectancy,
    annual_annuity: step7b_annual_annuity,
  };
};
export const annuity_payment_over_life = (medicareArray, report) => {
  console.log(
    anticipated_medicare_cost(medicareArray),
    seedMoney(medicareArray, report)
  );
  return (
    anticipated_medicare_cost(medicareArray) -
    seedMoney(medicareArray, report).seed_money
  );
};
export const lengthOfAnnuity = (report) => {
  const today = moment();
  const annuityStartDate = moment(report.annuity_start_date, "YYYY-MM-DD");

  const yearDif = annuityStartDate.diff(today, "years");
  let life_expectancy = report.life_expectancy;
  return life_expectancy - yearDif;
};
export const annuity_amount = (medicareArray, report) => {
  return (
    annuity_payment_over_life(medicareArray, report) / lengthOfAnnuity(report)
  );
};
export const total_annual_medicare_costs_ = (medicareArray) => {
  let total = 0.0;
  medicareArray.map((medicareItem) => {
    if (
      medicareItem.type == "Future Medical Need covered by Medicare" ||
      medicareItem.type == "Surgeries, Replacements & Procedures" ||
      medicareItem.type == "Prescription Medications Covered"
    ) {
      total = total + medicareItem.annual_cost;
    }
  });
  return total;
};
export const section_total = (medicareArray, section) => {
  let total = 0.0;
  medicareArray.map((medicareItem) => {
    if (medicareItem.type == section) {
      total = total + medicareItem.total_cost;
    }
  });
  return total;
};
export const total_annual_non_medicare_costs_ = (medicareArray) => {
  let total = 0.0;
  medicareArray.map((medicareItem) => {
    if (
      medicareItem.type == "Future Medical Needed" ||
      medicareItem.type ==
        "Surgeries, Replacements & Procedures(non-medicare)" ||
      medicareItem.type == "Prescription Medications Covered by Medicare"
    ) {
      total = total + medicareItem.annual_cost;
    }
  });
  return total;
};
