import React, { Component } from "react";
import { Row, Typography, DatePicker, Col, Button } from "antd";
import "./index.scss";
import moment from "moment";
import InputBox from "components/InputBox";
import { CloseCircleOutlined } from "@ant-design/icons";

const { Text } = Typography;
export default class AuthSideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      injuries: [{ date_of_injury: moment(), claim_number: null }],
    };
  }
  handleDelete = (index) => {
    let injuriesString = this.props.report_claim_id;
    let injuries = this.getArrayFromString(injuriesString);
    injuries.splice(index, 1);
    let props = this.getStringFromArray(injuries);
    this.props.onChange(props);
  };
  getArrayFromString = (props) => {
    let injuries = [];
    if (!props) {
      injuries = [{ date_of_injury: moment(), claim_number: "" }];
    } else {
      if (props !== "") {
        props.split(",").map((res) => {
          let item = res.split("$");
          injuries.push({
            date_of_injury: moment.unix(item[0]),
            claim_number: item[1],
          });
        });
      } else {
        injuries = [{ date_of_injury: moment(), claim_number: "" }];
      }
    }
    return injuries;
  };
  getStringFromArray = (injuriesArray) => {
    let props = "";

    injuriesArray.map((injury, i) => {
      let commaBefore = ",";
      let commaAfter = "";
      if (i === 0) {
        commaBefore = "";
      }
      props += `${commaBefore}${moment(injury.date_of_injury).unix()}$${
        injury.claim_number
      }`;
    });
    return props;
  };
  handleEdit = (index, field, value) => {
    let injuriesString = this.props.report_claim_id;
    let injuries = this.getArrayFromString(injuriesString);
    injuries[index][field] = value;
    let props = this.getStringFromArray(injuries);
    this.props.onChange(props);
  };
  render() {
    let props = this.props.report_claim_id;
    let injuries = this.getArrayFromString(props);

    console.log([...injuries]);
    return (
      <Col>
        {injuries.map((injury, i) => {
          let isOnlyOne = this.state.injuries.length > 1;
          return (
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              className="fix-padding"
            >
              {injuries.length > 1 && (
                <div className="center-content">
                  <Text>{i + 1}.</Text>
                </div>
              )}
              <Col md={isOnlyOne ? 10 : 11}>
                <Row className="label">
                  <Text>Injury Date</Text>
                </Row>
                <Row>
                  <DatePicker
                    className="date"
                    format="MM/DD/YYYY"
                    value={injury.date_of_injury}
                    onChange={(date, dateString) => {
                      this.handleEdit(i, "date_of_injury", date);
                    }}
                  />
                </Row>
              </Col>
              <Col md={isOnlyOne ? 10 : 11}>
                <InputBox
                  type="number"
                  label="Claim Number"
                  placeholder="Please enter"
                  value={injury.claim_number}
                  onChange={(value) => {
                    this.handleEdit(i, "claim_number", value);
                  }}
                />
              </Col>
              {injuries.length > 1 && (
                <div
                  className="center-content"
                  onClick={() => {
                    this.handleDelete(i);
                  }}
                >
                  <CloseCircleOutlined
                    style={{
                      color: "red",
                      fontSize: 17,
                      fontWeight: "bold",
                    }}
                    size={30}
                  />
                </div>
              )}
            </Row>
          );
        })}
        <Button
          type="primary"
          className="green-btn add-icd-code_button"
          onClick={() => {
            let injuries = this.getArrayFromString(this.props.report_claim_id);
            injuries.push({ date_of_injury: moment(), claim_number: "" });
            let props = this.getStringFromArray(injuries);
            this.props.onChange(props);
          }}
        >
          Add New
        </Button>
      </Col>
    );
  }
}
