import React, { Component } from 'react';
import { Row, Col, Button, Typography } from 'antd';
import AuthSideBar from '../../../components/AuthSideBar';
import { CheckSuccessIcon } from "../../../components/Icons";
import "./ConfirmReset.scss";

const { Title, Text } = Typography;

export default class ConfirmReset extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      email: ""
    };
  }

  confirm = () => {
    this.props.history.push("/login");
  }

  render() {
    return (
      <Row type="flex" justify="center" className="confirmReset-container">
        <Col xs={0} xl={8}>
          <AuthSideBar />
        </Col>
        <Col xs={24} xl={16}>
          <Row type="flex" justify="center">
            <Col xs={20} md={10}>
              <Row type="flex" justify="space-around" className="main-container">
                <Col style={{margin: "auto"}}>
                  <Row type="flex" justify="center" align="middle" style={{ flexDirection: "column" }}>
                    <CheckSuccessIcon
                      className="check-icon"
                    />
                    <Title level={1}>
                      Password changed
                    </Title>
                    <Text className="label">
                      Now you can use the new password to sign in
                    </Text>
                    <Button size="large" onClick={this.confirm} className="button">
                      Go to Sign In
                    </Button>
                  </Row>
                </Col>
                <Row className="reset">
                  Back to
                  <Button type="link" href="/login">Sign in</Button>
                </Row>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}
