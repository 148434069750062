import React, { Component } from "react";
import { Row, Col, Typography, Upload, message } from "antd";

import SpinBox from "components/SpinBox";
import { getBase64 } from "utils/utils";
import * as API from "utils/api";

import "./UploadCode.scss";

const { Title, Text } = Typography;

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

export default class UploadCode extends Component {
  constructor(props) {
    super(props);

    let report_id = "";
    if (this.props.location && this.props.location.state) {
      report_id = this.props.location.state.data.report_id;
    }

    this.state = {
      report_id: report_id,
      consent_forms: "",
      rated_ages: "",
      court_wc_board_documents: "",
      msa_administration_agreement: "",
      medical_records: "",
      payment_information: "",
      supplemental_documentation_additional_info: "",
      loading: false,
    };
  }

  handleDocUpload = (info, type) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (value) =>
        this.setState({
          [type]: value,
          loading: false,
        })
      );
    }
  };

  handleGenerateReport = () => {
    const {
      report_id,
      consent_forms,
      rated_ages,
      court_wc_board_documents,
      msa_administration_agreement,
      medical_records,
      payment_information,
      supplemental_documentation_additional_info,
    } = this.state;

    const params = {
      id: report_id,
      consent_forms,
      rated_ages,
      court_wc_board_documents,
      msa_administration_agreement,
      medical_records,
      payment_information,
      supplemental_documentation_additional_info,
    };

    API.updateReport(params).then((res) => {
      message.success("Report is generated");
    });
  };

  render() {
    const {
      consent_forms,
      court_wc_board_documents,
      medical_records,
      loading,
    } = this.state;

    return (
      <Row className="upload-code-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Upload Code
            </Title>
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading}>
            <Row className="content">
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={11}>
                  <Row className="label">
                    <Text>ICD Codes</Text>
                  </Row>
                  <Row
                    type="flex"
                    justify="center"
                    align="middle"
                    className="upload"
                  >
                    <Upload
                      name="logo"
                      listType="text"
                      className="logo-uploader"
                      showUploadList={false}
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      beforeUpload={beforeUpload}
                      onChange={(info) =>
                        this.handleDocUpload(info, "consent_forms")
                      }
                      ref={(node) => {
                        this.upload_btn = node;
                      }}
                    >
                      {consent_forms ? (
                        <Text className="logo-button">Uploaded</Text>
                      ) : (
                        <Text className="logo-button">Upload</Text>
                      )}
                    </Upload>
                  </Row>
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={11}>
                  <Row className="label">
                    <Text>HCPC/CPT Codes</Text>
                  </Row>
                  <Row
                    type="flex"
                    justify="center"
                    align="middle"
                    className="upload"
                  >
                    <Upload
                      name="logo"
                      listType="text"
                      className="logo-uploader"
                      showUploadList={false}
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      beforeUpload={beforeUpload}
                      onChange={(info) =>
                        this.handleDocUpload(info, "court_wc_board_documents")
                      }
                      ref={(node) => {
                        this.upload_btn = node;
                      }}
                    >
                      {court_wc_board_documents ? (
                        <Text className="logo-button">Uploaded</Text>
                      ) : (
                        <Text className="logo-button">Upload</Text>
                      )}
                    </Upload>
                  </Row>
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={11}>
                  <Row className="label">
                    <Text>Fee Schedule Codes</Text>
                  </Row>
                  <Row
                    type="flex"
                    justify="center"
                    align="middle"
                    className="upload"
                  >
                    <Upload
                      name="logo"
                      listType="text"
                      className="logo-uploader"
                      showUploadList={false}
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      beforeUpload={beforeUpload}
                      onChange={(info) =>
                        this.handleDocUpload(info, "medical_records")
                      }
                      ref={(node) => {
                        this.upload_btn = node;
                      }}
                    >
                      {medical_records ? (
                        <Text className="logo-button">Uploaded</Text>
                      ) : (
                        <Text className="logo-button">Upload</Text>
                      )}
                    </Upload>
                  </Row>
                </Col>
              </Row>
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}
