import React, { Component } from "react";
import { Row, Col, Typography, Button, Divider, Modal } from "antd";

import SpinBox from "components/SpinBox";
import { BackIcon } from "components/Icons";
import * as API from "utils/api";

import "./NonMedicareTotals.scss";
import { formatCurrency } from "utils/utils";

const { Title, Text } = Typography;

export default class NonMedicareTotals extends Component {
  constructor(props) {
    super(props);

    let report_id = "";
    if (this.props.location && this.props.location.state) {
      report_id = this.props.location.state.data.report_id;
    }

    this.state = {
      report_id: report_id,
      medicareInfo: {},
      loading: false,
    };
  }

  componentDidMount() {
    const { report_id } = this.state;

    this.setState({ loading: true });
    API.getNonMedicareInfoData(report_id).then((res) => {
      this.setState({
        medicareInfo: res.totals,
        loading: false,
      });
    });
  }

  handleUpdateReport = () => {
    const { report_id } = this.state;
    this.props.history.push("/app/reports/msa/submitCoverLetter", {
      data: { report_id: report_id },
    });
  };

  goToPreviousStep = () => {
    this.props.history.goBack();
  };

  handleGenerateReport = () => {
    const { report_id } = this.state;

    this.setState({
      loading: true,
      loadingTitle: "Generating...",
    });

    API.downloadReport(report_id)
      .then((res) => {
        this.setState({ loading: false });
        Modal.success({
          content: "Report is generated",
          onOk: () => {
            window.open(res.report_url);
          },
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        Modal.error({
          content: "Error",
        });
      });
  };

  render() {
    const { medicareInfo, loading } = this.state;

    return (
      <Row className="msa-non-medical-totals-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Setup MSA Report
            </Title>
          </Col>
          <Col className="back" onClick={() => this.props.history.goBack()}>
            <BackIcon className="icon" />
            <Text>Cancel</Text>
          </Col>
          <Col className="step">
            <Text>Step 6 of 17</Text>
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading}>
            <Row className="content">
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <Text className="title">
                    Totals and Allocations (Non Medicare)
                  </Text>
                </Col>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text>Annual Cost Total</Text>
                </Col>
                <Text className="text">
                  {formatCurrency(medicareInfo.annual_cost_total || 0)}
                </Text>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">Recommended Medical Allocation</Text>
                </Col>
                <Text className="text">
                  {formatCurrency(
                    medicareInfo.recommended_medical_allocation || 0
                  )}
                </Text>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">
                    Surgeries, Replacements and Procedures Allocation
                  </Text>
                </Col>
                <Text className="text">
                  {formatCurrency(
                    medicareInfo.surgeries_replacements_procedures_allocation ||
                      0
                  )}
                </Text>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">
                    Recommended Prescription Allocation
                  </Text>
                </Col>
                <Text className="text">
                  {formatCurrency(medicareInfo.prescription_allocation || 0)}
                </Text>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">Recommended Allocation</Text>
                </Col>
                <Text className="text">
                  {formatCurrency(
                    medicareInfo.prescription_allocation +
                      medicareInfo.recommended_medical_allocation +
                      medicareInfo.surgeries_replacements_procedures_allocation
                  )}
                </Text>
              </Row>
              <Divider className="divider" />
              <Row
                type="flex"
                justify="center"
                align="middle"
                className="confirm-button"
              >
                <Button
                  type="link"
                  className="prev-button button"
                  onClick={this.goToPreviousStep}
                >
                  &lt; PREVIOUS STEP
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="green-btn button"
                  onClick={this.handleUpdateReport}
                >
                  Next Step
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="green-btn button generate-btn"
                  onClick={this.handleGenerateReport}
                >
                  Generate Report
                </Button>
              </Row>
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}
