import React, { Component } from 'react';
import { Row, Col, Typography, Input, Empty, Button, Modal } from 'antd';

import DeleteModal from 'components/DeleteModal';
import SpinBox from 'components/SpinBox';
import Pagination from "components/Pagination";
import { EditIcon, RemoveIcon } from 'components/Icons';
import * as API from "utils/api";

import "./Templates.scss";

const { Search } = Input;
const { Title } = Typography;

export default class Templates extends Component {

  constructor() {
    super();

    this.state = {
      pageIndex: 0,
      templatesData: [],
      totalCount: 0,
      showModal: false,
      selectedTemplate: {},
      searchQuery: "",
      loadingTitle: "Loading...",
      loading: false
    }
  }

  componentDidMount() {
    this.handleSearch();
  }

  handlePagination = (pageIndex) => {
    this.setState({
      pageIndex: pageIndex
    }, () => this.handleSearch())
  }

  handleOpenModal = item => {
    this.setState({
      showModal: true,
      selectedTemplate: item
    })
  }

  handleCloseModal = () => {
    this.setState({
      showModal: false
    })
  }

  handleEditTemplate = item => {
    this.props.history.push("/app/templates/edit", {"data": item});
  }

  handleDeleteTemplate = item => {
    const params = {
      id: item.id
    }

    this.setState({
      showModal: false,
      loading: true,
      loadingTitle: "Deleting..."
    })
    API.deleteTemplate(params)
    .then(res => {
      this.setState({loading: false});
      Modal.success({
        content: "Success",
        onOk: () => this.handleSearch()
      })
    })
    .catch(error => {
      this.setState({loading: false});
      const errorMessage = error.response.data.reason ? error.response.data.reason.message : "Error";
      Modal.error({
        content: errorMessage
      })
    });
  }

  handleSearch = () => {
    const { searchQuery, pageIndex } = this.state;
    this.setState({ loading: true, loadingTitle: "Loading..." });
    API.getTemplatesData(searchQuery, pageIndex)
    .then(res => {
      this.setState({
        templatesData: res.templates,
        totalCount: res.total_count,
        loading: false,
      })
    })
    .catch(error => {
      this.setState({
        templatesData: [],
        totalCount: 0,
        loading: false,
      })
      if (error.response.status === 401) {
        Modal.error({
          content: "You are not allowed to access to this page",
          onOk: () => this.props.history.push("/app/main")
        })
      }
    })
  }

  render() {
    const { pageIndex, totalCount, templatesData, showModal, selectedTemplate, loading, loadingTitle } = this.state;

    return (
      <Row className="templates-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">Templates</Title>
          </Col>
          <Col className="search">
            <Search
              placeholder="Search..."
              className="search-box"
              onChange={e => this.setState({searchQuery: e.target.value})}
              onSearch={this.handleSearch}
            />
          </Col>
          <Col className="pagination">
            <Pagination
              onChange={this.handlePagination}
              totalCount={totalCount}
              pageIndex={pageIndex}
              pageSize={10}
            />
          </Col>
          <Col className="add-button">
            <Button type="primary" className="green-btn" onClick={()=>this.props.history.push("/app/templates/add")}>
              Add Template
            </Button>
          </Col>
        </Row>
        <Row type="flex" className="table-header">
          <Col md={10}>Name</Col>
          <Col md={10}>Report Type</Col>
          <Col md={4} style={{textAlign: "right"}}>Actions</Col>
        </Row>
        <Row className="table-content">
          <SpinBox loading={loading} title={loadingTitle}>
          {
            templatesData.length > 0 ?
              <Row>
                {
                  templatesData.map((item, index) => 
                    <Row key={index} className="record">
                      <Col md={10}>{item.name}</Col>
                      <Col md={10}>{item.report_type.name}</Col>
                      <Col md={4} style={{textAlign: "right"}} className="actions">
                        <EditIcon className="icon" onClick={()=>this.handleEditTemplate(item)}/>
                        <RemoveIcon className="icon" onClick={()=>this.handleOpenModal(item)}/>
                      </Col>
                    </Row>
                  )
                }
              </Row>
            :
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="empty-icon" />
          }
          </SpinBox>
        </Row>        
        <DeleteModal
          title="Delete this Template?"
          content={selectedTemplate.name}
          isOpen={showModal}
          onConfirm={()=>this.handleDeleteTemplate(selectedTemplate)}
          onCancel={this.handleCloseModal}
        />
      </Row>
    )
  }
}
