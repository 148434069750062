import React, { useState } from "react";
import {
  Row,
  Col,
  Typography,
  Button,
  Modal,
  Select,
  Radio,
  Icon,
  Checkbox,
  message,
  Upload,
  InputNumber,
  Input,
  Skeleton,
} from "antd";
import * as API from "utils/api";
import "../ReportModal/ReportModal.scss";
import InputBox from "components/InputBox";
import { UploadOutlined } from "@ant-design/icons";
import SpinBox from "components/SpinBox";

const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

export default function FilePhotoModal({
  isOpen,
  onConfirm,
  onCancel,
  onChangeName,
  EditFile,
  onChange,
  onFileSubmit,
  renderUploadedImage,
}) {
  const [loading, setLoading] = useState(true);
  return (
    <div>
      <Modal
        visible={isOpen}
        onCancel={onCancel}
        footer={null}
        centered={true}
        closable={true}
        width={550}
        className="report-modal"
        title={"Update File"}
      >
        <Row type="flex" align="middle" className="report-body">
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            className="main-info"
          >
            {/* {console.log(renderUploadedImage.url == true, "tru of false")}
            <Skeleton loading={loading}>
              <Col md={24}>
                <Text>Your Uploaded File</Text>
                <img src={renderUploadedImage.url} style={{ width: "100%" }} />
              </Col>
            </Skeleton>
            {() => setLoading(false)()} */}
            <Col md={24}>
              <Text>Your Uploaded File</Text>
              <img src={renderUploadedImage.url} style={{ width: "100%" }} />
            </Col>
          </Row>
        </Row>
      </Modal>
    </div>
  );
}
