import React, { Component } from "react";
import { Row, Typography, Button, Icon } from "antd";
import { SketchPicker } from "react-color";

import "./index.scss";

const { Text } = Typography;

export default class AddressFormatter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  render() {
    let { address } = this.props;
    const { isOpen } = this.state;
    address = address.split(",");
    return (
      <div>
        {address[0]}
        <br />
        {address[address.length - 2]}
        <br />
        {address[address.length - 1]}
      </div>
    );
  }
}
