import React, { Component } from "react";
import { Row, Col, Typography, Input, Empty, Button, Modal } from "antd";

import DeleteModal from "components/DeleteModal";
import SpinBox from "components/SpinBox";
import { BackIcon, EditIcon, RemoveIcon, DetailsIcon } from "components/Icons";
import * as API from "utils/api";
import Pagination from "components/Pagination";

import "./Claimants.scss";

const { Search } = Input;
const { Title, Text } = Typography;

export default class Claimant extends Component {
  constructor(props) {
    super(props);

    let clientInfo = {};
    if (this.props.location && this.props.location.state) {
      clientInfo = this.props.location.state.data;
    }

    this.state = {
      client_id: clientInfo.id,
      client_name: clientInfo.client_name,
      pageIndex: 0,
      showModal: false,
      claimantsData: [],
      totalCount: 0,
      selectedClaimant: {},
      searchQuery: "",
      loadingTitle: "Loading...",
      loading: false,
    };
  }

  componentDidMount() {
    this.handleSearch();
  }

  handlePagination = (pageIndex) => {
    this.setState(
      {
        pageIndex: pageIndex,
      },
      () => this.handleSearch()
    );
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
    });
  };

  handleOpenModal = (item) => {
    this.setState({
      showModal: true,
      selectedClaimant: item,
    });
  };

  goToClientAddPage = () => {
    const { client_id } = this.state;
    this.props.history.push("/app/claimants/add", { data: client_id });
  };

  handleEditClaimant = (item) => {
    this.props.history.push("/app/claimants/detail", { data: item });
  };

  handleDeleteClaimant = (item) => {
    const params = {
      id: item.id,
    };

    this.setState({
      showModal: false,
      loading: true,
      loadingTitle: "Deleting...",
    });
    API.deleteClaimant(params)
      .then((res) => {
        this.setState({ loading: false });
        Modal.success({
          content: "Success",
          onOk: () => this.handleSearch(),
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        const errorMessage = error.response.data.reason
          ? error.response.data.reason.message
          : "Error";
        Modal.error({
          content: errorMessage,
        });
      });
  };

  handleSearch = () => {
    const { client_id, searchQuery, pageIndex } = this.state;
    this.setState({ loading: true, loadingTitle: "Loading..." });
    API.getClaimantsData(client_id, searchQuery, pageIndex).then((res) => {
      this.setState({
        claimantsData: res.claimants,
        totalCount: res.total_count,
        loading: false,
      });
    });
  };

  render() {
    const {
      pageIndex,
      totalCount,
      claimantsData,
      client_name,
      selectedClaimant,
      showModal,
      loading,
      loadingTitle,
    } = this.state;

    return (
      <Row className="contacts-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Claimant
            </Title>
            <Text>{client_name}</Text>
          </Col>
          <Col className="back-icon">
            <BackIcon
              className="icon"
              onClick={() => this.props.history.goBack()}
            />
          </Col>
          <Col className="search">
            <Search
              placeholder="Search..."
              className="search-box"
              onChange={(e) => this.setState({ searchQuery: e.target.value })}
              onSearch={this.handleSearch}
            />
          </Col>
          <Col className="pagination">
            <Pagination
              onChange={this.handlePagination}
              totalCount={totalCount}
              pageIndex={pageIndex}
              pageSize={10}
            />
          </Col>
          <Col className="add-button">
            <Button
              type="primary"
              className="green-btn"
              onClick={this.goToClientAddPage}
            >
              Add New Claimant
            </Button>
          </Col>
        </Row>
        <Row type="flex" className="table-header">
          <Col md={5}>Full Name</Col>
          <Col md={5}>Title</Col>
          <Col md={6}>Email</Col>
          <Col md={4}>Phone</Col>
          <Col md={4} style={{ textAlign: "right" }}>
            Actions
          </Col>
        </Row>
        <Row className="table-content">
          <SpinBox loading={loading} title={loadingTitle}>
            {claimantsData.length > 0 ? (
              <Row>
                {claimantsData.map((item, index) => (
                  <Row key={index} className="record">
                    <Col md={5}>
                      <Text>
                        {item.claimant_name +
                          " " +
                          item.claimant_middle_name +
                          " " +
                          item.claimant_last_name}
                      </Text>
                    </Col>
                    <Col md={5}>
                      <Text>{item.claimant_title}</Text>
                    </Col>
                    <Col md={6}>
                      <Text>{item.claimant_email}</Text>
                    </Col>
                    <Col md={4}>
                      <Text>{item.claimant_phone}</Text>
                    </Col>
                    <Col md={4} className="actions">
                      <DetailsIcon
                        className="icon"
                        onClick={() => this.handleEditClaimant(item)}
                      />
                      <RemoveIcon
                        className="icon"
                        onClick={() => this.handleOpenModal(item)}
                      />
                    </Col>
                  </Row>
                ))}
              </Row>
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                className="empty-icon"
              />
            )}
          </SpinBox>
        </Row>
        <DeleteModal
          isOpen={showModal}
          title="Delete this Claimant?"
          content={selectedClaimant.claimant_title}
          onConfirm={() => this.handleDeleteClaimant(selectedClaimant)}
          onCancel={this.handleCloseModal}
        />
      </Row>
    );
  }
}
