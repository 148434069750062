import React, { Component } from "react";
import {
  Row,
  Col,
  Typography,
  Button,
  Select,
  Icon,
  DatePicker,
  Modal,
} from "antd";
import InputMask from "react-input-mask";
import "./Address.scss";
import * as API from "utils/api";
import InputBox from "components/InputBox";
const { Text } = Typography;
const { Option } = Select;

export default class Address extends Component {
  constructor(props) {
    super(props);

    this.state = {
      address_line_1: "",
      address_line_2: "",
      city: "",
      juridiction_list: [],
      juridiction: "",
      zip: "",
    };
  }
  componentDidMount = () => {
    API.getJuridictionStateList().then((res) => {
      this.setState({ juridiction_list: res.juridictionStates });
    });
    if (this.props.address) {
      var address = this.props.address;
      this.setState({
        address_line_1: address.split(",")[0],
        address_line_2: address.split(",")[1],
        city: address.split(",")[2],
        juridiction: address.split(",")[3],
        zip: address.split(",")[4],
      });
    }
  };
  createFinalAddress = () => {
    var address = `${this.state.address_line_1.replace(
      /,/g,
      ""
    )},${this.state.address_line_2.replace(/,/g, "")},${this.state.city.replace(
      /,/g,
      ""
    )},${this.state.juridiction.replace(/,/g, "")},${this.state.zip.replace(
      /,/g,
      ""
    )}`;
    this.props.getAddress(address);
  };
  componentWillReceiveProps = (props) => {
    if (props.address == "") {
      this.setState({
        address_line_1: "",
        address_line_2: "",
        city: "",
        juridiction: "",
        zip: "",
      });
    }
  };
  render() {
    const {
      address,
      address_line_1,
      address_line_2,
      city,
      juridiction_list,
      juridiction,
      zip,
    } = this.state;
    return (
      <>
        <Row className="label">
          <Text>Address Line 1</Text>
        </Row>
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          className="main-info"
        >
          <Col md={24} className="row">
            <InputBox
              placeholder=" "
              value={address_line_1}
              onChange={(value) =>
                this.setState(
                  { address_line_1: value },
                  this.createFinalAddress
                )
              }
            />
          </Col>
        </Row>
        <Row className="label">
          <Text>Address Line 2</Text>
        </Row>
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          className="main-info"
        >
          <Col md={24} className="row">
            <InputBox
              placeholder=" "
              value={address_line_2}
              onChange={(value) =>
                this.setState(
                  { address_line_2: value },
                  this.createFinalAddress
                )
              }
            />
          </Col>
        </Row>

        <Row
          type="flex"
          justify="space-between"
          align="middle"
          className="main-info"
        >
          <Col md={15} className="row">
            <Row className="label">
              <Text>City</Text>
            </Row>
            <InputBox
              placeholder=" "
              value={city}
              onChange={(value) =>
                this.setState({ city: value }, this.createFinalAddress)
              }
            />
          </Col>
          <Col md={4} className="row">
            <Row className="label">
              <Text>State</Text>
            </Row>
            <Select
              showSearch
              placeholder="Please select"
              optionFilterProp="children"
              className="dropdown"
              value={juridiction}
              onChange={(value) => {
                this.setState({ juridiction: value }, this.createFinalAddress);
              }}
              suffixIcon={
                <Icon
                  type="caret-down"
                  style={{ color: "black" }}
                  theme="filled"
                />
              }
            >
              {juridiction_list.map((el, i) => {
                return (
                  <Option key={i} value={el.name}>
                    {el.name}
                  </Option>
                );
              })}
            </Select>
          </Col>
          <Col md={3} className="row">
            <Row className="label more_margin">
              <Text>ZIP Code</Text>
            </Row>
            <InputMask
              mask="99999"
              placeholder=" "
              className="input-ssn"
              value={zip}
              onChange={(e) =>
                this.setState({ zip: e.target.value }, this.createFinalAddress)
              }
            />
          </Col>
        </Row>
      </>
    );
  }
}
