import React, { Component } from "react";
import { Row, Col, Typography, Empty } from "antd";
import { Tooltip } from "antd";
import { connect } from "react-redux";
import {
  EditIcon,
  RemoveIcon,
  CloneIcon,
  UpIcon,
  DownIcon,
} from "components/Icons";
import { formatCurrency } from "utils/utils";
import getInflation from "../../utils/inflation";
import "./MedicareCostTable.scss";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  UpCircleTwoTone,
  DownCircleTwoTone,
  UpCircleOutlined,
  DownCircleOutlined,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";
const { Text } = Typography;

class MedicareCostTable extends Component {
  render() {
    const {
      data,
      handleEdit,
      handleDelete,
      handleSeed,
      handleClone,
      handleChangePosition,
    } = this.props;
    return (
      <Row className="msa-medicare-cost-table-container">
        <Row type="flex" align="middle" className="table-header">
          <Col md={3} className="border-right center">
            {this.props.isMcp
              ? "Future Medical Need"
              : "Future Medical Need Of The Type Normally Covered By Medicare"}
          </Col>
          <Col md={2} className="border-right center">
            Speciality
          </Col>
          <Col md={2} className="border-right center">
            CPT/HCPCS Code
          </Col>
          <Col md={3} className="border-right">
            <Row className="sub-group-header">
              <Col md={24}>Frequency And Duration Of Need</Col>
            </Row>
            <Row type="flex" align="middle" className="sub-header border-top">
              <Col md={8} className="border-right center">
                Units Needed
              </Col>
              <Col md={8} className="border-right center">
                Every
              </Col>
              <Col md={8} className="center">
                'X' Years
              </Col>
            </Row>
          </Col>
          <Col md={2} className="border-right center">
            Per Unit cost
          </Col>
          <Col md={2} className="border-right center">
            Annual Cost
          </Col>
          <Col md={1} className="border-right center">
            Total Years
          </Col>
          <Col md={2} className="border-right center">
            Total Cost
          </Col>
          {this.props.report.has_inflation && (
            <Col md={3} className="border-right center">
              Inflation Total
            </Col>
          )}
          ><Col className="border-right center comment">Comments</Col>
          {!this.props.isMcp && (
            <Col md={2} className="border-right center">
              Used in seed money calculation
            </Col>
          )}
          <Col className="action"></Col>
        </Row>
        <Row className="table-content">
          {data.length > 0 ? (
            <Row>
              {data
                .sort((a, b) => {
                  return a.position - b.position;
                })
                .map((item, index) => (
                  <Row
                    key={index}
                    className="record"
                    id={"table-item-" + item.id}
                  >
                    <Col md={3} className="text-left">
                      <Text>{item.name}</Text>
                    </Col>
                    <Col md={2}>
                      <Text>{item.speciality && item.speciality.name}</Text>
                    </Col>
                    <Col md={2}>
                      <Text>{item.cpt_code && item.cpt_code.code}</Text>
                    </Col>
                    <Col md={3}>
                      <Row>
                        <Col md={8}>
                          <Text>{item.units_needed}</Text>
                        </Col>
                        <Col md={8}>
                          <Text>{"Every"}</Text>
                        </Col>
                        <Col md={8}>
                          <Text>{item.every_x_year}</Text>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={2}>
                      <Text>{formatCurrency(item.per_unit_cost)}</Text>
                    </Col>
                    <Col md={2}>
                      <Text>{formatCurrency(item.annual_cost)}</Text>
                    </Col>
                    <Col md={1}>
                      <Text>{item.total_years}</Text>
                    </Col>
                    <Col md={2}>
                      <Text>{formatCurrency(item.total_cost)}</Text>
                    </Col>
                    {this.props.report.has_inflation && (
                      <Col md={3}>
                        <Text>
                          {formatCurrency(
                            getInflation(
                              {
                                interest_rate: this.props.report.interest_rate,
                              },
                              item.total_cost,
                              item.total_years
                            )
                          )}
                        </Text>
                      </Col>
                    )}

                    <Col className="comment text-left">
                      <Text>{item.comments}</Text>
                    </Col>
                    {!this.props.isMcp && (
                      <Col md={2}>
                        <Text>
                          {!item.is_seed_calculated ? (
                            <CloseCircleOutlined
                              onClick={() => {
                                handleSeed(item.id, item.is_seed_calculated);
                              }}
                              style={{
                                color: "gray",
                                fontSize: 17,
                                fontWeight: "bold",
                              }}
                            />
                          ) : (
                            <CheckCircleOutlined
                              onClick={() => {
                                handleSeed(item.id, item.is_seed_calculated);
                              }}
                              style={{
                                color: "green",
                                fontSize: 17,
                                fontWeight: "bold",
                              }}
                            />
                          )}
                        </Text>
                      </Col>
                    )}
                    <Col
                      style={{
                        textAlign: "right",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                      className="actions"
                    >
                      <div
                        className="position"
                        style={{
                          height: 34,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <UpOutlined
                          onClick={() => {
                            handleChangePosition(item.id, "up");
                          }}
                          style={{
                            fontSize: 15,
                            fontWeight: "bold",
                            color: "#0082FF",
                          }}
                          className="icon"
                        />

                        {/* <UpIcon
                          onClick={() => {
                            handleChangePosition(item.id, "up");
                          }}
                        /> */}

                        <DownOutlined
                          onClick={() => {
                            handleChangePosition(item.id, "down");
                          }}
                          style={{
                            fontSize: 15,
                            fontWeight: "bold",
                            color: "#0082FF",
                          }}
                          className="icon"
                        />
                        {/* <DownIcon
                          onClick={() => {
                            handleChangePosition(item.id, "down");
                          }}
                        /> */}
                      </div>
                      <Tooltip title="Clone">
                        <CloneIcon
                          className="icon"
                          onClick={() => handleClone(item)}
                        />
                      </Tooltip>
                      <Tooltip title="Edit">
                        <EditIcon
                          className="icon"
                          onClick={() => handleEdit("EDIT", item)}
                        />
                      </Tooltip>
                      <Tooltip title="Remove">
                        <RemoveIcon
                          className="icon"
                          onClick={() => handleDelete(item)}
                        />
                      </Tooltip>
                    </Col>
                  </Row>
                ))}
            </Row>
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              className="empty-icon"
            />
          )}
        </Row>
      </Row>
    );
  }
}
const mapStateToProps = (state) => {
  console.log(state, "wholestate");
  const props = {
    settings: state.auth.settings,
    report: state.report.reportInfo,
  };
  return props;
};

export default connect(mapStateToProps, {})(MedicareCostTable);
