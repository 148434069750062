import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Typography, Button, Divider, Select, Icon } from "antd";

import SpinBox from "components/SpinBox";
import { BackIcon } from "components/Icons";
import { updateReportInfo } from "redux/report";
import * as API from "utils/api";

import "./CalculationMethod.scss";

const { Title, Text } = Typography;
const { Option } = Select;

class CalculationMethod extends Component {
  constructor(props) {
    super(props);

    let report_id = "";
    if (this.props.location && this.props.location.state) {
      report_id = this.props.location.state.data.report_id;
    }

    this.state = {
      report_id: report_id,
      msa_calculation_method: "Fee Schedule",
      loadingTitle: "Loading",
      loading: false,
    };
    this.timeoutId = setInterval(
      this.handleUpdateReport,
      this.props.autoSavingTime
    );
  }
  componentWillUnmount = () => {
    clearInterval(this.timeoutId);
  };
  componentDidMount() {
    const { report_id } = this.state;

    this.setState({ loading: true });
    API.getReportById(report_id).then((res) => {
      const reportInfo = res.report;
      this.setState({
        msa_calculation_method:
          reportInfo.msa_calculation_method || "Fee Schedule",
        loading: false,
      });
      this.props.updateReportInfo(res.report);
    });
  }

  componentDidUpdate(prevProps) {
    const { reportInfo } = this.props;
    if (JSON.stringify(reportInfo) !== JSON.stringify(prevProps.reportInfo)) {
      this.setState({
        msa_calculation_method:
          reportInfo.msa_calculation_method || "Fee Schedule",
        loading: false,
      });
    }
  }

  handleUpdateReport = (isNextStep = false) => {
    const { report_id, msa_calculation_method } = this.state;

    const params = {
      id: report_id,
      msa_calculation_method,
    };

    if (isNextStep) {
      this.setState({
        loading: true,
        loadingTitle: "Saving...",
      });

      API.updateReport(params).then((res) => {
        this.setState({ loading: false });
        clearTimeout(this.timeoutId);
        this.props.history.push("/app/reports/msa/futurePrescription", {
          data: { report_id: report_id },
        });
      });
    } else {
      API.updateReport(params);
    }
  };

  goToPreviousStep = () => {
    this.props.history.goBack();
  };

  render() {
    const { msa_calculation_method, loadingTitle, loading } = this.state;

    return (
      <Row className="msa-calculation-method-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Setup MSA Report
            </Title>
          </Col>
          <Col className="back" onClick={() => this.props.history.goBack()}>
            <BackIcon className="icon" />
            <Text>Cancel</Text>
          </Col>
          <Col className="step">
            <Text>Step 13 of 17</Text>
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <Text className="title">MSA Calculation Method</Text>
                </Col>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">MSA calculation method</Text>
                </Col>
                <Row>
                  <Select
                    showSearch
                    placeholder="Please select"
                    optionFilterProp="children"
                    className="dropdown"
                    value={msa_calculation_method}
                    onChange={(value) =>
                      this.setState({ msa_calculation_method: value })
                    }
                    suffixIcon={
                      <Icon
                        type="caret-down"
                        style={{ color: "black" }}
                        theme="filled"
                      />
                    }
                  >
                    <Option value={"Fee Schedule"} key={"1"}>
                      Fee Schedule
                    </Option>
                    <Option value={"Full Actual Charges"} key={"2"}>
                      Full Actual Charges
                    </Option>
                  </Select>
                </Row>
              </Row>
              <Divider className="divider" />
              <Row
                type="flex"
                justify="center"
                align="middle"
                className="confirm-button"
              >
                <Button
                  type="link"
                  className="prev-button button"
                  onClick={this.goToPreviousStep}
                >
                  &lt; PREVIOUS STEP
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="green-btn button"
                  onClick={() => this.handleUpdateReport(true)}
                >
                  Next Step
                </Button>
              </Row>
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  const props = {
    autoSavingTime: state.report.autoSavingTime,
    reportInfo: state.report.reportInfo,
  };
  return props;
};

export default connect(mapStateToProps, { updateReportInfo })(
  CalculationMethod
);
