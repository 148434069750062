import React, { Component } from "react";
import { Row, Typography, Input } from "antd";

import "./InputBox.scss";

const { Text } = Typography;

export default class InputBox extends Component {
  render() {
    const { label, value, disabled, placeholder, onChange } = this.props;

    return (
      <Row className="input-box-container">
        <Row className="label">
          <Text>{label}</Text>
        </Row>
        <Row>
          <Input
            placeholder={placeholder || "Required"}
            className="input"
            disabled={disabled || false}
            value={value}
            onChange={(e) => onChange(e.target.value)}
          />
        </Row>
      </Row>
    );
  }
}
