import { getPresignedUrl } from "./api";
import axios from "axios";
export const UploadFile = async (fileBase64, name) => {
  let presignedUrl = await getPresignedUrl(name);
  let res = await fetch(fileBase64);
  let blob = await res.blob();
  const fd = new FormData();
  const file = new File([blob], "234f.pdf");
  fd.append("file", file);

  try {
    await axios({
      url: presignedUrl.url,
      method: "put",
      // headers: { "Content-Type": "multipart/form-data" },
      data: fd,
    });
  } catch (error) {
    return { error: "Cannot upload" };
  }
  return presignedUrl.url.split("?")[0];
};
