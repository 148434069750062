import React, { Component } from "react";
import {
  Row,
  Col,
  Typography,
  Button,
  Modal,
  Select,
  Radio,
  Icon,
  Checkbox,
  InputNumber,
} from "antd";
import * as API from "utils/api";
import "./ReportModal.scss";
import InputBox from "components/InputBox";

const { Text } = Typography;
const { Option } = Select;

export default class ReportModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      report_name: "",
      report_type: 1,
      report_status: null,
      allReports: [],
      feeding_id: null,
      show_additional_comments: true,
      has_inflation: false,
      interest_rate: "0",
    };
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      const { data, reportStatuses } = this.props;
      console.log(data);
      this.setState({
        report_name: data.report_name || "",
        report_type: data.report_type_id || 1,
        show_additional_comments:
          data.show_additional_comments === undefined
            ? true
            : data.show_additional_comments,
        has_inflation: data.has_inflation,
        interest_rate: data.interest_rate ? data.interest_rate : "0",
        report_status:
          data.report_status_id ||
          (reportStatuses[0] ? reportStatuses[0].id : ""),
      });
    }
  }
  componentDidMount = () => {
    API.getReportsDataByClaimantId(this.props.claimant_id, 0, 10000).then(
      (res) => {
        this.setState({ allReports: res.reports });
      }
    );
  };
  handleConfirm = () => {
    const { onConfirm } = this.props;
    const {
      report_name,
      report_type,
      report_status,
      show_additional_comments,
      has_inflation,
      interest_rate,
    } = this.state;
    if (report_name == "" || report_name == null || report_name == " ") {
      Modal.error({ content: "Please Enter Report Name" });
    } else {
      const param = {
        show_additional_comments,
        report_name,
        report_type_id: report_type,
        report_status_id: report_status,
        has_inflation,
        interest_rate,
      };
      if (this.state.feeding_id) {
        param.feeding_id = this.state.feeding_id;
      }

      onConfirm(param);
    }
  };

  render() {
    const { reportStatuses, type, isOpen, onCancel } = this.props;
    const {
      report_name,
      report_type,
      report_status,
      show_additional_comments,
      has_inflation,
      interest_rate,
    } = this.state;

    return (
      <Modal
        visible={isOpen}
        onCancel={onCancel}
        footer={null}
        centered={true}
        closable={true}
        width={550}
        className="report-modal"
        title={type === "ADD" ? "Add Report" : "Edit Report"}
      >
        <Row type="flex" align="middle" className="report-body">
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            className="main-info"
          >
            <Col md={24} className="row">
              <InputBox
                label="Report Name"
                value={report_name}
                onChange={(value) => this.setState({ report_name: value })}
              />
            </Col>
          </Row>
          {type !== "CLONE" && (
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              className="main-info"
            >
              <Col md={11} className="row">
                <Row>
                  <Text>Report type</Text>
                </Row>
                <Row className="radio-button">
                  <Radio.Group
                    value={report_type}
                    size="large"
                    buttonStyle="solid"
                    onChange={(e) =>
                      this.setState({ report_type: e.target.value })
                    }
                  >
                    <Col className="radio">
                      <Radio.Button value={1}>MSA</Radio.Button>
                    </Col>
                    <Col className="radio">
                      <Radio.Button value={3}>MCP</Radio.Button>
                    </Col>
                  </Radio.Group>
                </Row>
              </Col>
              <Col md={11} className="row">
                <Row>
                  <Text>Report Status</Text>
                </Row>
                <Row>
                  <Select
                    showSearch
                    placeholder="Please select"
                    optionFilterProp="children"
                    className="dropdown"
                    value={report_status}
                    onChange={(value) =>
                      this.setState({ report_status: value })
                    }
                    suffixIcon={
                      <Icon
                        type="caret-down"
                        style={{ color: "black" }}
                        theme="filled"
                      />
                    }
                  >
                    {reportStatuses.map((item) => (
                      <Option value={item.id} key={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Row>
              </Col>
            </Row>
          )}
          {this.state.allReports.length > 0 && (
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              className="main-info"
            >
              <Col md={11} className="row">
                <Row>
                  <Text>Copy data from report:</Text>
                </Row>
                <Row>
                  <Select
                    placeholder="Please select"
                    optionFilterProp="children"
                    className="dropdown"
                    value={this.state.feeding_id}
                    onChange={(value) => this.setState({ feeding_id: value })}
                    suffixIcon={
                      <Icon
                        type="caret-down"
                        style={{ color: "black" }}
                        theme="filled"
                      />
                    }
                  >
                    {this.state.allReports.map((item) => (
                      <Option value={item.id} key={item.id}>
                        {item.report_name}
                      </Option>
                    ))}
                  </Select>
                </Row>
              </Col>
              <Col md={11} className="row">
                <Checkbox
                  checked={show_additional_comments}
                  onChange={(value) => {
                    this.setState({
                      show_additional_comments: !show_additional_comments,
                    });
                  }}
                >
                  Show comment sections on the PDF
                </Checkbox>
              </Col>
            </Row>
          )}

          <Row
            type="flex"
            justify="space-between"
            align="middle"
            className="main-info"
          >
            <Col md={12} className="row">
              <Checkbox
                checked={has_inflation}
                onChange={(value) => {
                  this.setState({
                    has_inflation: !has_inflation,
                  });
                }}
              >
                Use inflation
              </Checkbox>
            </Col>
            {has_inflation && (
              <Col md={12} className="row">
                <Row>
                  <Text>inflation Rate</Text>
                </Row>

                <InputNumber
                  className="input"
                  style={{ width: "100%" }}
                  defaultValue={0}
                  min={0}
                  max={100}
                  value={parseFloat(interest_rate)}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace("%", "")}
                  onChange={(value) => {
                    this.setState({ interest_rate: value + "" });
                  }}
                />
              </Col>
            )}
          </Row>

          <Button
            type="primary"
            size="large"
            className="green-btn button"
            onClick={this.handleConfirm}
          >
            {type == "EDIT" ? "Save" : "Setup Report"}
          </Button>
        </Row>
      </Modal>
    );
  }
}
