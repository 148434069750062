import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Col, Typography, Progress } from 'antd';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';

import {
  AssignmentsIcon,
  ReportsIcon,
  ClientIcon,
  ClaimantsIcon,
  UserIcon
} from "components/Icons";
import SpinBox from "components/SpinBox";
import { logout } from "redux/auth/actions";
import * as API from "utils/api";

import "./Dashboard.scss";

const { Title } = Typography;
const MonthInfo = {
  "01": "Jan", "02": "Feb", "03": "Mar", "04": "Apr", "05": "May", "06": "Jun", "07": "Jul", "08": "Aug", "09": "Sep", "10": "Oct", "11": "Nov", "12": "Dec"
};

class Dashboard extends Component {

  constructor(props) {
    super(props);

    this.state = {
      countData: {},
      chartData: [],
      loading: false,
    }
  }

  componentDidMount() {
    this.setState({ loading: true });
    API.getDashboardData()
    .then(res => {
      let chartData = res.reportCountLast12Month;
      chartData.sort(function(a, b) {
        return a.dt > b.dt ? 1 : -1
      });
      this.setState({
        countData: res,
        chartData: chartData,
        loading: false,
      })
    });
  }

  render() {
    const { countData, chartData, loading } = this.state;

    let ratio = parseInt(parseInt(countData.total_reports || 0) / (parseInt(countData.total_claimants || 0) + parseInt(countData.total_reports || 0)) * 100);    

    return (
      <Row className="dashboard-container">
        <Row type="flex" align="middle" justify="center" className="header">
          <Title level={4} className="title">Dashboard</Title>
        </Row>
        <SpinBox loading={loading}>
          <Row type="flex" justify="space-between" className="report">
            <Col className="report-item assignments">
              <Row type="flex" justify="space-between" align="middle">
                <Col>
                  <Row className="title">
                    Open Assignments
                  </Row>
                  <Row className="count">
                    {countData.openAssigments || 0}
                  </Row>
                </Col>
                <Col className="icon">
                  <AssignmentsIcon />
                </Col>
              </Row>
            </Col>
            <Col className="report-item reports">
              <Row type="flex" justify="space-between" align="middle">
                <Col>
                  <Row className="title">
                    Total Reports
                  </Row>
                  <Row className="count">
                    {countData.total_reports || 0}
                  </Row>
                </Col>
                <Col className="icon">
                  <ReportsIcon />
                </Col>
              </Row>
            </Col>
            <Col className="report-item clients">
              <Row type="flex" justify="space-between" align="middle">
                <Col>
                  <Row className="title">
                    Total Clients
                  </Row>
                  <Row className="count">
                    {countData.total_clients || 0}
                  </Row>
                </Col>
                <Col className="icon">
                  <ClientIcon />
                </Col>
              </Row>
            </Col>
            <Col className="report-item claimants">
              <Row type="flex" justify="space-between" align="middle">
                <Col>
                  <Row className="title">
                    Total Claimants
                  </Row>
                  <Row className="count">
                    {countData.total_claimants || 0}
                  </Row>
                </Col>
                <Col className="icon">
                  <ClaimantsIcon />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row type="flex" justify="space-between" className="chart">
            <Col md={16} xl={16} className="area-chart">
              <Row type="flex" justify="space-between" align="middle" className="chart-header">
                <Col className="title">
                  Reports Overview
                </Col>
                <Col className="period">
                  12 months
                </Col>
              </Row>
              <Row className="main-chart">
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart
                    data={chartData}
                    margin={{top: 10, right: 40, left: 10, bottom: 30}}
                  >
                    <CartesianGrid vertical={false} strokeDasharray="2 4"/>
                    <defs>
                      <linearGradient id="colorReport" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="rgba(131, 227, 99, 0.8)" stopOpacity={0.8}/>
                        <stop offset="95%" stopColor="rgba(131, 227, 99, 0)" stopOpacity={0}/>
                      </linearGradient>
                    </defs>
                    <XAxis dataKey="dt" height={30} padding={{ left: 0 }} tick={<CustomizedAxisTick />} tickLine={false}/>
                    <YAxis dataKey="count" dx={-5} axisLine={false} tickLine={false} />
                    <Tooltip/>
                    <Area
                      type='monotone'
                      dataKey='count'
                      stroke='#83e363'
                      fillOpacity={1}
                      fill="url(#colorReport)"
                      dot={<CustomizedDot />}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </Row>
            </Col>
            <Col sm={16} md={12} xl={7} className="circle-chart">
              <Progress
                percent={100}
                successPercent={ratio}
                type="circle"
                width={250}
                strokeWidth={7}
                strokeColor="#72ccff"
                strokeLinecap="square"
                className="main-chart"
                format={(props)=>{
                  return (
                    <Row>
                      <Row className="percentage">{countData.total_claimants || 0} / {countData.total_reports || 0}</Row>
                      <Row className="description">Claimants / Reports</Row>
                    </Row>
                  )
                }}
              />
              <Row type="flex" justify="space-between" className="description">
                <Col className="label"><span className="icon-1"/>Claimants</Col>
                <Col className="label"><span className="icon-2"/>Reports</Col>
              </Row>
            </Col>
          </Row>
          <Row type="flex" justify="space-between" className="button">
            <Col className="button-item assignments" onClick={() => this.props.history.push("/app/assignments")}>
              <Row className="icon">
                <AssignmentsIcon />
              </Row>
              <Row className="text">
                My Assignments
              </Row>
            </Col>
            <Col className="button-item reports" onClick={() => this.props.history.push("/app/reports")}>
              <Row className="icon">
                <ReportsIcon />
              </Row>
              <Row className="text">
                Reports
              </Row>
            </Col>
            <Col className="button-item clients" onClick={() => this.props.history.push("/app/clients")}>
              <Row className="icon">
                <ClientIcon />
              </Row>
              <Row className="text">
                My Clients
              </Row>
            </Col>
            <Col className="button-item users" onClick={() => this.props.history.push("/app/users")}>
              <Row className="icon">
                <UserIcon />
              </Row>
              <Row className="text">
                My Users
              </Row>
            </Col>
          </Row>
        </SpinBox>
      </Row>
    )
  }
}

class CustomizedDot extends React.Component {
  render() {
    const { cx, cy } = this.props;

    return (
      <circle cx={cx} cy={cy} r={4} stroke="#83e363" strokeWidth={2} fill="white" />
    );
  }
};

class CustomizedAxisTick extends React.Component {
  render() {
    const {
      x, y, payload,
    } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={12} y={10} dy={16} textAnchor="end" fill="#666">{MonthInfo[payload.value]}</text>
      </g>
    );
  }
}

export default connect(
  null,
  { logout }
)(Dashboard);