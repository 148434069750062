import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Typography,
  Button,
  Divider,
  Select,
  Modal,
  Icon,
  Radio,
} from "antd";

import SpinBox from "components/SpinBox";
import { BackIcon } from "components/Icons";
import ContactModal from "components/ContactModal";
import { updateReportInfo } from "redux/report";
import * as API from "utils/api";
import ContactsOnReport from "components/ContactsOnReport";

import "./InjuryInfo.scss";

const { Title, Text } = Typography;
const { Option } = Select;

class InjuryInfo extends Component {
  constructor(props) {
    super(props);

    let report_id = "";
    if (this.props.location && this.props.location.state) {
      report_id = this.props.location.state.data.report_id;
    }

    this.state = {
      report_id: report_id,
      contactsList: [],
      releases_attached: true,
      msa_administrator: null,
      isModalOpen: false,
      loadingTitle: "Loading",
      loading: false,
      report_contacts: [],
      defenseList: [],
      plaintifList: [],
      insuranceList: [],
    };
    this.timeoutId = setInterval(
      this.handleUpdateReport,
      this.props.autoSavingTime
    );
  }
  componentWillUnmount = () => {
    clearInterval(this.timeoutId);
  };
  componentDidMount() {
    const { report_id } = this.state;

    this.setState({ loading: true });
    API.getContactsData("", 0, 1000) //Getting all contacts data
      .then((res) => {
        this.setState({
          contactsList: res.contacts,
        });
      });
    API.getReportById(report_id).then((res) => {
      const reportInfo = res.report;
      this.setState({
        releases_attached:
          reportInfo.releases_attached !== null
            ? reportInfo.releases_attached
            : true,
        msa_administrator: reportInfo.msa_administrator
          ? reportInfo.msa_administrator
          : null,
        loading: false,
      });
      this.props.updateReportInfo(reportInfo);
    });
    API.getReportContacts(report_id).then((res) => {
      var list = res.report_contacts.rows;
      console.log({ franko: list });
      var plaintifList = list.filter((el) => el.type == "plaintiff_attorney");
      var defenseList = list.filter((el) => el.type == "defense_attorney");
      var insuranceList = list.filter((el) => el.type == "insurance_carrier");
      this.setState({
        report_contacts: list,
        defenseList,
        plaintifList,
        insuranceList,
      });
    });
  }

  componentDidUpdate(prevProps) {
    const { reportInfo } = this.props;
    if (JSON.stringify(reportInfo) !== JSON.stringify(prevProps.reportInfo)) {
      this.setState({
        releases_attached:
          reportInfo.releases_attached !== null
            ? reportInfo.releases_attached
            : true,
        msa_administrator: reportInfo.msa_administrator
          ? reportInfo.msa_administrator
          : null,
        loading: false,
      });
    }
  }

  handleUpdateReport = (isNextStep = false) => {
    const { report_id, releases_attached, msa_administrator } = this.state;

    const params = {
      id: report_id,
      releases_attached,
      msa_administrator,
    };

    if (isNextStep) {
      this.setState({
        loading: true,
        loadingTitle: "Saving...",
      });

      API.updateReport(params).then((res) => {
        this.setState({ loading: false });
        clearTimeout(this.timeoutId);
        this.props.history.push("/app/reports/msa/settlementInfo", {
          data: { report_id: report_id },
        });
      });
    } else {
      API.updateReport(params);
    }
  };

  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  handleConfirmModal = () => {
    this.setState({ isModalOpen: false });
    API.getContactsData("", 0, 1000) //Getting all contacts data
      .then((res) => {
        this.setState({
          contactsList: res.contacts,
        });
      });
  };

  goToPreviousStep = () => {
    this.props.history.goBack();
  };

  render() {
    const {
      contactsList,
      releases_attached,
      msa_administrator,
      isModalOpen,
      loadingTitle,
      loading,
      report_contacts,
      defenseList,
      plaintifList,
      insuranceList,
    } = this.state;
    const { reportInfo } = this.props;

    return (
      <Row className="msa-injury-info-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Setup MSA Report
            </Title>
          </Col>
          <Col className="back" onClick={() => this.props.history.goBack()}>
            <BackIcon className="icon" />
            <Text>Cancel</Text>
          </Col>
          <Col className="step">
            <Text>Step 9 of 17</Text>
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <Text className="title">Injury Information</Text>
                </Col>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text>Description of Injury</Text>
                </Col>
                <Text className="text">{reportInfo.description_of_injury}</Text>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">Date of Injury</Text>
                </Col>
                <Text className="text">{reportInfo.date_of_injury}</Text>
              </Row>
              <Row type="flex" className="row">
                <Col md="24">
                  <Text className="label">
                    Diagnostic codes and description
                  </Text>
                  <div style={{ height: 5 }} />
                  {reportInfo.related_diagnosis &&
                    reportInfo.related_diagnosis.split(",").map((item) => {
                      if (item)
                        if (item) {
                          let item2;
                          try {
                            item2 = JSON.parse(item.replace(/</g, ","));
                          } catch (error) {
                            return "";
                          }
                          return (
                            <>
                              <Text className="text">
                                {`${item2.med_descr.replace("$", "")} (${
                                  item2.code
                                })`}
                              </Text>
                              <br />
                            </>
                          );
                        }
                      return "";
                    })}
                </Col>
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">Releases attached</Text>
                </Col>
                <Row>
                  <Select
                    showSearch
                    placeholder="Please select"
                    optionFilterProp="children"
                    className="dropdown"
                    value={releases_attached ? "yes" : "no"}
                    onChange={(value) =>
                      this.setState({
                        releases_attached: value === "yes" ? true : false,
                      })
                    }
                    suffixIcon={
                      <Icon
                        type="caret-down"
                        style={{ color: "black" }}
                        theme="filled"
                      />
                    }
                  >
                    <Option value={"yes"} key={"1"}>
                      Yes
                    </Option>
                    <Option value={"no"} key={"2"}>
                      No
                    </Option>
                  </Select>
                </Row>
              </Row>
              <Row type="flex" className="row" style={{ marginBottom: 10 }}>
                <Row></Row>
                <Col className="label">
                  <Text className="label">MSA administrator</Text>
                </Col>
                <Col className="input-col">
                  <Row className="radio-item">
                    <Radio.Group
                      onChange={(e) =>
                        this.setState({
                          msa_administrator:
                            e.target.value === "Claimant"
                              ? null
                              : e.target.value,
                        })
                      }
                      value={msa_administrator ? "Other" : "Claimant"}
                    >
                      <Radio value={"Claimant"}>Claimant</Radio>
                      <Radio value={"Other"}>Other</Radio>
                    </Radio.Group>
                  </Row>
                </Col>
              </Row>
              {msa_administrator && (
                <Row align="middle" type="flex">
                  <Col className="label"></Col>
                  <Col md={8}>
                    <Select
                      showSearch
                      placeholder="Please select"
                      optionFilterProp="children"
                      className="dropdown"
                      value={msa_administrator}
                      onChange={(value) =>
                        this.setState({ msa_administrator: value })
                      }
                      suffixIcon={
                        <Icon
                          type="caret-down"
                          style={{ color: "black" }}
                          theme="filled"
                        />
                      }
                    >
                      {contactsList.map((item) => (
                        <Option value={item.id} key={item.id}>
                          {item.contact_name}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      className="green-btn add-administrator_button"
                      onClick={this.handleOpenModal}
                    >
                      Add Administrator
                    </Button>
                  </Col>
                </Row>
              )}
              <Text className="label">Claimant Attorney</Text>

              <Row>
                <br />
                <ContactsOnReport contactList={plaintifList} />
              </Row>
              <Text className="label">Defense Attorney</Text>

              <Row>
                <br />
                <ContactsOnReport contactList={defenseList} />
              </Row>
              <Text className="label">Insurance Carrier</Text>

              <Row>
                <br />
                <ContactsOnReport contactList={insuranceList} />
              </Row>
              <Row type="flex" className="row">
                <Col className="label">
                  <Text className="label">State of Jurisdiction</Text>
                </Col>
                <Text className="text">
                  {reportInfo.juridiction_state &&
                    reportInfo.juridiction_state.name}
                </Text>
              </Row>
              <Divider className="divider" />
              <Row
                type="flex"
                justify="center"
                align="middle"
                className="confirm-button"
              >
                <Button
                  type="link"
                  className="prev-button button"
                  onClick={this.goToPreviousStep}
                >
                  &lt; PREVIOUS STEP
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="green-btn button"
                  onClick={() => this.handleUpdateReport(true)}
                >
                  Next Step
                </Button>
              </Row>
            </Row>
          </SpinBox>
        </Row>
        <ContactModal
          title={"Add Administrator"}
          isOpen={isModalOpen}
          contactType={1}
          onConfirm={this.handleConfirmModal}
          onCancel={this.handleCloseModal}
        />
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  const props = {
    autoSavingTime: state.report.autoSavingTime,
    reportInfo: state.report.reportInfo,
  };
  return props;
};

export default connect(mapStateToProps, { updateReportInfo })(InjuryInfo);
