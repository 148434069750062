import React, { Component } from "react";
import moment from "moment";
import {
  Row,
  Col,
  Typography,
  Button,
  Modal,
  Select,
  DatePicker,
  Icon,
} from "antd";

import InputBox from "components/InputBox";

import "./NoteModal.scss";

const { Text } = Typography;
const { Option } = Select;

export default class NoteModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      note: "",
      start_date: moment(),
      end_date: moment(),
      assign_to: undefined,
      report_id: undefined,
      status_id: undefined,
    };
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      const { data } = this.props;
      this.setState({
        id: data.id || "",
        note: data.note || "",
        start_date: moment(data.start_date) || moment(),
        end_date: moment(data.end_date) || moment(),
        assigned_id: data.assigned_id || undefined,
        report_id: data.report_id || undefined,
        status_id: data.status_id || undefined,
      });
    }
  }

  handleConfirm = () => {
    const { onConfirm } = this.props;
    const {
      id,
      note,
      start_date,
      end_date,
      assigned_id,
      report_id,
      status_id,
    } = this.state;

    const param = {
      id,
      note,
      start_date,
      end_date,
      assigned_id,
      report_id,
      status_id,
    };

    onConfirm(param);
  };

  render() {
    const { type, usersData, reportsData, isOpen, onCancel } = this.props;
    const {
      note,
      start_date,
      end_date,
      assigned_id,
      report_id,
      status_id,
    } = this.state;

    return (
      <Modal
        visible={isOpen}
        onCancel={onCancel}
        footer={null}
        centered={true}
        closable={true}
        width={550}
        className="note-modal"
        title={type === "ADD" ? "Add Note" : "Edit Note"}
      >
        <Row type="flex" align="middle" className="report-body">
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            className="main-info"
          >
            <Col md={24} className="row">
              <InputBox
                label="Note"
                placeholder="Please enter"
                value={note}
                onChange={(value) => this.setState({ note: value })}
              />
            </Col>
          </Row>
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            className="main-info"
          >
            <Col md={11} className="row">
              <Row>
                <Text>Start date</Text>
              </Row>
              <Row>
                <DatePicker
                  className="date"
                  format="MM/DD/YYYY"
                  value={start_date}
                  onChange={(date, dateString) =>
                    this.setState({ start_date: date })
                  }
                />
              </Row>
            </Col>
            <Col md={11} className="row">
              <Row>
                <Text>End date</Text>
              </Row>
              <Row>
                <DatePicker
                  className="date"
                  format="MM/DD/YYYY"
                  value={end_date}
                  onChange={(date, dateString) =>
                    this.setState({ end_date: date })
                  }
                />
              </Row>
            </Col>
          </Row>
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            className="main-info"
          >
            {/* <Col md={11} className="row">
              <Row>
                <Text>Assign to</Text>
              </Row>
              <Row>
                <Select
                  showSearch
                  placeholder="Please select"
                  optionFilterProp="children"
                  className="dropdown"
                  value={assigned_id}
                  onChange={(value)=>this.setState({assigned_id: value})}
                  suffixIcon={
                    <Icon type="caret-down" style={{color: "black"}} theme="filled" />
                  }
                >
                  {
                    usersData.map(item => (
                    <Option value={item.Username} key={item.Username}>{item.given_name && item.given_name + ' ' + item.family_name && item.family_name}</Option>
                    ))
                  }
                </Select>
              </Row>
            </Col> */}
            <Col md={11} className="row">
              <Row>
                <Text>Report</Text>
              </Row>
              <Row>
                <Select
                  showSearch
                  placeholder="Please select"
                  optionFilterProp="children"
                  className="dropdown"
                  value={report_id}
                  onChange={(value) => this.setState({ report_id: value })}
                  suffixIcon={
                    <Icon
                      type="caret-down"
                      style={{ color: "black" }}
                      theme="filled"
                    />
                  }
                >
                  {reportsData.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.report_name}
                    </Option>
                  ))}
                </Select>
              </Row>
            </Col>
          </Row>
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            className="main-info"
          >
            <Col md={11} className="row">
              <Row>
                <Text>Status</Text>
              </Row>
              <Row>
                <Select
                  showSearch
                  placeholder="Please select"
                  optionFilterProp="children"
                  className="dropdown"
                  value={status_id}
                  onChange={(value) => this.setState({ status_id: value })}
                  suffixIcon={
                    <Icon
                      type="caret-down"
                      style={{ color: "black" }}
                      theme="filled"
                    />
                  }
                >
                  <Option value={1}>Open</Option>
                  <Option value={2}>Closed</Option>
                </Select>
              </Row>
            </Col>
          </Row>
          {type === "ADD" ? (
            <Button
              type="primary"
              size="large"
              className="green-btn button"
              onClick={this.handleConfirm}
            >
              Create
            </Button>
          ) : (
            <Button
              type="primary"
              size="large"
              className="green-btn button"
              onClick={this.handleConfirm}
            >
              Save Changes
            </Button>
          )}
        </Row>
      </Modal>
    );
  }
}
