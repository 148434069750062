import React, { Component } from "react";
import { Row, Col, Typography, Button, Modal, Select, Radio, Icon } from "antd";
import "./index.scss";
import * as API from "utils/api";
const { Option } = Select;
export default class DropdownStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      report_status: null,
    };
  }
  componentDidMount = () => {
    this.setState({
      report_status: this.props.data.report_status.id,
    });
  };
  updateReport = (value) => {
    this.setState({ report_status: value });
    let params = { report_status_id: value, id: this.props.data.id };
    API.updateReport(params).then(() => {
      this.setState({ report_status: value });
    });
  };
  render() {
    const { report_status } = this.state;
    const { reportStatuses } = this.props;
    return (
      <Row>
        <Select
          bordered={false}
          showSearch
          placeholder="Please select"
          optionFilterProp="children"
          className="dropdown_status"
          value={report_status}
          onChange={(value) => this.updateReport(value)}
          suffixIcon={
            <Icon type="caret-down" style={{ color: "black" }} theme="filled" />
          }
        >
          {reportStatuses.map((item) => (
            <Option value={item.id} key={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Row>
    );
  }
}
