import React, { Component } from "react";
import { Row, Col, Typography, Button, Select, Modal, Icon } from "antd";
import InputMask from "react-input-mask";

import InputBox from "components/InputBox";
import { BackIcon } from "components/Icons";
import SpinBox from "components/SpinBox";
import * as API from "utils/api";
import * as Utils from "utils/utils";
import Address from "components/Address";
import dynamicData from "dynamicData";

import "./AddContact.scss";

const { Title, Text } = Typography;
const { Option } = Select;

export default class AddContact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contact_name: "",
      organisation_name: "",
      address: "",
      phone_number: "",
      fax_number: "",
      email: "",
      contact_type: null,
      loading: false,
      loadingTitle: "Loading...",
      specialities: [],
      speciality_id: "",
      contact_list: [],
    };
  }

  handleSetValue = (type, value) => {
    this.setState({
      [type]: value,
    });
  };

  handleCreateUser = () => {
    const {
      contact_name,
      organisation_name,
      address,
      phone_number,
      fax_number,
      email,
      contact_type,
      specialities,
      speciality_id,
    } = this.state;
    const param =
      this.props.removeHeader && this.props.another
        ? {
            contact_name,
            organisation_name,
            address,
            phone_number,
            fax_number,
            email,
            contact_type_id: this.props.contact_type_id || contact_type,
            speciality_id,
          }
        : {
            contact_name,
            organisation_name,
            address,
            phone_number,
            fax_number,
            email,
            contact_type_id: this.props.contact_type_id || contact_type,
          };

    if (!contact_name) {
      Modal.error({ content: "Please input the Contact Name" });
      return;
    }

    if (!organisation_name) {
      Modal.error({ content: "Please input the Organization Name" });
      return;
    }

    if (!phone_number || phone_number.includes("_")) {
      Modal.error({ content: "Please input the Phone number" });
      return;
    }

    if (fax_number != "" && fax_number.includes("_")) {
      Modal.error({ content: "Please input the Fax Number" });
      return;
    }
    if (!contact_type && !this.props.removeHeader) {
      Modal.error({ content: "Please select a contact type" });
      return;
    }
    if (
      contact_type == dynamicData().plaintiff_attorney_id ||
      contact_type == dynamicData().defense_attorney_id
    ) {
      if (!email || !Utils.validateEmail(email)) {
        Modal.error({ content: "Please input the Valid Email" });
        return;
      }
    }
    if (!param.contact_type_id) {
      delete param.contact_type_id;
    }
    this.setState({ loadingTitle: "Saving...", loading: true });
    API.createContact(param)
      .then((res) => {
        this.setState({ loading: false });
        Modal.success({
          content: "Success",
          onOk: () => {
            this.setState({
              contact_name: "",
              organisation_name: "",
              address: "",
              phone_number: "",
              fax_number: "",
              email: "",
              contact_type: null,
              loading: false,
              loadingTitle: "Loading...",
              speciality_id: "",
            });
            if (this.props.func) {
              return this.props.func(res.contact);
            }
            return this.props.history.goBack();
          },
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        const errorMessage = error.response.data.reason
          ? error.response.data.reason[0].message
          : "Error";
        Modal.error({
          content: errorMessage,
        });
      });
  };
  getAddress = (address) => {
    this.setState({ address });
  };
  getSpecialities = () => {
    API.getSpeciality().then((res) => {
      this.setState({ specialities: res.specialities });
    });
  };
  getContactTypes = () => {
    API.getContactTypes().then((res) => {
      this.setState({ contact_list: res.contact_type });
    });
  };
  componentDidMount = () => {
    this.getSpecialities();
    this.getContactTypes();
  };
  render() {
    const {
      contact_name,
      organisation_name,
      address,
      phone_number,
      fax_number,
      email,
      contact_type,
      loading,
      loadingTitle,
      specialities,
      speciality_id,
      contact_list,
    } = this.state;
    console.log(address);
    return (
      <Row className="add-user-container">
        {!this.props.removeHeader && (
          <Row type="flex" align="middle" className="header">
            <Col className="title">
              <Title level={4} className="title-text">
                Add New Contact
              </Title>
            </Col>
            <Col className="back" onClick={() => this.props.history.goBack()}>
              <BackIcon className="icon" />
              <Text>Cancel</Text>
            </Col>
          </Row>
        )}
        <Row className="main">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="main-info"
              >
                <Col md={11} className="row">
                  <InputBox
                    label="Contact Name"
                    value={contact_name}
                    onChange={(value) => this.setState({ contact_name: value })}
                  />
                </Col>
                <Col md={11} className="row">
                  <InputBox
                    label="Organization Name"
                    value={organisation_name}
                    onChange={(value) =>
                      this.setState({ organisation_name: value })
                    }
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="main-info"
              >
                <Col md={24} className="row">
                  <Address
                    getAddress={this.getAddress}
                    address={this.state.address}
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="main-info"
              >
                <Col md={11} className="row">
                  <Row className="label">
                    <Text>Phone</Text>
                  </Row>
                  <InputMask
                    mask="999-999-9999"
                    placeholder="Required"
                    className="input-ssn"
                    value={phone_number}
                    onChange={(e) =>
                      this.setState({ phone_number: e.target.value })
                    }
                  />
                </Col>
                <Col md={11} className="row">
                  <Row className="label">
                    <Text>Fax Number</Text>
                  </Row>
                  <InputMask
                    mask="999-999-9999"
                    placeholder=""
                    className="input-ssn"
                    value={fax_number}
                    onChange={(e) =>
                      this.setState({ fax_number: e.target.value })
                    }
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="main-info"
              >
                <Col md={11} className="row">
                  <InputBox
                    placeholder={
                      contact_type == dynamicData().plaintiff_attorney_id ||
                      contact_type == dynamicData().defense_attorney_id
                        ? "Required"
                        : " "
                    }
                    label="Email"
                    value={email}
                    onChange={(value) => this.setState({ email: value })}
                  />
                </Col>
                <Col md={11} className="row">
                  {this.props.removeHeader && this.props.another ? (
                    <>
                      <Row>
                        <Text>Provider Speciality</Text>
                      </Row>
                      <Row>
                        <Select
                          showSearch
                          placeholder="Required"
                          optionFilterProp="children"
                          className="dropdown"
                          value={speciality_id}
                          onChange={(value) =>
                            this.handleSetValue("speciality_id", value)
                          }
                          suffixIcon={
                            <Icon
                              type="caret-down"
                              style={{ color: "black" }}
                              theme="filled"
                            />
                          }
                        >
                          {specialities.map((el, i) => {
                            return (
                              <Option key={i} value={el.id}>
                                {el.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Row>
                    </>
                  ) : null}
                  {!this.props.removeHeader && (
                    <>
                      <Row>
                        <Text>Contact Type</Text>
                      </Row>
                      <Row>
                        <Select
                          showSearch
                          placeholder="Required"
                          optionFilterProp="children"
                          className="dropdown"
                          value={contact_type}
                          onChange={(value) =>
                            this.handleSetValue("contact_type", value)
                          }
                          suffixIcon={
                            <Icon
                              type="caret-down"
                              style={{ color: "black" }}
                              theme="filled"
                            />
                          }
                        >
                          {contact_list.map((el, i) => {
                            return (
                              <Option key={i} value={el.id}>
                                {el.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
              <Row type="flex" justify="center" className="confirm-button">
                <Button
                  type="primary"
                  size="large"
                  className="green-btn button"
                  onClick={this.handleCreateUser}
                >
                  Create
                </Button>
              </Row>
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}
