import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Typography, Button, Divider } from "antd";

import SpinBox from "components/SpinBox";
import InputCurrencyBox from "components/InputCurrencyBox";
import { BackIcon } from "components/Icons";
import { updateReportInfo } from "redux/report";
import * as API from "utils/api";
import * as Logic from "./logic";
import "./ProposedMedicare.scss";

const { Title, Text } = Typography;

class ProposedMedicare extends Component {
  constructor(props) {
    super(props);

    let report_id = "";
    if (this.props.location && this.props.location.state) {
      report_id = this.props.location.state.data.report_id;
    }

    this.state = {
      medicare: [],
      report: {},
      report_id: report_id,
      contactsList: [],
      life_expectancy: 1,
      anticipated_medicare_costs: "",
      proposed_amount_for_future_from_medicare: "",
      proposed_amount_for_future_not_covered_from_medicare: "",
      cost_of_first_surgery_and_first_procedure_replacement: "",
      total_allocation_minus_first_surgery_procedure_replacement: 0,
      seed_money_or_initial_deposit: 0,
      annuity_payment_over_remaining_expectancy_at_starting_date: 0,
      loadingText: "Loading",
      loading: false,
    };
    this.timeoutId = setInterval(
      this.handleUpdateReport,
      this.props.autoSavingTime
    );
  }
  componentWillUnmount = () => {
    clearInterval(this.timeoutId);
  };
  componentDidMount() {
    const { report_id } = this.state;

    this.setState({ loading: true });
    API.getReportById(report_id).then((res) => {
      const reportInfo = res.report;

      let anticipated_medicare_costs =
        reportInfo.cost_of_first_surgery_and_first_procedure_replacement +
        reportInfo.total_allocation_minus_first_surgery_procedure_replacement;

      API.getMedicareData(report_id).then((res) => {
        let default_anticipated_medicare_costs = 0,
          default_proposed_amount_for_future_from_medicare = 0,
          default_proposed_amount_for_future_not_covered_from_medicare = 0,
          default_cost_of_first_surgery_and_first_procedure_replacement = 0;
        res.medicare_cost_projections.forEach((item) => {
          if (
            item.type === "Future Medical Need covered by Medicare" ||
            item.type === "Surgeries, Replacements & Procedures" ||
            item.type === "Prescription Medications Covered"
          ) {
            default_anticipated_medicare_costs += item.total_cost;
          }
          if (item.type === "Future Medical Need covered by Medicare") {
            default_proposed_amount_for_future_from_medicare += item.total_cost;
          }
          if (item.type === "Future Medical Needed") {
            default_proposed_amount_for_future_not_covered_from_medicare +=
              item.total_cost;
          }
          if (
            item.type === "Surgeries, Replacements & Procedures" &&
            default_cost_of_first_surgery_and_first_procedure_replacement === 0
          ) {
            default_cost_of_first_surgery_and_first_procedure_replacement =
              item.total_cost;
          }
        });
        this.setState({
          report: reportInfo,
          medicare: res.medicare_cost_projections,
          life_expectancy: reportInfo.life_expectancy || 1,
          anticipated_medicare_costs:
            anticipated_medicare_costs || default_anticipated_medicare_costs,
          proposed_amount_for_future_from_medicare:
            reportInfo.proposed_amount_for_future_from_medicare ||
            default_proposed_amount_for_future_from_medicare,
          proposed_amount_for_future_not_covered_from_medicare:
            reportInfo.proposed_amount_for_future_not_covered_from_medicare ||
            default_proposed_amount_for_future_not_covered_from_medicare,
          cost_of_first_surgery_and_first_procedure_replacement:
            reportInfo.cost_of_first_surgery_and_first_procedure_replacement ||
            default_cost_of_first_surgery_and_first_procedure_replacement,
          loading: false,
        });
        this.props.updateReportInfo(res.report);
      });
    });
  }

  // componentDidUpdate(prevProps) {
  //   const { reportInfo } = this.props;
  //   if (JSON.stringify(reportInfo) !== JSON.stringify(prevProps.reportInfo)) {
  //     let anticipated_medicare_costs = reportInfo.cost_of_first_surgery_and_first_procedure_replacement + reportInfo.total_allocation_minus_first_surgery_procedure_replacement;
  //     this.setState({
  //       life_expectancy: reportInfo.life_expectancy || 1,
  //       anticipated_medicare_costs: anticipated_medicare_costs || "",
  //       proposed_amount_for_future_from_medicare: reportInfo.proposed_amount_for_future_from_medicare || "",
  //       proposed_amount_for_future_not_covered_from_medicare: reportInfo.proposed_amount_for_future_not_covered_from_medicare || "",
  //       cost_of_first_surgery_and_first_procedure_replacement: reportInfo.cost_of_first_surgery_and_first_procedure_replacement || "",
  //       loading: false,
  //     })
  //   }
  // }

  handleUpdateReport = (isNextStep = false) => {
    const {
      report_id,
      life_expectancy,
      anticipated_medicare_costs,
      proposed_amount_for_future_from_medicare,
      proposed_amount_for_future_not_covered_from_medicare,
      cost_of_first_surgery_and_first_procedure_replacement,
    } = this.state;

    let total_allocation_minus_first_surgery_procedure_replacement = Number(
      anticipated_medicare_costs -
        cost_of_first_surgery_and_first_procedure_replacement
    ).toFixed(2);
    let seed_money_or_initial_deposit = Number(
      (total_allocation_minus_first_surgery_procedure_replacement /
        life_expectancy) *
        2 +
        cost_of_first_surgery_and_first_procedure_replacement
    ).toFixed(2);
    let annuity_payment_over_remaining_expectancy_at_starting_date = Number(
      anticipated_medicare_costs - seed_money_or_initial_deposit
    ).toFixed(2);

    const params = {
      id: report_id,
      anticipated_medicare_costs,
      proposed_amount_for_future_from_medicare,
      proposed_amount_for_future_not_covered_from_medicare,
      cost_of_first_surgery_and_first_procedure_replacement,
      total_allocation_minus_first_surgery_procedure_replacement,
      seed_money_or_initial_deposit,
      annuity_payment_over_remaining_expectancy_at_starting_date,
    };

    if (isNextStep) {
      this.setState({
        loading: true,
        loadingTitle: "Saving...",
      });

      API.updateReport(params).then((res) => {
        this.setState({ loading: false });
        clearTimeout(this.timeoutId);
        this.props.history.push("/app/reports/msa/annuityInfo", {
          data: { report_id: report_id },
        });
      });
    } else {
      API.updateReport(params);
    }
  };

  goToPreviousStep = () => {
    this.props.history.goBack();
  };

  render() {
    const {
      life_expectancy,
      anticipated_medicare_costs,
      proposed_amount_for_future_from_medicare,
      proposed_amount_for_future_not_covered_from_medicare,
      cost_of_first_surgery_and_first_procedure_replacement,
      loadingTitle,
      loading,
    } = this.state;

    let total_allocation_minus_first_surgery_procedure_replacement = Number(
      anticipated_medicare_costs -
        cost_of_first_surgery_and_first_procedure_replacement
    ).toFixed(2);
    let seed_money_or_initial_deposit = Number(
      (total_allocation_minus_first_surgery_procedure_replacement /
        life_expectancy) *
        2 +
        cost_of_first_surgery_and_first_procedure_replacement
    ).toFixed(2);
    let annuity_payment_over_remaining_expectancy_at_starting_date = Number(
      anticipated_medicare_costs - seed_money_or_initial_deposit
    ).toFixed(2);

    return (
      <Row className="msa-proposed-medicare-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Setup MSA Report
            </Title>
          </Col>
          <Col className="back" onClick={() => this.props.history.goBack()}>
            <BackIcon className="icon" />
            <Text>Cancel</Text>
          </Col>
          <Col className="step">
            <Text>Step 11 of 17</Text>
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <Text className="title">
                    Proposed Medicare Set-aside Amount
                  </Text>
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <InputCurrencyBox
                    disabled={true}
                    label="Anticipated Medicare Costs"
                    placeholder="Please enter"
                    value={Logic.total_anticipated_medicare_cost(
                      this.state.medicare
                    ).toFixed(2)}
                    onChange={(value) =>
                      this.setState({ anticipated_medicare_costs: value })
                    }
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <InputCurrencyBox
                    disabled={true}
                    label="Proposed amount for future medical treatment from Medicare"
                    placeholder="Please enter"
                    value={Logic.anticipated_medicare_cost(
                      this.state.medicare
                    ).toFixed(2)}
                    onChange={(value) =>
                      this.setState({
                        proposed_amount_for_future_from_medicare: value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <InputCurrencyBox
                    disabled={true}
                    label="Proposed amount for future medical treatment not covered by medicare"
                    placeholder="Please enter"
                    value={Logic.anticipated_non_medicare_cost(
                      this.state.medicare
                    ).toFixed(2)}
                    onChange={(value) =>
                      this.setState({
                        proposed_amount_for_future_not_covered_from_medicare: value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <InputCurrencyBox
                    disabled={true}
                    label="Cost of first surgery and first procedure/replacement"
                    placeholder="Please enter"
                    value={Logic.first_surgery_cost(
                      this.state.medicare
                    ).toFixed(2)}
                    onChange={(value) =>
                      this.setState({
                        cost_of_first_surgery_and_first_procedure_replacement: value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <InputCurrencyBox
                    disabled={true}
                    label="Total allocation minus cost of first surgery and first procedure/replacement"
                    placeholder="$0.00"
                    disabled={true}
                    value={
                      Logic.total_anticipated_medicare_cost(
                        this.state.medicare
                      ).toFixed(2) -
                      Logic.first_surgery_cost(this.state.medicare).toFixed(2)
                    }
                    onChange={(value) =>
                      this.setState({
                        total_allocation_minus_first_surgery_procedure_replacement: value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <InputCurrencyBox
                    disabled={true}
                    label="Seed money or initial deposit"
                    placeholder="$0.00"
                    disabled={true}
                    value={Logic.seedMoney(
                      this.state.medicare,
                      this.state.report
                    ).seed_money.toFixed(2)}
                    onChange={(value) =>
                      this.setState({ seed_money_or_initial_deposit: value })
                    }
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <InputCurrencyBox
                    disabled={true}
                    value={Logic.seedMoney(
                      this.state.medicare,
                      this.state.report
                    ).annuity_amount_over_life.toFixed(2)}
                    label="Annuity Payment over life expectancy remaining at annuity starting date"
                    placeholder="$0.00"
                    disabled={true}
                    onChange={(value) =>
                      this.setState({
                        annuity_payment_over_remaining_expectancy_at_starting_date: value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Divider className="divider" />
              <Row
                type="flex"
                justify="center"
                align="middle"
                className="confirm-button"
              >
                <Button
                  type="link"
                  className="prev-button button"
                  onClick={this.goToPreviousStep}
                >
                  &lt; PREVIOUS STEP
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="green-btn button"
                  onClick={() => this.handleUpdateReport(true)}
                >
                  Next Step
                </Button>
              </Row>
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  const props = {
    autoSavingTime: state.report.autoSavingTime,
    reportInfo: state.report.reportInfo,
  };
  return props;
};

export default connect(mapStateToProps, { updateReportInfo })(ProposedMedicare);
