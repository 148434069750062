import React, { useState } from "react";
import {
  Row,
  Col,
  Typography,
  Button,
  Checkbox,
  Modal,
  Divider,
  Dropdown,
  Menu,
  Tooltip,
  Input,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import "./EditTemplateContent.scss";
import RLDD from "react-list-drag-and-drop/lib/RLDD";
import ModalTemplateConent from "./ModalTemplateConent";
import {
  BackIcon,
  TemplateEditIcon,
  MoneyTemplateIcon,
  EditIcon,
  RemoveIcon,
} from "components/Icons";
export default function EditTemplateContent() {
  const [templateOrders, setTemplateOrders] = useState([
    {
      id: 3,
    },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
  ]);
  const [openModal, setOpenModal] = useState(false);

  const displayModal = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };
  const { Title, Text } = Typography;

  const text = <span>Pre Filled</span>;

  const handleRLDDChange = (items) => {
    setTemplateOrders(items);
    // this.setState({ templateOrders: items });
  };
  const itemRenderer = (item, index) => {
    return (
      <>
        <div className="report_item">
          <img
            src={require("../../../assets/icons/ico-menu.png")}
            className="menu-icon"
            alt="menu"
          />
          <div className="name" style={{ flex: "1", marginLeft: "8%" }}>
            Name
          </div>

          <div className="action_template_wrapper">
            <EditIcon style={{ marginRight: "7px" }} onClick={displayModal} />
            <RemoveIcon style={{ marginLeft: "7px" }} />
            <Divider type="vertical" style={{ height: "1.9em" }} />
            <Tooltip placement="topLeft" title={text}>
              <TemplateEditIcon
                className="template_icon"
                style={{ marginRight: "8px" }}
              />
            </Tooltip>
            <MoneyTemplateIcon />
          </div>
          {/* <div className="payment_actions_wrapper">
            <Divider type="vertical" />
            <Tooltip placement="topLeft" title={text}>
              <TemplateEditIcon className="template_icon" />
            </Tooltip>
            <MoneyTemplateIcon />
          </div> */}
        </div>
      </>
    );
  };

  return (
    <Row className="edit-template-container">
      <Row type="flex" align="middle" className="header">
        <Col className="title">
          <Title level={4} className="title-text">
            Edit Section
          </Title>
        </Col>
        <Col className="back">
          <BackIcon className="icon" />
          <Text>Cancel</Text>
        </Col>
      </Row>
      <Row className="main">
        <Row className="content">
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            className="main-info"
          >
            <Col md={24} style={{ marginLeft: "-2%" }} className="row">
              <h2>Section Name</h2>
              <Input
                size="large"
                placeholder="Name"
                className="template_edit_input"
              />
            </Col>
          </Row>

          <Row className="list template_row">
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              className="main-info"
            >
              <Col md={24} style={{ marginLeft: "-2%" }} className="row ">
                <h2>Form Builder</h2>
              </Col>
            </Row>
            <RLDD
              cssClasses="list-content"
              items={templateOrders}
              itemRenderer={itemRenderer}
              onChange={handleRLDDChange}
            />
          </Row>
          <Button type="primary" className="field_btn">
            Add Field
          </Button>
          <Modal
            title="Add Field"
            visible={openModal}
            // onOk={this.handleOk}
            closeModal={closeModal}
            onCancel={closeModal}
          >
            <ModalTemplateConent />
          </Modal>
          <Row type="flex" justify="center" className="confirm-button">
            <Button type="primary" size="large" className="green-btn button">
              Save Changes
            </Button>
          </Row>
          <div className="cancel_action_btn">
            <a style={{ color: "#343434", textDecoration: "underline" }}>
              Save
            </a>
          </div>
        </Row>
      </Row>
    </Row>
  );
}
