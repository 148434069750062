import React, { Component } from "react";
import moment from "moment";
import {
  Row,
  Col,
  Typography,
  Button,
  Divider,
  Tabs,
  Badge,
  Empty,
  Select,
  Menu,
  Dropdown,
  message,
  Upload,
  Modal,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { UploadFile } from "utils/upload";
import SpinBox from "components/SpinBox";
import DeleteModal from "components/DeleteModal";
import Pagination from "components/Pagination";
import { CloneIcon, EditIcon, RemoveIcon, DetailsIcon } from "components/Icons";
import { BackIcon } from "components/Icons";
import * as API from "utils/api";
import { getBase64 } from "utils/utils";

import ReportModal from "./ReportModal";
import NoteModal from "./NoteModal";
import RequestAgeModal from "./RequestAgeModal/Index";
import "./DetailedClaimant.scss";
import DropdownStatus from "components/DropdownStatus";
import UploadLogo from "components/UploadLogo/UploadLogo";
import UpdateFile from "./FilesModal/UpdateFile";
import FilePhotoModal from "./FilesModal/FilePhotoModal";
import RatedAgesData from "containers/RatedAges/RatedAgesData";
const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;
export default class DetailedClaimant extends Component {
  constructor(props) {
    super(props);

    let claimantInfo = {};
    if (this.props.location && this.props.location.state) {
      claimantInfo = this.props.location.state.data;
    }
    this.state = {
      claimantInfo: claimantInfo,
      isShowDetail: false,
      selectedTab: "1",
      reportsData: [],
      reportsTotalCount: 0,
      reportsPageIndex: 0,
      reportStatuses: [],
      notesData: [],
      notesTotalCount: 0,
      notesPageIndex: 0,
      usersData: [],
      selectedReport: {},
      showReportDeleteModal: false,
      showReportModal: false,
      selectedNote: {},
      showNoteDeleteModal: false,
      showNoteModal: false,
      showAgeModal: false,
      modalType: "Add",
      loading: false,
      ReportName: "Sample",
      RequestName: "John",
      SentToName: "Kyle",
      Status: "Pending",
      reportFiles: "",
      name: "",
      type: "",
      url: "",
      uploadedFiles: [],
      showFileModal: false,
      showFilePhotoModal: false,
      EditFile: "",
      renderUploadedImage: {},
      GetAllAssignemetData: [],
      selectUser: "",
      notes: "",
      fileValue: [],
    };
  }
  FileUpload = React.createRef();

  componentDidMount() {
    this.getClaimantData();
    this.getReportsData();
    this.getReportStatuses();
    this.getNotesData();
    this.getUsersData();
    this.GetUploadedFiles();
    this.getAllData();
  }

  getFileToAssign = (value) => {
    this.setState({ fileValue: value });
  };
  handleSetValue = (type, value) => {
    this.setState({
      [type]: value,
    });
  };

  getAllData = () => {
    API.GetCreatedAssignement(this.state.claimantInfo.id).then((res) => {
      // const id = claimantInfo.id;

      console.log(res.assignments, "resut");
      this.setState({
        GetAllAssignemetData: res.assignments,
        loading: false,
      });
      // setGetAllData(res.assignments);
      // setLoading(false);
      console.log(res, "result");
    });
  };

  deleteAssignementData = (id) => {
    const filteredItems = this.state.GetAllAssignemetData.filter(
      (el) => el.id !== id
    );
    this.setState({ loading: true });
    API.deleteAssignement(id).then((res) => {
      console.log(res, "ok");
      this.setState({
        GetAllAssignemetData: filteredItems,
        loading: false,
      });
      // setGetAllData(filteredItems);
      // setLoading(false);
    });
  };

  goToEditClaimant = () => {
    const { claimantInfo } = this.state;
    this.props.history.push("/app/claimants/edit", { data: claimantInfo });
  };

  getClaimantData = () => {
    const { claimantInfo } = this.state;

    this.setState({ loading: true });
    API.getClaimantsDataByClaimantId(claimantInfo.id).then((res) => {
      this.setState({
        claimantInfo: res.claimant,
        loading: false,
      });
    });
  };
  getReportsData = (pageIndex) => {
    const { claimantInfo, reportsPageIndex } = this.state;

    this.setState({ loading: true });
    API.getReportsDataByClaimantId(claimantInfo.id, reportsPageIndex).then(
      (res) => {
        this.setState({
          reportsData: res.reports,
          reportsTotalCount: res.total_count,
          loading: false,
        });
      }
    );
  };

  getReportStatuses = () => {
    API.getReportStatuses().then((res) => {
      this.setState({
        reportStatuses: res.reportStatuses,
      });
    });
  };

  getNotesData = () => {
    const { claimantInfo, notesPageIndex } = this.state;

    this.setState({ loading: true });
    API.getNotesDataByClaimantId(claimantInfo.id, notesPageIndex).then(
      (res) => {
        this.setState({
          notesData: res.notes,
          notesTotalCount: res.total_count,
          loading: false,
        });
      }
    );
  };

  getUsersData = () => {
    API.getUsersDataSynchronised("").then((res) => {
      this.setState({
        usersData: res.users,
      });
    });
  };

  handleReportDelete = () => {
    const { selectedReport } = this.state;
    const param = {
      id: selectedReport.id,
    };

    this.closeReportDeleteModal();
    API.deleteReport(param).then((res) => {
      this.getReportsData();
    });
  };

  openReportDeleteModal = (item) => {
    this.setState({
      selectedReport: item,
      showReportDeleteModal: true,
    });
  };

  closeReportDeleteModal = () => {
    this.setState({ showReportDeleteModal: false });
  };

  handleReport = (param) => {
    const { modalType, selectedReport, claimantInfo } = this.state;

    this.closeReportModal();
    if (modalType === "ADD") {
      const params = {
        claimant_id: claimantInfo.id,
        ...param,
      };
      API.createReport(params).then((res) => {
        this.getReportsData();
        if (param.report_type_id === 1)
          this.props.history.push("/app/reports/msa/demographic", {
            data: { report_id: res.report.id },
          });
        else
          this.props.history.push("/app/reports/mcp/demographic", {
            data: { report_id: res.report.id },
          });
      });
    } else if (modalType === "EDIT") {
      const params = {
        ...param,
        id: selectedReport.id,
        claimant_id: claimantInfo.id,
      };
      API.updateReport(params).then((res) => {
        this.getReportsData();
        // if (param.report_type_id === 1)
        //   this.props.history.push("/app/reports/msa/demographic", {
        //     data: { report_id: selectedReport.id },
        //   });
        // else
        //   this.props.history.push("/app/reports/mcp/demographic", {
        //     data: { report_id: selectedReport.id },
        //   });
      });
    } else {
      let report_type_id =
        param.report_type_id === selectedReport.report_type_id
          ? null
          : param.report_type_id;
      const params = {
        id: selectedReport.id,
        report_type_id,
      };
      API.copyReport(params).then((res) => {
        this.getReportsData();
        API.updateReport({
          ...param,
          id: res.id,
          claimant_id: claimantInfo.id,
        });
      });
    }
  };

  handleGotoReportEdit = (selectedReport) => {
    if (selectedReport.report_type_id === 1)
      this.props.history.push("/app/reports/msa/demographic", {
        data: { report_id: selectedReport.id },
      });
    else
      this.props.history.push("/app/reports/mcp/demographic", {
        data: { report_id: selectedReport.id },
      });
  };

  openReportModal = (type, item) => {
    this.setState({
      modalType: type,
      selectedReport: item,
      showReportModal: true,
    });
  };

  closeReportModal = () => {
    this.setState({ showReportModal: false });
  };

  handleNoteDelete = () => {
    const { selectedNote } = this.state;
    const param = {
      id: selectedNote.id,
    };

    this.closeNoteDeleteModal();
    API.deleteNote(param).then((res) => {
      this.getNotesData();
    });
  };

  openNoteDeleteModal = (item) => {
    this.setState({
      selectedNote: item,
      showNoteDeleteModal: true,
    });
  };

  closeNoteDeleteModal = () => {
    this.setState({ showNoteDeleteModal: false });
  };

  handleNote = (param) => {
    const { modalType, claimantInfo } = this.state;

    this.closeNoteModal();
    if (modalType === "ADD") {
      const params = {
        claimant_id: claimantInfo.id,
        ...param,
      };
      API.createNote(params).then((res) => {
        this.getNotesData();
      });
    } else {
      const params = {
        ...param,
      };
      API.updateNote(params).then((res) => {
        this.getNotesData();
      });
    }
  };

  handleFileModal = (EditFile) => {
    const { claimantInfo } = this.state;

    this.setState({
      showFileModal: true,
      EditFile: EditFile,
    });
  };

  openNoteModal = (type, item) => {
    this.setState({
      modalType: type,
      selectedNote: item,
      showNoteModal: true,
    });
  };

  closeNoteModal = () => {
    this.setState({ showNoteModal: false });
  };

  openAgeModal = () => {
    this.setState({
      showAgeModal: true,
    });
  };
  closeAgeModal = () => {
    this.setState({
      showAgeModal: false,
    });
  };
  closeFileModal = () => {
    this.setState({
      showFileModal: false,
    });
  };

  openPhotoModal = (id) => {
    this.setState({
      showFilePhotoModal: true,
    });
    console.log(id, "id");
    API.getPresignLogoUrl(id).then((res) => {
      this.setState({
        renderUploadedImage: res,
      });
      console.log(
        `got it
        `,
        res
      );
    });
  };
  closePhotoModal = () => {
    this.setState({
      showFilePhotoModal: false,
    });
  };
  handleReportsPagination = (pageIndex) => {
    this.setState(
      {
        reportsPageIndex: pageIndex,
      },
      () => this.getReportsData()
    );
  };

  handleNotesPagination = (pageIndex) => {
    this.setState(
      {
        notesPageIndex: pageIndex,
      },
      () => this.getNotesData()
    );
  };
  handleChange(value) {
    console.log(`selected ${value}`);
  }

  onStaticDelete = () => {
    this.setState({
      ReportName: "",
      RequestName: "",
      SentToName: "",
      Status: "",
    });
    console.log("deleted");
  };

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    this.upload(file);
    console.log(this.upload(file), "file");
    return isJpgOrPng && isLt2M;
  };
  onDropdownTrigger = () => {
    this.FileUpload.current.click();
  };
  handleDocUpload = (info, type) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (value) =>
        this.setState({
          [type]: value,
          loading: false,
        })
      );
    }
  };

  onNoteChange = (e) => {
    this.setState({
      notes: e.target.value,
    });
    console.log(e.target.value, `value`);
  };
  GetUploadedFiles = () => {
    const { claimantInfo } = this.state;

    API.GetReportFiles(claimantInfo.id).then((res) => {
      this.setState({
        uploadedFiles: res,
      });
    });
  };

  upload = async (file) => {
    const { claimantInfo } = this.state;
    this.setState({ loading: true });

    const data = await API.getPresignUrlFile({
      name: file.name,
      type: file.type,
    });

    console.log(data);

    const formData = new FormData();
    // formData.append("Content-Type", file.type);

    Object.entries(data.url.data.fields).forEach(([k, v]) => {
      formData.append(k, v);
    });
    formData.append("file", file);
    await fetch(data.url.data.url, {
      method: "POST",
      body: formData,
    });
    let url = data.url.data.url + "/" + data.url.data.fields["key"];
    // this.props.changeImage(url);
    console.log(url, "urll");
    this.setState({ loading: false });
    let UploadData = {
      name: file.name,
      type: file.type,
      url: url,
    };

    API.ReportFileUpload(claimantInfo.id, UploadData).then((response) => {
      this.setState({
        uploadedFiles: {
          files: [...this.state.uploadedFiles.files, response.file],
        },
      });
    });
  };
  render() {
    const {
      isShowDetail,
      selectedTab,
      claimantInfo,
      reportsData,
      reportsTotalCount,
      reportsPageIndex,
      reportStatuses,
      notesData,
      notesTotalCount,
      notesPageIndex,
      usersData,
      selectedReport,
      showReportDeleteModal,
      showReportModal,
      modalType,
      selectedNote,
      showNoteDeleteModal,
      showNoteModal,
      loading,
      showAgeModal,
      ReportName,
      RequestName,
      SentToName,
      Status,
      uploadedFiles,
      showFileModal,
      EditFile,
      renderUploadedImage,
      showFilePhotoModal,
      GetAllAssignemetData,
    } = this.state;
    const { files } = uploadedFiles;
    console.log(claimantInfo, "claimantInfo");
    // const { EditFile } = this.state;
    // const {
    //   files: { id },
    // } = { EditFile };
    // console.log(id, "");
    let imageUrl = "";
    const beforeUpload = (file) => {
      // const isJpgOrPng =
      //   file.type === "image/jpeg" || file.type === "image/png";
      // if (!isJpgOrPng) {
      //   message.error("You can only upload JPG/PNG file!");
      //   return false;
      // }
      // const isLt2M = file.size / 1024 / 1024 < 2;
      // if (!isLt2M) {
      //   message.error("Image must smaller than 2MB!");
      //   return false;
      // }
      this.upload(file);
      // return false;
      // return isJpgOrPng && isLt2M;
    };
    const deleteFile = (id) => {
      console.log(this.state.uploadedFiles);
      this.setState({ loading: true });
      const filteredItems = this.state.uploadedFiles.files.filter(
        (el) => el.id !== id
      );
      console.log(filteredItems, "filter");

      API.deleteFile(id).then((res) => {
        this.setState({
          loading: false,
          uploadedFiles: { files: [...filteredItems] },
        });
      });

      console.log(id);
    };

    const RenderImage = (item) => {
      // let id = files.id;
      API.getPresignLogoUrl(item.id).then((res) => {
        window.open(res.url, "_blank");
      });
    };
    const DropDownmenu = () => (
      <Menu>
        {/* <Menu.Item key="0">
          <a href="http://www.alipay.com/">1st menu item</a>
        </Menu.Item> */}
        <Menu.Item key="1">
          {/* <a onClick={this.onDropdownTrigger}>Upload</a> */}
          <Upload
            name="logo"
            listType="text"
            className="logo-uploader"
            showUploadList={false}
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            beforeUpload={beforeUpload}
            // onChange={(info) => {
            //   this.handleDocUpload(info, "reportFiles");
            //   console.log(info, "info here");
            //   console.log(info.file.response, "url");
            //   this.setState({
            //     ...this.state,
            //     name: info.file.name,
            //     type: info.file.type,
            //     url: info.file.url,
            //   });
            //   console.log(this.state.reportFiles, "report");
            //   let data = {
            //     name: this.state.name,
            //     type: this.state.type,
            //     url: this.state.url,
            //   };
            //   console.log(this.state, "state");
            //   console.log(data, "data here");

            //   API.ReportFileUpload(claimantInfo.id, data).then((res) => {
            //     console.log(res);
            //   });
            //   // this.onFileSubmit();
            // }}
          >
            {this.state.url ? (
              <img
                src={this.state.url}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              <Text className="logo-button">Upload</Text>
            )}
          </Upload>
          <input
            type="file"
            ref={this.FileUpload}
            style={{ display: "none" }}
          />
        </Menu.Item>
        <Menu.Divider />
      </Menu>
    );

    return (
      <Row className="detailed-claimant-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Claimant Details
            </Title>
            <Text>
              {claimantInfo.claimant_name +
                " " +
                claimantInfo.claimant_middle_name +
                " " +
                claimantInfo.claimant_last_name}
            </Text>
          </Col>
          <Col className="back" onClick={() => this.props.history.goBack()}>
            <BackIcon className="icon" />
            <Text>Cancel</Text>
          </Col>
        </Row>
        <Row className="main">
          <Row className="first-content">
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              className="row title-row"
            >
              <Text className="title">Claimant Information</Text>
              <EditIcon className="icon" onClick={this.goToEditClaimant} />
            </Row>
            <Row type="flex" className="row">
              <Col className="label">
                <Text>Title</Text>
              </Col>
              <Text className="text">{claimantInfo.claimant_title}</Text>
            </Row>
            <Row type="flex" className="row">
              <Col className="label">
                <Text className="label">Full Name</Text>
              </Col>
              <Text className="text">
                {claimantInfo.claimant_name +
                  " " +
                  claimantInfo.claimant_middle_name +
                  " " +
                  claimantInfo.claimant_last_name}
              </Text>
            </Row>
            {isShowDetail && (
              <>
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text>Date of birth</Text>
                  </Col>
                  <Text className="text">
                    {moment(claimantInfo.dob).format("MM.DD.YYYY")}
                  </Text>
                </Row>
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text>Rated Age</Text>
                  </Col>
                  <Text className="text">{claimantInfo.rated_age} y.o.</Text>
                </Row>
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text>Gender</Text>
                  </Col>
                  <Text className="text">{claimantInfo.Gender}</Text>
                </Row>
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text>SSN</Text>
                  </Col>
                  <Text className="text">{claimantInfo.SSN}</Text>
                </Row>
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text>HICN</Text>
                  </Col>
                  <Text className="text">{claimantInfo.HICN}</Text>
                </Row>
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text>Address</Text>
                  </Col>
                  <Text className="text">{`${
                    claimantInfo.address.split(",")[0]
                  }, ${claimantInfo.address.split(",")[1]}, ${
                    claimantInfo.address.split(",")[2]
                  }, ${claimantInfo.address.split(",")[3]}, ${
                    claimantInfo.address.split(",")[4]
                  }, `}</Text>
                </Row>
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text>Email</Text>
                  </Col>
                  <Text className="text">{claimantInfo.claimant_email}</Text>
                </Row>
                <Row type="flex" className="row">
                  <Col className="label">
                    <Text>Phone</Text>
                  </Col>
                  <Text className="text">{claimantInfo.claimant_phone}</Text>
                </Row>
              </>
            )}
            <Divider className="divider" />
            {isShowDetail ? (
              <Row type="flex" justify="center">
                <Text
                  className="textButton"
                  onClick={() => this.setState({ isShowDetail: false })}
                >
                  Hide Details
                </Text>
              </Row>
            ) : (
              <Row type="flex" justify="center">
                <Text
                  className="textButton"
                  onClick={() => this.setState({ isShowDetail: true })}
                >
                  Show Details
                </Text>
              </Row>
            )}
          </Row>
          <Row className="second-content">
            <Row type="flex" justify="end">
              {selectedTab === "1" && (
                <>
                  <Pagination
                    onChange={this.handleReportsPagination}
                    totalCount={reportsTotalCount}
                    pageIndex={reportsPageIndex}
                    pageSize={10}
                  />
                  <Button
                    type="primary"
                    size="default"
                    className="green-btn button"
                    onClick={() => this.openReportModal("ADD", {})}
                  >
                    Add Report
                  </Button>
                </>
              )}

              {selectedTab === "2" && (
                <>
                  <Pagination
                    onChange={this.handleNotesPagination}
                    totalCount={notesTotalCount}
                    pageIndex={notesPageIndex}
                    pageSize={10}
                  />
                  <Button
                    type="primary"
                    size="default"
                    className="green-btn button"
                    onClick={() => this.openNoteModal("ADD", {})}
                  >
                    Add Note
                  </Button>
                </>
              )}
              {selectedTab === "3" && (
                <div>
                  <Button
                    type="primary"
                    size="default"
                    className="green-btn button"
                    onClick={this.openAgeModal}
                  >
                    Request Rated age{" "}
                  </Button>
                </div>
              )}

              {selectedTab === "4" && (
                <Upload
                  name="logo"
                  listType="text"
                  className="logo-uploader green-btn button"
                  showUploadList={false}
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  beforeUpload={beforeUpload}
                  style={{ padding: 25, cursor: "pointer" }}
                  // onChange={(info) => {
                  //   this.handleDocUpload(info, "reportFiles");
                  //   console.log(info, "info here");
                  //   console.log(info.file.response, "url");
                  //   this.setState({
                  //     ...this.state,
                  //     name: info.file.name,
                  //     type: info.file.type,
                  //     url: info.file.url,
                  //   });
                  //   console.log(this.state.reportFiles, "report");
                  //   let data = {
                  //     name: this.state.name,
                  //     type: this.state.type,
                  //     url: this.state.url,
                  //   };
                  //   console.log(this.state, "state");
                  //   console.log(data, "data here");

                  //   API.ReportFileUpload(claimantInfo.id, data).then((res) => {
                  //     console.log(res);
                  //   });
                  //   // this.onFileSubmit();
                  // }}
                >
                  {/* {this.state.url ? (
                        <img
                          src={this.state.url}
                          alt="avatar"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        <Text className="logo-button">Upload</Text>
                      )} */}
                  <span style={{ color: "white" }}> Add File</span>
                </Upload>
              )}

              {/* {selectedTab === "1" ? (
                <>
                  <Pagination
                    onChange={this.handleReportsPagination}
                    totalCount={reportsTotalCount}
                    pageIndex={reportsPageIndex}
                    pageSize={10}
                  />
                  <Button
                    type="primary"
                    size="default"
                    className="green-btn button"
                    onClick={() => this.openReportModal("ADD", {})}
                  >
                    Add Report
                  </Button>
                </>
              ) : (
                <>
                  <Pagination
                    onChange={this.handleNotesPagination}
                    totalCount={notesTotalCount}
                    pageIndex={notesPageIndex}
                    pageSize={10}
                  />
                  <Button
                    type="primary"
                    size="default"
                    className="green-btn button"
                    onClick={() => this.openNoteModal("ADD", {})}
                  >
                    Add Note
                  </Button>
                </>
              )} */}
            </Row>
            <Tabs
              className="tab"
              activeKey={selectedTab}
              onChange={(value) => this.setState({ selectedTab: value })}
            >
              <TabPane
                tab={
                  selectedTab === "1" ? (
                    <span>
                      Reports
                      <Badge
                        overflowCount={9999}
                        showZero
                        count={reportsTotalCount}
                        className="selected-badge-icon"
                      />
                    </span>
                  ) : (
                    <span>
                      Reports
                      <Badge
                        overflowCount={9999}
                        showZero
                        count={reportsTotalCount}
                        className="badge-icon"
                      />
                    </span>
                  )
                }
                key="1"
              >
                <Row className="reports-list-container">
                  <Row type="flex" className="table-header">
                    <Col md={6}>Name</Col>
                    <Col md={3}>Type</Col>
                    <Col md={6}>Date of Report</Col>
                    <Col md={6}>Status</Col>
                    <Col md={3} style={{ textAlign: "right" }}>
                      Actions
                    </Col>
                  </Row>
                  <Row className="table-content">
                    <SpinBox loading={loading}>
                      {reportsData.length > 0 ? (
                        <Row>
                          {reportsData.map((item, index) => (
                            <Row key={index} className="record">
                              <Col md={6}>
                                <Text>{item.report_name}</Text>
                              </Col>
                              <Col md={3}>
                                <Text>
                                  {item.report_type && item.report_type.name}
                                </Text>
                              </Col>
                              <Col md={6}>
                                <Text>
                                  {moment(item.createdAt).format(
                                    "MM/DD/YYYY, hh:mm a"
                                  )}
                                </Text>
                              </Col>
                              <Col md={6}>
                                <DropdownStatus
                                  reportStatuses={reportStatuses}
                                  data={item}
                                />
                              </Col>
                              <Col
                                md={3}
                                style={{ textAlign: "right" }}
                                className="actions"
                              >
                                <DetailsIcon
                                  className="icon"
                                  onClick={() =>
                                    this.handleGotoReportEdit(item)
                                  }
                                />
                                <EditIcon
                                  className="icon"
                                  onClick={() =>
                                    this.openReportModal("EDIT", item)
                                  }
                                />

                                <RemoveIcon
                                  className="icon"
                                  onClick={() =>
                                    this.openReportDeleteModal(item)
                                  }
                                />
                              </Col>
                            </Row>
                          ))}
                        </Row>
                      ) : (
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          className="empty-icon"
                        />
                      )}
                    </SpinBox>
                  </Row>
                </Row>
              </TabPane>
              <TabPane
                tab={
                  selectedTab === "2" ? (
                    <span>
                      Notes
                      <Badge
                        overflowCount={9999}
                        showZero
                        count={notesTotalCount}
                        className="selected-badge-icon"
                      />
                    </span>
                  ) : (
                    <span>
                      Notes
                      <Badge
                        overflowCount={9999}
                        showZero
                        count={notesTotalCount}
                        className="badge-icon"
                      />
                    </span>
                  )
                }
                key="2"
              >
                <Row className="reports-list-container">
                  <Row type="flex" className="table-header">
                    <Col md={6}>Note</Col>
                    <Col md={5}>Assigned to</Col>
                    <Col md={3}>Starts</Col>
                    <Col md={3}>Ends</Col>
                    <Col md={4}>Status</Col>
                    <Col md={3} style={{ textAlign: "right" }}>
                      Actions
                    </Col>
                  </Row>
                  <Row className="table-content">
                    <SpinBox loading={loading}>
                      {notesData.length > 0 ? (
                        <Row>
                          {notesData.map((item, index) => (
                            <Row key={index} className="record">
                              <Col md={6}>
                                <Text>{item.note}</Text>
                              </Col>
                              <Col md={5}>
                                <Text>
                                  {item.given_name &&
                                    item.given_name + " " + item.family_name &&
                                    item.family_name}
                                </Text>
                              </Col>
                              <Col md={3}>
                                <Text>
                                  {moment(item.start_date).format("MM/DD/YYYY")}
                                </Text>
                              </Col>
                              <Col md={3}>
                                <Text>
                                  {moment(item.end_date).format("MM/DD/YYYY")}
                                </Text>
                              </Col>
                              <Col md={4} className="note-status">
                                {item.status_id === 1 ? (
                                  <span className="circle circle-pending" />
                                ) : item.reportStatus === 2 ? (
                                  <span className="circle circle-completed" />
                                ) : (
                                  <span className="circle circle-dismissed" />
                                )}
                                <Text>{item.status.name}</Text>
                              </Col>
                              <Col
                                md={3}
                                style={{ textAlign: "right" }}
                                className="actions"
                              >
                                <EditIcon
                                  className="icon"
                                  onClick={() =>
                                    this.openNoteModal("EDIT", item)
                                  }
                                />
                                <RemoveIcon
                                  className="icon"
                                  onClick={() => this.openNoteDeleteModal(item)}
                                />
                              </Col>
                            </Row>
                          ))}
                        </Row>
                      ) : (
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          className="empty-icon"
                        />
                      )}
                    </SpinBox>
                  </Row>
                </Row>
              </TabPane>
              <TabPane
                tab={
                  selectedTab === "3" ? (
                    <>
                      <span>
                        Rated Ages
                        <Badge
                          overflowCount={9999}
                          showZero
                          count={GetAllAssignemetData.length}
                          className="selected-badge-icon"
                        />
                      </span>
                    </>
                  ) : (
                    <span>
                      Rated Ages
                      <Badge
                        overflowCount={9999}
                        showZero
                        count={GetAllAssignemetData.length}
                        className="badge-icon"
                      />
                    </span>
                  )
                }
                key="3"
              >
                <RatedAgesData
                  claimantInfo={claimantInfo}
                  getAllData={this.getAllData}
                  GetAllAssignemetData={this.state.GetAllAssignemetData}
                  loading={this.state.loading}
                  deleteAssignementData={this.deleteAssignementData}
                />
              </TabPane>
              <TabPane
                tab={
                  selectedTab === "4" ? (
                    <>
                      <span>
                        Files
                        <Badge
                          overflowCount={9999}
                          showZero
                          count={files && files.length}
                          className="selected-badge-icon"
                        />
                      </span>
                    </>
                  ) : (
                    <span>
                      Files
                      <Badge
                        overflowCount={9999}
                        showZero
                        count={files && files.length}
                        className="badge-icon"
                      />
                    </span>
                  )
                }
                key="4"
              >
                <Row className="reports-list-container">
                  <Row type="flex" className="table-header">
                    <Col md={6}>Name</Col>
                    <Col md={3}>Type</Col>
                    <Col md={6} style={{ textAlign: "center" }}>
                      Size
                    </Col>
                    <Col md={6}>Created</Col>
                    <Col md={3} style={{ textAlign: "right" }}>
                      Actions
                    </Col>
                  </Row>

                  <Row className="table-content">
                    <SpinBox loading={loading}>
                      {files && files.length > 0 ? (
                        <Row>
                          {files.map((item, index) => (
                            <Row key={index} className="record">
                              <Col md={6}>
                                <Text>{item.name}</Text>
                              </Col>
                              <Col md={3}>
                                <Text>{item.type}</Text>
                              </Col>
                              <Col md={6} style={{ textAlign: "center" }}>
                                <Text>{item.name}</Text>
                              </Col>
                              <Col md={6}>
                                <Text style={{ paddingLeft: "5px" }}>
                                  {item.createdAt}{" "}
                                </Text>
                              </Col>

                              <Col
                                md={3}
                                style={{ textAlign: "right" }}
                                className="actions"
                              >
                                <DetailsIcon
                                  onClick={() => RenderImage(item)}

                                  // onClick={() => RenderImage(item.id)}
                                />
                                <EditIcon
                                  className="icon"
                                  onClick={() => this.handleFileModal(item)}
                                />
                                <RemoveIcon
                                  className="icon"
                                  onClick={() => deleteFile(item.id)}
                                />
                              </Col>
                            </Row>
                          ))}
                        </Row>
                      ) : (
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          className="empty-icon"
                        />
                      )}
                    </SpinBox>
                  </Row>
                </Row>
              </TabPane>
            </Tabs>
          </Row>
        </Row>
        {showFileModal && (
          <UpdateFile
            isOpen={showFileModal}
            onConfirm={this.handleFileModal}
            onCancel={this.closeFileModal}
            EditFile={EditFile}
            onChangeName={(value) =>
              this.setState({
                EditFile: { ...EditFile, name: value },
              })
            }
            onFileSubmit={() => {
              this.setState({ loading: true });
              const { id, name } = EditFile;
              console.log(id, "id for submit");
              const data = {
                name: name,
              };
              // this.setState({ loading: true });
              API.updateUploadedFile(id, data).then((res) => {
                console.log(res, "i");
                const temp = [...files];
                const fileToBeUpdated = temp.find(
                  (file) => file.id === res.file.id
                );
                fileToBeUpdated.name = res.file.name;
                this.setState({
                  loading: false,
                  uploadedFiles: {
                    files: temp,
                  },
                  showFileModal: false,
                });
                console.log(this.state.uploadedFiles.files, "ss");
              });
            }}
            loading={this.state.loading}
          />
        )}
        <ReportModal
          reportsData={this.state.reportsData}
          isOpen={showReportModal}
          type={modalType}
          data={selectedReport}
          claimant_id={this.state.claimantInfo.id}
          reportStatuses={reportStatuses}
          onConfirm={this.handleReport}
          onCancel={this.closeReportModal}
        />
        <DeleteModal
          title="Delete this Report?"
          content={selectedReport.report_name}
          isOpen={showReportDeleteModal}
          onConfirm={this.handleReportDelete}
          onCancel={this.closeReportDeleteModal}
        />
        <NoteModal
          isOpen={showNoteModal}
          type={modalType}
          data={selectedNote}
          reportsData={reportsData}
          usersData={usersData}
          onConfirm={this.handleNote}
          onCancel={this.closeNoteModal}
        />
        <DeleteModal
          title="Delete this Note?"
          content={selectedNote.note}
          isOpen={showNoteDeleteModal}
          onConfirm={this.handleNoteDelete}
          onCancel={this.closeNoteDeleteModal}
        />

        <RequestAgeModal
          isOpen={showAgeModal}
          onConfirm={this.handleNote}
          onCancel={this.closeAgeModal}
          claimantInfo={claimantInfo}
          onFieldChange={(value) => {
            this.setState({ selectUser: value });
          }}
          onAssignSubmit={() => {
            const data = {
              user_id: this.state.selectUser,
              claimant_id: claimantInfo.id,
              assignment_type_id: 3,

              request_type: "RA",
              status: 1,
              note: this.state.notes,
            };
            this.setState({ loading: true });
            // setLoading(true);
            API.CreateRateDUser(data).then((res) => {
              const assignment_id = res.assignment.id;
              let params = this.state.fileValue.map((file_id) => {
                return { assignment_id, file_id };
              });

              API.CreateAssignementFiles(params);
              // console.log(updatedAssignement, "update");
              Modal.success({
                content: "Success",
                //   onOk: () => this.props.onConfirm(),
              });
              this.setState((prevState) => ({
                GetAllAssignemetData: [
                  ...this.state.GetAllAssignemetData,
                  res.assignment,
                ],
                showAgeModal: false,
                loading: false,
              }));
              // this.setState({
              //   loading: false,
              // }));
              this.setState({
                GetAllAssignemetData: [GetAllAssignemetData],
                res,
                loading: false,
              });
              debugger;
              console.log([GetAllAssignemetData], res, "wokr");
            });
          }}
          getFileToAssign={this.getFileToAssign}
          loading={this.state.loading}
          onNoteChange={this.onNoteChange}
          notes={this.state.notes}
        />
        <FilePhotoModal
          isOpen={showFilePhotoModal}
          // RenderImage={RenderImage}
          renderUploadedImage={renderUploadedImage}
          onCancel={this.closePhotoModal}
        />
      </Row>
    );
  }
}
