import React, { Component } from "react";
import { Row, Col, Typography, Button, Select, Modal, Icon } from "antd";

import InputBox from "components/InputBox";
import SpinBox from "components/SpinBox";
import * as API from "utils/api";
import * as Utils from "utils/utils";

import "./ContactModal.scss";

const { Text } = Typography;
const { Option } = Select;

export default class ContactModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contact_name: "",
      organisation_name: "",
      address: "",
      phone_number: "",
      fax_number: "",
      email: "",
      contact_type: null,
      loading: false,
      loadingTitle: "Loading...",
    };
  }

  handleSetValue = (type, value) => {
    this.setState({
      [type]: value,
    });
  };

  handleCreateContact = () => {
    const { contactType } = this.props;
    const {
      contact_name,
      organisation_name,
      address,
      phone_number,
      fax_number,
      email,
    } = this.state;

    const param = {
      contact_name,
      organisation_name,
      address,
      phone_number,
      fax_number,
      email,
      contact_type_id: contactType,
    };

    if (!contact_name) {
      Modal.error({ content: "Please input the Contact Name" });
      return;
    }

    if (!organisation_name) {
      Modal.error({ content: "Please input the Organization Name" });
      return;
    }

    if (!address) {
      Modal.error({ content: "Please input the Organization Name" });
      return;
    }

    if (!phone_number || phone_number.includes("_")) {
      Modal.error({ content: "Please input the Phone number" });
      return;
    }

    if (fax_number != "" && fax_number.includes("_")) {
      Modal.error({ content: "Please input the Fax Number" });
      return;
    }

    if (!email || !Utils.validateEmail(email)) {
      Modal.error({ content: "Please input the Valid Email" });
      return;
    }
    if (!contactType) {
      delete param.contact_type_id;
    }

    this.setState({ loadingTitle: "Saving...", loading: true });
    API.createContact(param)
      .then((res) => {
        this.setState({ loading: false });
        Modal.success({
          content: "Success",
          onOk: () => this.props.onConfirm(),
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        const errorMessage = error.response.data.reason
          ? error.response.data.reason[0].message
          : "Error";
        Modal.error({
          content: errorMessage,
        });
      });
  };

  render() {
    const { title, isOpen, onCancel } = this.props;
    const {
      contact_name,
      organisation_name,
      address,
      phone_number,
      fax_number,
      email,
      loading,
      loadingTitle,
    } = this.state;

    return (
      <Modal
        visible={isOpen}
        onCancel={!loading && onCancel}
        footer={null}
        centered={true}
        closable={true}
        width={600}
        className="add-contact-modal"
        title={title}
      >
        <Row className="modal-body">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="main-info"
              >
                <Col md={11} className="row">
                  <InputBox
                    label="Contact Name"
                    value={contact_name}
                    onChange={(value) => this.setState({ contact_name: value })}
                  />
                </Col>
                <Col md={11} className="row">
                  <InputBox
                    label="Organization Name"
                    value={organisation_name}
                    onChange={(value) =>
                      this.setState({ organisation_name: value })
                    }
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="main-info"
              >
                <Col md={24} className="row">
                  <InputBox
                    label="Address"
                    value={address}
                    onChange={(value) => this.setState({ address: value })}
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="main-info"
              >
                <Col md={11} className="row">
                  <InputBox
                    label="Phone"
                    value={phone_number}
                    onChange={(value) => this.setState({ phone_number: value })}
                  />
                </Col>
                <Col md={11} className="row">
                  <InputBox
                    label="Fax Number"
                    value={fax_number}
                    onChange={(value) => this.setState({ fax_number: value })}
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="main-info"
              >
                <Col md={11} className="row">
                  <InputBox
                    label="Email"
                    value={email}
                    onChange={(value) => this.setState({ email: value })}
                  />
                </Col>
                {/* <Col md={11} className="row">
                  <Row>
                    <Text>Contact Type</Text>
                  </Row>
                  <Row>
                    <Select
                      showSearch
                      placeholder="Please select"
                      optionFilterProp="children"
                      className="dropdown"
                      value={contact_type}
                      onChange={(value)=>this.handleSetValue("contact_type", value)}
                      suffixIcon={
                        <Icon type="caret-down" style={{color: "black"}} theme="filled" />
                      }
                    >
                      <Option value={1}>MSA</Option>
                      <Option value={2}>Standard</Option>
                      <Option value={3}>MCP</Option>
                    </Select>
                  </Row>
                </Col> */}
              </Row>
              <Row type="flex" justify="center" className="confirm-button">
                <Button
                  type="primary"
                  size="large"
                  className="green-btn button"
                  onClick={this.handleCreateContact}
                >
                  Create
                </Button>
              </Row>
            </Row>
          </SpinBox>
        </Row>
      </Modal>
    );
  }
}
