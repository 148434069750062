import React, { Component } from "react";
import {
  Row,
  Col,
  Typography,
  Button,
  Divider,
  Icon,
  Upload,
  Modal,
} from "antd";

import InputBox from "components/InputBox";
import ColorPicker from "components/ColorPicker";
import { BackIcon, UploadImageIcon } from "components/Icons";
import SpinBox from "components/SpinBox";
import { getBase64 } from "utils/utils";
import * as API from "utils/api";

import "./AddClient.scss";

const { Title, Text } = Typography;

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    Modal.error({ content: "You can only upload JPG/PNG file!" });
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    Modal.error({ content: "Image must smaller than 2MB!" });
  }
  return isJpgOrPng && isLt2M;
};

export default class AddClient extends Component {
  constructor() {
    super();

    this.state = {
      loadingImg: false,
      clientId: "",
      imageUrl: "",
      clientName: "",
      header: "",
      footer: "",
      primaryColor: "#0082ff",
      secondaryColor: "#1ed46a",
      loadingTitle: "Loading...",
      loading: false,
    };
  }

  handlePhotoChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loadingImg: true });
      return;
    }
    console.log(info.file.status);
    if (info.file.status === "error") {
      Modal.error({ content: "File is not supported!" });
      this.setState({
        loadingImg: false,
      });
      return;
    }
    if (info.file.status === "done") {
      console.log("done");
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {
        console.log("here");
        this.setState({
          imageUrl,
          loadingImg: false,
        });
      });
    }
  };

  handlePrimaryColorPicker = (color) => {
    this.setState({
      primaryColor: color.hex,
    });
  };

  handleSecondaryColorPicker = (color) => {
    this.setState({
      secondaryColor: color.hex,
    });
  };

  handleCreateClient = () => {
    const {
      imageUrl,
      clientName,
      header,
      footer,
      primaryColor,
      secondaryColor,
    } = this.state;
    const params = {
      client_name: clientName,
      client_logo: imageUrl,
      client_header: header,
      client_footer: footer,
      client_primary_color: primaryColor,
      client_secondary_color: secondaryColor,
    };

    this.setState({ loadingTitle: "Saving...", loading: true });
    API.createClient(params)
      .then((res) => {
        this.setState({ loading: false });
        Modal.success({
          content: "Success",
          onOk: () => this.props.history.goBack(),
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        const errorMessage = error.response.data.reason
          ? error.response.data.reason[0].message
          : "Error";
        Modal.error({
          content: errorMessage,
        });
      });
  };

  render() {
    const {
      imageUrl,
      clientName,
      header,
      footer,
      primaryColor,
      secondaryColor,
      loadingTitle,
      loading,
    } = this.state;

    return (
      <Row className="add-client-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Add New Client
            </Title>
          </Col>
          <Col className="back" onClick={() => this.props.history.goBack()}>
            <BackIcon className="icon" />
            <Text>Cancel</Text>
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row type="flex" justify="space-around" className="client-info">
              <Col>
                <Row type="flex" align="middle" className="logo">
                  <Row
                    type="flex"
                    align="middle"
                    justify="center"
                    className="logo-image"
                  >
                    {this.state.loadingImg ? (
                      <Icon type="loading" style={{ fontSize: "20px" }} />
                    ) : imageUrl ? (
                      <img
                        src={imageUrl}
                        alt="avatar"
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                      />
                    ) : (
                      <UploadImageIcon />
                    )}
                  </Row>
                  <Upload
                    name="logo"
                    listType="text"
                    className="logo-uploader"
                    showUploadList={false}
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    beforeUpload={beforeUpload}
                    onChange={this.handlePhotoChange}
                    ref={(node) => {
                      this.upload_btn = node;
                    }}
                  >
                    <Row>
                      <Text className="logo-button">Upload Logo</Text>
                    </Row>
                  </Upload>
                </Row>
              </Col>
              <Col className="content">
                <Row className="row">
                  <InputBox
                    label="Client Name"
                    value={clientName}
                    onChange={(value) => this.setState({ clientName: value })}
                  />
                </Row>
                {/* <Row className="row">
                  <InputBox
                    label="Header"
                    placeholder="Header text here"
                    value={header}
                    onChange={(value)=>this.setState({header: value})}
                  />
                </Row>
                <Row className="row">
                  <InputBox
                    label="Footer"
                    placeholder="Footer text here"
                    value={footer}
                    onChange={(value)=>this.setState({footer: value})}
                  />
                </Row> */}
                {/* <Row>
                  <Row>
                    <Text className="colors-text">Colors</Text>
                  </Row>
                  <Row type="flex" justify="space-between">
                    <Col className="primary">
                      <ColorPicker
                        title="Primary color"
                        color={primaryColor}
                        onChange={this.handlePrimaryColorPicker}
                      />
                    </Col>
                    <Col className="secondary">
                      <ColorPicker
                        title="Secondary color"
                        color={secondaryColor}
                        onChange={this.handleSecondaryColorPicker}
                      />
                    </Col>
                  </Row>
                </Row> */}
              </Col>
            </Row>
            <Row>
              <Divider />
            </Row>
            <Row type="flex" justify="center">
              <Button
                type="primary"
                size="large"
                className="green-btn button"
                onClick={this.handleCreateClient}
              >
                Create
              </Button>
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}
