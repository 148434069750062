import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Typography, Button, Select, Icon, Divider } from "antd";
import InlineContact from "components/InlineContact";
import TextAreaBox from "components/TextAreaBox";
import InputBox from "components/InputBox";
import TagsInputBox from "components/TagsInputBox";
import SpinBox from "components/SpinBox";
import { BackIcon } from "components/Icons";
import { updateReportInfo } from "redux/report";
import * as API from "utils/api";
import * as Utils from "utils/utils";
import dynamicData from "dynamicData";
import ClaimantInfo from "components/ClaimantInfoOnWizard";
import "./MedicalContent.scss";
import RichTextEditor from "components/RichTextEditor/RichTextEditor";
const { Title, Text } = Typography;
const { Option } = Select;

class MedicalContent extends Component {
  constructor(props) {
    super(props);

    let report_id = "";
    if (this.props.location && this.props.location.state) {
      report_id = this.props.location.state.data.report_id;
    }

    this.state = {
      report_id: report_id,
      introduction: "",
      medical_records_reviewed: "",
      description_of_injury: "",
      medical_summary: "",
      missing_medical_records: "",
      related_diagnosis: "",
      pre_existing_conditions: "",
      surgeries_procedures: "",
      treating_providers: undefined,
      current_treatment_plan: "",
      life_expectancy: "",
      projected_treatment_plan: "",
      social_security_disability_status: "",
      medicare_status: "",
      medicare_lien_status: `Please note that Medicare has a statutory right of recovery for any conditional payments made pursuant to 42 U.S.C. 1395y(b)(2) and 42 C.F.R. 411.24. We recommend that the settlement documents take this factor into account by having a provision that specifies which party will be responsible for any conditional payments made by Medicare. 
      According to the MSPRC (Medicare Secondary Payer Recovery Contractor), a conditional payment is a payment made by Medicare for services which another payer was responsible. The MSPRC will provide a payment summary listing of claims paid by Medicare that are related to an accident, illness or other incident. The total money paid by Medicare on one of these listing is called the conditional payment amount.`,
      medicare_allocation_administration: "",
      source_id: 0,
      life_table_id: 0,
      contactsList: [],
      lifeSources: [],
      lifeTables: [],
      loading: false,
      medical_status: "",
      rated_ages_text: "",
      report_contacts: [],
      treatingList: [],
      report_claim_id: "",
    };
    this.timeoutId = setInterval(
      this.handleUpdateReport,
      this.props.autoSavingTime
    );
  }
  componentWillUnmount = () => {
    clearInterval(this.timeoutId);
  };
  componentDidMount() {
    const { report_id } = this.state;

    this.setState({ loading: true });
    API.getContactsData("", 0, 1000) //Getting all contacts data
      .then((res) => {
        const dynamic = dynamicData();
        this.setState({
          contactsList: res.contacts,
          treatingList: res.contacts.filter(
            (item) =>
              (item.contact_type ? item.contact_type.name : "") ==
              dynamic.treating_providers
          ),
        });
      });
    API.getLifeSources().then((res) => {
      this.setState({
        lifeSources: res.life_expects,
      });
    });
    API.getLifeTables().then((res) => {
      this.setState({
        lifeTables: res.life_expects,
      });
    });
    API.getReportContacts(report_id).then((res) => {
      this.setState({ report_contacts: res.report_contacts.rows });
    });
    API.getReportById(report_id).then((res) => {
      // this.setState({ loading: false });
      let reportInfo = res.report;
      this.props.updateReportInfo(res.report);
      this.setState({
        introduction: reportInfo.introduction,
        medical_records_reviewed: reportInfo.medical_records_reviewed,
        description_of_injury: reportInfo.description_of_injury,
        medical_summary: reportInfo.medical_summary,
        missing_medical_records: reportInfo.missing_medical_records,
        related_diagnosis: reportInfo.related_diagnosis,
        pre_existing_conditions: reportInfo.pre_existing_conditions,
        surgeries_procedures: reportInfo.surgeries_procedures,
        treating_providers: reportInfo.treating_providers || undefined,
        current_treatment_plan: reportInfo.current_treatment_plan,
        projected_treatment_plan: reportInfo.projected_treatment_plan,
        social_security_disability_status:
          reportInfo.social_security_disability_status,
        medicare_status: reportInfo.medicare_status,
        medicare_lien_status: reportInfo.medicare_lien_status,
        medicare_allocation_administration:
          reportInfo.medicare_allocation_administration,
        life_expectancy: reportInfo.life_expectancy,
        rated_ages_text: reportInfo.rated_ages_text,
        medical_status: reportInfo.medical_status,
        prognosis: reportInfo.prognosis,
        report_claim_id: reportInfo.report_claim_id || "",
        claimant_id: reportInfo.claimant_id,
        loading: false,
        prognosis: reportInfo.prognosis,
        references: reportInfo.references,
        additional_comments: reportInfo.additional_comments,
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { reportInfo } = this.props;
    const { lifeSources, lifeTables, life_expectancy } = this.state;
    if (JSON.stringify(reportInfo) !== JSON.stringify(prevProps.reportInfo)) {
      // this.setState({
      //   introduction: reportInfo.introduction,
      //   medical_records_reviewed: reportInfo.medical_records_reviewed,
      //   description_of_injury: reportInfo.description_of_injury,
      //   medical_summary: reportInfo.medical_summary,
      //   missing_medical_records: reportInfo.missing_medical_records,
      //   related_diagnosis: reportInfo.related_diagnosis,
      //   pre_existing_conditions: reportInfo.pre_existing_conditions,
      //   surgeries_procedures: reportInfo.surgeries_procedures,
      //   treating_providers: reportInfo.treating_providers || undefined,
      //   current_treatment_plan: reportInfo.current_treatment_plan,
      //   projected_treatment_plan: reportInfo.projected_treatment_plan,
      //   social_security_disability_status:
      //     reportInfo.social_security_disability_status,
      //   medicare_status: reportInfo.medicare_status,
      //   medicare_lien_status: reportInfo.medicare_lien_status
      //     ? reportInfo.medicare_lien_status
      //     : `Please note that Medicare has a statutory right of recovery for any conditional payments made pursuant to 42 U.S.C. 1395y(b)(2) and 42 C.F.R. 411.24. We recommend that the settlement documents take this factor into account by having a provision that specifies which party will be responsible for any conditional payments made by Medicare.
      //   According to the MSPRC (Medicare Secondary Payer Recovery Contractor), a conditional payment is a payment made by Medicare for services which another payer was responsible. The MSPRC will provide a payment summary listing of claims paid by Medicare that are related to an accident, illness or other incident. The total money paid by Medicare on one of these listing is called the conditional payment amount.`,
      //   medicare_allocation_administration:
      //     reportInfo.medicare_allocation_administration,
      //   life_expectancy: reportInfo.life_expectancy,
      //   rated_ages_text: reportInfo.rated_ages_text,
      //   medical_status: reportInfo.medical_status,
      //   loading: false,
      // });
    }
    if (
      reportInfo.claimant_id &&
      lifeSources.length > 0 &&
      lifeTables.length > 0 &&
      !life_expectancy
    ) {
      const param = {
        source_id: lifeSources[0].id,
        life_table_id: lifeTables[0].id,
      };
      API.getLifeExpect(reportInfo.claimant_id, param)
        .then((res) =>
          this.setState({
            life_expectancy: Math.round(res.years_left),
          })
        )
        .catch((err) =>
          this.setState({
            life_expectancy: "",
          })
        );
    }
  }
  getReportContacts = async () => {
    const { report_id } = this.state;
    let res = await API.getReportContacts(report_id);
    this.setState({ report_contacts: res.report_contacts.rows });
  };
  handleUpdateReport = (isNextStep = false) => {
    const {
      report_id,
      introduction,
      medical_records_reviewed,
      description_of_injury,
      medical_summary,
      missing_medical_records,
      related_diagnosis,
      pre_existing_conditions,
      surgeries_procedures,
      treating_providers,
      current_treatment_plan,
      life_expectancy,
      projected_treatment_plan,
      social_security_disability_status,
      medicare_status,
      medicare_lien_status,
      medicare_allocation_administration,
      rated_ages_text,
      medical_status,
    } = this.state;
    let yrly_src = this.state.lifeSources[this.state.source_id];
    let lyfe_table = this.state.lifeTables[this.state.life_table_id];

    let life_expectancy_source = yrly_src.name + "." + lyfe_table.name + " ";
    const params = {
      life_expectancy_source,
      id: report_id,
      introduction,
      medical_records_reviewed,
      description_of_injury,
      medical_summary,
      missing_medical_records,
      related_diagnosis,
      pre_existing_conditions,
      surgeries_procedures,
      treating_providers,
      current_treatment_plan,
      life_expectancy,
      projected_treatment_plan,
      social_security_disability_status,
      medicare_status,
      medicare_lien_status,
      medicare_allocation_administration,
      rated_ages_text,
      medical_status,
    };

    if (isNextStep) {
      this.setState({
        loading: true,
        loadingTitle: "Saving...",
      });

      API.updateReport(params).then((res) => {
        this.setState({ loading: false });
        clearTimeout(this.timeoutId);
        this.props.updateReportInfo(res.report);
        this.props.history.push("/app/reports/msa/medicareCost", {
          data: { report_id: report_id },
        });
      });
    } else {
      this.props.updateReportInfo(params);
      API.updateReport(params).then((res) => {});
    }
  };

  handleUpdateTextArea = (type, value) => {
    var duration = 700;
    this.setState({
      [type]: value,
    });
    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(this.handleUpdateReport, duration);
  };

  goToPreviousStep = () => {
    this.props.history.goBack();
  };

  render() {
    let dataDynamic = { ...dynamicData() };
    const {
      introduction,
      medical_records_reviewed,
      description_of_injury,
      medical_summary,
      missing_medical_records,
      related_diagnosis,
      pre_existing_conditions,
      surgeries_procedures,
      treating_providers,
      current_treatment_plan,
      projected_treatment_plan,
      social_security_disability_status,
      medicare_status,
      medicare_lien_status,
      medicare_allocation_administration,
      life_expectancy,
      // source_id,
      // life_table_id,
      contactsList,
      lifeSources,
      lifeTables,
      loading,
      loadingTitle,
      medical_status,
      rated_ages_text,
      treatingList,
      report_claim_id,
    } = this.state;

    return (
      <Row className="msa-medical-content-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Setup MSA Report
            </Title>
          </Col>
          <Col className="back" onClick={() => this.props.history.goBack()}>
            <BackIcon className="icon" />
            <Text>Cancel</Text>
          </Col>
          <Col className="step">
            <Text>Step 2 of 17</Text>
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Row type="flex" justify="space-between" align="middle">
                <Col md={24}>
                  <ClaimantInfo
                    claimant={{
                      ...this.props.reportInfo.claimant,
                      report_claim_id,
                    }}
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <Text className="title">Medical Content</Text>
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <RichTextEditor
                    label="Introduction"
                    placeholder="Please enter"
                    value={introduction}
                    onChange={(value) =>
                      this.handleUpdateTextArea("introduction", value)
                    }
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <RichTextEditor
                    label="Records Reviewed"
                    placeholder="Please enter"
                    value={medical_records_reviewed}
                    onChange={(value) =>
                      this.handleUpdateTextArea(
                        "medical_records_reviewed",
                        value
                      )
                    }
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <RichTextEditor
                    label="Description of Injury"
                    placeholder="Please enter"
                    value={description_of_injury}
                    onChange={(value) =>
                      this.handleUpdateTextArea("description_of_injury", value)
                    }
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col sm={24}>
                  <RichTextEditor
                    onChange={(value) =>
                      this.handleUpdateTextArea("medical_summary", value)
                    }
                    value={medical_summary}
                    label="Medical Summary"
                  />
                </Col>
                {/* <Col md={24}>
                  <TextAreaBox
                    label="Medical Care Summary"
                    placeholder="Please enter"
                    value={medical_summary}
                    onChange={(value) =>
                      this.handleUpdateTextArea("medical_summary", value)
                    }
                  />
                </Col> */}
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <TagsInputBox
                    label="Missing Medical Records"
                    placeholder="Please enter"
                    value={missing_medical_records}
                    onChange={(value) =>
                      this.handleUpdateTextArea(
                        "missing_medical_records",
                        value
                      )
                    }
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <InputBox
                    label="Current Injury Related Conditions"
                    placeholder=" "
                    disabled={true}
                    value={
                      related_diagnosis &&
                      related_diagnosis
                        .split(",")
                        .map((item) => {
                          if (item)
                            if (item) {
                              let item2;
                              try {
                                item2 = JSON.parse(item.replace(/</g, ","));
                              } catch (error) {
                                return "";
                              }
                              return item2.code;
                            }
                          return "";
                        })
                        .toString()
                    }
                    onChange={(value) =>
                      this.handleUpdateTextArea("related_diagnosis", value)
                    }
                  />
                </Col>
              </Row>
              {/* <Row type="flex" justify="space-between" align="middle" className="row">
                <Col md={11}>
                  <Row className="label">
                    <Text>Current Injury Related Conditions</Text>
                  </Row>
                  <Row>
                    <Input
                      value={icd_codes}
                      disabled
                    />
                  </Row>
                </Col>
              </Row> */}
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <TagsInputBox
                    label="Pre-existing Conditions"
                    placeholder="Please enter"
                    value={pre_existing_conditions}
                    onChange={(value) =>
                      this.handleUpdateTextArea(
                        "pre_existing_conditions",
                        value
                      )
                    }
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <TagsInputBox
                    label="Surgeries/Procedures related to injury"
                    placeholder="Please enter"
                    value={surgeries_procedures}
                    onChange={(value) =>
                      this.handleUpdateTextArea("surgeries_procedures", value)
                    }
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={11}>
                  <InlineContact
                    getReportContacts={this.getReportContacts}
                    contact_type_id={dataDynamic.treating_providers_id}
                    another={true}
                    label="Treating Providers"
                    report_id={this.state.report_id}
                    contactsList={treatingList}
                    type={"treating_providers"}
                    value={this.state.report_contacts
                      .filter((item) => item.type === "treating_providers")
                      .map((item) => item.contact.id)}
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <RichTextEditor
                    label="Current Treatment Plan"
                    placeholder="Please enter"
                    value={current_treatment_plan}
                    onChange={(value) =>
                      this.handleUpdateTextArea("current_treatment_plan", value)
                    }
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <RichTextEditor
                    label="Current Medical Status"
                    placeholder="Please enter"
                    value={medical_status}
                    onChange={(value) =>
                      this.handleUpdateTextArea("medical_status", value)
                    }
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col sm={24}>
                  <RichTextEditor
                    onChange={(value) =>
                      this.handleUpdateTextArea(
                        "projected_treatment_plan",
                        value
                      )
                    }
                    value={projected_treatment_plan}
                    label="Projected Treatment Plan"
                  />
                </Col>
                {/* <Col md={24}>
                  <TextAreaBox
                    label="Projected Treatment Plan"
                    placeholder="Please enter"
                    value={projected_treatment_plan}
                    onChange={(value) =>
                      this.handleUpdateTextArea(
                        "projected_treatment_plan",
                        value
                      )
                    }
                  />
                </Col> */}
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <RichTextEditor
                    label="Social Security Disability Status"
                    placeholder="Please enter"
                    value={social_security_disability_status}
                    onChange={(value) =>
                      this.handleUpdateTextArea(
                        "social_security_disability_status",
                        value
                      )
                    }
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <RichTextEditor
                    label="Medicare Status"
                    placeholder="Please enter"
                    value={medicare_status}
                    onChange={(value) =>
                      this.handleUpdateTextArea("medicare_status", value)
                    }
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <RichTextEditor
                    label="Medicare Lien Information"
                    placeholder="Please enter"
                    value={medicare_lien_status}
                    onChange={(value) =>
                      this.handleUpdateTextArea("medicare_lien_status", value)
                    }
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={24}>
                  <InputBox
                    label="Medicare Allocation Administration"
                    placeholder="Please enter"
                    value={medicare_allocation_administration}
                    onChange={(value) =>
                      this.setState({
                        medicare_allocation_administration: value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={11}>
                  <Row>
                    <Text>Life Source</Text>
                  </Row>
                  <Row>
                    <Select
                      showSearch
                      placeholder="Please select"
                      optionFilterProp="children"
                      className="dropdown"
                      // value={source_id}
                      value={
                        lifeSources.length > 0 ? lifeSources[0].id : undefined
                      }
                      disabled={true}
                      onChange={(value) => this.setState({ source_id: value })}
                      suffixIcon={
                        <Icon
                          type="caret-down"
                          style={{ color: "black" }}
                          theme="filled"
                        />
                      }
                    >
                      {lifeSources.map((item) => (
                        <Option value={item.id} key={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Row>
                </Col>
                <Col md={11}>
                  <Row>
                    <Text>Life Table</Text>
                  </Row>
                  <Row>
                    <Select
                      showSearch
                      placeholder="Please select"
                      optionFilterProp="children"
                      className="dropdown"
                      // value={life_table_id}
                      value={
                        lifeTables.length > 0 ? lifeTables[0].id : undefined
                      }
                      disabled={true}
                      onChange={(value) =>
                        this.setState({ life_table_id: value })
                      }
                      suffixIcon={
                        <Icon
                          type="caret-down"
                          style={{ color: "black" }}
                          theme="filled"
                        />
                      }
                    >
                      {lifeTables.map((item) => (
                        <Option value={item.id} key={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Row>
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="row"
              >
                <Col md={11}>
                  <InputBox
                    label="Life Expectancy"
                    disabled={true}
                    placeholder=" "
                    value={life_expectancy}
                  />
                </Col>
              </Row>
              <Row>
                <br />
                <p>
                  Our organization certifes that all rated ages we have obtained
                  and/or have knowledge of regarding this claimant, and
                  generated at any time on or after the Date of Incident for the
                  alleged accident/illness/injury/incident at issue, have been
                  included as part of this submission of a proposed amount for a
                  Workers' Compensation Medicare Set-Aside Arrangement (WCMSA)
                  to the Centers for Medicare & Medicaid Services
                </p>
              </Row>
              <Row>
                <Col md={24}>
                  <TextAreaBox
                    label="Rated Age"
                    placeholder="Please enter"
                    value={rated_ages_text}
                    onChange={(value) =>
                      this.handleUpdateTextArea("rated_ages_text", value)
                    }
                  />
                </Col>
              </Row>
              <Divider className="divider" />
              <Row
                type="flex"
                justify="center"
                align="middle"
                className="confirm-button"
              >
                <Button
                  type="link"
                  className="prev-button button"
                  onClick={this.goToPreviousStep}
                >
                  &lt; PREVIOUS STEP
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="green-btn button"
                  onClick={() => this.handleUpdateReport(true)}
                >
                  Next Step
                </Button>
              </Row>
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  const props = {
    autoSavingTime: state.report.autoSavingTime,
    reportInfo: state.report.reportInfo,
  };
  return props;
};

export default connect(mapStateToProps, { updateReportInfo })(MedicalContent);
