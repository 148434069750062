import React, { Component } from 'react';
import { Row, Col, Button, Typography } from 'antd';
import AuthSideBar from '../../../components/AuthSideBar';
import { CheckEmailIcon } from "../../../components/Icons";
import "./CheckEmail.scss";

const { Title, Text } = Typography;

export default class CheckEmail extends Component {

  check = () => {
    // this.props.history.push("/resetPassword");
  }

  render() {
    const email = this.props.location.state.email;
    return (
      <Row type="flex" justify="center" className="checkEmail-container">
        <Col xs={0} xl={8}>
          <AuthSideBar />
        </Col>
        <Col xs={24} xl={16}>
          <Row type="flex" justify="center">
            <Col xs={20} md={16}>
              <Row type="flex"  justify="space-around" className="main-container">
                <Col style={{margin: "auto"}}>
                  <Row
                    type="flex"
                    justify="center"
                    align="middle"
                    style={{ flexDirection: "column" }}
                    onClick={this.check}
                  >
                    <CheckEmailIcon className="check-icon" />
                    <Title level={1}>
                      Check your email
                    </Title>
                    <Col className="label-group">
                      <Text className="label">
                        If an account associated with this email,
                      </Text>
                      <Text className="label-email">
                        {" " + email}
                      </Text>                      
                      <Text className="label">
                        , was found, a password reset email has been sent to it
                      </Text>
                    </Col>
                  </Row>
                </Col>
                <Row className="reset">
                  Back to
                  <Button type="link" href="/login">Sign in</Button>
                </Row>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}
