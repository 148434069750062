import React, { Component } from 'react';
import { connect } from "react-redux";
import { sendEmail } from "../../../redux/auth/actions";
import { Row, Col, Button, Input, Typography } from 'antd';
import AuthSideBar from '../../../components/AuthSideBar';
import "./SendRequest.scss";

const { Title, Text } = Typography;

class SendRequest extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      changeFlag: false,
      error: ""
    };
  }

  validateEmail = email => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  sendEmail = () => {
    const { email } = this.state;

    if (!this.validateEmail(email)) {
      this.setState({
        error: "Please input valid email",
        changeFlag: false
      })
      return;
    }
    this.props.sendEmail(email);
    this.props.history.push('/checkEmail', {"email": email})
  }

  render() {
    const { email, error, changeFlag } = this.state;
    const { isLoading } = this.props;
    return (
      <Row type="flex" justify="center" className="sendRequest-container">
        <Col xs={0} xl={8}>
          <AuthSideBar />
        </Col>
        <Col xs={24} xl={16}>
          <Row type="flex" justify="center">
            <Col xs={20} md={10}>
              <Row type="flex" justify="space-around" className="main-container">
                <Col style={{margin: "auto"}}>
                  <Row type="flex" justify="center" align="middle" style={{ flexDirection: "column" }}>
                    <Title level={1}>
                      Reset Password
                    </Title>
                    <Text className="label">
                      Please enter the email you received the invitation to
                    </Text>
                    <Row style={{width: "95%"}}>
                      <Input
                        size="large"
                        placeholder="Email"
                        className="email"
                        value={email}
                        onChange={e => this.setState({ email: e.target.value, changeFlag: true})}
                      />
                    </Row>
                    <Row className="error-text">
                    {
                      error && !changeFlag &&
                        <Text type="danger">{error}</Text>                      
                    }
                    </Row>
                    <Row>
                      {
                        isLoading ?
                          <Button type="primary" size="large" className="green-btn button" loading>
                            Loading
                          </Button>
                        :                          
                          <Button type="primary" size="large" onClick={this.sendEmail} className="green-btn button">
                            Send request
                          </Button>
                      }
                    </Row>
                  </Row>
                </Col>
                <Row className="reset">
                  Back to
                  <Button type="link" href="/login">Sign in</Button>
                </Row>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

export default connect(
  null,
  { sendEmail }
)(SendRequest);
