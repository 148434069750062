export default () => {
  return {
    plaintiff_attorney: "Plaintiff attorney",
    defense_attorney: "Defense attorney",
    insurance_carrier: "Insurance carrier",
    employer_demographics: "Employer",
    treating_providers: "Treating provider",
    plaintiff_attorney_id: 1,
    defense_attorney_id: 2,
    insurance_carrier_id: 6,
    employer_demographics_id: 8,
    treating_providers_id: 3,
  };
};
