import * as types from "./actionTypes";
import * as API from "../../utils/api";
import { Auth } from "aws-amplify";
import moment from "moment";
import { listener } from "../../index";
export const login = (username, password) => (dispatch) => {
  dispatch({
    type: types.LOGIN_REQUEST,
  });

  // check if user is logged in already
  API.isActiveUser(username)
    .then((res) => {
      if (res.data.activeUser.is_active) {
        let errorMessage =
          "This user already has an active session on another device";
        dispatch({
          type: types.LOGIN_FAILURE,
          errorMessage: errorMessage,
        });
        return;
      }
      Auth.signIn(username, password)
        .then((res) => {
          // window.addEventListener("beforeunload", listener);
          let isNewPasswordRequest = false;
          let isAuthenticated = true;
          if (res.challengeName === "NEW_PASSWORD_REQUIRED") {
            isNewPasswordRequest = true;
            isAuthenticated = false;
          }
          API.updateActiveUser({ email: username, is_active: true });
          localStorage.setItem("isAuthenticated", isAuthenticated);
          localStorage.setItem("beforeUnloadDate", moment());
          dispatch({
            type: types.LOGIN_SUCCESS,
            isNewPasswordRequest: isNewPasswordRequest,
            isAuthenticated: isAuthenticated,
            userInfo: res,
          });
        })
        .catch((error) => {
          let errorMessage = "Incorrect username or password.";
          if (error) {
            if (password === "") errorMessage = "Password cannot be empty.";
            if (username === "") errorMessage = "Username cannot be empty.";
          }
          dispatch({
            type: types.LOGIN_FAILURE,
            errorMessage: errorMessage,
          });
        });
    })
    .catch((err) => {
      Auth.signIn(username, password)
        .then((res) => {
          let isNewPasswordRequest = false;
          let isAuthenticated = true;
          if (res.challengeName === "NEW_PASSWORD_REQUIRED") {
            isNewPasswordRequest = true;
            isAuthenticated = false;
          }
          API.addActiveUser({ email: username, is_active: true });
          localStorage.setItem("isAuthenticated", isAuthenticated);
          dispatch({
            type: types.LOGIN_SUCCESS,
            isNewPasswordRequest: isNewPasswordRequest,
            isAuthenticated: isAuthenticated,
            userInfo: res,
          });
        })
        .catch((error) => {
          let errorMessage = "Incorrect username or password.";
          if (error) {
            if (password === "") errorMessage = "Password cannot be empty.";
            if (username === "") errorMessage = "Username cannot be empty.";
          }
          dispatch({
            type: types.LOGIN_FAILURE,
            errorMessage: errorMessage,
          });
        });
    });
};

export const logout = () => (dispatch, getState) => {
  try {
    API.updateActiveUser({
      email: getState().auth.userInfo.attributes.email,
      is_active: false,
    });
  } catch (error) {}

  Auth.signOut();
  window.removeEventListener("beforeunload", listener);
  localStorage.removeItem("isAuthenticated");
  dispatch({
    type: types.LOGOUT_REQUEST,
  });
};

export const sendEmail = (email) => (dispatch) => {
  dispatch({
    type: types.SEND_EMAIL_REQUEST,
  });
  Auth.forgotPassword(email)
    .then((res) => {
      dispatch({
        type: types.SEND_EMAIL_SUCCESS,
      });
    })
    .catch((error) => {
      let errorMessage = "";
      if (error) {
        errorMessage = error.message;
      }
      dispatch({
        type: types.SEND_EMAIL_FAILURE,
        errorMessage: errorMessage,
      });
    });
};

export const resetPassword = (userName, verifyCode, newPassword) => (
  dispatch
) => {
  dispatch({
    type: types.RESET_PASSWORD_REQUEST,
  });
  Auth.forgotPasswordSubmit(userName, verifyCode, newPassword)
    .then((res) => {
      dispatch({
        type: types.RESET_PASSWORD_SUCCESS,
      });
    })
    .catch((error) => {
      let errorMessage = "";
      if (error) {
        errorMessage = error.message;
      }
      dispatch({
        type: types.RESET_PASSWORD_FAILURE,
        errorMessage: errorMessage,
      });
    });
};

export const getUserInfo = () => (dispatch) => {
  dispatch({
    type: types.GET_USER_INFO_REQUEST,
  });
  Auth.currentUserInfo()
    .then((res) => {
      dispatch({
        type: types.GET_USER_INFO_SUCCESS,
        userInfo: res,
      });
    })
    .catch((error) => {
      let errorMessage = "";
      if (error) {
        errorMessage = error.message;
      }
      dispatch({
        type: types.GET_USER_INFO_FAILURE,
        errorMessage: errorMessage,
      });
    });
};

export const setPassword = (userName, newPassword) => (dispatch) => {
  dispatch({
    type: types.SET_PASSWORD_REQUEST,
  });
  Auth.completeNewPassword(userName, newPassword)
    .then((res) => {
      dispatch({
        type: types.SET_PASSWORD_SUCCESS,
      });
    })
    .catch((error) => {
      let errorMessage = "";
      if (error) {
        errorMessage = error.message;
      }
      dispatch({
        type: types.SET_PASSWORD_FAILURE,
        errorMessage: errorMessage,
      });
    });
};
