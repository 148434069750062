import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Typography,
  Button,
  Modal,
  Select,
  Radio,
  Icon,
  Checkbox,
  message,
  Upload,
  InputNumber,
  Input,
} from "antd";
import * as API from "utils/api";
import "../ReportModal/ReportModal.scss";
import InputBox from "components/InputBox";
import { UploadOutlined } from "@ant-design/icons";
import SpinBox from "components/SpinBox";

const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

export default function Index({
  isOpen,
  onConfirm,
  onCancel,
  claimantInfo,
  GetAllAssignemetData,
  onFieldChange,
  getFileToAssign,
  onAssignSubmit,
  loading,
  onNoteChange,
  notes,
}) {
  const uploadDatas = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const [getAllFiles, setGetAllFiles] = useState([]);
  const [fileValue, setFileValue] = useState([]);
  const [getAllusers, setGetAllUsers] = useState([]);
  const [selectUser, setSelectUser] = useState("");
  // const [notes, setNotes] = useState("");
  const [l, setLoading] = useState(false);

  const displayUsers = () => {
    API.GetAllUsers().then((res) => {
      setGetAllUsers(res.users);
      console.log(res.users, "users");
    });
  };
  useEffect(() => {
    API.GetReportFiles().then((res) => {
      setGetAllFiles(res.files);
    });
    displayUsers();
  }, []);

  function handleChange(value) {
    setFileValue(value);
    console.log(`Selected: ${value}`);
    console.log(fileValue, "selectinve");
  }
  const children = [];
  for (let i = 0; i < getAllFiles.length; i++) {
    children.push(
      <Option key={i.toString(36) + i} value={getAllFiles[i].id}>
        {getAllFiles[i].name}
      </Option>
    );
  }
  const onSubmit = () => {
    let data = {
      user_id: selectUser,
      claimant_id: claimantInfo.id,
      assignment_type_id: 3,

      request_type: "RA",
      status: 1,
      note: notes,
    };
    setLoading(true);
    API.CreateRateDUser(data).then((res) => {
      console.log(res, "done");
      const assignment_id = res.assignment.id;
      let params = fileValue.map((file_id) => {
        return { assignment_id, file_id };
      });

      API.CreateAssignementFiles(params);
      setLoading(false);
    });
  };

  const styles = {
    paddingBottom: "5px",
  };
  return (
    <div>
      <Modal
        visible={isOpen}
        onCancel={onCancel}
        footer={null}
        centered={true}
        closable={true}
        width={550}
        className="report-modal"
        title={"Request rated age"}
      >
        <SpinBox loading={loading}>
          <Row type="flex" align="middle" className="report-body">
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              className="main-info"
            >
              <Col md={24} className="row">
                <Row style={styles}>
                  {" "}
                  <Text> Users</Text>
                </Row>
                <Select
                  placeholder="Please Select"
                  style={{ width: "100%", zIndex: 10 }}
                  // onChange={(value) => {
                  //   setSelectUser(value);
                  // }}
                  onChange={onFieldChange}
                >
                  {console.log(selectUser, "id selected ")}
                  {getAllusers &&
                    getAllusers.map((user, index) => (
                      <Option key={index} value={user.id}>
                        {user.given_name}
                      </Option>
                    ))}
                </Select>{" "}
              </Col>
              <Col md={24} className="row">
                <Row style={{ marginTop: 5, paddingBottom: 5 }}>
                  <Text>Full name</Text>
                </Row>
                <InputBox
                  // label="Full Name"
                  value={
                    claimantInfo.claimant_name +
                    " " +
                    claimantInfo.claimant_middle_name +
                    " " +
                    claimantInfo.claimant_last_name
                  }
                  disabled
                />
              </Col>
              <Col md={24} className="row">
                <Row style={{ marginTop: 5, paddingBottom: 5 }}>
                  <Text>Date of Birth</Text>
                </Row>
                <InputBox
                  // label="Date of Birth"
                  value={claimantInfo.dob}
                  disabled
                />
              </Col>
              <Col md={24} className="row">
                <Row style={{ marginTop: 5, paddingBottom: 5 }}>
                  {" "}
                  <Text> Notes</Text>
                </Row>
                <TextArea
                  placeholder="Notes "
                  value={notes}
                  onChange={onNoteChange}
                />
              </Col>
              <Col md={24} className="row">
                <Row style={{ marginTop: 5, paddingBottom: 10 }}>
                  {" "}
                  <Text> Files</Text>
                </Row>
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Please select"
                  // onChange={handleChange}
                  onChange={getFileToAssign}
                >
                  {children}
                </Select>
                {/* <Upload {...uploadDatas}>
                <Button>
                  <UploadOutlined />
                  Click to Upload
                </Button>
              </Upload> */}
              </Col>
            </Row>

            <Button
              type="primary"
              size="large"
              className="green-btn button"
              onClick={onAssignSubmit}
            >
              Request
            </Button>
          </Row>
        </SpinBox>
      </Modal>
    </div>
  );
}
