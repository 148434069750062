import React, { useState, useEffect } from "react";
import { Select, Radio } from "antd";
import "./UserRole.scss";
import * as API from "utils/api";

export default function Index({ roleValue, OnRoleChange }) {
  const [GetRoles, setGetRoles] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  useEffect(() => {
    API.getNurseRoles().then((res) => {
      setGetRoles(res.roles);
      console.log(res.roles);
    });
  }, []);
  const { Option } = Select;
  const children = [];
  for (let i = 0; i < GetRoles.length; i++) {
    children.push(
      <Option key={i.toString(36) + i} value={GetRoles[i].id}>
        {GetRoles[i].name}
      </Option>
    );
  }

  function handleChange(value) {
    console.log(`Selected: ${value}`);
    setSelectedValues(value);
    console.log(selectedValues, "select");
  }
  return (
    <div>
      <Select
        mode="multiple"
        placeholder="Please select"
        onChange={OnRoleChange}
        style={{ width: "100%" }}
      >
        {children}
      </Select>
    </div>
  );
}
