import React, { Component } from 'react'
import { Input, Icon } from 'antd';

export default class Password extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isDisalbeEye: true
    }
  }

  render() {
    const { onChange, ...rest  } = this.props;
    const { isDisalbeEye } = this.state;
    const suffix = 
      isDisalbeEye 
      ? <Icon type="eye" theme="outlined" onClick={() => this.setState({isDisalbeEye: false})}/>
      : <Icon type="eye-invisible" theme="outlined" onClick={() => this.setState({isDisalbeEye: true})}/>

    return (
      <Input
        type={isDisalbeEye ? "password" : ""}
        suffix={suffix}
        {...rest}
        onChange={onChange}
      />
    )
  }
}
