import React, { Component } from "react";
import { Row, Col, Typography, Button, Checkbox, Modal } from "antd";

import InputBox from "components/InputBox";
import { BackIcon } from "components/Icons";
import SpinBox from "components/SpinBox";
import * as API from "utils/api";
import EditUserRoles from "../../../components/EditUserRoles/index";
import "./EditUser.scss";

const { Title, Text } = Typography;

export default class EditUser extends Component {
  constructor(props) {
    super(props);

    let userInfo = {};
    if (this.props.location && this.props.location.state) {
      userInfo = this.props.location.state.data;
    }
    this.state = {
      id: userInfo.id,
      given_name: userInfo.given_name,
      family_name: userInfo.family_name,
      email: userInfo.email,
      phone: userInfo.locale,
      restriction_templates: userInfo["custom:restrictions"]
        ? userInfo["custom:restrictions"].includes("templates")
        : false,
      restriction_users: userInfo["custom:restrictions"]
        ? userInfo["custom:restrictions"].includes("users")
        : false,
      restriction_contacts: userInfo["custom:restrictions"]
        ? userInfo["custom:restrictions"].includes("contacts")
        : false,
      loadingTitle: "Loading...",
      loading: false,
    };
  }

  handleSetValue = (type, value) => {
    this.setState({
      [type]: value,
    });
  };

  handleEditUser = () => {
    const {
      given_name,
      family_name,
      email,
      phone,
      restriction_templates,
      restriction_contacts,
      restriction_users,
    } = this.state;

    let restrictions = "";
    if (restriction_templates) restrictions += "templates,";
    if (restriction_contacts) restrictions += "contacts,";
    if (restriction_users) restrictions += "users,";
    if (restrictions === "") restrictions = ",";

    const param = {
      email,
      given_name,
      family_name,
      locale: phone,
      "custom:restrictions": restrictions,
    };

    this.setState({ loadingTitle: "Saving...", loading: true });
    console.log(this.state, this.props);
    API.updateUser(email, param)
      .then((res) => {
        console.log(this.state, this.props);
        API.updateUserSynchronised({
          ...param,
          id: this.state.id,
        }).then((res) => {
          this.setState({ loading: false });
          Modal.success({
            content: "Success",
            onOk: () => this.props.history.goBack(),
          });
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        const errorMessage = error.response.data.reason
          ? error.response.data.reason[0].message
          : "Error";
        Modal.error({
          content: errorMessage,
        });
      });
  };

  render() {
    const {
      given_name,
      family_name,
      email,
      phone,
      restriction_templates,
      restriction_contacts,
      restriction_users,
      loadingTitle,
      loading,
    } = this.state;
    return (
      <Row className="edit-user-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Edit User
            </Title>
          </Col>
          <Col className="back" onClick={() => this.props.history.goBack()}>
            <BackIcon className="icon" />
            <Text>Cancel</Text>
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading} title={loadingTitle}>
            <Row className="content">
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="main-info"
              >
                <Col md={11} className="row">
                  <InputBox
                    label="First Name"
                    value={given_name}
                    onChange={(value) => this.setState({ given_name: value })}
                  />
                </Col>
                <Col md={11} className="row">
                  <InputBox
                    label="Last Name"
                    value={family_name}
                    onChange={(value) => this.setState({ family_name: value })}
                  />
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="main-info"
              >
                <Col md={11} className="row">
                  <InputBox
                    label="Email"
                    value={email}
                    onChange={(value) => this.setState({ email: value })}
                  />
                </Col>
                <Col md={11} className="row">
                  <InputBox
                    label="Phone"
                    value={phone}
                    onChange={(value) => this.setState({ phone: value })}
                  />
                </Col>
              </Row>
              <Row>
                <Row className="row" style={{ marginBottom: "10px" }}>
                  <Text className="checkbox-label">Roles</Text>
                </Row>
                <Row>
                  <Col md={11}>
                    <EditUserRoles user_id={this.state.id} />
                  </Col>
                </Row>
              </Row>
              <Row type="flex" justify="center" className="confirm-button">
                <Button
                  type="primary"
                  size="large"
                  className="green-btn button"
                  onClick={this.handleEditUser}
                >
                  Save Changes
                </Button>
              </Row>
            </Row>
          </SpinBox>
        </Row>
      </Row>
    );
  }
}
