import React, { Component } from 'react';
import moment from "moment";
import { Row, Col, Typography, Input, Empty, Avatar, Button, Modal } from 'antd';

import SpinBox from "components/SpinBox";
import DeleteModal from "components/DeleteModal";
import Pagination from "components/Pagination";
import { EditIcon, RemoveIcon, DetailsIcon } from 'components/Icons';
import * as API from "utils/api";

import "./MyClients.scss";

const { Search } = Input;
const { Title, Text } = Typography;

export default class MyClients extends Component {

  constructor() {
    super();

    this.state = {
      pageIndex: 0,
      showModal: false,
      clientsData: [],
      totalCount: 0,
      selectedClient: {},
      searchQuery: "",
      loadingTitle: "Loading...",
      loading: false,
    }
  }

  componentDidMount() {
    this.handleSearch();
  }

  handlePagination = (pageIndex) => {
    this.setState({
      pageIndex: pageIndex
    }, () => {
      this.handleSearch();
    })
  }

  handleCloseModal = () => {
    this.setState({
      showModal: false
    })
  }

  handleOpenModal = item => {
    this.setState({
      showModal: true,
      selectedClient: item
    })
  }

  handleEditClient = item => {
    this.props.history.push("/app/clients/edit", {"data": item});
  }

  goToClaimantsPage = item => {
    this.props.history.push("/app/claimants", {"data": item});
  }

  handleDeleteClient = item => {
    const params = {
      id: item.id
    }

    this.setState({
      showModal: false,
      loading: true,
      loadingTitle: "Deleting..."
    })
    API.deleteClient(params)
    .then(res => {
      this.setState({loading: false});
      Modal.success({
        content: "Success",
        onOk: () => this.handleSearch()
      })
    })
    .catch(error => {
      this.setState({loading: false});
      const errorMessage = error.response.data.reason ? error.response.data.reason.message : "Error";
      Modal.error({
        content: errorMessage
      })
    });
  }

  handleSearch = () => {
    const { searchQuery, pageIndex } = this.state;
    this.setState({ loading: true, loadingTitle: "Loading..." });
    API.getClientsData(searchQuery, pageIndex)
    .then(res => {
      this.setState({
        clientsData: res.clients,
        totalCount: res.total_count,
        loading: false,
      })
    })
  }

  render() {
    const { pageIndex, totalCount, showModal, selectedClient, clientsData, searchQuery, loadingTitle, loading } = this.state;

    return (
      <Row className="clients-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">My Clients</Title>
          </Col>
          <Col className="search">
            <Search
              placeholder="Search..."
              value={searchQuery}
              onChange={e => this.setState({searchQuery: e.target.value})}
              onSearch={this.handleSearch}
              className="search-box"
            />
          </Col>
          <Col className="pagination">
            <Pagination
              onChange={this.handlePagination}
              totalCount={totalCount}
              pageIndex={pageIndex}
              pageSize={10}
            />
          </Col>
          <Col className="add-button">
            <Button type="primary" className="green-btn" onClick={()=>this.props.history.push("/app/clients/add")}>
              Add New Client
            </Button>
          </Col>
        </Row>
        <Row type="flex" className="table-header">
          <Col md={7}>Client</Col>
          <Col md={7}>Date Added</Col>
          <Col md={5}>Claimants</Col>
          <Col md={5} style={{textAlign: "right", paddingRight: "30px"}}>Actions</Col>
        </Row>
        <Row className="table-content">
          <SpinBox loading={loading} title={loadingTitle}>
          {
            clientsData.length > 0 ?
              <Row>
                {
                  clientsData.map((item, index) => 
                    <Row key={index} className="record">
                      <Col md={7}>
                        <Col className="client-info" onClick={()=>this.goToClaimantsPage(item)}>
                          <Avatar size={48} src={item.client_logo} />
                          <Text className="client-name">{item.client_name}</Text>
                        </Col>
                      </Col>
                      <Col md={7}><Text>{moment(item.createdAt).format("MMMM DD, YYYY")}</Text></Col>
                      <Col md={5}><Text>{item.claimants}</Text></Col>
                      <Col md={5} className="actions">
                        <DetailsIcon className="icon" onClick={()=>this.goToClaimantsPage(item)}/>
                        <EditIcon className="icon" onClick={()=>this.handleEditClient(item)}/>
                        <RemoveIcon className="icon" onClick={()=>this.handleOpenModal(item)}/>
                      </Col>
                    </Row>
                  )
                }
              </Row>
            :
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="empty-icon" />
          }
          </SpinBox>
        </Row>
        <DeleteModal
          isOpen={showModal}
          title="Delete this Client?"
          avatar={selectedClient.client_logo}
          content={selectedClient.client_name}
          onConfirm={()=>this.handleDeleteClient(selectedClient)}
          onCancel={this.handleCloseModal}
        />
      </Row>
    )
  }
}
