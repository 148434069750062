import React, { Component } from "react";
import { Row, Col, Button, Typography } from "antd";
import AuthSideBar from "../../../components/AuthSideBar";
import { CheckSuccessIcon } from "../../../components/Icons";
import "./ConfirmSet.scss";

const { Title, Text } = Typography;

export default class ConfirmSet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
  }

  confirm = () => {
    this.props.history.push("/");
  };

  render() {
    return (
      <Row type="flex" justify="center" className="confirmSet-container">
        <Col xs={0} xl={8}>
          <AuthSideBar />
        </Col>
        <Col xs={24} xl={16}>
          <Row type="flex" justify="center">
            <Col xs={20} md={10}>
              <Row
                type="flex"
                justify="space-around"
                className="main-container"
              >
                <Col style={{ margin: "auto" }}>
                  <Row
                    type="flex"
                    justify="center"
                    align="middle"
                    style={{ flexDirection: "column" }}
                  >
                    <CheckSuccessIcon className="check-icon" />
                    <Title level={1}>Your Account Created</Title>
                    <Text className="label">
                      Now you can sign in using your email and password
                    </Text>
                    <Button
                      size="large"
                      onClick={this.confirm}
                      className="button"
                    >
                      Go to Sign In
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
