import React, { Component } from 'react';
import { Row, Avatar, Typography, Button, Modal } from 'antd';

import { TrashIcon } from 'components/Icons';

import './DeleteModal.scss';

const { Text } = Typography;

export default class DeleteModal extends Component {
  render() {
    const { title, content, avatar, isOpen, onConfirm, onCancel } = this.props;

    return (
      <Modal
        visible={isOpen}
        onCancel={onCancel}
        footer={null}
        centered={true}
        closable={false}
        width={400}
        className="delete-modal"
        title={null}
      >
        <Row type="flex" align="middle" className="delete-body">
          <Row className="trash-icon">
            <TrashIcon />
          </Row>
          <Row>
            <Text className="label">{title}</Text>
          </Row>
          <Row className="content-info">
            {avatar && <Avatar src={avatar} size={48} />}
            <Text className="content-name">{content}</Text>
          </Row>
          <Row>
            <Button type="primary" className="pink-btn button" onClick={onConfirm}>
              Delete
            </Button>
          </Row>
          <Row className="cancel-button">
            <Text onClick={onCancel}>Cancel</Text>
          </Row>
        </Row>
      </Modal>
    )
  }
}
