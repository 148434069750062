import React, { Component } from "react";
import {
  Row,
  Col,
  Typography,
  Input,
  Empty,
  Button,
  Modal,
  Avatar,
} from "antd";

import SpinBox from "components/SpinBox";
import * as API from "utils/api";
import Pagination from "components/Pagination";
import { EditIcon, RemoveIcon, TrashIcon } from "components/Icons";
import "./Contacts.scss";

const { Search } = Input;
const { Title, Text } = Typography;

export default class Contacts extends Component {
  constructor() {
    super();

    this.state = {
      pageIndex: 0,
      showModal: false,
      contactsData: [],
      selectedUser: {},
      searchQuery: "",
      loadingTitle: "Loading...",
      loading: false,
    };
  }

  componentDidMount() {
    this.handleSearch();
  }

  handlePagination = (pageIndex) => {
    this.setState(
      {
        pageIndex: pageIndex,
      },
      () => this.handleSearch()
    );
  };
  handleDeleteContact = (item) => {
    this.setState({
      showModal: false,
      loading: true,
      loadingTitle: "Deleting...",
    });
    API.deleteContact(item.id)
      .then((res) => {
        this.setState({ loading: false });
        Modal.success({
          content: "Success",
          onOk: () => this.handleSearch(),
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        const errorMessage = error.response.data.reason
          ? error.response.data.reason[0].message
          : "Error";
        Modal.error({
          content: errorMessage,
        });
      });
  };
  handleEditContact = (item) => {
    this.props.history.push("/app/contacts/edit", { data: item });
  };
  handleCloseModal = () => {
    this.setState({
      showModal: false,
    });
  };

  handleOpenModal = (item) => {
    this.setState({
      showModal: true,
      selectedUser: item,
    });
  };

  handleSearch = () => {
    const { searchQuery, pageIndex } = this.state;
    this.setState({ loading: true, loadingTitle: "Loading..." });
    API.getContactsData(searchQuery, pageIndex).then((res) => {
      this.setState({
        contactsData: res.contacts,
        totalCount: res.total_count,
        loading: false,
      });
    });
  };

  render() {
    const {
      pageIndex,
      totalCount,
      contactsData,
      loading,
      loadingTitle,
      showModal,
      selectedUser,
    } = this.state;

    return (
      <Row className="contacts-container">
        <Row type="flex" align="middle" className="header">
          <Col className="title">
            <Title level={4} className="title-text">
              Contacts
            </Title>
          </Col>
          <Col className="search">
            <Search
              placeholder="Search..."
              className="search-box"
              onChange={(e) => this.setState({ searchQuery: e.target.value })}
              onSearch={this.handleSearch}
            />
          </Col>
          <Col className="pagination">
            <Pagination
              onChange={this.handlePagination}
              totalCount={totalCount}
              pageIndex={pageIndex}
              pageSize={10}
            />
          </Col>
          <Col className="add-button">
            <Button
              type="primary"
              className="green-btn"
              onClick={() => this.props.history.push("/app/contacts/add")}
            >
              Add Contacts
            </Button>
          </Col>
        </Row>
        <Row type="flex" className="table-header">
          <Col md={5}>Contact Name</Col>
          <Col md={5}>Contact Type</Col>
          <Col md={5}>Organization Name</Col>
          <Col md={6}>Email</Col>
          <Col md={3} style={{ textAlign: "right" }}>
            Actions
          </Col>
        </Row>
        <Row className="table-content">
          <SpinBox loading={loading} title={loadingTitle}>
            {contactsData.length > 0 ? (
              <Row>
                {contactsData.map((item, index) => (
                  <Row key={index} className="record">
                    <Col md={5}>
                      <Text>{item.contact_name}</Text>
                    </Col>
                    <Col md={5}>
                      <Text>
                        {item.contact_type ? item.contact_type.name : ""}
                      </Text>
                    </Col>
                    <Col md={5}>
                      <Text>{item.organisation_name}</Text>
                    </Col>
                    <Col md={5}>
                      <Text>{item.email}</Text>
                    </Col>
                    <Col md={4} className="actions">
                      <Row>
                        <EditIcon
                          className="icon"
                          onClick={() => this.handleEditContact(item)}
                        />
                        <RemoveIcon
                          className="icon"
                          onClick={() => this.handleOpenModal(item)}
                        />
                      </Row>
                    </Col>
                  </Row>
                ))}
              </Row>
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                className="empty-icon"
              />
            )}
          </SpinBox>
        </Row>
        <Modal
          visible={showModal}
          onCancel={this.handleCloseModal}
          footer={null}
          centered={true}
          closable={false}
          width={400}
          className="delete-modal"
          title={null}
        >
          <Row type="flex" align="middle" className="delete-body">
            <Row className="trash-icon">
              <TrashIcon />
            </Row>
            <Row>
              <Text className="label">Delete this contact?</Text>
            </Row>
            <Row type="flex" align="middle" className="user-info">
              <Col className="user-name">
                <Row>
                  <Text ellipsis className="text-row name">
                    {selectedUser.contact_name}
                  </Text>
                </Row>
                <Row>
                  <Text ellipsis className="text-row">
                    {selectedUser.email}
                  </Text>
                </Row>
              </Col>
            </Row>
            <Row>
              <Button
                type="primary"
                className="pink-btn button"
                onClick={() => this.handleDeleteContact(selectedUser)}
              >
                Delete
              </Button>
            </Row>
            <Row className="cancel-button">
              <Text onClick={this.handleCloseModal}>Cancel</Text>
            </Row>
          </Row>
        </Modal>
      </Row>
    );
  }
}
