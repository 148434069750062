import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Typography, Button, Divider } from 'antd';

import SpinBox from "components/SpinBox";
import { BackIcon } from 'components/Icons';
import * as API from 'utils/api';
import { updateReportInfo } from 'redux/report';
import { formatCurrency } from 'utils/utils';

import './CalculationTable.scss';

const { Title, Text } = Typography;

class CalculationTable extends Component {

  constructor(props) {
    super(props);

    let report_id = "";
    if (this.props.location && this.props.location.state) {
      report_id = this.props.location.state.data.report_id;
    }

    this.state = {
      report_id: report_id,
      medicareDataList: [],
      loadingTitle: "Loading",
      loading: false,
    }
  }

  componentDidMount() {
    const { report_id } = this.state;

    this.setState({ loading: true });
    API.getReportById(report_id)
    .then(res => {
      this.props.updateReportInfo(res.report);
    })
    API.getMedicareData(report_id)
    .then(res => {
      this.setState({
        medicareDataList: res.medicare_cost_projections,
        loading: false,
      })
    })
  }

  goToNextStep = () => {
    const { report_id } = this.state;
    this.props.history.push("/app/reports/msa/contactInfo", {data: {report_id: report_id}});
  }

  goToPreviousStep = () => {
    this.props.history.goBack();
  }
  
  render() {
    const {
      medicareDataList,
      loading,
    } = this.state;

    const futureList = medicareDataList.filter(item => {
      return item.type.includes("Future Medical Need covered by Medicare")
    });
    const surgeriesList = medicareDataList.filter(item => {
      return item.type.includes("Surgeries, Replacements & Procedures")
    })
    const prescriptionList = medicareDataList.filter(item => {
      return item.type.includes("Prescription Medications Covered")
    })

    let futureTotal = 0, surgeryTotal = 0, prescriptionTotal = 0, total = 0;

    medicareDataList.forEach(item => {
      if (item.type.includes("Future Medical Need covered by Medicare")) futureTotal += item.annual_cost;
      if (item.type.includes("Surgeries, Replacements & Procedures")) surgeryTotal += item.annual_cost;
      if (item.type.includes("Prescription Medications Covered")) prescriptionTotal += item.annual_cost;
      total += item.annual_cost;
    })

    return (
      <Row className="msa-calculation-table-container">
        <Row type="flex" align="middle" className="header">          
          <Col className="title">
            <Title level={4} className="title-text">           
              Setup MSA Report
            </Title>
          </Col>
          <Col className="back" onClick={()=>this.props.history.goBack()}>
            <BackIcon className="icon"/>
            <Text>Cancel</Text>
          </Col>
          <Col className="step">
            <Text>Step 15 of 17</Text>
          </Col>
        </Row>
        <Row className="main">
          <SpinBox loading={loading}>
            <Row className="content">
              <Row type="flex" justify="space-between" align="middle">
                <Col md={24}>
                  <Text className="title">MSA Calculation Table</Text>
                </Col>
              </Row>        
              <Row>
                <Row type="flex" className="table-header">
                  <Col md={8}>Service</Col>
                  <Col md={2}>Frequency</Col>
                  <Col md={3}>Every X Years</Col>
                  <Col md={3}>Number of Years</Col>
                  <Col md={4}>Price Per Service</Col>
                  <Col md={4}>Total</Col>
                </Row>
                <Row className="table-content">
                  <Row className="section-title">
                    <Text>Periodic Items and Services</Text>
                  </Row>
                  <Row>
                    {
                      futureList.map((item, index) => 
                        <Row key={index} className="record">
                          <Col md={8} className="text-left"><Text>{item.name}</Text></Col>
                          <Col md={2}><Text>{item.units_needed}</Text></Col>
                          <Col md={3}><Text>{item.every_x_year}</Text></Col>
                          <Col md={3}><Text>{item.total_years}</Text></Col>
                          <Col md={4} className="text-right"><Text>{formatCurrency(item.total_cost)}</Text></Col>
                          <Col md={4} className="text-right"><Text>{formatCurrency(item.annual_cost)}</Text></Col>
                        </Row>
                      )
                    }
                  </Row>
                  <Row className="record">
                    <Col md={20} className="text-left"><Text>Sub Total</Text></Col>
                    <Col md={4} className="text-right"><Text>{formatCurrency(futureTotal)}</Text></Col>
                  </Row>
                  <Row className="section-title">
                    <Text>Surgeries, Replacements and Procedures</Text>
                  </Row>
                  <Row>
                    {
                      surgeriesList.map((item, index) => 
                        <Row key={index} className="record">
                          <Col md={8} className="text-left"><Text>{item.name}</Text></Col>
                          <Col md={2}><Text>{item.units_needed}</Text></Col>
                          <Col md={3}><Text>{item.every_x_year}</Text></Col>
                          <Col md={3}><Text>{item.total_years}</Text></Col>
                          <Col md={4} className="text-right"><Text>{formatCurrency(item.total_cost)}</Text></Col>
                          <Col md={4} className="text-right"><Text>{formatCurrency(item.annual_cost)}</Text></Col>
                        </Row>
                      )
                    }
                  </Row>
                  <Row className="record">
                    <Col md={20} className="text-left"><Text>Sub Total</Text></Col>
                    <Col md={4} className="text-right"><Text>{formatCurrency(surgeryTotal)}</Text></Col>
                  </Row>
                  <Row className="section-title">
                    <Text>Prescriptions</Text>
                  </Row>
                  <Row>
                    {
                      prescriptionList.map((item, index) => 
                        <Row key={index} className="record">
                          <Col md={8} className="text-left"><Text>{item.prescriptions}</Text></Col>
                          <Col md={2}><Text>{item.units_needed}</Text></Col>
                          <Col md={3}><Text>{item.every_x_year}</Text></Col>
                          <Col md={3}><Text>{item.total_years}</Text></Col>
                          <Col md={4} className="text-right"><Text>{formatCurrency(item.total_cost)}</Text></Col>
                          <Col md={4} className="text-right"><Text>{formatCurrency(item.annual_cost)}</Text></Col>
                        </Row>
                      )
                    }
                  </Row>
                  <Row className="record">
                    <Col md={20} className="text-left"><Text>Sub Total</Text></Col>
                    <Col md={4} className="text-right"><Text>{formatCurrency(prescriptionTotal)}</Text></Col>
                  </Row>
                  <Row className="record">
                    <Col md={20} className="text-left"><Text>Total</Text></Col>
                    <Col md={4} className="text-right"><Text>{formatCurrency(total)}</Text></Col>
                  </Row>
                </Row>
              </Row>
              <Divider className="divider"/>
              <Row type="flex" justify="center" align="middle" className="confirm-button">
                <Button type="link" className="prev-button button" onClick={this.goToPreviousStep}>
                  &lt; PREVIOUS STEP
                </Button> 
                <Button type="primary" size="large" className="green-btn button" onClick={this.goToNextStep}>
                  Next Step
                </Button>              
              </Row>
            </Row>
          </SpinBox>
        </Row>
      </Row>
    )
  }
}

const mapStateToProps = state => {
  const props = {
    autoSavingTime: state.report.autoSavingTime,
    reportInfo: state.report.reportInfo,
  };
  return props;
}

export default connect(mapStateToProps, {updateReportInfo})(CalculationTable);